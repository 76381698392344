import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Applied from './Applied';
import { Hidden } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import LeadsIcon from '@material-ui/icons/FormatListBulletedRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(MuiStyleFunction);

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const Settings = (props) => {
  const classes = useStyles();

  const { userData } = useContext(AuthContext);
  const { studies } = useContext(StudiesContext);

  const [applications, setApplications] = useState([]);
  const [settingTab, setSettingTab] = useState(1);
  const [openTabsMobileMenu, setOpenTabsMobileMenu] = useState(false);

  // navigateToStudies = () => {
  //   this.props.main.setState({currentPage: 1})
  // }

  const openLinkInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  const getNumberOfApplicationInSpecificStage = (currentTab) => {
    let applications = props.data.applications;
    let relevants = [];
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const status = application.status[application.status.length - 1].status;
      if (!application.userData) continue;
      if (currentTab === 'Applied') {
        if (
          status === 'Applied' ||
          status === 'Different Date Needed' ||
          status === 'Schedule Appointment'
        ) {
          relevants.push(application);
        }
      } else if (currentTab === 'Pending') {
        if (status === 'Pending') {
          relevants.push(application);
        }
      } else if (currentTab === 'Scheduled') {
        if (status === 'Confirmed Appointment') {
          relevants.push(application);
        }
      } else if (currentTab === 'Failed') {
        if (status === 'Not Eligible') {
          relevants.push(application);
        }
      } else if (currentTab === 'Eligible') {
        if (status === 'Completed') {
          relevants.push(application);
        }
      } else if (currentTab === 'Post Call') {
        if (status === 'TBD') {
          relevants.push(application);
        }
      }
    }
    return relevants.length;
  };

  const getNotificationsNumber = (currentTab) => {
    let applications = props.data.applications;
    let relevants = [];
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const status = application.status[application.status.length - 1].status;
      if (!application.userData) continue;
      if (currentTab === 'Applied') {
        if (
          status === 'Applied' ||
          status === 'Different Date Needed' ||
          status === 'Schedule Appointment'
        ) {
          if (!application.reviewed) {
            relevants.push(application);
          }
        }
      } else if (currentTab === 'Pending') {
        if (status === 'Pending') {
          relevants.push(application);
        }
      } else if (currentTab === 'Scheduled') {
        if (status === 'Confirmed Appointment') {
          relevants.push(application);
        }
      } else if (currentTab === 'Failed') {
        if (status === 'Not Eligible' || status === 'Failed') {
          relevants.push(application);
        }
      } else if (currentTab === 'Eligible') {
        if (status === 'Completed') {
          relevants.push(application);
        }
      } else if (currentTab === 'Post Call') {
        if (status === 'TBD') {
          relevants.push(application);
        }
      }
    }
    return relevants.length;
  };

  const getTabName = () => {
    if (settingTab === 1) return 'Applied';
    if (settingTab === 2) return 'Pending';
    if (settingTab === 3) return 'Scheduled';
    if (settingTab === 4) return 'Post Call';
    if (settingTab === 5) return 'Completed';
    if (settingTab === 6) return 'Failed';
  };

  const getTopSize = () => {
    if (
      window.document.getElementById('studyTitle') &&
      window.document.getElementById('studyTitle').offsetHeight > 50
    ) {
      return 198 + 46;
    } else {
      return 198;
    }
  };

  return (
    <div>
      {/* <div style={{overflow: 'hidden'}}> */}
      <Drawer
        anchor={'bottom'}
        open={openTabsMobileMenu}
        onClose={() => setOpenTabsMobileMenu(false)}
        style={{ display: 'relative', zIndex: 9999999 }}
      >
        <div style={{ padding: 16, position: 'relative' }}>
          <IconButton
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => setOpenTabsMobileMenu(false)}
          >
            <CloseRoundedIcon />
          </IconButton>
          <Typography
            variant="h2"
            style={{
              fontSize: 22,
              fontWeight: 800,
              marginBottom: 38,
              marginTop: 32,
            }}
          >
            Select which leads you would like to view
          </Typography>
          <div
            onClick={() => {
              setSettingTab(1);
              setOpenTabsMobileMenu(false);
            }}
            className={
              settingTab === 1 ? classes.selectedSettingTab : classes.settingTab
            }
          >
            <Typography
              variant="h2"
              className={
                settingTab === 1
                  ? classes.selectedSettingTabTitle
                  : classes.settingTabTitle
              }
            >
              Applied
            </Typography>
            {getNotificationsNumber('Applied') !== 0 && (
              <div className={classes.notification}>
                {getNotificationsNumber('Applied')}
              </div>
            )}
            {getNumberOfApplicationInSpecificStage('Applied') !== 0 && (
              <div className={classes.totalApplicationsTag}>
                {getNumberOfApplicationInSpecificStage('Applied')}
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setSettingTab(2);
              setOpenTabsMobileMenu(false);
            }}
            className={
              settingTab === 2 ? classes.selectedSettingTab : classes.settingTab
            }
          >
            <Typography
              variant="h2"
              className={
                settingTab === 2
                  ? classes.selectedSettingTabTitle
                  : classes.settingTabTitle
              }
            >
              Pending
            </Typography>
            {getNumberOfApplicationInSpecificStage('Pending') !== 0 && (
              <div className={classes.totalApplicationsTag}>
                {getNotificationsNumber('Pending')}
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setSettingTab(3);
              setOpenTabsMobileMenu(false);
            }}
            className={
              settingTab === 3 ? classes.selectedSettingTab : classes.settingTab
            }
          >
            <Typography
              variant="h2"
              className={
                settingTab === 3
                  ? classes.selectedSettingTabTitle
                  : classes.settingTabTitle
              }
            >
              Scheduled
            </Typography>
            {getNumberOfApplicationInSpecificStage('Scheduled') !== 0 && (
              <div className={classes.totalApplicationsTag}>
                {getNotificationsNumber('Scheduled')}
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setSettingTab(4);
              setOpenTabsMobileMenu(false);
            }}
            className={
              settingTab === 4 ? classes.selectedSettingTab : classes.settingTab
            }
          >
            <Typography
              variant="h2"
              className={
                settingTab === 4
                  ? classes.selectedSettingTabTitle
                  : classes.settingTabTitle
              }
            >
              Post Call
            </Typography>
            {getNumberOfApplicationInSpecificStage('Post Call') !== 0 && (
              <div className={classes.totalApplicationsTag}>
                {getNotificationsNumber('Post Call')}
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setSettingTab(5);
              setOpenTabsMobileMenu(false);
            }}
            className={
              settingTab === 5 ? classes.selectedSettingTab : classes.settingTab
            }
          >
            <Typography
              variant="h2"
              className={
                settingTab === 5
                  ? classes.selectedSettingTabTitle
                  : classes.settingTabTitle
              }
            >
              Completed
            </Typography>
            {getNumberOfApplicationInSpecificStage('Completed') !== 0 && (
              <div className={classes.totalApplicationsTag}>
                {getNotificationsNumber('Completed')}
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setSettingTab(6);
              setOpenTabsMobileMenu(false);
            }}
            className={
              settingTab === 6 ? classes.selectedSettingTab : classes.settingTab
            }
          >
            <Typography
              variant="h2"
              className={
                settingTab === 6
                  ? classes.selectedSettingTabTitle
                  : classes.settingTabTitle
              }
            >
              Failed
            </Typography>
            {getNumberOfApplicationInSpecificStage('Failed') !== 0 && (
              <div className={classes.totalApplicationsTag}>
                {getNotificationsNumber('Failed')}
              </div>
            )}
          </div>
        </div>
      </Drawer>
      <div
        style={{
          paddingTop: mobile ? 0 : 30,
          width: '100%',
          paddingBottom: 90,
        }}
      >
        {/* <Grid container direction="row" spacing={8} style={{paddingTop: mobile ? 0 : 0}}>
            <Grid item xs={12} sm={12} md={12} xl={12}> */}
        <Hidden smDown>
          <Stack direction="row" spacing={2} style={{ marginTop: -40 }}>
            <Stack
              spacing={2}
              style={{
                width: 300,
                minHeight: 600,
                position: 'fixed',
                top: getTopSize(),
              }}
            >
              <div
                onClick={() => setSettingTab(1)}
                className={
                  settingTab === 1
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 1
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Applied
                </Typography>
                {getNotificationsNumber('Applied') !== 0 && (
                  <div className={classes.notification}>
                    {getNotificationsNumber('Applied')}
                  </div>
                )}
                {getNumberOfApplicationInSpecificStage('Applied') !== 0 && (
                  <div className={classes.totalApplicationsTag}>
                    {getNumberOfApplicationInSpecificStage('Applied')}
                  </div>
                )}
              </div>
              <div
                onClick={() => setSettingTab(2)}
                className={
                  settingTab === 2
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 2
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Pending
                </Typography>
                {getNumberOfApplicationInSpecificStage('Pending') !== 0 && (
                  <div className={classes.totalApplicationsTag}>
                    {getNotificationsNumber('Pending')}
                  </div>
                )}
              </div>
              <div
                onClick={() => setSettingTab(3)}
                className={
                  settingTab === 3
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 3
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Scheduled
                </Typography>
                {getNumberOfApplicationInSpecificStage('Scheduled') !== 0 && (
                  <div className={classes.totalApplicationsTag}>
                    {getNotificationsNumber('Scheduled')}
                  </div>
                )}
              </div>
              <div
                onClick={() => setSettingTab(4)}
                className={
                  settingTab === 4
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 4
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Post Call
                </Typography>
                {getNumberOfApplicationInSpecificStage('Post Call') !== 0 && (
                  <div className={classes.totalApplicationsTag}>
                    {getNotificationsNumber('Post Call')}
                  </div>
                )}
              </div>
              <div
                onClick={() => setSettingTab(5)}
                className={
                  settingTab === 5
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 5
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Completed
                </Typography>
                {getNumberOfApplicationInSpecificStage('Completed') !== 0 && (
                  <div className={classes.totalApplicationsTag}>
                    {getNotificationsNumber('Completed')}
                  </div>
                )}
              </div>
              <div
                onClick={() => setSettingTab(6)}
                className={
                  settingTab === 6
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 6
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Failed
                </Typography>
                {getNumberOfApplicationInSpecificStage('Failed') !== 0 && (
                  <div className={classes.totalApplicationsTag}>
                    {getNotificationsNumber('Failed')}
                  </div>
                )}
              </div>
            </Stack>
            <Stack spacing={2} style={{ width: '100%', marginLeft: 336 }}>
              {settingTab === 1 && (
                <Applied
                  tab={'Applied'}
                  study={props.data}
                  getNumberOfApplicationInSpecificStage={
                    getNumberOfApplicationInSpecificStage
                  }
                  getNotificationsNumber={getNotificationsNumber}
                />
              )}
              {settingTab === 2 && (
                <Applied
                  tab={'Pending'}
                  study={props.data}
                  getNumberOfApplicationInSpecificStage={
                    getNumberOfApplicationInSpecificStage
                  }
                  getNotificationsNumber={getNotificationsNumber}
                />
              )}
              {settingTab === 3 && (
                <Applied
                  tab={'Scheduled'}
                  study={props.data}
                  getNumberOfApplicationInSpecificStage={
                    getNumberOfApplicationInSpecificStage
                  }
                  getNotificationsNumber={getNotificationsNumber}
                />
              )}
              {settingTab === 4 && (
                <Applied
                  tab={'Post Call'}
                  study={props.data}
                  getNumberOfApplicationInSpecificStage={
                    getNumberOfApplicationInSpecificStage
                  }
                  getNotificationsNumber={getNotificationsNumber}
                />
              )}
              {settingTab === 5 && (
                <Applied
                  tab={'Eligible'}
                  study={props.data}
                  getNumberOfApplicationInSpecificStage={
                    getNumberOfApplicationInSpecificStage
                  }
                  getNotificationsNumber={getNotificationsNumber}
                />
              )}
              {settingTab === 6 && (
                <Applied
                  tab={'Failed'}
                  study={props.data}
                  getNumberOfApplicationInSpecificStage={
                    getNumberOfApplicationInSpecificStage
                  }
                  getNotificationsNumber={getNotificationsNumber}
                />
              )}
            </Stack>
          </Stack>
        </Hidden>
        <Hidden mdUp>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: -12,
                }}
              >
                <Typography
                  className={classes.mobileTitle}
                  style={{ flexGrow: 0 }}
                  variant="h2"
                >
                  Inquiries
                </Typography>
                {/* {getNumberOfApplicationInSpecificStage(getTabName()) !== 0 && (
                        <div className={classes.totalApplicationsTag}>{getNotificationsNumber(getTabName())}</div>
                      )} */}
                {getNotificationsNumber(getTabName()) !== 0 && (
                  <div
                    className={classes.notification}
                    style={{ marginLeft: 8, marginTop: 4 }}
                  >
                    {getNotificationsNumber(getTabName())}
                  </div>
                )}
                {getNumberOfApplicationInSpecificStage(getTabName()) !== 0 && (
                  <div
                    className={classes.totalApplicationsTag}
                    style={{ marginTop: 4 }}
                  >
                    {getNumberOfApplicationInSpecificStage(getTabName())}
                  </div>
                )}
                <div style={{ flexGrow: 1 }}></div>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <Typography
                    style={{ fontSize: 14, color: '#4A4A4A' }}
                    variant="h2"
                  >
                    {getTabName()}
                  </Typography>
                  <IconButton onClick={() => setOpenTabsMobileMenu(true)}>
                    <LeadsIcon />
                  </IconButton>
                </div>
              </div>
              {settingTab === 1 && (
                <Applied tab={'Applied'} study={props.data} />
              )}
              {settingTab === 2 && (
                <Applied tab={'Pending'} study={props.data} />
              )}
              {settingTab === 3 && (
                <Applied tab={'Scheduled'} study={props.data} />
              )}
              {settingTab === 4 && (
                <Applied tab={'Post Call'} study={props.data} />
              )}
              {settingTab === 5 && (
                <Applied tab={'Eligible'} study={props.data} />
              )}
              {settingTab === 6 && (
                <Applied tab={'Failed'} study={props.data} />
              )}
            </Grid>
          </Grid>
        </Hidden>
        {/* </Grid> */}
        {/* </Grid> */}
      </div>
    </div>
  );
};

export default React.memo(Settings);
