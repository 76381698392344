import React, { useCallback, useEffect, useState } from 'react';
import { LineChart } from '@opd/g2plot-react';
import { LineConfig } from '@antv/g2plot';
import moment from 'moment';

export default ({ study, whatLeadsToShowInChart, leads, deals }) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfing] = useState(null);

  useEffect(() => {
    if (study) {
      let month1 = 0;
      let month2 = 0;
      let month3 = 0;
      let month4 = 0;
      let month5 = 0;
      let applications = study.applications;

      let now = moment(new Date().getTime());
      let nowMinus1M = moment(new Date().getTime() - 2629743 * 1 * 1000);
      let nowMinus2M = moment(new Date().getTime() - 2629743 * 2 * 1000);
      let nowMinus3M = moment(new Date().getTime() - 2629743 * 3 * 1000);
      let nowMinus4M = moment(new Date().getTime() - 2629743 * 4 * 1000);
      let appliedAt;

      if (whatLeadsToShowInChart === 'Leads') {
        const leadsAndDeals = [...leads, ...deals];
        for (let index = 0; index < leadsAndDeals.length; index++) {
          const application = leadsAndDeals[index];
          now = moment(new Date().getTime());
          appliedAt = moment(application.created_at);
          if (
            now.format('YYYY') === appliedAt.format('YYYY') &&
            now.format('MMM') === appliedAt.format('MMM')
          ) {
            month1 += 1;
          }
          if (
            nowMinus1M.format('YYYY') === appliedAt.format('YYYY') &&
            nowMinus1M.format('MMM') === appliedAt.format('MMM')
          ) {
            month2 += 1;
          }
          if (
            nowMinus2M.format('YYYY') === appliedAt.format('YYYY') &&
            nowMinus2M.format('MMM') === appliedAt.format('MMM')
          ) {
            month3 += 1;
          }
          if (
            nowMinus3M.format('YYYY') === appliedAt.format('YYYY') &&
            nowMinus3M.format('MMM') === appliedAt.format('MMM')
          ) {
            month4 += 1;
          }
          if (
            nowMinus4M.format('YYYY') === appliedAt.format('YYYY') &&
            nowMinus4M.format('MMM') === appliedAt.format('MMM')
          ) {
            month5 += 1;
          }
        }
        const configData = {
          height: 280,
          title: {
            visible: false,
            text: '配置折线数据点样式',
          },
          description: {
            visible: false,
            text: '自定义配置趋势线上数据点的样式',
          },
          padding: 'auto',
          forceFit: true,
          xField: 'value',
          yField: 'leads',
          color: '#4fb4e8',
          label: {
            visible: true,
            type: 'point',
          },
          point: {
            visible: true,
            size: 5,
          },
          xAxis: {
            tickCount: 10,
          },
          data: [
            { leads: month5, value: nowMinus4M.format('MMM') },
            { leads: month4, value: nowMinus3M.format('MMM') },
            { leads: month3, value: nowMinus2M.format('MMM') },
            { leads: month2, value: nowMinus1M.format('MMM') },
            { leads: month1, value: now.format('MMM') },
          ],
        };
        setConfing(configData);
        setLoading(false);
        return;
      }

      for (let index = 0; index < applications.length; index++) {
        const application = applications[index];
        now = moment(new Date().getTime());
        appliedAt = moment(application.appliedAt.seconds * 1000);
        if (
          whatLeadsToShowInChart === 'Completed' &&
          application.status[application.status.length - 1].status !==
            'Completed'
        ) {
          continue;
        }
        if (
          now.format('YYYY') === appliedAt.format('YYYY') &&
          now.format('MMM') === appliedAt.format('MMM')
        ) {
          month1 += 1;
        }
        if (
          nowMinus1M.format('YYYY') === appliedAt.format('YYYY') &&
          nowMinus1M.format('MMM') === appliedAt.format('MMM')
        ) {
          month2 += 1;
        }
        if (
          nowMinus2M.format('YYYY') === appliedAt.format('YYYY') &&
          nowMinus2M.format('MMM') === appliedAt.format('MMM')
        ) {
          month3 += 1;
        }
        if (
          nowMinus3M.format('YYYY') === appliedAt.format('YYYY') &&
          nowMinus3M.format('MMM') === appliedAt.format('MMM')
        ) {
          month4 += 1;
        }
        if (
          nowMinus4M.format('YYYY') === appliedAt.format('YYYY') &&
          nowMinus4M.format('MMM') === appliedAt.format('MMM')
        ) {
          month5 += 1;
        }
      }
      const configData = {
        height: 280,
        title: {
          visible: false,
          text: '配置折线数据点样式',
        },
        description: {
          visible: false,
          text: '自定义配置趋势线上数据点的样式',
        },
        padding: 'auto',
        forceFit: true,
        xField: 'value',
        yField: 'leads',
        color: '#4fb4e8',
        label: {
          visible: true,
          type: 'point',
        },
        point: {
          visible: true,
          size: 5,
        },
        xAxis: {
          tickCount: 10,
        },
        data: [
          { leads: month5, value: nowMinus4M.format('MMM') },
          { leads: month4, value: nowMinus3M.format('MMM') },
          { leads: month3, value: nowMinus2M.format('MMM') },
          { leads: month2, value: nowMinus1M.format('MMM') },
          { leads: month1, value: now.format('MMM') },
        ],
      };
      setConfing(configData);
      setLoading(false);
    }
  }, [whatLeadsToShowInChart, leads, deals]);

  const handleChartMount = useCallback((chart) => {}, []);
  return (
    <div>
      {!loading && <LineChart {...config} onMount={handleChartMount} />}
    </div>
  );
};
