import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Sponsored.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from './Card';
import SkeletonCard from './SkeletonCard';
import StudyService from '../../../Services/StudyService/StudyService';

const useStyles = makeStyles(MuiStyleFunction);

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const Home = (props) => {
  const classes = useStyles();

  const { userData, userType } = useContext(AuthContext);
  const { studies, getStudies } = useContext(StudiesContext);

  useEffect(() => {
    if (userType === 'center') {
      if (studies.length > 0) {
        let allEmpty = true;
        for (let index = 0; index < studies.length; index++) {
          const study = studies[index];
          if (study.applications.length !== 0) {
            allEmpty = false;
          }
        }
        if (allEmpty) {
          // setOpenNotActivatedPopup(true)
        }
      }
    }
  }, [studies]);

  const updateStudies = async (ref, data) => {
    await StudyService.updateStudyByRef(ref, data);
    getStudies();
  };

  return (
    <div>
      <Container disableGutters maxWidth="lg">
        <div
          style={{
            paddingTop: mobile ? 0 : 30,
            width: '100%',
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 90,
          }}
        >
          {mobile && (
            <Typography className={classes.mobileTitle} variant="h2">
              My Studies
            </Typography>
          )}
          {!mobile && (
            <div style={{ width: '100%', height: 70 }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <Typography className={classes.pageTitle} variant="h2">
                    {userData.firstName} {userData.lastName}
                  </Typography>
                </div>
              </div>
              {userData.type === 'center' ? (
                <Typography className={classes.adminLabel} variant="h2">
                  {studies.length > 0 &&
                  studies[0].applications.length > 0 &&
                  studies[0].applications[0].nearestLocationData
                    ? studies[0].applications[0].studyLocation
                    : ''}
                </Typography>
              ) : (
                <Typography className={classes.adminLabel} variant="h2">
                  {studies.length > 0 && studies[0].companyName}
                </Typography>
              )}
            </div>
          )}
          {!mobile && (
            <div>
              <Divider
                style={{
                  marginTop: 30,
                  marginBottom: 30,
                  height: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.14)',
                }}
              />
            </div>
          )}
          {studies.length > 0 && (
            <Grid
              container
              direction="row"
              spacing={8}
              style={{ paddingTop: mobile ? 0 : 0 }}
            >
              {!mobile ? (
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Grid container direction="row" spacing={4}>
                    {studies
                      .filter((s) => s.studyData)
                      .map((item, i) => (
                        <Card
                          key={i}
                          i={i}
                          data={item}
                          updateStudies={updateStudies}
                        />
                      ))}
                    {studies.length === 0 && <SkeletonCard />}
                    {studies.length === 0 && <SkeletonCard />}
                    {studies.length === 0 && <SkeletonCard />}
                    {studies.length === 0 && <SkeletonCard />}
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Grid container direction="row" spacing={2}>
                    {studies.map((item, i) => (
                      <Card
                        key={i}
                        i={i}
                        data={item}
                        updateStudies={updateStudies}
                      />
                    ))}
                    {studies.length === 0 && <SkeletonCard />}
                    {studies.length === 0 && <SkeletonCard />}
                    {studies.length === 0 && <SkeletonCard />}
                    {studies.length === 0 && <SkeletonCard />}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </Container>
    </div>
  );
};

export default React.memo(Home);
