const font1 = "'Sofia Pro'";
const font2 = "'Alice', serif";
const font3 = "'sofia-pro', sans-serif";

export default {
  typography: {
    fontFamily: font2,
    main: font3,
    h2: {
      fontFamily: font3,
      fontWeight: 900,
      fontSize: 48,
    },
    button: {
      fontFamily: font3,
    },
    outlined: {
      fontFamily: font3,
    },
    h4: {
      fontFamily: font3,
      fontWeight: 'bold',
    },
    input: {
      fontFamily: font3,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        fontFamily: font3,
      },
      // label: {
      //     fontFamily: font3
      // }
    },
    MuiInputLabel: {
      root: {
        fontFamily: font3,
      },
      label: {
        fontFamily: font3,
      },
    },
  },
  direction: 'ltr',
  palette: {
    background: {
      default: 'white',
    },
    primary: {
      main: '#4fb4e8',
      secondary: '#ed3636',
      white: '#ed3636',
      dark: '#4A4A4A',
      gray: 'rgba(0, 0, 0, 0.6)',
    },
    secondary: {
      main: '#ed3636',
    },
  },
};
