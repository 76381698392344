import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/material';
import Lottie from 'lottie-react';

export default function AcceptedInvitationDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <Stack direction={'row'} justifyContent={'center'}>
          <Lottie
            animationData={require('../../Assets/Lotties/confirmation.json')}
            loop={true}
            style={{
              height: 200,
              width: 200,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Stack>
        <DialogTitle>
          Thank You for Signing up to Receive Patient Inquiries from Know Rare
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            You will be notified in the future about patient inquiries as they
            come in via email from the Know Rare team or by signing into the
            platform
          </DialogContentText>
          <DialogContentText sx={{ fontSize: 14, mt: 3 }}>
            Please note there may not be any patient inquiries for you at this
            time, but you can still preview the platform now.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close & Sign in</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
