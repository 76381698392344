import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import StudyService from '../../../Services/StudyService/StudyService';
import firebase from '../../../Firebase.js';
import { StudiesContext } from '../../../contexts/Studies';
import { SnackbarContext } from '../../../contexts/Snackbar';
import Loading from '../../../Components/Loading/LoadingFullScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const options = ['Applied', 'Pending', 'Scheduled', 'Failed', 'Completed'];

export default function SimpleListMenu({
  center,
  study,
  renameStatus,
  application,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [studyCenters, setStudyCenters] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { getStudies, studies, setStudies, loading } =
    React.useContext(StudiesContext);
  const { show } = React.useContext(SnackbarContext);

  useEffect(() => {
    setStudyCenters(study.allStudyCenters);
    for (let index = 0; index < study.allStudyCenters.length; index++) {
      const studyCenter = study.allStudyCenters[index];
      if (center === studyCenter.data().LocationFacility) {
        setSelectedIndex(index);
      }
    }
  }, []);

  const getStyleForStatus = (status) => {
    return {
      cursor: 'pointer',
      fontFamily: "'sofia-pro', sans-serif",
      fontWeight: 'bold',
      fontSize: 12,
      borderRadius: 8,
      backgroundColor: '#DBDBDB',
      color: '#4A4A4A',
      textAlign: 'center',
      padding: 12,
    };
  };

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    let newStudyCenter = studyCenters[index];
    let newStudyCenterData = newStudyCenter.data();
    let applicationId = application.id;
    let oldApplicationRef = application.doc.ref;
    let newApplication = application;
    delete newApplication.doc;
    // delete newApplication.userData;
    // delete newApplication.id;
    newApplication.studyLocation = newStudyCenterData.LocationFacility;
    newApplication.studyAddress = newStudyCenterData.LocationAddress;

    // Add application under new center with the same applicationId
    let newApplicationCreated = await newStudyCenter.ref
      .collection('applications')
      .doc(applicationId)
      .set(newApplication);
    // Remove the application from the old center
    await oldApplicationRef.delete();
    // NEW
    let updatedStudiesArray = studies;
    for (let index = 0; index < updatedStudiesArray.length; index++) {
      let currentStudy = updatedStudiesArray[index];
      if (currentStudy.document.id === study.document.id) {
        for (
          let applicationIndex = 0;
          applicationIndex < currentStudy.applications.length;
          applicationIndex++
        ) {
          let application = currentStudy.applications[applicationIndex];
          if (application.id === applicationId) {
            let newApplicationToReplace = newApplication;
            newApplicationToReplace.id = applicationId;
            currentStudy.applications[applicationIndex] =
              newApplicationToReplace;
            updatedStudiesArray[index] = currentStudy;
            setStudies([...updatedStudiesArray]);
            show('success', 'Saved');
          }
        }
      }
    }
    // END NEW
    // getStudies()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      {loading && <Loading />}
      {studyCenters && studyCenters.length > 0 && (
        <div>
          <div
            style={getStyleForStatus('Applied')}
            onClick={handleClickListItem}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {studyCenters[selectedIndex].data().LocationFacility}{' '}
              <ExpandMoreRoundedIcon style={{ marginLeft: 4 }} />
            </div>
          </div>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {studyCenters.map((option, index) => (
              <MenuItem
                key={index}
                disabled={index === selectedIndex}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                <div
                  style={getStyleForStatus('Apply')}
                  onClick={handleClickListItem}
                >
                  {option.data().LocationFacility}
                </div>
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
}
