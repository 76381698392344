import axios from 'axios';

const BASE = 'https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net';

const instance = axios.create({
  baseURL: BASE,
  headers: {
    'Content-type': 'application/json',
  },
});

export async function request(path, config) {
  return instance(path, {
    ...config,
  });
}

export async function get(path, config) {
  return request(path, { ...config, method: 'get' });
}

export async function post(path, params, config) {
  return request(path, {
    ...config,
    method: 'post',
    data: JSON.stringify(params),
  });
}

export async function put(path, params, config) {
  return request(path, {
    ...config,
    method: 'put',
    data: JSON.stringify(params),
  });
}
