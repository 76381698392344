import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import StudyService from '../../../Services/StudyService/StudyService';
import { SnackbarContext } from '../../../contexts/Snackbar';
import EmailService from '../../../Services/EmailService/EmailService';

const useStyles = makeStyles(MuiStyleFunction);

const Status = ({ data, updateMember, getTeam }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const select = (ref, data) => {
    updateMember(ref, data);
    setAnchorEl(null);
  };

  const deleteTeamMember = async (data) => {
    setAnchorEl(null);
    await StudyService.deleteByRef(data.memberDoc.ref);
    getTeam();
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
        }}
        className={classes.roleBox}
        onClick={handleClick}
      >
        <Typography variant="h4" className={classes.role}>
          {data.memberData.role}
        </Typography>
        <img
          alt=""
          className={classes.status}
          src={require('../../../Assets/Images/blackArrowDown.png')}
          width={8.41}
          height={9.13}
          style={{ marginLeft: 5, marginTop: 3 }}
        />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => select(data.memberDoc.ref, { role: 'Admin' })}
          value={10}
          className={classes.roleMenuItem}
        >
          Admin
        </MenuItem>
        <MenuItem
          onClick={() => select(data.memberDoc.ref, { role: 'Viewer' })}
          value={20}
          className={classes.roleMenuItem}
        >
          Viewer
        </MenuItem>
        <MenuItem
          onClick={() => deleteTeamMember(data)}
          value={30}
          className={classes.roleMenuItemRemove}
        >
          Remove
        </MenuItem>
      </Menu>
    </div>
  );
};

const AccountDetails = (props) => {
  const classes = useStyles();

  const { userData } = useContext(AuthContext);

  const { show } = useContext(SnackbarContext);

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const copyInvitationLink = () => {
    let link = `https://connect.knowrare.com/invitation/${props.data.memberData.invitationId}`;
    navigator.clipboard.writeText(link);
    show('success', 'Copied to clipboard');
  };

  const remind = () => {
    EmailService.centerReminder(
      props.data.memberData.email,
      props.data.memberData.invitationId,
      '',
      props.studyName,
      props.companyName,
    );
    show('success', 'Reminder sent to the member');
  };

  const remove = async () => {
    await StudyService.deleteByRef(props.data.memberDoc.ref);
    props.getTeam();
    show('success', 'Removed Expired Invitation');
  };

  return (
    <div className={classes.teamItem}>
      <div className={classes.teamItemContent}>
        {props.data.memberData.status !== 'pending' ? (
          <Avatar className={classes.avatar}>
            <b>
              {props.data.memberData.firstName[0] +
                props.data.memberData.lastName[0]}
            </b>
          </Avatar>
        ) : (
          <Avatar className={classes.avatar}>
            <b>?</b>
          </Avatar>
        )}
        <div style={{ flexGrow: 1 }}>
          {props.data.memberData.status !== 'pending' && (
            <Typography className={classes.userName} style={{ flexGrow: 1 }}>
              {props.data.memberData.firstName +
                ' ' +
                props.data.memberData.lastName}
            </Typography>
          )}
          <Typography className={classes.useEmail} style={{ flexGrow: 1 }}>
            {props.data.memberData.email}
          </Typography>
        </div>
        {/* {props.lock && (
                <img alt='' src={require('../../../Assets/Images/lockIcon.png')} style={{height: 18, weight: 18, marginTop: 18, marginRight: 12}} />
            )} */}
        {props.data.memberData.status !== 'pending' && (
          <Status
            data={props.data}
            updateMember={props.updateMember}
            getTeam={props.getTeam}
          />
        )}
        {props.data.memberData.status === 'pending' && (
          <Button
            className={classes.InviteLinkButton}
            onClick={copyInvitationLink}
          >
            Invite Link
          </Button>
        )}
        {props.data.memberData.status === 'pending' &&
          props.type === 'center' && (
            <Button className={classes.RemindButton} onClick={remind}>
              Remind
            </Button>
          )}
        {props.data.memberData.status === 'pending' &&
          props.type === 'center' && (
            <Button className={classes.RemoveButton} onClick={remove}>
              Remove
            </Button>
          )}
      </div>
      <Divider className={classes.teamDivider} />
    </div>
  );
};

export default React.memo(AccountDetails);
