import React, { useState, useEffect, useContext } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import PhoneIcon from '@material-ui/icons/CallRounded';
import EmailIcon from '@material-ui/icons/EmailRounded';
import { makeStyles } from '@material-ui/core';
import { SnackbarContext } from '../../../contexts/Snackbar';

import StudyService from '../../../Services/StudyService/StudyService';
import { IconButton, CircularProgress } from '@material-ui/core';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import UserService from '../../../Services/UserService/UserService';
import { Hidden } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  meetingLink: {
    marginTop: 12,
    height: 44,
    fontWeight: 'bold',
    borderRadius: 10,
    fontSize: 16,
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  reschedule: {
    marginTop: 12,
    height: 44,
    fontWeight: 'bold',
    borderRadius: 10,
    fontSize: 16,
    textTransform: 'none',
    color: theme.palette.primary.dark,
  },
}));

const ApplicationDetails = ({ item }) => {
  const navigate = useNavigate();

  const classes = useStyles();
  const { show } = useContext(SnackbarContext);
  const [team, setTeam] = useState([]);
  const [currentTeam, setCurrentTeam] = useState(0);

  useEffect(() => {
    getCenterTeamMembers(item.studyLocation);
  }, []);

  const getCenterTeamMembers = async (locationFacility) => {
    // console.log('locationFacility', locationFacility)
    // return
    let teamMembersDocs = await StudyService.getTeamOfLocationFacility(
      locationFacility,
    );
    let getTeamMembersNames = [];
    let finalTeamData = [];
    if (teamMembersDocs.length > 0) {
      for (let index = 0; index < teamMembersDocs.length; index++) {
        const teamMember = teamMembersDocs[index];
        getTeamMembersNames.push(
          UserService.getUserByEmail(teamMember.data().email),
        );
      }
    }
    let usersData = await Promise.all(getTeamMembersNames);
    // console.log('usersData', usersData)
    for (let index = 0; index < usersData.length; index++) {
      const d = usersData[index];
      finalTeamData.push({
        memberData: teamMembersDocs[index],
        userData: d,
      });
    }
    // console.log('finalTeamData', finalTeamData)
    setTeam(finalTeamData);
  };

  const reschedule = () => {
    navigate('/home/schedule/' + item.doc.id);
  };

  const copyPatientMeetingLink = () => {
    let link = `https://app.knowrare.com/meeting/${item.doc.id}`;
    navigator.clipboard.writeText(link);
    show('success', 'Patient meeting link copied to clipboard');
  };

  const copyStudyMeetingLink = () => {
    let link = `https://connect.knowrare.com/home/meeting/${item.doc.id}`;
    navigator.clipboard.writeText(link);
    show('success', 'Study meeting link copied to clipboard');
  };

  const reformatSymptomsAndMedications = (text) => {
    if (text === '') return <i>None</i>;
    return text.replace(/,/g, ', ');
  };

  const reformatSymptomsAndProcedures = (text) => {
    if (text === '') return <i>None</i>;
    return text
      .replace(/,/g, ', ')
      .replace('AntibodyTesting', 'Antibody Testing');
  };

  const getProceduresAsArray = (procedures) => {
    var objectConstructor = {}.constructor;
    if (procedures.constructor === objectConstructor) {
      let proceduresArray = [];
      for (let index = 0; index < Object.keys(procedures).length; index++) {
        const procedure = Object.keys(procedures)[index];
        if (procedures[procedure]) {
          proceduresArray.push(procedure);
        }
      }
      return proceduresArray;
    } else {
      return procedures;
    }
  };

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return str;
  };

  return (
    <>
      <div
        style={{ width: '100%', height: 2, backgroundColor: '#4fb4e820' }}
      ></div>
      <Hidden smDown>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row',
            flexWrap: 'wrap',
            marginTop: 16,
          }}
        >
          <div
            style={{ display: 'flex', flexWrap: 'wrap', width: 'calc(100%)' }}
          >
            <div
              style={{
                fontSize: 18,
                width: '100%',
                color: '#4fb4e8',
                fontWeight: 600,
                letterSpacing: 3,
              }}
            >
              PATIENT DETAILS
            </div>
            {/* Born */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '40%',
                width: '40%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Born</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.userData.birthDate}
              </div>
            </div>
            {/* Symptoms */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '60%',
                width: '60%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Symptoms</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {reformatSymptomsAndMedications(item.symptoms.toString())}
              </div>
            </div>
            {/* Gender */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '40%',
                width: '40%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Gender</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.userData.gender}
              </div>
            </div>
            {/* Medications */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '60%',
                width: '60%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Medications</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {reformatSymptomsAndMedications(item.medications.toString())}
              </div>
            </div>
            {/* Email */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '40%',
                width: '40%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Email</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.userData.email}
              </div>
            </div>
            {/* Procedures */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '60%',
                width: '60%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Procedures</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {reformatSymptomsAndProcedures(
                  getProceduresAsArray(item.userData.procedures).toString(),
                )}
              </div>
            </div>
            {/* Phone */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '40%',
                width: '40%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Phone</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {formatPhoneNumber(item.userData.phone)}
              </div>
            </div>
            {/* Center Name */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '60%',
                width: '60%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Center Name</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.studyLocation}
              </div>
            </div>
            {/* Diagnosed */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '40%',
                width: '40%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Diagnosed</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.diagnosedByDoctor ? 'Yes' : 'No'}
              </div>
            </div>
            {/* Center Address */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '60%',
                width: '60%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Center Address</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.studyAddress}
              </div>
            </div>
            {/* ZendeskId */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '40%',
                width: '40%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Zendesk ID</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item?.zendeskId || 'N/A'}
              </div>
            </div>
            {/* Patient Location */}
            <div
              style={{
                fontSize: 14,
                maxWidth: '40%',
                width: '40%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Patient Location</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.userData.locationData.description}
              </div>
            </div>
          </div>
          <div style={{ width: '100%', marginTop: 32 }}>
            <div
              style={{
                fontSize: 12,
                width: '100%',
                color: '#4fb4e8',
                fontWeight: 600,
                letterSpacing: 3,
              }}
            >
              STUDY CONTACTS
            </div>
            {team.length > 0 ? (
              <div
                style={{
                  boxShadow: '0px 6px 27px -7px rgba(0, 0, 0, 0.12)',
                  width: '100%',
                  maxWidth: 300,
                  background: 'white',
                  paddingTop: 24,
                  paddingBottom: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  borderRadius: 12,
                  marginTop: 24,
                }}
              >
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <div
                    style={{
                      FontSize: 14,
                      fontWeight: 'bold',
                      color: '#4A4A4A',
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexGrow: 1, gap: 8 }}>
                      <div>
                        {team[currentTeam].userData
                          ? team[currentTeam].userData.data().firstName +
                            ' ' +
                            team[currentTeam].userData.data().lastName
                          : 'N/A'}
                      </div>
                      <div
                        style={{
                          FontSize: 14,
                          fontWeight: 'bold',
                          color: '#4A4A4A70',
                        }}
                      >
                        {team[currentTeam].memberData.data().role}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    marginTop: 16,
                  }}
                >
                  <PhoneIcon
                    size={18}
                    style={{ color: '#4fb4e8', width: 18, height: 18 }}
                  />
                  <div
                    style={{
                      FontSize: 14,
                      fontWeight: 'bold',
                      color: '#4A4A4A70',
                    }}
                  >
                    {team[currentTeam].memberData.data().phone
                      ? team[currentTeam].memberData.data().phone
                      : 'N/A'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    marginTop: 16,
                  }}
                >
                  <EmailIcon
                    size={18}
                    style={{ color: '#4fb4e8', width: 18, height: 18 }}
                  />
                  <div
                    style={{
                      FontSize: 14,
                      fontWeight: 'bold',
                      color: '#4A4A4A70',
                    }}
                  >
                    {team[currentTeam].memberData.data().email}
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: 23 }}>
                  <div style={{ flexGrow: 1 }}></div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      disabled={currentTeam === 0}
                      onClick={() => setCurrentTeam(currentTeam - 1)}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowLeftRoundedIcon />
                    </IconButton>
                    <div style={{ width: 30, marginTop: -4 }}>
                      {currentTeam + 1} / {team.length}
                    </div>
                    <IconButton
                      disabled={currentTeam === team.length - 1}
                      onClick={() => setCurrentTeam(currentTeam + 1)}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowRightRoundedIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  boxShadow: '0px 6px 27px -7px rgba(0, 0, 0, 0.12)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  background: 'white',
                  paddingTop: 24,
                  paddingBottom: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  width: '100%',
                  borderRadius: 12,
                  marginTop: 24,
                }}
              >
                <div
                  style={{
                    FontSize: 14,
                    fontWeight: 'bold',
                    color: '#4A4A4A70',
                  }}
                >
                  0 Team Members
                </div>
              </div>
            )}
          </div>
        </div>
        {item.status[item.status.length - 1].status ===
          'Confirmed Appointment' && (
          <div
            style={{ width: '100%', display: 'flex', gap: 24, marginTop: 32 }}
          >
            <Button
              className={classes.meetingLink}
              onClick={copyPatientMeetingLink}
            >
              <u>Patient Meeting Link</u>
            </Button>
            <Button
              className={classes.meetingLink}
              onClick={copyStudyMeetingLink}
            >
              <u>Study Meeting Link</u>
            </Button>
            <Button className={classes.reschedule} onClick={reschedule}>
              <u>Reschedule</u>
            </Button>
          </div>
        )}
      </Hidden>
      <Hidden mdUp>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row',
            flexWrap: 'wrap',
            marginTop: 16,
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div
              style={{
                fontSize: 11,
                width: '100%',
                color: '#4fb4e8',
                fontWeight: 600,
                letterSpacing: 3,
              }}
            >
              PATIENT DETAILS
            </div>
            {/* Born */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Born</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.userData.birthDate}
              </div>
            </div>
            {/* Symptoms */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Symptoms</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {reformatSymptomsAndMedications(item.symptoms.toString())}
              </div>
            </div>
            {/* Gender */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Gender</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.userData.gender}
              </div>
            </div>
            {/* Medications */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Medications</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {reformatSymptomsAndMedications(item.medications.toString())}
              </div>
            </div>
            {/* Email */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Email</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.userData.email}
              </div>
            </div>
            {/* Procedures */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Procedures</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {reformatSymptomsAndProcedures(
                  getProceduresAsArray(item.userData.procedures).toString(),
                )}
              </div>
            </div>
            {/* Phone */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Phone</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {formatPhoneNumber(item.userData.phone)}
              </div>
            </div>
            {/* Center Name */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Center Name</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.studyLocation}
              </div>
            </div>
            {/* Diagnosed */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Diagnosed</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.diagnosedByDoctor ? 'Yes' : 'No'}
              </div>
            </div>
            {/* Center Address */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Center Address</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.studyAddress}
              </div>
            </div>
            {/* ZendeskId */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Zendesk ID</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item?.zendeskId || 'N/A'}
              </div>
            </div>
            {/* Patient Location */}
            <div
              style={{
                fontSize: 12,
                width: '100%',
                marginTop: 12,
                display: 'flex',
                gap: 8,
              }}
            >
              <div style={{ color: '#4A4A4A' }}>Patient Location</div>
              <div style={{ color: '#4A4A4A', opacity: 0.7 }}>
                {item.userData.locationData.description}
              </div>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div
              style={{
                fontSize: 11,
                width: '100%',
                marginTop: 16,
                color: '#4fb4e8',
                fontWeight: 600,
                letterSpacing: 3,
              }}
            >
              STUDY CONTACTS
            </div>
            {team.length > 0 ? (
              <div
                style={{ width: '100%', background: 'white', marginTop: 16 }}
              >
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <div
                    style={{
                      FontSize: 12,
                      fontWeight: 'bold',
                      color: '#4A4A4A',
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexGrow: 1, gap: 8 }}>
                      {/* {console.log('team[currentTeam]', team[currentTeam])} */}
                      <div>
                        {team[currentTeam].userData
                          ? team[currentTeam].userData.data().firstName +
                            ' ' +
                            team[currentTeam].userData.data().lastName
                          : 'N/A'}
                      </div>
                      <div
                        style={{
                          FontSize: 12,
                          fontWeight: 'bold',
                          color: '#4A4A4A70',
                        }}
                      >
                        {team[currentTeam].memberData.data().role}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    marginTop: 16,
                  }}
                >
                  <PhoneIcon
                    size={18}
                    style={{ color: '#4fb4e8', width: 18, height: 18 }}
                  />
                  <div
                    style={{
                      FontSize: 12,
                      fontWeight: 'bold',
                      color: '#4A4A4A70',
                    }}
                  >
                    {team[currentTeam].memberData.data().phone
                      ? team[currentTeam].memberData.data().phone
                      : 'N/A'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    marginTop: 16,
                  }}
                >
                  <EmailIcon
                    size={18}
                    style={{ color: '#4fb4e8', width: 18, height: 18 }}
                  />
                  <div
                    style={{
                      FontSize: 12,
                      fontWeight: 'bold',
                      color: '#4A4A4A70',
                    }}
                  >
                    {team[currentTeam].memberData.data().email}
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: 23 }}>
                  <div style={{ flexGrow: 1 }}></div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      disabled={currentTeam === 0}
                      onClick={() => setCurrentTeam(currentTeam - 1)}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowLeftRoundedIcon />
                    </IconButton>
                    <div style={{ width: 30, marginTop: -4 }}>
                      {currentTeam + 1} / {team.length}
                    </div>
                    <IconButton
                      disabled={currentTeam === team.length - 1}
                      onClick={() => setCurrentTeam(currentTeam + 1)}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowRightRoundedIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  boxShadow: '0px 6px 27px -7px rgba(0, 0, 0, 0.12)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  background: 'white',
                  paddingTop: 24,
                  paddingBottom: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  width: '100%',
                  borderRadius: 12,
                  marginTop: 24,
                }}
              >
                <div
                  style={{
                    FontSize: 14,
                    fontWeight: 'bold',
                    color: '#4A4A4A70',
                  }}
                >
                  0 Team Members
                </div>
              </div>
            )}
          </div>
        </div>
        {item.status[item.status.length - 1].status ===
          'Confirmed Appointment' && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 16,
              marginTop: 16,
            }}
          >
            <Button
              className={classes.meetingLink}
              onClick={copyPatientMeetingLink}
              style={{ marginTop: 0 }}
            >
              <u>Patient Meeting Link</u>
            </Button>
            <Button
              className={classes.meetingLink}
              onClick={copyStudyMeetingLink}
              style={{ marginTop: 0 }}
            >
              <u>Study Meeting Link</u>
            </Button>
            <Button
              className={classes.reschedule}
              onClick={reschedule}
              style={{ marginTop: 0 }}
            >
              <u>Reschedule</u>
            </Button>
          </div>
        )}
      </Hidden>
    </>
  );
};

export default ApplicationDetails;
