import React, { createContext, useState, useEffect, useContext } from 'react';

export const FilterContext = createContext();

function FilterContextProvider(props) {

    const [openFilter, setOpenFilter] = useState(false)

    return (
        <FilterContext.Provider value={{
            openFilter: openFilter,
            setOpenFilter: setOpenFilter
        }}>
            <div>{props.children}</div>
        </FilterContext.Provider>
    );
}
 
export default FilterContextProvider;
