import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Chart from '../Chart';
import ApplicationDialog from '../ApplicationDialog';
import { PieChart } from 'react-minimal-pie-chart';
import Tabs from '../Tabs.js';
import StudyOverviewSkeleton from './StudyOverviewSkeleton';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiStyleFunction from './StudyOverview.style';
import Inquiries from './Inquiries';
import Appointments from './Appointments';
import moment from 'moment';
import Map from '../Map';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

const StudyOverview = ({ study }) => {
  const classes = useStyles();

  const [top5, setTop5] = useState([]);

  const [topStudyCenterType, setTopStudyCenterType] = useState('Applied');
  const [whatLeadsToShowInChart, setWhatLeadsToShowInChart] =
    useState('Applied');

  const getAgeFromDate = (date) => {
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    return moment().diff(formattedDate, 'years', false);
  };

  const getGoingToCenter = () => {
    let applications = study.applications;
    let goingToCenter = 0;
    let notQualified = 0;
    let noShow = 0;
    let other = 0;
    let rescheduled = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const status = application.exitSurvey
        ? application.exitSurvey.selectedOption
        : '';
      if (status === 'Patient is coming for an in person visit') {
        goingToCenter += 1;
      } else if (status === 'Patient is not qualified or interested') {
        notQualified += 1;
      } else if (status === 'Patient did not attend') {
        noShow += 1;
      } else if (status === 'Other') {
        other += 1;
      } else {
        rescheduled += 1;
      }
    }
    return (
      (goingToCenter /
        (goingToCenter + notQualified + noShow + other + rescheduled)) *
      100
    );
  };

  const getNotQualified = () => {
    let applications = study.applications;
    let goingToCenter = 0;
    let notQualified = 0;
    let noShow = 0;
    let other = 0;
    let rescheduled = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const status = application.exitSurvey
        ? application.exitSurvey.selectedOption
        : '';
      if (status === 'Patient is coming for an in person visit') {
        goingToCenter += 1;
      } else if (status === 'Patient is not qualified or interested') {
        notQualified += 1;
      } else if (status === 'Patient did not attend') {
        noShow += 1;
      } else if (status === 'Other') {
        other += 1;
      } else {
        rescheduled += 1;
      }
    }
    return (
      (notQualified /
        (goingToCenter + notQualified + noShow + other + rescheduled)) *
      100
    );
  };

  const getNoShow = () => {
    let applications = study.applications;
    let goingToCenter = 0;
    let notQualified = 0;
    let noShow = 0;
    let other = 0;
    let rescheduled = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const status = application.exitSurvey
        ? application.exitSurvey.selectedOption
        : '';
      if (status === 'Patient is coming for an in person visit') {
        goingToCenter += 1;
      } else if (status === 'Patient is not qualified or interested') {
        notQualified += 1;
      } else if (status === 'Patient did not attend') {
        noShow += 1;
      } else if (status === 'Other') {
        other += 1;
      } else {
        rescheduled += 1;
      }
    }
    return (
      (noShow / (goingToCenter + notQualified + noShow + other + rescheduled)) *
      100
    );
  };

  const getOther = () => {
    let applications = study.applications;
    let goingToCenter = 0;
    let notQualified = 0;
    let noShow = 0;
    let other = 0;
    let rescheduled = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const status = application.exitSurvey
        ? application.exitSurvey.selectedOption
        : '';
      if (status === 'Patient is coming for an in person visit') {
        goingToCenter += 1;
      } else if (status === 'Patient is not qualified or interested') {
        notQualified += 1;
      } else if (status === 'Patient did not attend') {
        noShow += 1;
      } else if (status === 'Other') {
        other += 1;
      } else {
        rescheduled += 1;
      }
    }
    return (
      (other / (goingToCenter + notQualified + noShow + other + rescheduled)) *
      100
    );
  };

  const getRescheduled = () => {
    let applications = study.applications;
    let goingToCenter = 0;
    let notQualified = 0;
    let noShow = 0;
    let other = 0;
    let rescheduled = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const status = application.exitSurvey
        ? application.exitSurvey.selectedOption
        : '';
      if (status === 'Patient is coming for an in person visit') {
        goingToCenter += 1;
      } else if (status === 'Patient is not qualified or interested') {
        notQualified += 1;
      } else if (status === 'Patient did not attend') {
        noShow += 1;
      } else if (status === 'Other') {
        other += 1;
      } else {
        rescheduled += 1;
      }
    }
    return (
      (rescheduled /
        (goingToCenter + notQualified + noShow + other + rescheduled)) *
      100
    );
  };

  const get10Minus = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age10minus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge11to18 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age11to18 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge19to29 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age19to29 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge30to55 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age30to55 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge56to74 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age56to74 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge75plus = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age75plus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getFemale = () => {
    let applications = study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (female / (male + female)) * 100;
  };

  const getMale = () => {
    let applications = study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (male / (male + female)) * 100;
  };

  const getDiagnosed = () => {
    let applications = study.applications;
    let yes = 0;
    let no = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (!application.userData.diagnosedByDoctor) {
        no += 1;
      } else {
        yes += 1;
      }
    }
    return (yes / (yes + no)) * 100;
  };

  const getNotDiagnosed = () => {
    let applications = study.applications;
    let yes = 0;
    let no = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (!application.userData.diagnosedByDoctor) {
        no += 1;
      } else {
        yes += 1;
      }
    }
    return (no / (yes + no)) * 100;
  };

  const getTopLocations = () => {
    let applications = study.applications;
    let data = [];
    let centers = {};
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (topStudyCenterType === 'Completed') {
        let status = application.status[application.status.length - 1].status;
        if (status !== 'Completed') {
          continue;
        }
      }
      let stateShortName = application.studyAddress;
      let stateLongName = application.studyAddress;
      if (
        centers.hasOwnProperty(application.studyLocation.replace(/-/g, ' '))
      ) {
        centers[application.studyLocation.replace(/-/g, ' ')].applications =
          centers[application.studyLocation.replace(/-/g, ' ')].applications +
          1;
      } else {
        centers[application.studyLocation.replace(/-/g, ' ')] = {
          applications: 1,
          stateShortName,
          stateLongName,
          studyCenter: application.studyLocation.replace(/-/g, ' '),
          fill: '#4fb4e8',
        };
      }
    }

    let top5 = [
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
    ];

    for (let index = 0; index < Object.keys(centers).length; index++) {
      const centerObject = centers[Object.keys(centers)[index]];
      // console.log(centerObject)
      if (
        centerObject.applications >= top5[0].applications &&
        centerObject.applications >= top5[1].applications &&
        centerObject.applications >= top5[2].applications &&
        centerObject.applications >= top5[3].applications &&
        centerObject.applications >= top5[4].applications
      ) {
        top5 = [centerObject, top5[0], top5[1], top5[2], top5[3]];
      } else if (
        centerObject.applications >= top5[1].applications &&
        centerObject.applications >= top5[2].applications &&
        centerObject.applications >= top5[3].applications &&
        centerObject.applications >= top5[4].applications
      ) {
        top5 = [top5[0], centerObject, top5[1], top5[2], top5[3]];
      } else if (
        centerObject.applications >= top5[2].applications &&
        centerObject.applications >= top5[3].applications &&
        centerObject.applications >= top5[4].applications
      ) {
        top5 = [top5[0], top5[1], centerObject, top5[2], top5[3]];
      } else if (
        centerObject.applications >= top5[3].applications &&
        centerObject.applications >= top5[4].applications
      ) {
        top5 = [top5[0], top5[1], top5[2], centerObject, top5[3]];
      } else if (centerObject.applications >= top5[4].applications) {
        top5 = [top5[0], top5[1], top5[2], top5[3], centerObject];
      }
    }
    setTop5(top5);
  };

  const getTotalNumberOfApplicationsInTheFirst3 = () => {
    if (top5[2].applications !== -1) {
      return top5[2].applications + top5[1].applications + top5[0].applications;
    } else if (top5[1].applications !== -1) {
      return top5[1].applications + top5[2].applications;
    } else if (top5[0].applications !== -1) {
      return top5[0].applications;
    }
    return 0;
  };

  useEffect(() => {
    getTopLocations();
  }, [topStudyCenterType]);

  return (
    <div>
      <ApplicationDialog main={this} />
      {study !== null ? (
        <div>
          {/* {mobile && (
          <Typography className={classes.mobileTitle} variant="h2">Overview</Typography>
        )}        */}
          <div
            style={{
              paddingTop: 0,
              paddingBottom: mobile ? 80 : 40,
              width: '100%',
              marginTop: mobile ? 0 : 24,
            }}
          >
            <Grid
              container
              direction='row'
              justifyContent='space-around'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                xl={3}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction='row'
                  justifyContent='space-around'
                  alignItems='flex-start'
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 195,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        paddingTop: 28,
                      }}
                    >
                      <Typography variant='h4' className={classes.colorNumber}>
                        {study.applied}
                      </Typography>
                      <Typography
                        variant='h4'
                        className={classes.numberDescription}
                      >
                        Applied
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 195,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        paddingTop: 28,
                      }}
                    >
                      <Typography variant='h4' className={classes.colorNumber}>
                        {study.scheduled}
                      </Typography>
                      <Typography
                        variant='h4'
                        className={classes.numberDescription}
                      >
                        Scheduled
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 195,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: '#4fb4e8',
                        paddingTop: 28,
                        borderRadius: 8,
                      }}
                    >
                      <Typography variant='h4' className={classes.whiteNumber}>
                        {study.completed}
                      </Typography>
                      <Typography
                        variant='h4'
                        className={classes.whiteNumberDescription}
                      >
                        Completed
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                xl={9}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction='row'
                  justifyContent='space-around'
                  alignItems='flex-start'
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 324,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography
                          className={classes.boxTitle}
                          variant='h2'
                          style={{ flexGrow: 1 }}
                        >
                          Progress
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant='h2'
                          style={{
                            marginRight: 12,
                            cursor: 'pointer',
                            color:
                              whatLeadsToShowInChart === 'Applied'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setWhatLeadsToShowInChart('Applied')}
                        >
                          Applied
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant='h2'
                          style={{
                            cursor: 'pointer',
                            color:
                              whatLeadsToShowInChart === 'Completed'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setWhatLeadsToShowInChart('Completed')}
                        >
                          Completed
                        </Typography>
                      </div>
                      <Chart
                        study={study}
                        whatLeadsToShowInChart={whatLeadsToShowInChart}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={6} xl={6} style={{alignItems: 'center'}}>
                        <div style={{width: '100%', height: 324, boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)', backgroundColor: 'white', borderRadius: 8, padding: 24}}>
                          <Typography className={classes.boxTitle} variant="h2">Locations</Typography>
                          <div style={{width: '40%', display: 'inline-block', verticalAlign: 'top'}}>
                              <Typography variant="h2" style={{fontSize: 12, fontWeight: 900, color: '#4fb4e8', marginTop: 32, letterSpacing: '0.3em'}}>TOP AREAS</Typography>
                              <div style={{marginTop: 16, width: '80%', height: 1, backgroundColor: '#DBDBDB'}}></div>
                              {top5.map((item, index) => (
                                <div>
                                  {item.applications !== -1 && (
                                    <Typography variant="h2" style={{fontSize: 12, fontWeight: 500, color: '#4A4A4A', marginTop: 16, width: '80%'}}>{item.stateLongName}<span style={{marginLeft: 0, color: 'rgba(74, 74, 74, 0.5)', float: 'right'}}>{item.applications}</span></Typography>
                                  )}
                                </div>
                              ))}
                          </div>
                          <div style={{width: '60%', display: 'inline-block'}}>
                              <Map study={study} setTop5={setTop5} />
                          </div>
                        </div>
                        <div style={{width: 'calc(100% - 48px)', marginLeft: 24, height: 18, background: 'linear-gradient(270deg, #783131 0%, #A84545 17.71%, #D85959 36.98%, #4fb4e8 53.12%, #F49292 70.83%, #F7B1B1 85.94%, #FAD0D0 100%)', paddingLeft: 24, paddingRight: 24, marginTop: -46}}></div>
                        <div style={{width: 'calc(100% - 48px)', marginLeft: 24, height: 18, paddingLeft: 0, paddingRight: 0, marginTop: 0}}>
                            <div style={{display: 'inline-block', float: 'left'}}>
                              <Typography variant="h2" style={{fontSize: 12, fontWeight: 500, color: 'rgba(74, 74, 74, 0.5)'}}>0</Typography>
                            </div>
                            <div style={{display: 'inline-block', float: 'right'}}>
                              <Typography variant="h2" style={{fontSize: 12, fontWeight: 500, color: 'rgba(74, 74, 74, 0.5)'}}>100</Typography>
                            </div>
                        </div>
                      </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 324,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography
                          className={classes.boxTitle}
                          variant='h2'
                          style={{ flexGrow: 1 }}
                        >
                          Top Study Centers
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant='h2'
                          style={{
                            marginRight: 12,
                            cursor: 'pointer',
                            color:
                              topStudyCenterType === 'Applied'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setTopStudyCenterType('Applied')}
                        >
                          Applied
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant='h2'
                          style={{
                            cursor: 'pointer',
                            color:
                              topStudyCenterType === 'Completed'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setTopStudyCenterType('Completed')}
                        >
                          Completed
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {top5.length > 0 && top5[0].applications === -1 && (
                          <div
                            style={{
                              marginTop: 90,
                              textAlign: 'center',
                              fontSize: 22,
                            }}
                          >
                            No Data Yet
                          </div>
                        )}
                        {top5.length > 0 && top5[0].applications != -1 && (
                          <div
                            className={classes.locationProgressLine}
                            style={{
                              marginTop: 16,
                              width: `${
                                (top5[0].applications /
                                  getTotalNumberOfApplicationsInTheFirst3()) *
                                100
                              }%`,
                              background: '#4fb4e8',
                            }}
                          >
                            <div style={{ flexGrow: 1 }}></div>
                            <div>{top5[0].applications}</div>
                          </div>
                        )}
                        {top5.length > 1 && top5[1].applications != -1 && (
                          <div
                            className={classes.locationProgressLine}
                            style={{
                              marginTop: 6,
                              width: `${
                                (top5[1].applications /
                                  getTotalNumberOfApplicationsInTheFirst3()) *
                                100
                              }%`,
                              background: '#4fb4e850',
                            }}
                          >
                            <div style={{ flexGrow: 1 }}></div>
                            <div>{top5[1].applications}</div>
                          </div>
                        )}
                        {top5.length > 2 && top5[2].applications != -1 && (
                          <div
                            className={classes.locationProgressLine}
                            style={{
                              marginTop: 6,
                              width: `${
                                (top5[2].applications /
                                  getTotalNumberOfApplicationsInTheFirst3()) *
                                100
                              }%`,
                              background: '#4fb4e830',
                            }}
                          >
                            <div style={{ flexGrow: 1 }}></div>
                            <div>{top5[2].applications}</div>
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: 24,
                          }}
                        >
                          {top5.length > 0 && top5[0].applications != -1 && (
                            <div style={{ maxWidth: '33%' }}>
                              <div
                                style={{
                                  width: 14,
                                  height: 14,
                                  borderRadius: '50%',
                                  padding: 10,
                                  background: '#4fb4e8',
                                }}
                              ></div>
                              <div className={classes.locationName}>
                                {top5[0].studyCenter}
                              </div>
                              <div className={classes.locationState}>
                                {top5[0].stateLongName},{' '}
                                {top5[0].stateShortName}
                              </div>
                            </div>
                          )}
                          {top5.length > 1 && top5[1].applications != -1 && (
                            <div
                              style={{
                                marginLeft: 4,
                                marginRight: 4,
                                maxWidth: '33%',
                              }}
                            >
                              <div
                                style={{
                                  width: 14,
                                  height: 14,
                                  borderRadius: '50%',
                                  padding: 10,
                                  background: '#4fb4e850',
                                }}
                              ></div>
                              <div className={classes.locationName}>
                                {top5[1].studyCenter}
                              </div>
                              <div className={classes.locationState}>
                                {top5[1].stateLongName},{' '}
                                {top5[1].stateShortName}
                              </div>
                            </div>
                          )}
                          {top5.length > 2 && top5[2].applications != -1 && (
                            <div style={{ maxWidth: '33%' }}>
                              <div
                                style={{
                                  width: 14,
                                  height: 14,
                                  borderRadius: '50%',
                                  padding: 10,
                                  background: '#4fb4e830',
                                }}
                              ></div>
                              <div className={classes.locationName}>
                                {top5[2].studyCenter}
                              </div>
                              <div className={classes.locationState}>
                                {top5[2].stateLongName},{' '}
                                {top5[2].stateShortName}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 283,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant='h2'>
                        Gender
                      </Typography>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '60%',
                          verticalAlign: 'top',
                          marginTop: 12,
                        }}
                      >
                        {study.applicants === 0 ? (
                          <div
                            style={{
                              marginTop: 80,
                              textAlign: 'center',
                              fontSize: 22,
                            }}
                          >
                            No Data Yet
                          </div>
                        ) : (
                          <PieChart
                            animate={false}
                            label={({ dataEntry }) => dataEntry.value + '%'}
                            labelStyle={{
                              fontSize: 11,
                              fontFamily: "'sofia-pro', sans-serif",
                              fontWeight: 600,
                              color: '#FFFFFF',
                            }}
                            style={{
                              color: 'white',
                              color: '#FFFFFF',
                              height: 178,
                              marginTop: 10,
                            }}
                            data={[
                              {
                                title: 'Male',
                                value: parseFloat(getMale().toFixed(2)),
                                color: '#4fb4e8',
                              },
                              {
                                title: 'Female',
                                value: parseFloat(getFemale().toFixed(2)),
                                color: '#4fb4e830',
                              },
                            ]}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          width: '40%',
                          marginRight: 0,
                          display: 'inline-block',
                          verticalAlign: 'top',
                          marginTop: 24,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e8',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Male
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e830',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Female
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={4} xl={4} style={{alignItems: 'center'}}>
                          <div style={{width: '100%', height: 283, boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)', backgroundColor: 'white', borderRadius: 8, padding: 24}}>
                            <Typography className={classes.boxTitle} variant="h2">Diagnosed</Typography>
                            <div style={{display: 'inline-block', width: '60%', verticalAlign: 'top', marginTop: 12}}>
                              {study.applicants === 0 ? (
                                <div style={{marginTop: 80, textAlign: 'center', fontSize: 22}}>No Data Yet</div>
                              ) : (
                                <PieChart 
                                  animate={false}
                                  labelStyle={{
                                    fontSize: 14,
                                    fontFamily: "'sofia-pro', sans-serif",
                                    fontWeight: 600,
                                    fontColor: '#FFFFF',
                                    color: 'white',
                                    background: 'white',
                                    backgroundColor: 'white'
                                  }}
                                  label={({ dataEntry }) => dataEntry.value + '%'}
                                  style={{color: 'white', height: 178, marginTop: 10}}
                                  data={[
                                    { title: 'Yes', value: getDiagnosed(), color: '#4fb4e8' },
                                    { title: 'No', value: getNotDiagnosed(), color: '#4fb4e830' }
                                  ]}
                                />
                              )}
                            </div>
                            <div style={{width: '40%', marginRight: 0, display: 'inline-block', verticalAlign: 'top',marginTop: 24}}>
                              <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12}}>
                                <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#4fb4e8'}}></div>
                                <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'sofia-pro', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>Yes</div>
                              </div>
                              <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12}}>
                                <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#4fb4e830'}}></div>
                                <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'sofia-pro', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>No</div>
                              </div>
                            </div>
                          </div>
                        </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 283,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant='h2'>
                        Age
                      </Typography>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '60%',
                          verticalAlign: 'top',
                          marginTop: 12,
                        }}
                      >
                        {study.applicants === 0 ? (
                          <div
                            style={{
                              marginTop: 80,
                              textAlign: 'center',
                              fontSize: 22,
                            }}
                          >
                            No Data Yet
                          </div>
                        ) : (
                          <PieChart
                            animate={false}
                            label={({ dataEntry }) => dataEntry.value + '%'}
                            style={{
                              color: 'white',
                              color: '#FFFFFF',
                              height: 178,
                              marginTop: 10,
                            }}
                            labelStyle={{
                              fontSize: 11,
                              fontFamily: "'sofia-pro', sans-serif",
                              fontWeight: 600,
                              color: '#FFFFFFF',
                            }}
                            data={[
                              {
                                title: '10+',
                                value: parseFloat(get10Minus().toFixed(2)),
                                color: '#FDE6E6',
                              },
                              {
                                title: '11-18',
                                value: parseFloat(getAge11to18().toFixed(2)),
                                color: '#F7B1B1',
                              },
                              {
                                title: '19-29',
                                value: parseFloat(getAge19to29().toFixed(2)),
                                color: '#F38382',
                              },
                              {
                                title: '30-55',
                                value: parseFloat(getAge30to55().toFixed(2)),
                                color: '#D87877',
                              },
                              {
                                title: '56-74',
                                value: parseFloat(getAge56to74().toFixed(2)),
                                color: '#C0504F',
                              },
                              {
                                title: '75+',
                                value: parseFloat(getAge75plus().toFixed(2)),
                                color: '#A66262',
                              },
                            ]}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          width: '40%',
                          marginRight: 0,
                          display: 'inline-block',
                          verticalAlign: 'top',
                          marginTop: 24,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#FDE6E6',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Under 10
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#F7B1B1',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            11-18
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#F38382',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            19-29
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#D87877',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            56-74
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#C0504F',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            75+
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#A66262',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            30-55
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={4} xl={4} style={{alignItems: 'center'}}>
                          <div style={{width: '100%', height: 283, boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)', backgroundColor: 'white', borderRadius: 8, padding: 24}}>
                            <Typography className={classes.boxTitle} variant="h2">Visit Results</Typography>
                            <div style={{display: 'inline-block', width: '60%', verticalAlign: 'top', marginTop: 12}}>
                              {study.applicants === 0 ? (
                                <div style={{marginTop: 80, textAlign: 'center', fontSize: 22}}>No Data Yet</div>
                              ) : (
                                <PieChart 
                                  animate={false}
                                  label={({ dataEntry }) => dataEntry.value + '%'}
                                  style={{color: 'white', color: '#FFFFFF', height: 178, marginTop: 10}}
                                  labelStyle={{
                                    fontSize: 11,
                                    fontFamily: "'sofia-pro', sans-serif",
                                    fontWeight: 600,
                                    color: '#FFFFFF'
                                  }}
                                  data={[
                                    { title: 'Going To Center', value: parseFloat(getGoingToCenter().toFixed(2)), color: '#FDE6E6' },
                                    { title: 'Not Qualified', value: parseFloat(getNotQualified().toFixed(2)), color: '#F7B1B1' },
                                    { title: 'No Show', value: parseFloat(getNoShow().toFixed(2)), color: '#F38382' },
                                    { title: 'Other', value: parseFloat(getOther().toFixed(2)), color: '#D87877' },
                                    { title: 'Rescheduled', value: parseFloat(getRescheduled().toFixed(2)), color: '#C0504F' },
                                  ]}
                                />
                              )}
                            </div>
                            <div style={{width: '40%', marginRight: 0, display: 'inline-block', verticalAlign: 'top',marginTop: 24}}>
                              <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12, marginTop: 0}}>
                                <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#FDE6E6'}}></div>
                                <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'sofia-pro', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>Going To Center</div>
                              </div>
                              <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12, marginTop: 0}}>
                                <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#F7B1B1'}}></div>
                                <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'sofia-pro', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>Not Qualified</div>
                              </div>
                              <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12, marginTop: 0}}>
                                <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#F38382'}}></div>
                                <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'sofia-pro', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>No Show</div>
                              </div>
                              <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12, marginTop: 0}}>
                                <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#D87877'}}></div>
                                <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'sofia-pro', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>Other</div>
                              </div>
                              <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12, marginTop: 0}}>
                                <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#C0504F'}}></div>
                                <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'sofia-pro', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>Rescheduled</div>
                              </div>
                            </div>
                          </div>
                        </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <StudyOverviewSkeleton />
      )}
    </div>
  );
};

export default StudyOverview;
