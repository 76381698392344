import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder={'mm/dd/yyyy'}
      //   placeholderChar={'mm/dd/yyyy'}
      //   showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function FormattedInputs(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    textmask: '  /  /    ',
    numberformat: '1320',
  });

  const handleChange = (event) => {
    props.setDate(event.target.value);
  };

  return (
    <div className={classes.root}>
      <FormControl fullWidth disabled={props.disabled}>
        {/* <InputLabel shrink={true} htmlFor="formatted-text-mask-input">Birth Date (required)</InputLabel> */}
        <Input
          value={props.date}
          onChange={handleChange}
          placeholder="mm/dd/yyyy"
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
          fullWidth
        />
      </FormControl>
    </div>
  );
}
