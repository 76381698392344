import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import StudyService from '../../../Services/StudyService/StudyService';
import TeamPopup from './TeamPopup';
import locationUrils from '../../../utils/location';
import { SnackbarContext } from '../../../contexts/Snackbar';
import StudyCenterStatus from './StudyCenterStatus';

const useStyles = makeStyles(MuiStyleFunction);

const AccountDetails = (props) => {
  const classes = useStyles();

  const { userData } = useContext(AuthContext);

  const [age, setAge] = React.useState('');

  const [openTeamPopup, setOpenTeamPopup] = React.useState(false);
  const [adding, setAdding] = React.useState(false);
  const { show } = useContext(SnackbarContext);

  const setup = async () => {
    if (adding) return;
    setAdding(true);
    let location =
      await locationUrils.getLocationCoordinatesAndFormattedAddress(
        `${props.data.city}, ${
          props.data.state ? props.data.state : props.data.country
        }, ${props.data.zip}`,
      );
    let newStudyData = {
      LocationFacility: props.data.facility,
      LocationAddress: location.formattedAddress,
      status: 'Paused',
    };
    await StudyService.addNewStudyCenterByRef(
      props.studyData.document.ref,
      newStudyData,
    );
    await props.getCenters();
    setAdding(false);
  };

  const copyPatientSignupLink = async () => {
    let dName = '{DISEASE+NAME}';
    let studyData = await StudyService.getStudiesByNCTID(
      props.data.ref.parent.parent.id,
    );
    if (props.data.ref.parent.parent.id === 'NCT04370054') {
      dName = 'Myasthenia+Gravis+(MG)';
    }
    if (
      props.studyData &&
      props.studyData.studyData &&
      props.studyData.studyData.diseaseName
    ) {
      dName = props.studyData.studyData.diseaseName.replace(/ /g, '+');
    }
    let url =
      'https://app.knowrare.com/signup?disease=' +
      dName +
      '&NCTID=' +
      props.data.ref.parent.parent.id +
      '&center=' +
      props.data.data().LocationFacility.replace(/ /g, '+');
    navigator.clipboard.writeText(url);
    show('success', 'Copied to clipboard');
  };

  return (
    <div className={classes.teamItem}>
      {props.type === 'exists' && openTeamPopup && (
        <TeamPopup
          studyData={props.studyData}
          data={props.data}
          open={openTeamPopup}
          setOpenTeamPopup={setOpenTeamPopup}
        />
      )}
      <div className={classes.teamItemContent}>
        <div style={{ flexGrow: 1 }}>
          {props.type === 'new' && (
            <Typography className={classes.StudyCenterName}>
              {props.data.facility}
            </Typography>
          )}
          {props.type === 'new' && (
            <Typography className={classes.StudyCenterLocation}>
              {props.data.city},{' '}
              {props.data.state ? props.data.state : props.data.country},{' '}
              {props.data.zip}
            </Typography>
          )}
          {props.type === 'exists' && (
            <Typography className={classes.StudyCenterName}>
              {props.data.data().LocationFacility}
            </Typography>
          )}
          {props.type === 'exists' && (
            <Typography className={classes.StudyCenterLocation}>
              {props.data.data().LocationAddress}
            </Typography>
          )}
        </div>
        {props.type === 'exists' && (
          <StudyCenterStatus
            studyCenterData={props.data.data()}
            studyCenterRef={props.data.ref}
          />
        )}
        {props.type === 'exists' && (
          <Button
            className={classes.TeamButton}
            onClick={() => setOpenTeamPopup(true)}
          >
            Team
          </Button>
        )}
        {props.type === 'new' && (
          <Button
            className={classes.SetupButton}
            style={{ backgroundColor: 'rgb(226 245 255)' }}
            onClick={setup}
          >
            {adding ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              'Set Up'
            )}
          </Button>
        )}
        {props.type === 'exists' && (
          <div style={{ display: 'flex' }}>
            <img
              alt=""
              src={require('../../../Assets/Images/copyLinkIcon.png')}
              style={{
                marginLeft: 12,
                height: 32,
                width: 32,
                cursor: 'pointer',
              }}
              onClick={() => copyPatientSignupLink()}
            />
          </div>
        )}
      </div>
      <Divider className={classes.teamDivider} />
    </div>
  );
};

export default React.memo(AccountDetails);
