import React, {useEffect, useState, useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
import SettingStyle from './Settings.style';
import { IconButton, makeStyles } from '@material-ui/core';
import StudyService from '../../../Services/StudyService/StudyService';
import TeamItem from './TeamItem';
import AddNewTeamMemberFromPopup from './AddNewTeamMemberFromPopup';
import { SnackbarContext } from '../../../contexts/Snackbar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(SettingStyle)

export default function AlertDialogSlide(props) {

    const classes =  useStyles()    

    const [team, setTeam] = useState([])
    const [loadingTeam, setLoadingTeam] = useState(true)
    const [addNew, setAddNew] = React.useState(false);

    const {show} = useContext(SnackbarContext)

    const handleClose = () => {
        props.setOpenTeamPopup(false)
    };

    useEffect(() => {
        getTeam()
    }, [])

    const getTeam = async () => {
        let team = await StudyService.getTeamByStudyRef(props.data.ref)
        setTeam(team)
        setLoadingTeam(false)
        return
    }

    const updateMember = async (ref, data) => {
        await StudyService.updateTeamMemberByRef(ref, data)
        getTeam()
    }

    const copyInvitationLink = () => {
        let invitationLink = `https://connect.knowrare.com/invitation/${props.data.ref.parent.parent.id}?center=${props.data.data().LocationFacility.split(' ').join('+')}`
        navigator.clipboard.writeText(invitationLink)
        show('success', 'Copied to clipboard')
    }

    return (
        <div>
            <Dialog
                open={props.open}
                fullWidth={true}
                maxWidth={'sm'}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-described by="alert-dialog-slide-description"
                style={{minWidth: 490, minHeight: 450}}
            >
                <DialogContent style={{position: 'relative', paddingBottom: 136}}>
                    <IconButton style={{position: 'absolute', right: 16, top: 16}} onClick={handleClose}>
                        <img alt='' src={require('../../../Assets/Images/grayXIcon.png')} width={14} height={14} />
                    </IconButton>
                    <div style={{minHeight: 350}}>
                        <Typography className={classes.StudyCenterNameTeamPopup}>{props.data.data().LocationFacility}</Typography>
                        <Typography className={classes.StudyCenterLocationTeamPopup}>{props.data.data().LocationAddress}</Typography>
                        <Typography className={classes.StudyCenterTeamTitle}>STUDY CENTER TEAM</Typography>
                        {loadingTeam && (
                            <Skeleton width="100%" height="100px" style={{marginTop: 8}} />
                        )}
                        {loadingTeam && (
                            <Skeleton width="100%" height="100px" style={{marginTop: 8}} />
                        )}
                        {loadingTeam && (
                            <Skeleton width="100%" height="100px" style={{marginTop: 8}} />
                        )}
                        {team.map((data, index) => (
                            <TeamItem studyName={props.studyData.studyData.customTitle} companyName={props.studyData.companyName} type={'center'} key={index} lock={true} data={data} updateMember={updateMember} getTeam={getTeam} />
                        ))}
                        {(team.length === 0 && !loadingTeam) && (
                            <div style={{width: '100%', borderRadius: 12, marginTop: 24, padding: 48, backgroundColor: '#F6F6F6', color: '#B1B1B1', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography variant="h2" style={{color: '#B1B1B1', fontSize: 18}}>No team added yet</Typography>
                            </div>
                        )}
                        <AddNewTeamMemberFromPopup studyData={props.studyData} studyCenterData={props.data} getTeam={getTeam} addNew={addNew} setAddNew={setAddNew} centerName={props.data.data().LocationFacility} />
                    </div>
                    {!addNew && (
                        <Button className={classes.addTeamMemberPopupButton} onClick={()=> setAddNew(true)}>
                            <img alt='' src={require('../../../Assets/Images/plusRedBackgroundIcon.png')} width={16} height={16} style={{marginRight: 8}} />
                            Add Team Member
                        </Button>
                    )}
                    {!addNew && (
                        <Button className={classes.copyInvitationLinkPopupButton} onClick={()=> copyInvitationLink()}>
                            Copy Invitaion Link
                        </Button>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}
