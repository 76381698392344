const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

export default (theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.primary.secondary,
      },
    },
    '& 	.MuiOutlinedInput-root': {
      textAlign: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 30,
    },
  },
  backdrop: {
    zIndex: 9999999999,
  },
  backdropCircular: {
    zIndex: 999999999999,
    color: '#4fb4e8',
  },
  alreadyHaveAccount: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: theme.palette.primary.dark,
    fontFamily: "'sofia-pro', sans-serif",
    marginTop: theme.spacing(2),
    fontSize: 18,
  },
  generalError: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontFamily: "'sofia-pro', sans-serif",
    textAlign: 'left',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: 18,
  },
  signIn: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontFamily: "'sofia-pro', sans-serif",
    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 18,
  },
  cancel: {
    color: theme.palette.primary.main,
    fontFamily: "'sofia-pro', sans-serif",
    cursor: 'pointer',
    fontSize: 17,
    color: '#4A4A4A',
    opacity: 0.5,
    textTransform: 'none',
  },
  formContainer: {
    position: 'relative',
    // maxWidth: 500,
    marginBottom: mobile ? 30 : 50,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: mobile ? 20 : 20,
    width: '100%',
    boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.3);',
  },
  submit: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 16,
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: 18,
    height: 55,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  errorMessage: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    fontFamily: theme.typography.sofiaPro,
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 2,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  memberDetails: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: "'sofia-pro', sans-serif",
    fontSize: 14,
  },
  loading: {
    marginTop: theme.spacing(2.5),
    height: 55,
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    width: '25ch',
  },
  errorUser: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontSize: mobile ? 16 : 22,
    marginTop: theme.spacing(2),
    fontFamily: "'sofia-pro', sans-serif",
  },
  errorSignupButton: {
    color: '#4fb4e8',
    fontFamily: "'sofia-pro', sans-serif",
    alignSelf: 'baseline',
    textDecoration: 'underline',
    fontSize: mobile ? 16 : 22,
  },
  submit: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 16,
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: 18,
    height: 55,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  facebookSubmit: {
    backgroundColor: '#1877F2',
    color: 'white',
    borderRadius: 16,
    textTransform: 'none',
    fontSize: mobile ? 18 : 24,
    height: mobile ? 48 : 62,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    marginTop: 200,
    '&:hover': {
      backgroundColor: '#1877F2 !important',
    },
  },
  googleSubmit: {
    backgroundColor: '#FFF',
    color: 'white',
    borderRadius: 16,
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: mobile ? 18 : 24,
    height: mobile ? 48 : 62,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    '&:hover': {
      backgroundColor: '#FFF !important',
    },
  },
  emailSubmit: {
    backgroundColor: '#4fb4e8',
    color: 'white',
    borderRadius: 16,
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: mobile ? 18 : 24,
    height: mobile ? 48 : 62,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    '&:hover': {
      backgroundColor: '#4fb4e8 !important',
    },
  },
  dontHaveAccount: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: theme.palette.primary.dark,
    fontFamily: "'sofia-pro', sans-serif",
    marginTop: theme.spacing(4),
    fontSize: mobile ? 18 : 24,
  },
  signUp: {
    alignSelf: 'baseline',
    display: 'inline-block',
    fontFamily: "'sofia-pro', sans-serif",
    color: theme.palette.primary.main,

    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontSize: mobile ? 18 : 24,
  },
  formContainerStep1: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: mobile ? 400 : 600,
    backgroundColor: mobile ? 'transparent' : 'white',
    borderRadius: 30,
    padding: mobile ? 20 : 40,
    width: '100%',
    // boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.1);'
  },
});
