const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

export default (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    fontFamily: "'sofia-pro', sans-serif",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  movePatientButton: {
    width: '100%',
    height: 44,
    borderRadius: 10,
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  // root: {
  //     '& .MuiTextField-root': {
  //     marginTop: theme.spacing(1)
  //     },
  //     '& .MuiButton-root': {
  //         // marginTop: theme.spacing(2),
  //         "&:hover": {
  //             backgroundColor: theme.palette.primary.secondary
  //         }
  //     },
  //     '& 	.MuiOutlinedInput-root': {
  //     textAlign: 'center'
  //     },
  //     '& .MuiOutlinedInput-notchedOutline': {
  //     borderRadius: 30
  //     },
  // },
  backdrop: {
    zIndex: 9999999999,
  },
  backdropCircular: {
    zIndex: 999999999999,
    color: '#4fb4e8',
  },
  almostThereLabel: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 42,
    fontWeight: 700,
    marginTop: 24,
    textAlign: 'center',
  },
  almostThereText: {
    color: '#313131',
    fontSize: 20,
    fontWeight: 400,
    opacity: 0.8,
    marginTop: 24,
    width: mobile ? '100%' : 520,
    textAlign: 'center',
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 15,
    fontWeight: 900,
  },
  studiesTitle: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 12,
    opacity: 0.4,
  },
  noStudiesDescription: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 18,
  },
  virtualStudiesTitle: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 12,
    marginLeft: 8,
    opacity: 1.0,
  },
  sponsoredLabel: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 12,
    fontWeight: 700,
  },
  studyNamePage: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    fontSize: mobile ? 18 : 26,
    fontWeight: 700,
  },
  progressBar: {
    height: 10,
  },
  sectionTitlePage: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    fontSize: mobile ? 18 : 24,
    fontWeight: 700,
    whiteSpace: 'break-spaces',
  },
  sectionTextPage: {
    color: theme.palette.primary.dark,
    fontSize: mobile ? 16 : 18,
    fontWeight: 400,
    marginTop: 12,
    whiteSpace: 'break-spaces',
  },
  locationTitlePage: {
    color: theme.palette.primary.dark,
    fontSize: 15,
    fontWeight: 400,
  },
  locationSubTitlePage: {
    color: theme.palette.primary.dark,
    fontSize: 15,
    fontWeight: 400,
    opacity: 0.4,
  },
  sectionSubTitlePage: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 12,
    fontWeight: 700,
    marginTop: mobile ? 12 : 24,
  },
  companyNamePage: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    fontSize: mobile ? 12 : 16,
    fontWeight: 700,
    opacity: 0.4,
  },
  phaseBox: {
    backgroundColor: 'rgba(0,0,0,0.08)',
    borderRadius: 4,
    display: 'flex',
    padding: 4,
    alignItems: 'center',
    height: 22,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 12,
    fontWeight: 'bold',
    alignContent: 'center',
    justifyContent: 'center',
  },
  phaseStatus: {
    color: '#4A4A4A',
    opacity: 0.6,
    marginLeft: 4,
  },
  boxes: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: 12,
  },
  virtualTag: {
    marginTop: -1,
    marginLeft: 8,
  },
  mobileApply: {
    height: 48,
    borderRadius: 12,
    backgroundColor: '#4fb4e8',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
    display: 'inline-block',
    width: 'calc(100% - 24px)',
    fontSize: 16,
    left: 12,
    position: 'fixed',
    bottom: 18,
    boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.14)',
  },
  desktopApply: {
    marginTop: 0,
    color: 'white',
    height: 42,
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 15,
    backgroundColor: '#4fb4e8',
    paddingLeft: 32,
    paddingRight: 32,
  },
  desktopApplyWidthButton: {
    marginTop: 0,
    color: 'white',
    height: 42,
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 15,
    backgroundColor: '#4fb4e8',
    paddingLeft: 32,
    paddingRight: 32,
    width: '100%',
    marginTop: 24,
  },
  dividerPage: {
    backgroundColor: 'rgba(0,0,0,0.14)',
    width: '100%',
    height: 2,
    marginTop: 24,
    marginBottom: 24,
  },
  applyButton: {
    backgroundColor: theme.palette.primary.main,
    height: 42,
    borderRadius: 10,
    fontSize: 15,
    width: '100%',
    fontWeight: 700,
    textTransform: 'none',
    color: 'white',
  },
  question: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    fontSize: mobile ? 28 : 48,
    fontWeight: 900,
    marginBottom: 12,
    marginTop: 24,
  },
  studiesTitle: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    fontSize: mobile ? 18 : 24,
    fontWeight: 900,
    marginBottom: 12,
    marginTop: 24,
  },
  iconText: {
    fontFamily: theme.typography.sofiaPro,
    color: theme.palette.primary.main,
    fontSize: mobile ? 12 : 15,
    fontWeight: 'bold',
    marginLeft: 0,
  },
  additionalStudiesTitle: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.sofiaPro,
    opacity: '50%',
    fontSize: mobile ? 12 : 12,
    fontWeight: 900,
    marginBottom: 12,
    marginTop: 24,
  },
  faq: {
    color: 'white',
    fontFamily: theme.typography.sofiaPro,
    fontSize: mobile ? 24 : 38,
    fontWeight: 900,
    marginBottom: 8,
    marginTop: 32,
  },
  shareIcon: {
    fontFamily: theme.typography.sofiaPro,
    fontSize: 15,
    fontWeight: 600,
    marginTop: 8,
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  footerSectionTitle: {
    color: 'white',
    fontFamily: theme.typography.sofiaPro,
    fontSize: !mobile ? 20 : 18,
    fontWeight: 600,
    marginBottom: 24,
  },
  footerSectionLink: {
    color: 'white',
    fontSize: !mobile ? 20 : 18,
    marginTop: 18,
    marginBottom: 18,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  copyright: {
    color: 'white',
    opacity: 0.7,
    fontSize: !mobile ? 22 : 18,
    marginTop: 18,
    marginBottom: 18,
    cursor: 'pointer',
  },
  faqTitle: {
    color: 'white',
    fontFamily: theme.typography.sofiaPro,
    fontSize: !mobile ? 22 : 18,
    fontWeight: 900,
    marginTop: 32,
    marginBottom: 32,
  },
  faqText: {
    color: 'white',
    fontSize: !mobile ? 18 : 14,
  },
  findStudiesButton: {
    width: mobile ? '100%' : 200,
    height: 54,
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: 24,
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 12,
    textTransform: 'none',
  },
  diseaseDescription: {
    fontSize: mobile ? 15 : 24,
    color: '#31313170',
    width: mobile ? '100%' : '70%',
  },
  helper: {
    color: theme.palette.primary.dark,
    opacity: 0.4,
    fontFamily: theme.typography.sofiaPro,
    fontSize: 17,
    fontWeight: 600,
    marginBottom: 12,
    marginTop: 12,
  },
  yesno: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4fb4e8',
    height: 50,
    width: mobile ? '100%' : 400,
    color: '#A84545',
    fontSize: 17,
    textAlign: 'left',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  selected_yesno: {
    background: theme.palette.primary.main,
    height: 50,
    width: mobile ? '100%' : 400,
    color: '#FFFFFF',
    fontSize: 17,
    textAlign: 'left',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  question_red: {
    color: theme.palette.primary.main,
  },
  selectedBox: {
    // width: mobile ? '100%' : 400,
    width: '100%',
    height: 46,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    backgroundColor: '#FDEFF0',
    color: '#A84545',
    fontFamily: theme.typography.sofiaPro,
    fontWeight: 600,
    fontSize: 17,
    // marginTop: 12,
    cursor: 'pointer',
  },
  boxTitle: {
    marginTop: 20,
    fontSize: 22,
    color: 'rgba(49, 49, 49, 0.8)',
  },
  scheduleTitle: {
    marginTop: 20,
    fontSize: 12,
    // lineHeight: 20,
    fontWeight: 'bold',
    letterSpacing: '2.92px',
    color: '#4fb4e8',
  },
  boxSubTitle: {
    marginTop: 15,
    marginBottom: 20,
    fontSize: 17,
    color: 'rgba(49, 49, 49, 0.8)',
  },
  selectBox: {
    // width: mobile ? '100%' : 400,
    width: '100%',
    height: 46,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    backgroundColor: '#FEF6F5',
    color: '#CB8F8F',
    fontFamily: theme.typography.sofiaPro,
    fontWeight: 600,
    fontSize: 17,
    // marginTop: 12,
    cursor: 'pointer',
  },
  nextButton: {
    textTransform: 'none',
    width: 132,
    height: 52,
    borderRadius: 26,
    color: theme.palette.primary.main,
    border: '2px solid black',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'white',
    background: 'white',
    '&:hover': {
      color: 'white',
    },
    '&:disabled': {
      color: 'rgba(0,0,0,0.3)',
      borderColor: 'rgba(0,0,0,0.3)',
    },
    fontSize: 17,
  },
  // grayNextButton: {

  // },
  previousButton: {
    textTransform: 'none',
    width: 132,
    height: 52,
    borderRadius: 26,
    color: 'rgba(0,0,0,0.3)',
    fontSize: 17,
    backgroundColor: 'transparent',
    fontFamily: theme.typography.sofiaPro,
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
      // background: 'transparent',
      // backgroundColor: 'black'
    },
  },
  alreadyHaveAccount: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: theme.palette.primary.dark,
    fontFamily: "'sofia-pro', sans-serif",
    marginTop: theme.spacing(2),
    fontSize: 18,
  },
  generalError: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontFamily: "'sofia-pro', sans-serif",
    textAlign: 'left',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: 18,
  },
  signIn: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontFamily: "'sofia-pro', sans-serif",
    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 18,
  },
  cancel: {
    color: theme.palette.primary.main,
    fontFamily: "'sofia-pro', sans-serif",
    cursor: 'pointer',
    fontSize: 17,
    color: '#4A4A4A',
    opacity: 0.5,
    textTransform: 'none',
  },
  formContainer: {
    position: 'relative',
    // maxWidth: 500,
    marginBottom: mobile ? 30 : 50,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: mobile ? 20 : 20,
    width: '100%',
    boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.3);',
  },
  errorMessage: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    textAlign: 'left',
    fontSize: 16,
    marginLeft: 2,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  memberDetails: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: "'sofia-pro', sans-serif",
    fontSize: 14,
  },
  loading: {
    marginTop: theme.spacing(2.5),
    height: 55,
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    width: '25ch',
  },
  errorUser: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontSize: mobile ? 16 : 22,
    marginTop: theme.spacing(2),
    fontFamily: "'sofia-pro', sans-serif",
  },
  errorSignupButton: {
    color: '#4fb4e8',
    fontFamily: "'sofia-pro', sans-serif",
    alignSelf: 'baseline',
    textDecoration: 'underline',
    fontSize: mobile ? 16 : 22,
  },
  facebookSubmit: {
    backgroundColor: '#1877F2',
    color: 'white',
    borderRadius: 16,
    textTransform: 'none',
    fontSize: mobile ? 18 : 18,
    height: mobile ? 48 : 48,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    // width: mobile ? '100%' : 400,
    width: '100%',
    '&:hover': {
      backgroundColor: '#1877F2 !important',
    },
  },
  submit: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 27.5,
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: 18,
    width: '100%',
    height: 55,
  },
  emailSubmit: {
    backgroundColor: '#4fb4e8',
    color: 'white',
    borderRadius: 16,
    textTransform: 'none',
    fontSize: mobile ? 18 : 18,
    height: mobile ? 48 : 48,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    // width: mobile ? '100%' : 400,
    width: '100%',
    '&:hover': {
      backgroundColor: '#4fb4e8 !important',
    },
  },
  googleSubmit: {
    backgroundColor: '#FFF',
    color: 'white',
    borderRadius: 16,
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: mobile ? 18 : 18,
    height: mobile ? 48 : 48,
    // width: mobile ? '100%' : 400,
    width: '100%',
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    '&:hover': {
      backgroundColor: '#FFF !important',
    },
  },
  submitWithEmail: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 16,
    textTransform: 'none',
    fontSize: mobile ? 18 : 18,
    height: mobile ? 48 : 48,
    // width: mobile ? '100%' : 400,
    width: '100%',
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  facebookSubmitPage: {
    backgroundColor: '#1877F2',
    color: 'white',
    borderRadius: 16,
    textTransform: 'none',
    fontSize: mobile ? 18 : 18,
    height: mobile ? 48 : 48,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    marginTop: 42,
    // width: mobile ? '100%' : 400,
    width: '100%',
    '&:hover': {
      backgroundColor: '#1877F2 !important',
    },
  },
  googleSubmitPage: {
    backgroundColor: '#FFF',
    color: 'white',
    borderRadius: 16,
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: mobile ? 18 : 18,
    height: mobile ? 48 : 48,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    // width: mobile ? '100%' : 400,
    width: '100%',
    '&:hover': {
      backgroundColor: '#FFF !important',
    },
  },
  dontHaveAccount: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: theme.palette.primary.dark,
    fontFamily: "'sofia-pro', sans-serif",
    marginTop: theme.spacing(4),
    fontSize: mobile ? 18 : 24,
  },
  signUp: {
    alignSelf: 'baseline',
    display: 'inline-block',
    fontFamily: "'sofia-pro', sans-serif",
    color: theme.palette.primary.main,

    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontSize: mobile ? 18 : 24,
  },
  formContainerStep1: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: mobile ? 400 : 600,
    backgroundColor: mobile ? 'transparent' : 'white',
    borderRadius: 30,
    padding: mobile ? 20 : 40,
    width: '100%',
    // boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.1);'
  },
});
