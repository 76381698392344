import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
// import MuiStyleFunction from './Card.style';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

// const useStyles = makeStyles(MuiStyleFunction);

const Skeleton = ({data}) => {

  // const classes = useStyles();

  return (
    <div>
      <Grid container direction="row" alignItems="flex-start" spacing={0}>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'left'}}>
            <Skeleton variant="rect" width={120} height={30} style={{marginTop: 50}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="rect" width={'70%'} height={50} style={{marginTop: 20}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="rect" width={'20%'} height={30} style={{marginTop: 20}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="rect" width={'100%'} height={4} style={{marginTop: 40}}/>
          </Grid>
      </Grid>
      <Grid container direction="row" alignItems="flex-start" spacing={0}>
        <Grid item xs={12} sm={12} md={7} xl={7} style={{alignItems: 'left'}}>
          <Grid container direction="row" alignItems="flex-start" spacing={0}>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="rect" width={'22%'} height={30} style={{marginTop: 40}}/>
          </Grid>
          <Grid item xs={6} sm={6} md={6} xl={6} style={{alignItems: 'center'}}>
            <Skeleton variant="circle" width={60} height={60} style={{marginTop: 40, display: 'inline-block', verticalAlign: 'middle'}}/>
            <Skeleton variant="text" width={110} style={{marginTop: 38, marginLeft: 14, display: 'inline-block', verticalAlign: 'middle'}}/>
          </Grid>
          <Grid item xs={6} sm={6} md={6} xl={6} style={{alignItems: 'center'}}>
            <Skeleton variant="circle" width={60} height={60} style={{marginTop: 40, display: 'inline-block', verticalAlign: 'middle'}}/>
            <Skeleton variant="text" width={110} style={{marginTop: 38, marginLeft: 14, display: 'inline-block', verticalAlign: 'middle'}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="rect" width={'22%'} height={30} style={{marginTop: 60}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'15%'} style={{marginTop: 40}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'50%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'15%'} style={{marginTop: 40}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'80%'} style={{marginTop: 14}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
            <Skeleton variant="text" width={'50%'} style={{marginTop: 14}}/>
          </Grid>
        </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5} xl={5} style={{alignItems: 'left'}}>
        <Skeleton variant="rect" width={'100%'} height={350} style={{marginTop: 14, marginTop: 40}}/>
        <Skeleton variant="rect" width={'100%'} height={50} style={{marginTop: 14, marginTop: 20}}/>
        <Skeleton variant="rect" width={'30%'} height={32} style={{marginTop: 14, marginTop: 20, display: 'inline-block', marginLeft: 0}}/>
        <Skeleton variant="rect" width={'20%'} height={32} style={{marginTop: 14, marginTop: 20, display: 'inline-block', marginLeft: 12}}/>
        <Skeleton variant="rect" width={'10%'} height={32} style={{marginTop: 14, marginTop: 20, display: 'inline-block', marginLeft: 12}}/>
        </Grid>
      </Grid>
    </div>
  );
}
 
export default Skeleton;
