import firebase, { storage, analytics } from '../../Firebase';
import axios from 'axios';
import UserService from '../UserService/UserService';

export default {
  centerWelcomeMessage,
  studyWelcomeMessage,
  baaContract,
  sponsoredStudyCenterNewPatient,
  sponsoredStudyCenterVisitConfirmed,
  patientIneligible,
  patientConfirmVisit,
  centerReminder,
  exitSurveySignLater,
  notifyClientOnNewCenterSignup,
  rescheduleVisit,
  patientVisitConfirmation,
  virtualVisitBooked,
  patientOrStudyAskForReschedule,
};

const db = firebase.firestore();

async function sendEmail(type, data) {
  var response = await axios.post(
    `https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net/${type}`,
    data,
    { headers: { 'Content-Type': 'application/json' } }
  );
  return response;
}

async function sponsoredStudyCenterVisitConfirmed(
  email,
  patientFirstName,
  date,
  time,
  addToCalendarLink
) {
  let adminData = await UserService.getAdminDataByEmail(email);
  var data = {
    email,
    firstName: adminData.firstName,
    patientFirstName,
    date,
    time,
    addToCalendarLink: addToCalendarLink,
  };
  sendEmail('sponsoredStudyCenterVisitConfirmed', data);
}

function patientVisitConfirmation(
  email,
  studyName,
  patientFirstName,
  date,
  time,
  phone,
  addToCalendarLink,
  lang = 'en'
) {
  var data = {
    email,
    studyName,
    patientFirstName,
    date,
    time,
    addToCalendarLink,
    phone,
    lang,
  };
  sendEmail('patientVisitConfirmation', data);
}

async function virtualVisitBooked(
  email,
  patientFirstName,
  studyCenter,
  addToCalendarLink,
  applicationId,
  date,
  time,
  phone,
  userType,
  lang = 'en'
) {
  var data = {
    email,
    patientFirstName,
    studyCenter,
    addToCalendarLink,
    applicationId,
    date,
    time,
    phone,
    userType,
    lang,
  };
  await sendEmail('virtualVisitBooked', data);
  return true;
}

async function patientOrStudyAskForReschedule(
  email,
  patientFirstName,
  studyCenter,
  date,
  time,
  phone,
  userType,
  lang = 'en'
) {
  var data = {
    email,
    patientFirstName,
    studyCenter,
    date,
    time,
    phone,
    userType,
    lang,
  };
  await sendEmail('patientOrStudyAskForReschedule', data);
  return true;
}

async function notifyClientOnNewCenterSignup(email, StudyName, StudyCenter) {
  var data = {
    email,
    StudyName,
    StudyCenter,
  };
  // console.log('data', data)
  await sendEmail('studyCenterSignedUp', data);
  return true;
}

async function exitSurveySignLater(
  email,
  patientFirstName,
  docLink,
  studyName
) {
  var data = {
    email,
    patientFirstName,
    docLink,
    studyName,
  };
  await sendEmail('exitSurveySignLater', data);
  return true;
}

async function centerWelcomeMessage(
  email,
  invitationId,
  companyName,
  studyName
) {
  var data = {
    email,
    invitationId,
    companyName,
    studyName,
  };
  await sendEmail('centerWelcomeMessage', data);
  return true;
}

async function centerReminder(
  email,
  invitationId,
  firstName,
  studyName,
  companyName
) {
  var data = {
    email,
    invitationId,
    firstName,
    studyName,
    companyName,
  };
  await sendEmail('centerReminder', data);
  return true;
}

async function studyWelcomeMessage(email, invitationId, studyName) {
  var data = {
    email,
    invitationId,
    studyName,
  };
  await sendEmail('studyWelcomeMessage', data);
  return true;
}

async function baaContract(email, studyCenterName, studyCenterUserName, date) {
  var data = {
    email,
    studyCenterName,
    studyCenterUserName,
    date,
  };
  await sendEmail('baaContract', data);
  return true;
}

async function sponsoredStudyCenterNewPatient(
  email,
  firstName,
  studyName,
  applicationId
) {
  var data = {
    email,
    firstName,
    studyName,
    applicationId,
  };
  await sendEmail('sponsoredStudyCenterNewPatient', data);
  return true;
}

// function sponsoredStudyCenterVisitConfirmed(email, firstName, patientFirstName, date, time) {
//     var data = {
//         email,
//         firstName,
//         date,
//         time
//     }
//     sendEmail('sponsoredStudyCenterVisitConfirmed', data)
// }

async function patientIneligible(email, patientFirstName, studyName) {
  var data = {
    email,
    patientFirstName,
    studyName,
  };
  await sendEmail('patientIneligible', data);
  return true;
}

async function patientConfirmVisit(
  email,
  studyName,
  patientFirstName,
  date,
  time,
  applicationId,
  phone,
  lang = 'en'
) {
  var data = {
    email,
    studyName,
    patientFirstName,
    date,
    time,
    applicationId,
    phone,
    lang,
  };
  await sendEmail('patientConfirmVisit', data);
  return true;
}

async function rescheduleVisit(
  email,
  studyName,
  patientFirstName,
  date,
  time,
  applicationId
) {
  var data = {
    email,
    studyName,
    patientFirstName,
    date,
    time,
    applicationId,
  };
  await sendEmail('rescheduleVisit', data);
  return true;
}
