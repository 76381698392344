import React, {useState, useEffect, useContext} from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Login.style';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { CircularProgress } from '@material-ui/core';
var moment = require('moment-timezone');
const useStyles = makeStyles(MuiStyleFunction);

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

const Step1 = ({setFirstDiagnosed, setDisease, errorDisease, setStep, from, to,
    handleChangeFrom,
    handleChangeTo,
    handleChangeDay1,
    handleChangeDay2,
    handleChangeDay3,
    handleChangeDay4,
    handleChangeDay5,
    handleChangeDay6,
    day1,
    day2,
    day3,
    day4,
    day5,
    day6,
    applying,
    applyToStudy,
    setApplying,
    notSpecified,
    setNotSpecified
}) => {

    const classes = useStyles();

    const canApply = () => {
        if (day1 || day2 || day3 || day4 || day5 || day6) {
            if (from !== '' && to !== '') {
                return true
            }
        }
        return false
    }

    return (
        <div className={classes.root} style={{textAlign: 'left'}}>
            <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={0} style={{marginTop: 12}}>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography className={classes.title} style={{marginTop: 16, fontFamily: "'sofia-pro', sans-serif"}}>AVAILABILITY</Typography>
                        <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                            <div style={{fontFamily: "'sofia-pro', sans-serif", fontSize: 14, color: '#4A4A4A'}}>
                                Not Specified
                            </div>
                            <AntSwitch checked={notSpecified} onChange={(e) => setNotSpecified(e.target.checked)} />
                        </div>
                    </div>
                    {/* <Typography className={classes.helper}>Timezone - {moment.tz.guess()}</Typography> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} style={{marginBottom: 16, paddingBottom: 0, textAlign: 'left'}}>
                    <Typography className={classes.scheduleTitle} variant="h2">HOURS</Typography>
                    <FormControl variant="outlined" className={classes.formControl} style={{width: '48%', backgroundColor: 'white', marginTop: 16}}>
                        <TextField disabled={notSpecified} id="select" label={"From"} value={from} style={{fontFamily: "'sofia-pro', sans-serif"}} onChange={handleChangeFrom} placeholder={'05:00 PM'} select>
                        <MenuItem style={{fontFamily: "'sofia-pro', sans-serif"}} value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'08:00 AM'}>08:00 AM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'09:00 AM'}>09:00 AM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'10:00 AM'}>10:00 AM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'11:00 AM'}>11:00 AM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'12:00 PM'}>12:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'01:00 PM'}>01:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'02:00 PM'}>02:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'03:00 PM'}>03:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'04:00 PM'}>04:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'05:00 PM'}>05:00 PM</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl} style={{width: '48%', backgroundColor: 'white', marginLeft: '4%', marginTop: 16}}>
                        <TextField disabled={notSpecified} id="select" label={"To"} value={to} style={{fontFamily: "'sofia-pro', sans-serif"}} onChange={handleChangeTo} placeholder={'05:00 PM'} select>
                        <MenuItem style={{fontFamily: "'sofia-pro', sans-serif"}} value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'08:00 AM'}>08:00 AM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'09:00 AM'}>09:00 AM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'10:00 AM'}>10:00 AM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'11:00 AM'}>11:00 AM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'12:00 PM'}>12:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'01:00 PM'}>01:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'02:00 PM'}>02:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'03:00 PM'}>03:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'04:00 PM'}>04:00 PM</MenuItem>
                        <MenuItem disabled={notSpecified} style={{fontFamily: "'sofia-pro', sans-serif"}} value={'05:00 PM'}>05:00 PM</MenuItem>
                        </TextField>
                    </FormControl>
                    {/* {moment.tz.guess()} */}
                    <Typography className={classes.scheduleTitle} style={{marginTop: 24, marginBottom: 16}} variant="h2">DAYS</Typography>
                    <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                        <Grid item xs={4} sm={4} md={2} xl={2}>
                        <div style={{alignItems: 'center', width: '100%', padding: 12, backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)'}}>
                            <center>
                            <Checkbox
                            disabled={notSpecified}
                            checked={day1}
                            onChange={handleChangeDay1}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <div style={{fontFamily: "'sofia-pro', sans-serif", fontSize: 14, color: '#4A4A4A', opacity: day1 ? 1 : 0.5, marginTop: -4, fontWeight: 500}}>
                            Sun
                            </div>
                            </center>
                        </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} xl={2}>
                        <div style={{alignItems: 'center', width: '100%', padding: 12, backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)'}}>                                <center>
                            <Checkbox
                                disabled={notSpecified}
                                checked={day2}
                                onChange={handleChangeDay2}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <div style={{fontFamily: "'sofia-pro', sans-serif", fontSize: 14, color: '#4A4A4A', opacity: day2 ? 1 : 0.5, marginTop: -4, fontWeight: 500}}>
                                Mon
                            </div>
                            </center>
                        </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} xl={2}>
                        <div style={{alignItems: 'center', width: '100%', padding: 12, backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)'}}>                                <center>
                            <Checkbox
                                disabled={notSpecified}
                                checked={day3}
                                onChange={handleChangeDay3}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <div style={{fontFamily: "'sofia-pro', sans-serif", fontSize: 14, color: '#4A4A4A', opacity: day3 ? 1 : 0.5, marginTop: -4, fontWeight: 500}}>
                                Tue
                            </div>
                            </center>
                        </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} xl={2}>
                        <div style={{alignItems: 'center', width: '100%', padding: 12, backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)'}}>                              <center>
                            <Checkbox
                            disabled={notSpecified}
                            checked={day4}
                            onChange={handleChangeDay4}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <div style={{fontFamily: "'sofia-pro', sans-serif", fontSize: 14, color: '#4A4A4A', opacity: day4 ? 1 : 0.5, marginTop: -4, fontWeight: 500}}>
                            Wed
                            </div>
                            </center>
                        </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} xl={2}>
                        <div style={{alignItems: 'center', width: '100%', padding: 12, backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)'}}>                                <center>
                            <Checkbox
                                disabled={notSpecified}
                                checked={day5}
                                onChange={handleChangeDay5}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <div style={{fontFamily: "'sofia-pro', sans-serif", fontSize: 14, color: '#4A4A4A', opacity: day5 ? 1 : 0.5, marginTop: -4, fontWeight: 500}}>
                                Thu
                            </div>
                            </center>
                        </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} xl={2}>
                            <div style={{alignItems: 'center', width: '100%', padding: 12, backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)'}}>                                <center>
                            <Checkbox
                                disabled={notSpecified}
                                checked={day6}
                                onChange={handleChangeDay6}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <div style={{fontFamily: "'sofia-pro', sans-serif", fontSize: 14, color: '#4A4A4A', opacity: day6 ? 1 : 0.5, marginTop: -4, fontWeight: 500}}>
                                Fri
                            </div>
                            </center>
                        </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
 
export default React.memo(Step1);