import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../../../node_modules/antd/dist/antd.css';
import { Table } from 'antd';
import { Popover, Button } from 'antd';
import { FilterContext } from '../../../contexts/Filter';
import moment from 'moment';
import Status from '../Study/Status';
import { Stack } from '@mui/material';
import Stage from '../Study/Stage';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { InsertDriveFile } from '@material-ui/icons';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "'sofia-pro', sans-serif",
  },
  mobileTitle: {
    fontSize: 22,
    color: theme.palette.primary.dark,
    paddingBottom: 24,
  },
  searchInput: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    maxWidth: 350,
    marginBottom: 16,
    height: 38,
    borderRadius: 10,
    color: '#4A4A4A',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: theme.typography.main,
    border: 'none',
    paddingRight: 8,
    paddingLeft: 8,
    // marginTop: 6,
    paddingRight: 32,
    marginTop: 16,
  },
  filterButton: {
    width: 76,
    marginTop: 16,
    height: 38,
    borderRadius: 8,
    backgroundColor: '#4fb4e830',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.main,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none',
  },
}));

const LeadsTable = ({ leads, audience }) => {
  const classes = useStyle();

  const [filteredData, setFilteredData] = useState([]);
  const [applicationsToShow, setApplicationsToShow] = useState([]);
  const [openEditProceduresDialog, setOpenEditProceduresDialog] =
    useState(false);
  const [applicationInEdit, setApplicationInEdit] = useState(null);

  const [loadingTable, setLoadingTable] = useState(false);
  const [data, setData] = useState(false);

  const renameStatus = (status) => {
    if (status === 'Applied') {
      return 'Applied';
    } else if (status === 'New') {
      return 'New';
    } else if (status === 'Viewed') {
      return 'Viewed';
    } else if (status === 'Schedule Appointment') {
      return 'Applied';
    } else if (status === 'Confirmed Appointment') {
      return 'Scheduled';
    } else if (status === 'Completed') {
      return 'Completed';
    } else if (status === 'Not Eligible') {
      return 'Failed';
    } else if (status === 'Pending') {
      return 'Pending';
    } else if (status === 'TBD') {
      return 'Post Call';
    } else {
      return 'Applied';
    }
  };

  const [columns, setColumns] = useState([
    {
      title: '#',
      dataIndex: 'key',
      width: 64,
    },
    {
      title: 'Date',
      dataIndex: 'appliedAt',
      width: 130,
      fixed: mobile ? 'null' : 'left',
      sorter: {
        compare: (a, b) => a.appliedAt.seconds - b.appliedAt.seconds,
      },
      render: (result) => (
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#4A4A4A',
          }}
        >
          {result.format}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: mobile ? 'null' : 'left',
      width: 200,
      sorter: {
        compare: (a, b) => a.status.status.length - b.status.status.length,
      },
      render: (status) => (
        <>
          <Status
            status={status.status}
            application={status.application}
            getStyleForStatus={getStyleForStatus}
            renameStatus={renameStatus}
          />
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
      fixed: mobile ? 'null' : 'left',
      render: (name) => (
        <>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {name.name}
          </div>
        </>
      ),
    },
    // {
    //   title: 'Stage',
    //   dataIndex: 'stage',
    //   width: 200,
    //   sorter: {
    //     compare: (a, b) => a.stage.stage.length - b.stage.stage.length,
    //   },
    //   render: (data) => (
    //     <>
    //       <Stage
    //         stage={data.stage}
    //         application={data.application}
    //         getStyleForStatus={getStyleForStatus}
    //         renameStatus={renameStatus}
    //         editable={false}
    //       />
    //     </>
    //   ),
    // },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 160,
    },
    // {
    //   title: 'Study Center',
    //   dataIndex: 'studyCenter',
    //   width: 250,
    //   render: (center) => <>{center.center}</>,
    // },
    {
      title: 'Gender',
      dataIndex: 'gender',
      width: 100,
      sorter: {
        compare: (a, b) => a.gender.length - b.gender.length,
      },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      width: 100,
      sorter: {
        compare: (a, b) => parseInt(a.age) - parseInt(b.age),
      },
    },
    {
      title: 'Diagnosed',
      dataIndex: 'diagnosed',
      width: 120,
      render: (result) => (
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#4A4A4A',
          }}
        >
          {result.diagnosed ? 'Yes' : 'No'}
        </div>
      ),
    },
    {
      title: 'Symptoms',
      dataIndex: 'symptoms',
      width: 120,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.symptoms.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10, zIndex: 9999999 }}
                zIndexPopup={99999999}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    SYMPTOMS
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.symptoms.map((symptom, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {symptom}
                      </div>
                    ))}
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Medications',
      dataIndex: 'medications',
      width: 120,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.medications.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                zIndexPopup={99999999}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    MEDICATIONS
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.medications.map((medication, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {medication}
                      </div>
                    ))}
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 260,
    },
    {
      title: 'Phone',
      width: 160,
      dataIndex: 'phone',
    },
    {
      title: 'Procedures',
      dataIndex: 'procedures',
      width: 160,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.procedures.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                zIndexPopup={99999999}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    PROCEDURES
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.procedures.map((procedure, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {procedure}
                      </div>
                    ))}
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Zendesk ID',
      dataIndex: 'zendeskId',
      width: 240,
      render: (value) => (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <div>{value.application?.zendeskId || 'N/A'}</div>
        </Stack>
      ),
    },
    {
      title: 'Records',
      dataIndex: 'files',
      width: 170,
      render: (result) => (
        <>
          {result && result.files.length > 0 ? (
            <Box sx={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <Tooltip title="Download Records">
                <IconButton type="link" href={result.files[0]} target="_blank">
                  <InsertDriveFile />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <>No Records</>
          )}
        </>
      ),
    },
    // {
    //   title: 'Notes',
    //   dataIndex: 'note',
    //   width: 170,
    //   render: (result) => (
    //     <>
    //       {!result.status.note ? (
    //         <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
    //           {'Not Added'}
    //         </div>
    //       ) : (
    //         <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
    //           <div
    //             style={{
    //               display: 'inline-block',
    //               verticalAlign: 'middle',
    //               marginRight: 12,
    //             }}
    //           >
    //             {result.selectedOption}
    //           </div>
    //           <Popover
    //             placement="top"
    //             style={{ borderRadius: 10 }}
    //             zIndexPopup={99999999}
    //             title={() => (
    //               <div
    //                 style={{
    //                   color: '#4fb4e8',
    //                   fontSize: 11,
    //                   fontWeight: 900,
    //                   letterSpacing: 1,
    //                   fontFamily: "'sofia-pro', sans-serif",
    //                 }}
    //               >
    //                 NOTE
    //               </div>
    //             )}
    //             content={() => (
    //               <div
    //                 style={{
    //                   color: '#4A4A4A',
    //                   fontSize: 13,
    //                   fontWeight: 'normal',
    //                   letterSpacing: 0.24,
    //                   fontFamily: "'sofia-pro', sans-serif",
    //                   maxWidth: 340,
    //                 }}
    //               >
    //                 {result.status.note}
    //               </div>
    //             )}
    //             trigger="hover"
    //           >
    //             <Button
    //               style={{
    //                 border: 'none',
    //                 padding: 0,
    //                 backgroundColor: 'none',
    //                 background: 'none',
    //                 width: 24,
    //                 height: 24,
    //                 marginTop: 0,
    //               }}
    //             >
    //               {result.status.status === 'Completed' ||
    //               result.status.status === 'Not Eligible' ? (
    //                 <img
    //                   alt=""
    //                   src={require('../../../Assets/Images/reNoteIcon.png')}
    //                   width={24}
    //                   height={24}
    //                 />
    //               ) : (
    //                 <img
    //                   alt=""
    //                   src={require('../../../Assets/Images/blackNoteIcon.png')}
    //                   width={24}
    //                   height={24}
    //                 />
    //               )}
    //             </Button>
    //           </Popover>
    //         </div>
    //       )}
    //     </>
    //   ),
    // },
  ]);

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return str;
  };

  useEffect(() => {
    let applications = leads;
    setApplicationsToShow(applications);
  }, [leads]);

  useEffect(() => {
    if (audience === 'client') {
      let temp = columns;
      setColumns(
        temp.filter(
          (item) =>
            [
              'procedures',
              'medications',
              'symptoms',
              'status',
              'zendeskId',
            ].includes(item.dataIndex) === false,
        ),
      );
    } else {
      let temp = columns;
      setColumns(
        temp.filter((item) => ['stage'].includes(item.dataIndex) === false),
      );
    }
  }, [audience]);

  useEffect(() => {
    let data = [];
    console.log('applicationsToShow', applicationsToShow);
    for (let index = 0; index < applicationsToShow.length; index++) {
      const application = applicationsToShow[index];
      data.push({
        appliedAt: {
          format: moment(application.appliedAt._seconds * 1000).format('ll'),
          seconds: application.appliedAt._seconds,
        },
        zendeskId: { application },
        patientId: application.userId,
        files: { files: application.files || [], application: application },
        age: getAgeFromDate(application.userData.birthDate),
        ethnicity: application.userData.ethnicity,
        name: {
          name:
            application.userData.firstName +
            ' ' +
            application.userData.lastName,
          id: application.userData.firebaseId.substring(0, 9),
        },
        email: application.userData.email,
        phone: formatPhoneNumber(application.userData.phone),
        gender: application.userData.gender,
        location: application.userData.locationData.description,
        // location: application.nearestLocationData.locationData.address_components[0].long_name + ', ' +  application.nearestLocationData.locationData.address_components[0].short_name,
        diagnosed: {
          diagnosed: application.userData.diagnosedByDoctor,
          diagnosedAge: application.userData.diagnosedAge,
        },
        diagnosedAge: application.userData.diagnosedAge,
        studyCenter: {
          center: application.studyLocation,
          application: application,
        },
        status: {
          status: application.status[application.status.length - 1].status,
          application: application,
        },
        stage: {
          stage: application.stage || 'New',
          application: application,
        },
        note: { status: application.status[application.status.length - 1] },
        // note: application.status[application.status.length - 1].note,
        visitResults: application.exitSurvey
          ? application.exitSurvey
          : 'Not Accepted',
        icfReviewed: application.icfReviewed === true ? 'Yes' : 'No',
        symptoms: { symptoms: application.symptoms, application: application },
        medications: {
          medications: application.medications,
          application: application,
        },
        procedures: {
          procedures: getProceduresAsArray(application.procedures),
          application: application,
        },
        conditions: application.userData.otherConditions,
        comments: 'icon',
        utm: application.utm ? application.utm : 'platform',
        travelAssist: application.userData.willingToTravel ? 'Yes' : 'No',
        otherTrials: application.userData.startNewMedicationInTheLast6Month
          ? 'Yes'
          : 'No',
        records: application.records
          ? {
              study: application.studyName,
              application: application,
              ...application.records,
            }
          : {
              study: application.studyName,
              application: application,
              links: [],
            },
        share: application,
      });
    }
    const sortedData = data
      .sort((a, b) => a.appliedAt.seconds - b.appliedAt.seconds)
      .map((i, index) => ({ key: index + 1, ...i }));
    setData(sortedData);
    setFilteredData(sortedData);
    setLoadingTable(false);
  }, [applicationsToShow]);

  const getAgeFromDate = (date) => {
    // if (studyId === 'NCT05291091') return 'N/A';
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    const age = moment().diff(formattedDate, 'years', false);
    return age === 23 ? 'N/A' : age;
  };

  const getProceduresAsArray = (procedures) => {
    var objectConstructor = {}.constructor;
    if (procedures.constructor === objectConstructor) {
      let proceduresArray = [];
      for (let index = 0; index < Object.keys(procedures).length; index++) {
        const procedure = Object.keys(procedures)[index];
        if (procedures[procedure]) {
          proceduresArray.push(procedure);
        }
      }
      return proceduresArray;
    } else {
      return procedures;
    }
  };

  const getStyleForStatus = (status) => {
    if (status === 'Applied') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4fb4e830',
        color: '#4fb4e8',
        textAlign: 'center',
        paddingTop: 2,
      };
    }
    if (status === 'New') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4fb4e830',
        color: '#4fb4e8',
        textAlign: 'center',
        paddingTop: 2,
      };
    }
    if (status === 'Viewed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DBDBDB',
        color: '#4fb4e8',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Completed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DDF5DD',
        color: '#57CE54',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Failed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4A4A4A',
        color: 'white',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Confirmed Appointment') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Schedule Appointment') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Pending') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DBDBDB',
        color: '#4A4A4A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    }
  };

  return (
    <div
      style={{
        fontFamily: "'sofia-pro', sans-serif",
        color: '#4fb4e8',
        overflowY: 'scroll',
      }}
    >
      <Table
        loading={loadingTable}
        pagination={['bottomLeft', 'bottomRight']}
        scroll={{ x: mobile ? '100%' : 1545 }}
        columns={columns}
        dataSource={filteredData}
        style={{ marginTop: mobile ? 0 : 12, color: '#4fb4e8', zIndex: -100 }}
      />
    </div>
  );
};

export default LeadsTable;

const PopoverButton = () => {
  return (
    <Button
      style={{
        border: 'none',
        padding: 0,
        backgroundColor: 'none',
        background: 'none',
        width: 24,
        height: 24 * 1.125,
        marginLeft: 4,
        display: 'inline-block',
        verticalAlign: 'middle',
        marginTop: 0,
      }}
    >
      <img
        alt=""
        src={require('../../../Assets/Images/infoIcon.png')}
        style={{ width: 14, height: 14 * 1.125 }}
      />
    </Button>
  );
};

const PopoverWrapper = ({ title, content }) => {
  return (
    <Popover
      placement="top"
      style={{ borderRadius: 10, zIndex: 9999999 }}
      zIndexPopup={99999999}
      title={() => (
        <div
          style={{
            color: '#4fb4e8',
            fontSize: 11,
            fontWeight: 900,
            letterSpacing: 1,
            fontFamily: "'sofia-pro', sans-serif",
          }}
        >
          {title}
        </div>
      )}
      content={() => content}
      trigger="hover"
    >
      <PopoverButton />
    </Popover>
  );
};
