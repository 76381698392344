import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import MuiStyleFunction from './Card.style';
import Menu from '@material-ui/core/Menu';
import Switch from '@material-ui/core/Switch';
import { CircularProgress } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 27,
    padding: 0,
  },
  switchBase: {
    'padding': 1,
    '&$checked': {
      'transform': 'translateX(16px)',
      'color': theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Card = ({ data, updateStudies }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [changingToSponsored, setChangingToSponsored] = useState(false);

  const shortcutText = (text, max) => {
    if (text[0].length > max) {
      var res = text[0].substring(0, max);
      return res + '...';
    } else {
      return text[0];
    }
  };

  const navigateToStudy = () => {
    navigate('/home/study/' + data.document.id);
  };

  const handleChangeSwitch = (event) => {
    if (event.target.name === 'isSponsored') {
      setChangingToSponsored(true);
      updateStudies(data.document.ref, { isSponsored: event.target.checked });
    }
  };

  return (
    <Grid item xs={12} sm={4} md={4} xl={3}>
      <div className={classes.clinical} style={{ position: 'relative' }}>
        <div
          style={{
            paddingTop: 22,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 22,
          }}
        >
          {/* <IconButton style={{position: 'absolute', top: 18, right: 12}}><img alt='' style={{width: 16, height: 16}} src={require('../../../Assets/Images/verticleMenu.png')}/></IconButton> */}
          <Typography variant="h4" className={classes.nctid}>
            {data.document.id}
          </Typography>
          <Typography variant="h4" className={classes.CardTitle}>
            {data.studyData.customTitle}
          </Typography>
          <Typography variant="h4" className={classes.CardSubTitle}>
            {data.companyName}
          </Typography>
          <Divider style={{ marginTop: 12 }} />
          <div style={{ width: '100%' }}>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                display: 'inline-block',
                paddingBottom: 24,
              }}
            >
              <Typography variant="h4" className={classes.number}>
                {data.applicants}
              </Typography>
              <Typography variant="h4" className={classes.numberDescription}>
                Applications
              </Typography>
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                display: 'inline-block',
                borderLeft: '1px solid #E0E0E0',
                paddingBottom: 24,
              }}
            >
              <Typography variant="h4" className={classes.colorNumber}>
                {data.completed}
              </Typography>
              <Typography variant="h4" className={classes.numberDescription}>
                Completed
              </Typography>
            </div>
          </div>
          <Divider />
          <div
            style={{
              height: 54,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" className={classes.statusLabel}>
              Sponsored
            </Typography>
            {changingToSponsored ? (
              <CircularProgress size={30} />
            ) : (
              <IOSSwitch
                checked={data.studyData.isSponsored}
                onChange={handleChangeSwitch}
                name="isSponsored"
              />
            )}
          </div>
          <Divider />
          <Button onClick={() => navigateToStudy()} className={classes.apply}>
            View Study
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default Card;
