import React, { useContext, useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import '../../../../node_modules/antd/dist/antd.css';
import { Table, Tag, Space } from 'antd';
import { IconButton } from '@material-ui/core';
import { Popover, Button } from 'antd';
import moment from 'moment';
import FilterDrawer from './FilterDrawer';
import { FilterContext } from '../../../contexts/Filter';

const { Column, ColumnGroup } = Table;

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "'sofia-pro', sans-serif",
  },
  mobileTitle: {
    fontSize: 22,
    color: theme.palette.primary.dark,
    paddingBottom: 24,
  },
}));

const StudyTable = (props) => {
  const classes = useStyle();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const { openFilter, setOpenFilter } = useContext(FilterContext);

  const renameStatus = (status) => {
    if (status === 'Applied') {
      return 'Applied';
    } else if (status === 'Schedule Appointment') {
      return 'Applied';
    } else if (status === 'Confirmed Appointment') {
      return 'Scheduled';
    } else if (status === 'Completed') {
      return 'Completed';
    } else if (status === 'Not Eligible') {
      return 'Failed';
    } else if (status === 'Pending') {
      return 'Pending';
    } else if (status === 'TBD') {
      return 'Post Call';
    } else {
      return 'Applied';
    }
  };

  const getAgeFromDate = (date) => {
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    return moment().diff(formattedDate, 'years', false);
  };

  const [columns, setColumns] = useState([
    {
      title: '#',
      dataIndex: 'key',
      fixed: mobile ? 'null' : 'left',
      width: 60,
    },
    {
      title: 'Applied At',
      dataIndex: 'appliedAt',
      width: 130,
      fixed: mobile ? 'null' : 'left',
      sorter: {
        compare: (a, b) => a.appliedAt.seconds - b.appliedAt.seconds,
      },
      render: (result) => (
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#4A4A4A',
          }}
        >
          {result.format}
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
      fixed: mobile ? 'null' : 'left',
      render: (name) => (
        <>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {name.name}
          </div>
          <Popover
            placement="top"
            style={{ borderRadius: 10 }}
            title={() => (
              <div
                style={{
                  color: '#4fb4e8',
                  fontSize: 11,
                  fontWeight: 900,
                  letterSpacing: 1,
                  fontFamily: "'sofia-pro', sans-serif",
                }}
              >
                Patient ID
              </div>
            )}
            content={() => (
              <div
                style={{
                  color: '#4A4A4A',
                  fontSize: 13,
                  fontWeight: 'normal',
                  letterSpacing: 0.24,
                  fontFamily: "'sofia-pro', sans-serif",
                  maxWidth: 340,
                }}
              >
                {name.id}
              </div>
            )}
            trigger="hover"
          >
            <Button
              style={{
                border: 'none',
                padding: 0,
                backgroundColor: 'none',
                background: 'none',
                width: 24,
                height: 24 * 1.125,
                marginLeft: 4,
                display: 'inline-block',
                verticalAlign: 'middle',
                marginTop: 0,
              }}
            >
              {/* <IconButton style={{display: 'inline-block', verticalAlign: 'middle', width: 24, height: 24 * 1.125, marginTop: 4, marginLeft: 4}}> */}
              <img
                alt=""
                src={require('../../../Assets/Images/infoIcon.png')}
                style={{ width: 14, height: 14 * 1.125 }}
              />
              {/* </IconButton> */}
            </Button>
          </Popover>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: mobile ? 'null' : 'left',
      width: 200,
      sorter: {
        compare: (a, b) => a.status.length - b.status.length,
      },
      render: (status) => (
        <>
          <div style={getStyleForStatus(renameStatus(status))}>
            {renameStatus(status)}
          </div>
        </>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 160,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 220,
    },
    {
      title: 'Phone',
      width: 160,
      dataIndex: 'phone',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      width: 100,
      sorter: {
        compare: (a, b) => a.gender.length - b.gender.length,
      },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      width: 100,
      sorter: {
        compare: (a, b) => parseInt(a.age) - parseInt(b.age),
      },
    },
    // {
    //     title: 'Ethnicity',
    //     dataIndex: 'ethnicity',
    //     width: 140,
    // },
    {
      title: 'Diagnosed',
      dataIndex: 'diagnosed',
      width: 120,
      render: (result) => (
        // <div style={{display: 'inline-block', verticalAlign: 'middle', color: '#4A4A4A'}}>{result.diagnosed ? (result.diagnosedAge ? (result.diagnosedAge + 'y') : 'N/A') : 'No'}</div>
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#4A4A4A',
          }}
        >
          {result.diagnosed ? 'Yes' : 'No'}
        </div>
      ),
    },
    {
      title: 'Symptoms',
      dataIndex: 'symptoms',
      width: 120,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    Symptoms
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.map((symptom, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {symptom}
                      </div>
                    ))}
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  {/* <IconButton style={{display: 'inline-block', verticalAlign: 'middle', width: 24, height: 24 * 1.125, marginTop: 4, marginLeft: 4}}> */}
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                  {/* </IconButton> */}
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Medications',
      dataIndex: 'medications',
      width: 120,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    Medications
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.map((medication, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {medication}
                      </div>
                    ))}
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  {/* <IconButton style={{display: 'inline-block', verticalAlign: 'middle', width: 24, height: 24 * 1.125, marginTop: 4, marginLeft: 4}}> */}
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                  {/* </IconButton> */}
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Procedures',
      dataIndex: 'procedures',
      width: 120,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    Procedures
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.map((medication, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {medication}
                      </div>
                    ))}
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  {/* <IconButton style={{display: 'inline-block', verticalAlign: 'middle', width: 24, height: 24 * 1.125, marginTop: 4, marginLeft: 4}}> */}
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                  {/* </IconButton> */}
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
  ]);

  useEffect(() => {
    let study = props.study;
    let applications = study.applications;
    let data = [];
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      data.push({
        key: index + 1,
        appliedAt: {
          format: moment(application.appliedAt.seconds * 1000).format('ll'),
          seconds: application.appliedAt.seconds,
        },
        patientId: application.userId,
        age: getAgeFromDate(application.userData.birthDate),
        ethnicity: application.userData.ethnicity,
        name: {
          name: application.userData.firstName,
          id: application.userData.firebaseId.substring(0, 9),
        },
        email: application.userData.email,
        phone: '+' + application.userData.phone,
        gender: application.userData.gender,
        // location: application.nearestLocationData.locationData.address_components[0].long_name + ', ' +  application.nearestLocationData.locationData.address_components[0].short_name,
        location: application.userData.locationData.description,
        diagnosed: {
          diagnosed: application.userData.diagnosedByDoctor,
          diagnosedAge: application.userData.diagnosedAge,
        },
        diagnosedAge: application.userData.diagnosedAge
          ? application.userData.diagnosedAge
          : 'N/A',
        studyCenter: application.studyLocation,
        status: application.status[application.status.length - 1].status,
        visitResults: application.exitSurvey
          ? application.exitSurvey
          : 'Not Accepted',
        icfReviewed: application.icfReviewed === true ? 'Yes' : 'No',
        symptoms: application.symptoms,
        conditions: application.userData.otherConditions,
        procedures: getProceduresAsArray(application.userData.procedures),
        comments: 'icon',
        medications: application.medications,
        travelAssist: application.userData.willingToTravel ? 'Yes' : 'No',
        otherTrials: application.userData.startNewMedicationInTheLast6Month
          ? 'Yes'
          : 'No',
        // availability: getAvailability(application),
      });
    }
    const sortedData = data.sort(
      (a, b) => b.appliedAt.seconds - a.appliedAt.seconds,
    );
    setData(sortedData);
    setFilteredData(sortedData);
  }, []);

  const getAvailability = (application) => {
    let availability = '';
    if (application.availability.day1) {
      availability += 'Sun, ';
    }
    if (application.availability.day2) {
      availability += 'Mon, ';
    }
    if (application.availability.day3) {
      availability += 'Tue, ';
    }
    if (application.availability.day4) {
      availability += 'Wen, ';
    }
    if (application.availability.day5) {
      availability += 'Thu, ';
    }
    if (application.availability.day6) {
      availability += 'Fri, ';
    }
    availability += `${application.availability.from} - ${application.availability.to}`;
    return availability;
  };

  const getProceduresAsArray = (procedures) => {
    var objectConstructor = {}.constructor;
    if (procedures.constructor === objectConstructor) {
      let proceduresArray = [];
      for (let index = 0; index < Object.keys(procedures).length; index++) {
        const procedure = Object.keys(procedures)[index];
        if (procedures[procedure]) {
          proceduresArray.push(procedure);
        }
      }
      return proceduresArray;
    } else {
      return procedures;
    }
  };

  const getStyleForStatus = (status) => {
    if (status === 'Applied') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4fb4e830',
        color: '#4fb4e8',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Completed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DDF5DD',
        color: '#57CE54',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Failed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4A4A4A',
        color: 'white',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Confirmed Appointment') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Schedule Appointment') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Pending') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DBDBDB',
        color: '#4A4A4A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    }
  };

  // const getStyleForStatus = (status) => {
  //     if (status === 'Applied') {
  //         return {fontWeight: 'bold', width: 141, height: 30, borderRadius: 8, backgroundColor: '#DBDBDB', color: '#4A4A4A', textAlign: 'center', paddingTop: 2}
  //     } else if (status === 'Completed') {
  //         return {fontWeight: 'bold', width: 141, height: 30, borderRadius: 8, backgroundColor: '#DDF5DD', color: '#57CE54', textAlign: 'center', paddingTop: 2}
  //     } else if (status === 'Failed') {
  //         return {fontWeight: 'bold', width: 141, height: 30, borderRadius: 8, backgroundColor: '#4fb4e830', color: '#4fb4e8', textAlign: 'center', paddingTop: 2}
  //     } else {
  //         return {fontWeight: 'bold', width: 141, height: 30, borderRadius: 8, backgroundColor: '#FDFEDA', color: '#C6C93A', textAlign: 'center', paddingTop: 2}
  //     }
  // }

  const onChange = (pagination, filters, sorter, extra) => {};

  return (
    <div style={{ fontFamily: "'sofia-pro', sans-serif", color: '#4fb4e8' }}>
      {/* <Typography className={classes.mobileTitle} variant="h2">Leads</Typography> */}
      <FilterDrawer
        includeStudyCenter={false}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        data={data ? data : []}
        setFilteredData={setFilteredData}
      />
      <Table
        pagination={false}
        scroll={{ x: mobile ? '100%' : 1545 }}
        columns={columns}
        dataSource={filteredData}
        onChange={onChange}
        style={{ marginTop: mobile ? 0 : 12, color: '#4fb4e8' }}
      />
    </div>
  );
};

export default StudyTable;
