import React from 'react';

const StatusIndicator = ({ status }) => {
  let color;
  let bgcolor;

  switch (status) {
    case 'Applied':
      color = '#17803D';
      bgcolor = '#DCFCE7';
      break;
    case 'Active':
      color = '#854D0F';
      bgcolor = '#FEF9C3';
      break;
    case 'Failed':
      color = '#B91C1B';
      bgcolor = '#FEE2E1';
      break;
    default:
      color = '#4B5563';
      bgcolor = '#F3F4F6';
      break;
  }

  return (
    <div
      style={{
        backgroundColor: bgcolor,
        padding: '10px',
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: color,
        width: 'fit-content',
      }}
    >
      {status}
    </div>
  );
};

export default StatusIndicator;
