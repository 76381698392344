const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  clinical: {
    width: '100%',
    // minHeight: 400,
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0px 0px 25px -8px rgba(0, 0, 0, 0.3);',
    cursor: 'pointer',
  },
  number: {
    fontSize: 48,
    fontWeight: 'bold',
    color: 'rgb(75,75,75)',
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 900,
  },
  colorNumber: {
    fontSize: 48,
    color: '#4fb4e8',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 900,
  },
  numberDescription: {
    fontSize: 16,
    color: '#4A4A4A',
    opacity: 0.5,
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 500,
  },
  nctid: {
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  soonCardTitle: {
    fontSize: 24,
    color: 'rgb(75,75,75)',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  comingSoon: {
    fontSize: 18,
    color: '#4fb4e8',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 32,
  },
  CardTitle: {
    fontSize: 18,
    color: 'rgb(75,75,75)',
    fontWeight: 'bold',
    textAlign: 'left',
    marginTop: 4,
  },
  CardSubTitle: {
    fontSize: 12,
    color: 'rgb(164,164,164)',
    marginTop: 12,
    fontWeight: 500,
    textAlign: 'left',
  },
  statusLabel: {
    fontSize: 14,
    color: '#4A4A4A',
    fontWeight: 'bold',
    textAlign: 'left',
    flexGrow: 1,
  },
  doneStatusBox: {
    fontSize: 14,
    color: 'white',
    backgroundColor: '#4A4A4A',
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
  },
  liveStatusBox: {
    fontSize: 14,
    color: 'white',
    backgroundColor: '#34C659',
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
  },
  pausedStatusBox: {
    fontSize: 14,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
  },
  status: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
  },
  viewStudy: {
    height: 54,
    borderRadius: 10,
    backgroundColor: 'rgb(237,237,237)',
    color: 'rgb(75,75,75)',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    textTransform: 'none',
    display: 'inline-block',
    width: '47.5%',
    marginLeft: '5%',
    fontSize: 16,
  },
  apply: {
    height: 54,
    borderRadius: 10,
    backgroundColor: '#4fb4e830',
    color: '#4fb4e8',
    fontWeight: 'bold',
    marginTop: 16,
    textTransform: 'none',
    display: 'inline-block',
    width: '100%',
    fontSize: 16,
  },
  iconBackground: {
    display: 'inline-block',
    width: 40,
    height: 40,
    borderRadius: '50%',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #4fb4e8',
  },
  icon: {
    color: theme.palette.primary.main,
    height: 20,
    width: 20,
    marginLeft: 10,
    marginTop: 10,
  },
  iconTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  iconSubTitle: {
    fontSize: 18,
    color: theme.palette.primary.gray,
    marginTop: 2,
    textAlign: 'left',
  },
});
