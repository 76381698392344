const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  pageTitle: {
    color: theme.palette.primary.dark,
    textAlign: 'right',
    marginTop: '3%',
  },
  notification: {
    fontFamily: theme.typography.main,
    background: theme.palette.primary.main,
    width: 24,
    height: 24,
    borderRadius: 8,
    color: 'white',
    textAlign: 'center',
  },
  totalApplicationsTag: {
    fontFamily: theme.typography.main,
    width: 24,
    height: 24,
    borderRadius: 8,
    color: '#4A4A4A50',
    textAlign: 'center',
  },
  searchInput: {
    backgroundColor: '#F4F4F4',
    width: 350,
    height: 38,
    borderRadius: 10,
    color: '#4A4A4A',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: theme.typography.main,
    border: 'none',
    paddingRight: 8,
    paddingLeft: 8,
    // marginTop: 6,
    paddingRight: 32,
    marginLeft: 24,
  },
  searchInputMobile: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    height: 38,
    borderRadius: 10,
    color: '#4A4A4A',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: theme.typography.main,
    border: 'none',
    paddingRight: 8,
    paddingLeft: 8,
    // marginTop: 6,
    paddingRight: 32,
  },
  appointmentDateTimeLabel: {
    textTransform: 'None',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    height: 24,
    borderRadius: 4,
    backgroundColor: '#4fb4e830',
    paddingLeft: 16,
    display: 'flex',
    flex: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    paddingRight: 16,
    minWidth: 85,
  },
  tagLabel: {
    textTransform: 'None',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: 'white',
    height: 24,
    borderRadius: 4,
    backgroundColor: '#4A4A4A',
    paddingLeft: 16,
    fontSize: 12,
    paddingRight: 16,
    minWidth: 85,
  },
  editButton: {
    textTransform: 'None',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    height: 24,
    borderRadius: 4,
    backgroundColor: '#4fb4e830',
    fontSize: 12,
  },
  appointmentDateTime: {
    textTransform: 'None',
    fontWeight: 'bold',
    color: 'white',
    height: 44,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: 24,
    paddingRight: 24,
    minWidth: 134,
    transition: theme.transitions.create(['opacity']),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      opacity: 0.5,
    },
  },
  changeInquirieStatusButton: {
    cursor: 'pointer',
    width: 44,
    height: 44,
    transition: theme.transitions.create(['opacity']),
    '&:hover': {
      opacity: 0.5,
    },
  },
  detailsButton: {
    marginLeft: 24,
    color: 'red',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    // ransition: theme.transitions.create(['opacity']),
    // '&:hover': {
    //     opacity: 0.5
    // }
  },
  tabButtonActive: {
    color: '#4fb4e8',
    cursor: 'pointer',
    textAlign: 'left',
    fontSize: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  tabButtonNotActive: {
    color: theme.palette.primary.dark,
    textAlign: 'left',
    opacity: 0.5,
    cursor: 'pointer',
    fontSize: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  pageSubTitle: {
    fontSize: 17,
    color: theme.palette.primary.dark,
    textAlign: 'left',
    marginTop: 25,
  },
  section: {
    color: '#4A4A4A',
    marginTop: 0,
    fontSize: 24,
  },
  discoverMore: {
    cursor: 'pointer',
    width: '100%',
    height: 308,
    backgroundColor: '#F4F4F4',
    borderRadius: 10,
    boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.3);',
  },
  discoverMoreTitle: {
    textAlign: 'center',
    color: '#B0B0B0',
    fontSize: 18,
    marginTop: 24,
  },
  updateTitle: {
    color: '#4fb4e8',
    fontSize: 18,
    textAlign: 'left',
    marginTop: 22,
  },
  updateSubTitle: {
    color: '#4A4A4A',
    textAlign: 'left',
    fontSize: 14,
    marginTop: 8,
  },
  articleTag: {
    color: '#5D5D5D',
    fontSize: 14,
    textAlign: 'left',
  },
  articleTitle: {
    color: '#171717',
    fontSize: 24,
    textAlign: 'left',
    marginTop: 12,
  },
  articleText: {
    color: '#171717',
    fontSize: 18,
    textAlign: 'left',
    marginTop: 12,
    paddingRight: 8,
  },
  update: {
    backgroundColor: '#F4F4F4',
    marginTop: mobile ? 18 : 32,
    borderRadius: 12,
    width: '100%',
    height: 90,
  },
  newsItem: {
    marginTop: 24,
    borderRadius: 12,
    width: '100%',
    // height: mobile ? 120 : 240
  },
  noAppointmentsBox: {
    width: '100%',
    height: 70,
    backgroundColor: '#F4F4F4',
    borderRadius: 10,
    marginTop: 24,
  },
  noAppointmentsTitle: {
    textAlign: 'center',
    color: '#B0B0B0',
    fontSize: mobile ? 15 : 17,
    fontWeight: 600,
    paddingTop: 22,
  },
  selectedSettingTab: {
    width: '100%',
    height: 52,
    borderRadius: 12,
    backgroundColor: '#F5F5F5',
    color: '#000000',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'left',
    textTransform: 'none',
    marginTop: 12,
    padding: 14,
    cursor: 'pointer',
    display: 'flex',
  },
  settingTab: {
    width: '100%',
    height: 52,
    borderRadius: 12,
    color: '#000000',
    opacity: 0.8,
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'left',
    textTransform: 'none',
    marginTop: 12,
    padding: 14,
    cursor: 'pointer',
    display: 'flex',
  },
  logoutTabButton: {
    width: '100%',
    height: 52,
    borderRadius: 12,
    color: '#4fb4e8',
    fontSize: 17,
    fontWeight: 700,
    textAlign: 'left',
    textTransform: 'none',
    marginTop: 12,
    padding: 14,
    cursor: 'pointer',
  },
  logoutTitle: {
    color: '#4fb4e8',
    fontSize: 17,
  },
  selectedSettingTabTitle: {
    color: '#000000',
    fontSize: 17,
    flexGrow: 1,
  },
  settingTabTitle: {
    color: '#000000',
    opacity: 0.8,
    fontSize: 17,
    flexGrow: 1,
  },
  settingTitle: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.5)',
    textAlign: 'left',
    // marginTop: 20,
    // flexGrow: 1
  },
  settingTitleStudiesNumber: {
    color: theme.palette.primary.main,
  },
  divider: {
    marginTop: 14,
  },
  textField: {
    marginTop: 32,
  },
  emailTextField: {
    // flexGrow: 1
  },
  role: {
    fontSize: 14,
    color: '#4A4A4A',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  roleBox: {
    fontSize: 14,
    color: '#4A4A4A',
    backgroundColor: '#EDEDED',
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    height: 32,
  },
  addTeamMemberPopupButton: {
    fontSize: 14,
    backgroundColor: '#FDE9E9',
    '&:hover': {
      backgroundColor: '#FDE9E9',
    },
    borderRadius: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'none',
    position: 'absolute',
    bottom: 76,
    width: 'calc(100% - 48px)',
    height: 44,
  },
  accordionSummary: {
    padding: 0,
    cursor: 'default',
    '&:hover': {
      cursor: 'default',
    },
  },
  accordionDetails: {
    padding: 0,
    flexFlow: 'column',
  },
  accordion: {
    backgroundColor: 'transparent',
  },
  accordionMobile: {
    backgroundColor: 'white',
    borderRadius: 9,
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
    padding: 12,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
  },
  copyInvitationLinkPopupButton: {
    fontSize: 14,
    backgroundColor: '#FDE9E9',
    '&:hover': {
      backgroundColor: '#FDE9E9',
    },
    borderRadius: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'none',
    position: 'absolute',
    bottom: 16,
    width: 'calc(100% - 48px)',
    height: 44,
  },
  addNewStudyCenter: {
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    borderRadius: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
    position: 'absolute',
    bottom: 16,
    width: 'calc(100% - 48px)',
    height: 44,
    marginTop: 24,
  },
  disabledInviteButton: {
    fontSize: 14,
    backgroundColor: '#EDEDED',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    color: 'rgba(0,0,0,0.2)',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    height: 32,
    marginLeft: 12,
  },
  addingTeamMemberLoader: {
    color: '#34C659',
    marginLeft: 24,
  },
  TeamButton: {
    fontSize: 14,
    backgroundColor: '#EDEDED',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    color: '#4A4A4A',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    height: 32,
    marginLeft: 12,
  },
  SetupButton: {
    fontSize: 14,
    backgroundColor: '#4fb4e820',
    '&:hover': {
      backgroundColor: '#4fb4e830',
    },
    borderRadius: 10,
    paddingLeft: 48,
    paddingRight: 48,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    height: 32,
  },
  InvitedButton: {
    fontSize: 14,
    backgroundColor: '#4A4A4A',
    '&:hover': {
      backgroundColor: '#4A4A4A',
    },
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    height: 32,
    marginLeft: 12,
  },
  InviteLinkButton: {
    fontSize: 14,
    backgroundColor: '#4fb4e830',
    '&:hover': {
      backgroundColor: '#4fb4e830',
    },
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    color: '#4fb4e8',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    height: 32,
    marginLeft: 12,
  },
  RemindButton: {
    fontSize: 14,
    backgroundColor: '#4fb4e8',
    '&:hover': {
      backgroundColor: '#4fb4e8',
    },
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    height: 32,
    marginLeft: 12,
  },
  inviteButton: {
    fontSize: 14,
    backgroundColor: '#34C659',
    '&:hover': {
      backgroundColor: '#34C659',
    },
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    height: 32,
    marginLeft: 12,
  },
  cancelButton: {
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    borderRadius: 10,
    paddingLeft: 24,
    paddingRight: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    height: 38,
    marginTop: 24,
  },
  statusLabel: {
    fontSize: 14,
    color: '#4A4A4A',
    fontWeight: 'bold',
    textAlign: 'left',
    flexGrow: 1,
  },
  doneStatusBox: {
    fontSize: 14,
    color: 'white',
    backgroundColor: '#4A4A4A',
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
  },
  mobileTitle: {
    fontSize: 22,
    color: theme.palette.primary.dark,
    flexGrow: 1,
  },
  liveStatusBox: {
    fontSize: 14,
    color: 'white',
    backgroundColor: '#34C659',
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
  },
  pausedStatusBox: {
    fontSize: 14,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
  },
  status: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
  },
  avatar: {
    color: theme.palette.primary.main,
    backgroundColor: '#4fb4e830',
    fontFamily: theme.typography.main,
    width: 50,
    height: 50,
    textAlign: 'bold',
  },
  statusLabel: {
    fontSize: 14,
    color: '#4A4A4A',
    fontWeight: 400,
    textAlign: 'left',
    flexGrow: 1,
  },
  teamDivider: {
    marginTop: 24,
  },
  teamItem: {
    overflow: 'hidden',
    width: '100%',
    paddingTop: 24,
    paddingBottom: 0,
  },
  teamItemContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addNewTeamNumberContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  StudyCenterNameTeamPopup: {
    fontSize: 24,
    marginTop: 24,
    fontFamily: theme.typography.main,
    fontWeight: 'bold',
  },
  StudyCenterTeamTitle: {
    fontSize: 12,
    marginTop: 24,
    fontFamily: theme.typography.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  AddNewStudyCenterLabel: {
    fontSize: 12,
    marginTop: 24,
    fontFamily: theme.typography.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  StudyCenterLocationTeamPopup: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.5)',
    fontFamily: theme.typography.main,
    fontWeight: 'semi-bold',
  },
  StudyCenterName: {
    fontSize: 16,
  },
  StudyCenterLocation: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.5)',
  },
  userName: {
    fontSize: 16,
    marginLeft: 24,
  },
  useEmail: {
    fontSize: 16,
    marginLeft: 24,
    color: 'rgba(0,0,0,0.5)',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  roleMenuItem: {
    fontFamily: theme.typography.main,
    fontWeight: 'bold',
  },
  roleMenuItemRemove: {
    fontFamily: theme.typography.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  select: {
    fontFamily: theme.typography.main,
    fontWeight: 'bold',
  },
});
