import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    maxWidth: '100%',
    // marginTop: 8
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const proceduresOptions = [
  'Surgery',
  'Biopsy',
  'IVIg',
  'Plasmapheresis',
  'Genetic Testing',
  'Antibody Testing'
];

export default function MultipleSelect({setProcedures, procedures}) {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event) => {
    setProcedures(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        {/* <InputLabel id="demo-mutiple-checkbox-label">Procedures</InputLabel> */}
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={procedures}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          displayEmpty
        >
          <MenuItem key={0} value={
            []
          } disabled>
            Select all that apply (optional)
          </MenuItem>
          {proceduresOptions.map((procedure) => (
            <MenuItem key={procedure} value={procedure}>
              <Checkbox checked={procedures.indexOf(procedure) > -1} />
              <ListItemText primary={procedure} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
