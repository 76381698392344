import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

var moment = require('moment');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: "'sofia-pro', sans-serif",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    fontFamily: "'sofia-pro', sans-serif",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'center',
    flexBasis: '33.33%',
    fontWeight: 'bold',
    color: '#4A4A4A',
    fontFamily: "'sofia-pro', sans-serif",
    // opacity: 0.6
  },
  thirdHeading: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'right',
    flexBasis: '33.33%',
    fontFamily: "'sofia-pro', sans-serif",
    color: theme.palette.text.secondary,
  },
  timeButton: {
    fontFamily: "'sofia-pro', sans-serif",
    border: '2px solid #4fb4e8',
    borderRadius: 8,
    color: '#4fb4e8',
    width: 85,
    marginLeft: 32,
    marginTop: 12,
    display: 'inline-block',
  },
  selectedTimeButton: {
    fontFamily: "'sofia-pro', sans-serif",
    border: '2px solid #4fb4e8',
    backgroundColor: '#4fb4e8',
    borderRadius: 8,
    color: '#FFFFFF',
    width: 85,
    marginLeft: 32,
    marginTop: 12,
    display: 'inline-block',
  },
  disabledTimeButton: {
    fontFamily: "'sofia-pro', sans-serif",
    border: '2px solid #F2F2F2',
    backgroundColor: '#F2F2F2',
    color: '#828282',
    borderRadius: 8,
    width: 85,
    marginLeft: 32,
    marginTop: 12,
    display: 'inline-block',
  },
  unselectedChooseTime: {
    fontFamily: "'sofia-pro', sans-serif",
    borderRadius: 8,
    backgroundColor: '#F2F2F2',
    color: '#828282',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: 17,
    // boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)',
    marginTop: 0,
    width: '100%',
    height: 48,
    '&:hover': {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  chooseTime: {
    fontFamily: "'sofia-pro', sans-serif",
    borderRadius: 8,
    backgroundColor: '#4fb4e8',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: 17,
    boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)',
    marginTop: 0,
    width: '100%',
    height: 48,
    '&:hover': {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
}));

export default function ControlledExpansionPanels(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [selectedTime, setSelectedTime] = React.useState('');
  const [optionalDates, setOptionalDates] = React.useState([]);
  const [times, setTimes] = React.useState([
    {
      time: '9:00 AM',
      selected: false,
      available: true,
    },
    {
      time: '9:30 AM',
      selected: false,
      available: true,
    },
    {
      time: '10:00 AM',
      selected: false,
      available: true,
    },
    {
      time: '10:30 AM',
      selected: false,
      available: true,
    },
    {
      time: '11:00 AM',
      selected: false,
      available: true,
    },
    {
      time: '11:30 AM',
      selected: false,
      available: true,
    },
    {
      time: '12:00 PM',
      selected: false,
      available: true,
    },
    {
      time: '12:30 PM',
      selected: false,
      available: true,
    },
    {
      time: '13:00 PM',
      selected: false,
      available: true,
    },
    {
      time: '13:30 PM',
      selected: false,
      available: true,
    },
    {
      time: '14:00 PM',
      selected: false,
      available: true,
    },
    {
      time: '14:30 PM',
      selected: false,
      available: true,
    },
    {
      time: '15:00 PM',
      selected: false,
      available: true,
    },
    {
      time: '15:30 PM',
      selected: false,
      available: true,
    },
    {
      time: '16:00 PM',
      selected: false,
      available: true,
    },
    {
      time: '16:30 PM',
      selected: false,
      available: true,
    },
  ]);

  const handleChangeTime = (time) => {
    setSelectedTime(time);
  };

  React.useEffect(() => {
    getDates();
  }, []);

  const getTimeAsNumber = (time) => {
    var currentTimeAsNumber = parseInt(time.split(':')[0]);
    if (time.includes('PM')) {
      if (currentTimeAsNumber === 1) {
        currentTimeAsNumber = 13;
      }
      if (currentTimeAsNumber === 2) {
        currentTimeAsNumber = 14;
      }
      if (currentTimeAsNumber === 3) {
        currentTimeAsNumber = 15;
      }
      if (currentTimeAsNumber === 4) {
        currentTimeAsNumber = 16;
      }
      if (currentTimeAsNumber === 4) {
        currentTimeAsNumber = 16;
      }
      if (currentTimeAsNumber === 5) {
        currentTimeAsNumber = 17;
      }
      if (currentTimeAsNumber === 6) {
        currentTimeAsNumber = 18;
      }
      if (currentTimeAsNumber === 7) {
        currentTimeAsNumber = 19;
      }
      if (currentTimeAsNumber === 8) {
        currentTimeAsNumber = 20;
      }
      if (currentTimeAsNumber === 9) {
        currentTimeAsNumber = 21;
      }
      if (currentTimeAsNumber === 10) {
        currentTimeAsNumber = 2;
      }
    }
    return currentTimeAsNumber;
  };

  const checkTime = (time) => {
    if (!props.availability) return true;
    var currentTimeAsNumber = parseInt(time.split(':')[0]);
    if (time.includes('PM')) {
      if (currentTimeAsNumber === 1) {
        currentTimeAsNumber = 13;
      }
      if (currentTimeAsNumber === 2) {
        currentTimeAsNumber = 14;
      }
      if (currentTimeAsNumber === 3) {
        currentTimeAsNumber = 15;
      }
      if (currentTimeAsNumber === 4) {
        currentTimeAsNumber = 16;
      }
      if (currentTimeAsNumber === 4) {
        currentTimeAsNumber = 16;
      }
      if (currentTimeAsNumber === 5) {
        currentTimeAsNumber = 17;
      }
      if (currentTimeAsNumber === 6) {
        currentTimeAsNumber = 18;
      }
      if (currentTimeAsNumber === 7) {
        currentTimeAsNumber = 19;
      }
      if (currentTimeAsNumber === 8) {
        currentTimeAsNumber = 20;
      }
      if (currentTimeAsNumber === 9) {
        currentTimeAsNumber = 21;
      }
      if (currentTimeAsNumber === 10) {
        currentTimeAsNumber = 2;
      }
    }
    return (
      currentTimeAsNumber >= getTimeAsNumber(props.availability.from) &&
      currentTimeAsNumber < getTimeAsNumber(props.availability.to)
    );
  };

  const checkAvailability = (day) => {
    if (day === 'Saturday') return false;
    if (!props.availability) return true;
    if (day === 'Sunday') {
      if (props.availability.day1) return true;
      else return false;
    }
    if (day === 'Monday') {
      if (props.availability.day2) return true;
      else return false;
    }
    if (day === 'Tuesday') {
      if (props.availability.day3) return true;
      else return false;
    }
    if (day === 'Wednesday') {
      if (props.availability.day4) return true;
      else return false;
    }
    if (day === 'Thursday') {
      if (props.availability.day5) return true;
      else return false;
    }
    if (day === 'Friday') {
      if (props.availability.day6) return true;
      else return false;
    }
  };

  const getDates = async () => {
    var days = [];
    for (let index = 2; index < 16; index++) {
      var day = moment().add(index, 'day');
      var dayObject = {
        day: day,
        dayString: day.format('dddd'),
        dateString: day.format('LL').split(',')[0],
        available: checkAvailability(day.format('dddd')),
      };
      days.push(dayObject);
    }
    setOptionalDates(days);
  };

  const selectDateAndTime = () => {
    var day = optionalDates[parseInt(expanded.replace('index', ''))];
    props.setSelectedDay(day);
    props.setSelectedTime(selectedTime);
    props.applyWithEmailAndPassword(
      props.email,
      props.password,
      day,
      selectedTime
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSelectedTime('');
  };

  const getTimeButtonStyle = (item) => {
    if (!checkTime(item.time)) return classes.disabledTimeButton;
    else {
      return selectedTime === item
        ? classes.selectedTimeButton
        : classes.timeButton;
    }
  };

  return (
    <div className={classes.root}>
      {optionalDates.map((day, i) => (
        <div>
          <ExpansionPanel
            id={i}
            disabled={!day.available}
            expanded={expanded === 'index' + String(i)}
            onChange={handleChange('index' + String(i))}
            elevation={0}
            style={{
              marginTop: 14,
              borderRadius: 10,
              boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.10)',
              display: i > 6 && !showAll ? 'none' : 'block',
            }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography className={classes.heading}>
                {day.dayString}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {day.available ? '' : 'Not Available'}
              </Typography>
              <Typography className={classes.thirdHeading}>
                {day.dateString}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                {times.map((item, index) => (
                  <Button
                    onClick={() => handleChangeTime(item)}
                    disabled={!checkTime(item.time)}
                    className={getTimeButtonStyle(item)}
                  >
                    {item.time}
                  </Button>
                ))}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {expanded === 'index' + String(i) && (
            <Button
              onClick={() => selectDateAndTime()}
              className={
                selectedTime !== ''
                  ? classes.chooseTime
                  : classes.unselectedChooseTime
              }
            >
              Schedule Appointment
            </Button>
          )}
        </div>
      ))}
      {!showAll && (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Button
            onClick={() => setShowAll(true)}
            style={{ marginTop: 24, textTransform: 'none' }}
          >
            Show More
          </Button>
        </div>
      )}
    </div>
  );
}
