/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SymptomsData from './Symptoms.json';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 500,
    width: '100%',
    '& > * + *': {
      // marginTop: theme.spacing(3),
    },
  }
}));

export default function Tags(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        value={props.symptoms}
        freeSolo
        disabled={props.disabled ? true : false}
        fullWidth
        onChange={(event, value) => {
            props.setSymptoms(value)
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} fullWidth
            placeholder="Make selection by pressing enter (optional)"
            disabled={props.disabled ? true : false}
            fullWidth
          />
        )}
      />
    </div>
  );
}