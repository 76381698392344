import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import MuiStyleFunction from './Card.style';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

const useStyles = makeStyles(MuiStyleFunction);

const SoonCard = ({data}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={4} md={4} xl={3}> 
        <Skeleton variant="rect" width={'100%'} height={328} style={{borderRadius: 12}} />
    </Grid>
  );
}
 
export default SoonCard;
