import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import MuiStyleFunction from './Login.style';

import firebase from '../../Firebase';
import { useNavigate } from 'react-router-dom';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

const Login = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const { mode } = new URLSearchParams(window.location.search);

  const [emailVerified, setEmailVerified] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const [resetingPassword, setResetingPassword] = useState(false);

  const [passwordRested, setPasswordRested] = useState(false);

  const [oobCode, setOobCode] = useState('');

  useEffect(() => {
    console.log(new URLSearchParams(window.location.search).get('oobCode'));
    setOobCode(new URLSearchParams(window.location.search).get('oobCode'));
  }, []);

  // useEffect(() => {
  //     if (oobCode) verifyEmail(oobCode)
  // }, [oobCode])

  // const verifyEmail = async () => {
  //     if (mode !== 'verifyEmail') return
  //     let res = await UserService.verifyEmail(oobCode)
  //     if (res.status) {
  //         setEmailVerified(true)
  //     } else {
  //         setEmailVerified(false)
  //         setErrorMessage(res.message)
  //     }
  // }

  const validatePassword = () => {
    let strength = 0;
    if (password1.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password1.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password1.match(/[0-9]+/)) {
      strength += 1;
    }
    if (strength < 3 || password1.length < 8) {
      return false;
    }
    return true;
  };

  const resetPassword = () => {
    if (password1 !== password2) {
      setErrorMessage('Passwords are not matched');
      return;
    }

    if (!validatePassword()) {
      setErrorMessage(
        'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
      );
      return;
    }

    setResetingPassword(true);
    setErrorMessage('');
    if (password1 === password2) {
      if (password1.length >= 8) {
        // Verify the password reset code is valid.
        firebase
          .auth()
          .verifyPasswordResetCode(oobCode)
          .then((email) => {
            // TODO: Show the reset screen with the user's email and ask the user for
            // the new password.

            // Save the new password.
            firebase
              .auth()
              .confirmPasswordReset(oobCode, password1)
              .then((resp) => {
                // Password reset has been confirmed and new password updated.

                // TODO: Display a link back to the app, or sign-in the user directly
                // if the page belongs to the same domain as the app:
                // auth.signInWithEmailAndPassword(accountEmail, newPassword);

                // TODO: If a continue URL is available, display a button which on
                // click redirects the user back to the app via continueUrl with
                // additional state determined from that URL's parameters.
                alert('Password reset successfully');
                setResetingPassword(false);
                setPasswordRested(true);
              })
              .catch((error) => {
                // Error occurred during confirmation. The code might have expired or the
                // password is too weak.
                setErrorMessage(error.message);
                setResetingPassword(false);
              });
          })
          .catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            setErrorMessage(error.message);
            setResetingPassword(false);
          });
      } else {
        setErrorMessage(
          'The password is to short. Should contain at least 8 characters.',
        );
        setResetingPassword(false);
      }
    } else {
      setErrorMessage('The passwords are not matched.');
      setResetingPassword(false);
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        {!passwordRested ? (
          <Grid item xs={12} sm={11} md={10} xl={8}>
            <center>
              <div className={classes.formContainerStep1}>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <img
                      alt=""
                      src={require('../../Assets/Images/logo.png')}
                      style={{
                        height: 'auto',
                        width: mobile ? '85%' : '100%',
                        marginBottom: 30,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div
                      style={{
                        marginTop: mobile ? -60 : -70,
                        fontSize: mobile ? 15 : 23,
                        fontWeight: 'bold',
                        color: 'rgb(157,157,157)',
                        fontFamily: "'sofia-pro', sans-serif",
                        marginLeft: mobile ? 60 : 40,
                      }}
                    >
                      Reset Password
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <TextField
                      label="Password"
                      id="filled-start-adornment"
                      className={clsx(classes.formControl, classes.textField)}
                      variant="outlined"
                      style={{ width: '100%' }}
                      onChange={(event) => setPassword1(event.target.value)}
                      type={'password'}
                      value={password1}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <TextField
                      id="outlined-password-input"
                      label="Verify Password"
                      fullWidth
                      type="password"
                      variant="outlined"
                      value={password2}
                      onChange={(event) => setPassword2(event.target.value)}
                      error={errorPassword !== ''}
                      helperText={errorPassword}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    {resetingPassword ? (
                      <CircularProgress size={30} className={classes.loading} />
                    ) : (
                      <Button
                        disabled={password1 === '' || password2 === ''}
                        onClick={() => resetPassword()}
                        className={classes.submit}
                        fullWidth
                      >
                        Reset
                      </Button>
                    )}
                  </Grid>
                  {errorMessage !== '' && (
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                      <div
                        className={classes.errorMessage}
                        style={{
                          marginTop: 12,
                          width: '100%',
                          padding: 12,
                          backgroundColor: '#4fb4e830',
                          color: '#4fb4e8',
                          borderRadius: 12,
                          textAlign: 'center',
                        }}
                      >
                        {errorMessage}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </center>
          </Grid>
        ) : (
          <Grid item xs={12} sm={11} md={10} xl={8}>
            <center>
              <div className={classes.formContainerStep1}>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <img
                      alt=""
                      src={require('../../Assets/Images/logo.png')}
                      style={{
                        height: 'auto',
                        width: mobile ? '85%' : '100%',
                        marginBottom: 30,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div
                      style={{
                        marginTop: mobile ? -60 : -70,
                        fontSize: mobile ? 15 : 23,
                        fontWeight: 'bold',
                        color: 'rgb(157,157,157)',
                        fontFamily: "'sofia-pro', sans-serif",
                        marginLeft: mobile ? 60 : 40,
                      }}
                    >
                      Password Changed Successfully
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Button
                      onClick={() => navigate('/login')}
                      className={classes.submit}
                      fullWidth
                    >
                      Back To Login
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </center>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default React.memo(Login);
