import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Router } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiTheme from './MuiTheme';
import './polyfills';

import AuthContextProvider from './contexts/Auth.js';
import SnackbarContextProvider from './contexts/Snackbar.js';
import FilterContextProvider from './contexts/Filter';
import StudiesContextProvider from './contexts/Studies';
import StudyContextProvider from './contexts/StudyContext';

const theme = createMuiTheme(MuiTheme);

ReactDOM.render(
  <AuthContextProvider>
    <StudiesContextProvider>
      <StudyContextProvider>
        <SnackbarContextProvider>
          <FilterContextProvider>
            <MuiThemeProvider theme={theme}>
              <CssBaseline>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CssBaseline>
            </MuiThemeProvider>
          </FilterContextProvider>
        </SnackbarContextProvider>
      </StudyContextProvider>
    </StudiesContextProvider>
  </AuthContextProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
