import 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/performance';

const config = {
  apiKey: 'AIzaSyB3K3TOzWv87QtfV9cQO2XEEe6YhcLKPhg',
  authDomain: 'knowrare-platform-e2cc9.firebaseapp.com',
  projectId: 'knowrare-platform-e2cc9',
  storageBucket: 'knowrare-platform-e2cc9',
  messagingSenderId: '1023460500015',
  appId: '1:1023460500015:web:add2b58047926a8d90dcda',
  measurementId: 'G-JJG8NPQMXH',
};

firebase.initializeApp(config);

export default firebase;
