import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  saveButton: {
    width: '100%',
    borderRadius: 10,
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
    height: 44
  }
});

const useStyles = makeStyles((theme) => ({
    saveButton: {
      width: '100%',
      borderRadius: 10,
      background: theme.palette.primary.main,
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'none',
      height: 44,
      '&:hover': {
        color: 'white',
        background: theme.palette.primary.main,
      }
    }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2" style={{fontSize: 26, marginTop: 32, marginBottom: 16}}>{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({open, setOpen, travelPolicy, saveTravelPolicy}) {

  const classes = useStyles()

  const [newTravelPolicy, setNewTravelPolicy] = React.useState(travelPolicy)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Travel Policy
        </DialogTitle>
        <DialogContent dividers>
          <TextField label="" multiline fullWidth variant="outlined" rows={24} value={newTravelPolicy} onChange={(e) => setNewTravelPolicy(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=> saveTravelPolicy(newTravelPolicy)} color="primary" fullWidth className={classes.saveButton}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
