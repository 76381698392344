import React, { useCallback, useEffect, useState } from 'react';
// import { ColumnChart } from '@opd/g2plot-react'
// import { LineConfig } from '@antv/g2plot'
import moment from 'moment';
// import { ColumnLineConfig } from '@antv/g2plot';

import { ColumnChart } from '@opd/g2plot-react';
import { ColumnConfig } from '@antv/g2plot';

export default ({
  study,
  whatAgesToShowInChart,
  get10Minus,
  getAge11to18,
  getAge19to29,
  getAge30to55,
  getAge56to74,
  getAge75plus,
}) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfing] = useState(null);

  useEffect(() => {
    if (study) {
      const configData: ColumnConfig = {
        height: 280,
        padding: 'auto',
        forceFit: true,
        color: '#4fb4e8',
        // title: {
        //   visible: false,
        //   text: '配置折线数据点样式',
        // },
        // description: {
        //   visible: false,
        //   text: '自定义配置趋势线上数据点的样式',
        // },
        // label: {
        //   visible: false,
        //   type: 'point',
        // },
        point: {
          visible: true,
          size: 5,
        },
        meta: {
          age: {
            alias: 'Age',
          },
          leads: {
            alias: 'Leads',
          },
        },
        xField: 'value',
        yField: 'leads',
        yAxis: {
          title: null,
          label: {
            autoHide: true,
          },
        },
        xAxis: {
          title: null,
          label: {
            autoHide: true,
          },
        },
        minColumnWidth: 20,
        maxColumnWidth: 20,
        data: [
          { value: '10+', leads: parseInt(get10Minus()) },
          { value: '11-18', leads: parseInt(getAge11to18()) },
          { value: '19-29', leads: parseInt(getAge19to29()) },
          { value: '30-55', leads: parseInt(getAge30to55()) },
          { value: '56-74', leads: parseInt(getAge56to74()) },
          { value: '75+', leads: parseInt(getAge75plus()) },
        ],
      };
      console.log('configData', configData);
      setConfing(configData);
      setLoading(false);
    }
  }, [whatAgesToShowInChart]);

  const handleChartMount = useCallback((chart) => {}, []);
  return (
    <div>
      {!loading && <ColumnChart {...config} onMount={handleChartMount} />}
    </div>
  );
};

// const columnPlot = new Column('container', {
//   data,
//   xField: 'age',
//   yField: 'leads',
//   xAxis: {
//     label: {
//       autoHide: true,
//       autoRotate: false,
//     },
//   },
//   meta: {
//     age: {
//       alias: 'Age',
//     },
//     leads: {
//       alias: 'Leads',
//     },
//   },
//   minColumnWidth: 20,
//   maxColumnWidth: 20,
// });

// columnPlot.render();
