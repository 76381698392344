import React, { createContext, useState, useEffect } from 'react';
import UserService from '../Services/UserService/UserService';
export const AuthContext = createContext();

function AuthContextProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userData, setUserData] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const [userType, setUserType] = useState(null);

  const getUserData = async () => {
    const data = await UserService.getUser();
    if (data.userType) {
      setUserData(data);
    } else {
      setUserData({ ...data, type: 'center' });
      setUserType('center');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        authUser: authUser,
        userData: userData,
        userType: userType,
        setIsAuthenticated: setIsAuthenticated,
        setUserData: setUserData,
        setAuthUser: setAuthUser,
        getUserData: getUserData,
        setUserType: setUserType,
      }}
    >
      <div>{props.children}</div>
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
