import React, { Component } from 'react';
// import './App.css'; /* optional for styling like the :hover pseudo-class */
import USAMap from 'react-usa-map';

class App extends Component {
  /* mandatory */

  state = {
    data: [],
  };

  componentDidMount() {
    let study = this.props.study;
    let applications = study.applications;
    let data = [];
    let centers = {};
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      let addressComponents =
        application.nearestLocationData.locationData.address_components;
      let stateShortName = '';
      let stateLongName = '';
      addressComponents.forEach((component) => {
        if (component.types.includes('administrative_area_level_1')) {
          stateShortName = component.short_name;
          stateLongName = component.long_name;
        }
      });
      if (centers.hasOwnProperty(stateShortName)) {
        centers[stateShortName].applications =
          centers[stateShortName].applications + 1;
      } else {
        centers[stateShortName] = {
          applications: 1,
          stateShortName,
          stateLongName,
          studyCenter: application.studyLocation.replace(/-/g, ' '),
          fill: '#4fb4e8',
        };
      }
    }
    this.setState({ data: centers });

    let top5 = [
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
    ];

    for (let index = 0; index < Object.keys(centers).length; index++) {
      const centerObject = centers[Object.keys(centers)[index]];
      if (centerObject.applications >= top5[0].applications) {
        top5 = [centerObject, top5[0], top5[1], top5[2], top5[3]];
      } else if (centerObject.applications >= top5[1].applications) {
        top5 = [top5[0], centerObject, top5[1], top5[2], top5[3]];
      } else if (centerObject.applications >= top5[2].applications) {
        top5 = [top5[0], top5[1], centerObject, top5[2], top5[3]];
      } else if (centerObject.applications >= top5[3].applications) {
        top5 = [top5[0], top5[1], top5[2], centerObject, top5[3]];
      } else if (centerObject.applications >= top5[4].applications) {
        top5 = [top5[0], top5[1], top5[2], top5[3], centerObject];
      }
    }
    this.props.setTop5(top5);
  }

  mapHandler = (event) => {};

  /* optional customization of filling per state and calling custom callbacks per state */
  statesCustomConfig = () => {
    return {
      NJ: {
        fill: 'navy',
        clickHandler: (event) =>
          console.log('Custom handler for NJ', event.target.dataset),
      },
      NY: {
        fill: '#CC0000',
      },
    };
  };

  render() {
    return (
      <div style={{ width: '100%', marginTop: -180 }}>
        <USAMap
          customize={this.state.data}
          onClick={this.mapHandler}
          width={'100%'}
          defaultFill={'rgba(200,200,200,0.4)'}
        />
      </div>
    );
  }
}

export default App;
