import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Chart from '../Chart';
import ApplicationDialog from '../ApplicationDialog';
import { PieChart } from 'react-minimal-pie-chart';
import Tabs from '../Tabs.js';
import StudyOverviewSkeleton from './StudyOverviewSkeleton';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiStyleFunction from './StudyOverview.style';
import Inquiries from './Inquiries';
import Appointments from './Appointments';
import moment from 'moment';
import Map from '../Map';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

const StudyOverview = ({ study }) => {
  const classes = useStyles();

  const [top5, setTop5] = useState([]);

  const getAgeFromDate = (date) => {
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    return moment().diff(formattedDate, 'years', false);
  };

  const get10Minus = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age10minus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge11to18 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age11to18 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge19to29 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age19to29 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge30to55 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age30to55 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge56to74 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age56to74 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge75plus = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age75plus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getFemale = () => {
    let applications = study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (female / (male + female)) * 100;
  };

  const getMale = () => {
    let applications = study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      console.log('application', application);
      console.log('applicationuserData', application.userData);
      console.log('application.userData', Object.keys(application));
      console.log('application.userData', application['userData']);
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (male / (male + female)) * 100;
  };

  const getDiagnosed = () => {
    let applications = study.applications;
    let yes = 0;
    let no = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (!application.userData.diagnosedByDoctor) {
        no += 1;
      } else {
        yes += 1;
      }
    }
    return (yes / (yes + no)) * 100;
  };

  const getNotDiagnosed = () => {
    let applications = study.applications;
    let yes = 0;
    let no = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (!application.userData.diagnosedByDoctor) {
        no += 1;
      } else {
        yes += 1;
      }
    }
    return (no / (yes + no)) * 100;
  };

  return (
    <div>
      <ApplicationDialog main={this} />
      {study !== null ? (
        <div>
          <div
            style={{
              paddingTop: 0,
              paddingBottom: mobile ? 160 : 40,
              width: '100%',
              marginTop: 24,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                xl={3}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 304,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        paddingTop: 82,
                      }}
                    >
                      {/* {console.log(study)} */}
                      <Typography variant="h4" className={classes.colorNumber}>
                        {study.applied}
                      </Typography>
                      <Typography
                        variant="h4"
                        className={classes.numberDescription}
                      >
                        Applied
                      </Typography>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
                        <div style={{width: '100%', height: 195, boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)', backgroundColor: 'white', borderRadius: 8, paddingTop: 28}}>
                          <Typography variant="h4" className={classes.colorNumber}>{study.scheduled}</Typography>
                          <Typography variant="h4" className={classes.numberDescription}>Schedule</Typography>
                        </div>
                      </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 304,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: '#4fb4e8',
                        paddingTop: 82,
                        borderRadius: 8,
                      }}
                    >
                      <Typography variant="h4" className={classes.whiteNumber}>
                        {study.completed}
                      </Typography>
                      <Typography
                        variant="h4"
                        className={classes.whiteNumberDescription}
                      >
                        Completed
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                xl={9}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 324,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant="h2">
                        Applications
                      </Typography>
                      <Chart study={study} />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 324,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant="h2">
                        Locations
                      </Typography>
                      <div
                        style={{
                          width: '40%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        <Typography
                          variant="h2"
                          style={{
                            fontSize: 12,
                            fontWeight: 900,
                            color: '#4fb4e8',
                            marginTop: 32,
                            letterSpacing: '0.3em',
                          }}
                        >
                          Top Areas
                        </Typography>
                        <div
                          style={{
                            marginTop: 16,
                            width: '80%',
                            height: 1,
                            backgroundColor: '#DBDBDB',
                          }}
                        ></div>
                        {top5.map((item, index) => (
                          <div key={index}>
                            {item.applications !== -1 && (
                              <Typography
                                variant="h2"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  color: '#4A4A4A',
                                  marginTop: 16,
                                  width: '80%',
                                }}
                              >
                                {item.stateLongName}
                                <span
                                  style={{
                                    marginLeft: 0,
                                    color: 'rgba(74, 74, 74, 0.5)',
                                    float: 'right',
                                  }}
                                >
                                  {item.applications}
                                </span>
                              </Typography>
                            )}
                          </div>
                        ))}
                        {/* <Typography variant="h2" style={{fontSize: 12, fontWeight: 500, color: '#4A4A4A', marginTop: 16, width: '80%'}}>Arkansas<span style={{marginLeft: 0, color: 'rgba(74, 74, 74, 0.5)', float: 'right'}}>24</span></Typography>
                              <Typography variant="h2" style={{fontSize: 12, fontWeight: 500, color: '#4A4A4A', marginTop: 16, width: '80%'}}>California<span style={{marginLeft: 0, color: 'rgba(74, 74, 74, 0.5)', float: 'right'}}>4</span></Typography>
                              <Typography variant="h2" style={{fontSize: 12, fontWeight: 500, color: '#4A4A4A', marginTop: 16, width: '80%'}}>New York<span style={{marginLeft: 0, color: 'rgba(74, 74, 74, 0.5)', float: 'right'}}>3</span></Typography>
                              <Typography variant="h2" style={{fontSize: 12, fontWeight: 500, color: '#4A4A4A', marginTop: 16, width: '80%'}}>Texas<span style={{marginLeft: 0, color: 'rgba(74, 74, 74, 0.5)', float: 'right'}}>2</span></Typography>
                              <Typography variant="h2" style={{fontSize: 12, fontWeight: 500, color: '#4A4A4A', marginTop: 16, width: '80%'}}>Lousiana<span style={{marginLeft: 0, color: 'rgba(74, 74, 74, 0.5)', float: 'right'}}>1</span></Typography> */}
                      </div>
                      <div style={{ width: '60%', display: 'inline-block' }}>
                        <Map study={study} setTop5={setTop5} />
                        {/* {console.log('mapmap', study)} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: 'calc(100% - 48px)',
                        marginLeft: 24,
                        height: 18,
                        background:
                          'linear-gradient(270deg, #783131 0%, #A84545 17.71%, #D85959 36.98%, #4fb4e8 53.12%, #F49292 70.83%, #F7B1B1 85.94%, #FAD0D0 100%)',
                        paddingLeft: 24,
                        paddingRight: 24,
                        marginTop: -46,
                      }}
                    ></div>
                    <div
                      style={{
                        width: 'calc(100% - 48px)',
                        marginLeft: 24,
                        height: 18,
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginTop: 0,
                      }}
                    >
                      <div style={{ display: 'inline-block', float: 'left' }}>
                        <Typography
                          variant="h2"
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            color: 'rgba(74, 74, 74, 0.5)',
                          }}
                        >
                          0
                        </Typography>
                      </div>
                      <div style={{ display: 'inline-block', float: 'right' }}>
                        <Typography
                          variant="h2"
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            color: 'rgba(74, 74, 74, 0.5)',
                          }}
                        >
                          100
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 283,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant="h2">
                        Gender
                      </Typography>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '60%',
                          verticalAlign: 'top',
                          marginTop: 12,
                        }}
                      >
                        <PieChart
                          animate={false}
                          label={({ dataEntry }) => dataEntry.value + '%'}
                          labelStyle={{
                            fontSize: 14,
                            fontFamily: "'sofia-pro', sans-serif",
                            fontWeight: 600,
                            color: '#FFFFF',
                          }}
                          style={{
                            color: 'white',
                            color: '#FFFFFF',
                            height: 178,
                            marginTop: 10,
                          }}
                          data={[
                            {
                              title: 'Male',
                              value: getMale(),
                              color: '#4fb4e8',
                            },
                            {
                              title: 'Female',
                              value: getFemale(),
                              color: '#4fb4e830',
                            },
                          ]}
                        />
                      </div>
                      <div
                        style={{
                          width: '40%',
                          marginRight: 0,
                          display: 'inline-block',
                          verticalAlign: 'top',
                          marginTop: 24,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e8',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Male
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e830',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Female
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 283,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant="h2">
                        Diagnosed
                      </Typography>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '60%',
                          verticalAlign: 'top',
                          marginTop: 12,
                        }}
                      >
                        <PieChart
                          // style={{boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)'}}
                          animate={false}
                          labelStyle={{
                            fontSize: 14,
                            // boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.2)',
                            fontFamily: "'sofia-pro', sans-serif",
                            fontWeight: 600,
                            fontColor: '#FFFFF',
                            color: 'white',
                            background: 'white',
                            backgroundColor: 'white',
                          }}
                          label={({ dataEntry }) => dataEntry.value + '%'}
                          style={{ color: 'white', height: 178, marginTop: 10 }}
                          data={[
                            {
                              title: 'Yes',
                              value: getDiagnosed(),
                              color: '#4fb4e8',
                            },
                            {
                              title: 'No',
                              value: getNotDiagnosed(),
                              color: '#4fb4e830',
                            },
                          ]}
                        />
                      </div>
                      <div
                        style={{
                          width: '40%',
                          marginRight: 0,
                          display: 'inline-block',
                          verticalAlign: 'top',
                          marginTop: 24,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e8',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Yes
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e830',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            No
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 283,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant="h2">
                        Age
                      </Typography>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '60%',
                          verticalAlign: 'top',
                          marginTop: 12,
                        }}
                      >
                        <PieChart
                          // style={{boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)'}}
                          animate={false}
                          style={{ color: 'white', height: 150, marginTop: 10 }}
                          data={[
                            {
                              title: '10+',
                              value: get10Minus(),
                              color: '#FDE6E6',
                            },
                            {
                              title: '11-18',
                              value: getAge11to18(),
                              color: '#F7B1B1',
                            },
                            {
                              title: '19-29',
                              value: getAge19to29(),
                              color: '#F38382',
                            },
                            {
                              title: '30-55',
                              value: getAge30to55(),
                              color: '#D87877',
                            },
                            {
                              title: '56-74',
                              value: getAge56to74(),
                              color: '#C0504F',
                            },
                            {
                              title: '75+',
                              value: getAge75plus(),
                              color: '#A66262',
                            },
                          ]}
                        />
                      </div>
                      <div
                        style={{
                          width: '40%',
                          marginRight: 0,
                          display: 'inline-block',
                          verticalAlign: 'top',
                          marginTop: 24,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 8,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e8',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Under 10
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 8,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e830',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            11-18
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 8,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e8',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            19-29
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 8,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e830',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            56-74
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 8,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e8',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            75+
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 8,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e830',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'sofia-pro', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            30-55
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <StudyOverviewSkeleton />
      )}
    </div>
  );
};

export default StudyOverview;
