import firebase, { storage, analytics } from '../../Firebase';
import axios from 'axios';

export default {
  createUser,
  getUser,
  getUserPages,
  updateUser,
  getUserOptionalStudies,
  updateStudies,
  createOptionalStudiesItem,
  getUserById,
  getUserByIds,
  getStudiesOfCenter,
  getStudiesByEmail,
  getUserDataByEmail,
  getUserByEmail,
  getAdminDataByEmail,
};

const db = firebase.firestore();

async function getAdminDataByEmail(email) {
  let users = await db.collection('users').where('email', '==', email).get();
  let user = users.docs[0].data();
  return user;
}

async function getStudiesByEmail() {
  let currentUser = firebase.auth().currentUser;
  var studies = (
    await db
      .collectionGroup('team')
      .where('email', '==', currentUser.email)
      .get()
  ).docs;
  if (studies.length > 0) {
    if (studies[0].ref.parent.path.includes('centers')) {
    } else {
    }
  }
}

async function getUserByEmail(email) {
  let users = await db.collection('users').where('email', '==', email).get();
  if (users.docs.length > 0) {
    return users.docs[0];
  } else {
    return null;
  }
}

async function getUserDataByEmail(email) {
  let users = await db.collection('users').where('email', '==', email).get();
  if (users.docs.length > 0) {
    return users.docs[0].data();
  } else {
    return { firstName: 'T', lastName: 'T', email: email };
  }
}

async function getUser() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var user = await db.collection('users').doc(currentUser.uid).get();
    if (user.exists) {
      return {
        userType: user.data().type,
        userData: user.data(),
      };
    } else {
    }
  } else {
    return null;
  }
}

async function getUserByIds(ids) {
  const refs = ids.map((id) => db.collection('patients').doc(id).get());
  const users = await Promise.all(refs).then((users) =>
    users.map((doc) => doc.data()),
  );
  return users;
}

async function getUserById(id) {
  return (await db.collection('patients').doc(id).get()).data();
}

async function getStudiesOfCenter() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var studies = (
      await db
        .collection('sponsored')
        .where('email', '==', currentUser.email)
        .get()
    ).docs;
    let studiesData = [];
    for (let index = 0; index < studies.length; index++) {
      const study = studies[index].data();
      let applied = [];
      let completed = [];
      let scheduled = [];
      let applications = [];
      const studyApplications = (
        await db
          .collection('applications')
          .where('studyId', '==', study.studyId)
          .get()
      ).docs;
      for (let index1 = 0; index1 < studyApplications.length; index1++) {
        const studyApplication = studyApplications[index1].data();
        studyApplication['objectId'] = studyApplications[index1].id;
        applications.push(studyApplication);
        let isCompleted = false;
        let isScheduled = false;
        for (
          let index2 = 0;
          index2 < studyApplication.status.length;
          index2++
        ) {
          const status = studyApplication.status[index2];
          if (status === 'Confirmed Appointment') {
            scheduled = true;
          }
          if (status === 'Completed') {
            completed = true;
          }
        }
        if (isCompleted) {
          completed.push(studyApplication);
        } else if (isScheduled) {
          scheduled.push(studyApplication);
        } else {
          applied.push(studyApplication);
        }
      }
      studiesData.push({
        ...study,
        applications: applications,
        applied,
        completed,
        scheduled,
      });
    }
    return studiesData;
  } else {
    return [];
  }
}

async function getUserPages() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var pages = (await db.collection('pages').doc(currentUser.uid).get()).docs;
    return pages;
  } else {
    return null;
  }
}

async function getUserOptionalStudies() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var optionalStudies = (
      await db.collection('optionalStudies').doc(currentUser.uid).get()
    ).data();
    return optionalStudies;
  } else {
    return null;
  }
}

async function updateStudies(studies) {
  let user = firebase.auth().currentUser;
  if (user) {
    const docRef = db.collection('optionalStudies').doc(user.uid);
    await docRef.update({ studies: studies });
    return true;
  } else {
    return false;
  }
}

async function updateUser(newData) {
  let user = firebase.auth().currentUser;
  if (user) {
    const docRef = db.collection('users').doc(user.uid);
    await docRef.update(newData);
    return true;
  } else {
    return false;
  }
}

async function createUser(uid, userData) {
  // 176 id of undefined
  await db
    .collection('users')
    .doc(uid)
    .set({
      ...userData,
    })
    .then(function (docRef) {
      return true;
    })
    .catch(function (error) {
      console.error('Error adding document: ', error);
    });
}

async function createOptionalStudiesItem(uid, studies) {
  await db
    .collection('optionalStudies')
    .doc(uid)
    .set({
      studies: studies,
    })
    .then(function (docRef) {
      let docId = docRef.id;
      return docId;
    })
    .catch(function (error) {
      console.error('Error adding document: ', error);
    });
}
