import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import StudySettings from './StudySettings';
import StudyListing from './StudyListing';
import Team from './Team';
import SupportTeam from './SupportTeam';
import Help from './Help';
import StudyCenters from './StudyCenters';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles(MuiStyleFunction);

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const Settings = (props) => {
  const classes = useStyles();

  const params = useParams();

  const { userData } = useContext(AuthContext);
  const { studies } = useContext(StudiesContext);

  const [applications, setApplications] = useState([]);
  const [settingTab, setSettingTab] = useState(1);

  // navigateToStudies = () => {
  //   this.props.main.setState({currentPage: 1})
  // }

  const openLinkInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  const getTopSize = () => {
    if (
      window.document.getElementById('studyTitle') &&
      window.document.getElementById('studyTitle').offsetHeight > 50
    ) {
      return 196 + 46;
    } else {
      return 196;
    }
  };

  return (
    <div>
      <div style={{ paddingTop: 30, width: '100%', paddingBottom: 90 }}>
        {!mobile && (
          <Stack direction="row" spacing={2} style={{ marginTop: -40 }}>
            <Stack
              spacing={2}
              style={{
                width: 300,
                minHeight: 600,
                position: 'fixed',
                top: getTopSize(),
              }}
            >
              <div
                onClick={() => setSettingTab(1)}
                className={
                  settingTab === 1
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 1
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Study Settings
                </Typography>
              </div>
              <div
                onClick={() => setSettingTab(2)}
                className={
                  settingTab === 2
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 2
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Study Listing
                </Typography>
              </div>
              <div
                onClick={() => setSettingTab(3)}
                className={
                  settingTab === 3
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 3
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Company Team
                </Typography>
              </div>
              <div
                onClick={() => setSettingTab(5)}
                className={
                  settingTab === 5
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 5
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Support Team
                </Typography>
              </div>
              <div
                onClick={() => setSettingTab(4)}
                className={
                  settingTab === 4
                    ? classes.selectedSettingTab
                    : classes.settingTab
                }
              >
                <Typography
                  variant="h2"
                  className={
                    settingTab === 4
                      ? classes.selectedSettingTabTitle
                      : classes.settingTabTitle
                  }
                >
                  Study Centers
                </Typography>
              </div>
            </Stack>
            <Stack spacing={2} style={{ width: '100%', marginLeft: 336 }}>
              {settingTab === 1 && <StudySettings data={props.data} />}
              {settingTab === 2 && <StudyListing data={props.data} />}
              {settingTab === 3 && <Team data={props.data} />}
              {settingTab === 5 && <SupportTeam data={props.data} />}
              {settingTab === 4 && <StudyCenters data={props.data} />}
            </Stack>
          </Stack>
        )}
      </div>
    </div>
  );
};

export default React.memo(Settings);
