import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import StudyService from '../../../Services/StudyService/StudyService';
import { CircularProgress } from '@material-ui/core';
import InvitationService from '../../../Services/InvitationService/InvitationService';
import { SnackbarContext } from '../../../contexts/Snackbar';

const useStyles = makeStyles(MuiStyleFunction);

const Status = ({ role, setRole }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const select = (role) => {
    setRole(role);
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
        }}
        className={classes.roleBox}
        onClick={handleClick}
      >
        <Typography variant="h4" className={classes.role}>
          {role}
        </Typography>
        <img
          alt=""
          className={classes.status}
          src={require('../../../Assets/Images/blackArrowDown.png')}
          width={8.41}
          height={9.13}
          style={{ marginLeft: 5, marginTop: 3 }}
        />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => select('Admin')}
          value={10}
          className={classes.roleMenuItem}
        >
          Admin
        </MenuItem>
        <MenuItem
          onClick={() => select('Viewer')}
          value={20}
          className={classes.roleMenuItem}
        >
          Viewer
        </MenuItem>
      </Menu>
    </div>
  );
};

const AccountDetails = (props) => {
  const classes = useStyles();

  const { userData } = useContext(AuthContext);
  const { show } = useContext(SnackbarContext);

  const [role, setRole] = React.useState('Admin');
  const [emailToInvite, setEmailToInvite] = React.useState('');
  const [emailIsValid, setEmailIsValid] = React.useState(false);
  const [addingUser, setAddingUser] = React.useState(false);

  const checkIfEmailIsValid = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return true;
    }
    return false;
  };

  const handleChangeEmail = (newValue) => {
    setEmailToInvite(newValue);
    if (checkIfEmailIsValid(newValue)) {
      if (!emailIsValid) setEmailIsValid(true);
    } else {
      if (emailIsValid) setEmailIsValid(false);
    }
  };

  // const addTeamMember = async () => {
  //   setAddingUser(true)
  //   console.(props)

  //   let teamMemberId = await StudyService.addTeamMemberToStudyByRef(props.studyCenterData.ref, emailToInvite, role)
  //   await InvitationService.createInvitation(props.studyData.document.id, props.studyData.companyName, emailToInvite, props.studyData.studyData.customTitle, teamMemberId, 'center', props.centerName)
  //   await props.getTeam()
  //   setAddingUser(false)
  //   props.setAddNew(false)
  // }

  const addTeamMember = async () => {
    setAddingUser(true);
    let teamMemberDoc = await StudyService.addTeamMemberToStudyByRef(
      props.studyCenterData.ref,
      emailToInvite,
      role,
    );
    let invitationDoc = await InvitationService.createInvitation(
      props.studyData.document.id,
      props.studyData.companyName,
      emailToInvite,
      props.studyData.studyData.customTitle,
      teamMemberDoc.id,
      'center',
      props.centerName,
    );
    await props.studyCenterData.ref
      .collection('team')
      .doc(teamMemberDoc.id)
      .update({
        invitationId: invitationDoc.id,
      });
    await props.getTeam();
    setAddingUser(false);
    show('success', 'Added successfully');
    props.setAddNew(false);
  };

  return (
    <div className={props.addNew ? classes.teamItem : null}>
      {props.addNew && (
        <div className={classes.teamItemContent}>
          <div style={{ flexGrow: 1, paddingRight: 24 }}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={emailToInvite}
              id="standard-basic"
              label="Email"
              className={classes.emailTextField}
              onChange={(e) => handleChangeEmail(e.target.value.toLowerCase())}
            />
          </div>
          <Status role={role} setRole={setRole} />
          {addingUser ? (
            <CircularProgress
              size={24}
              className={classes.addingTeamMemberLoader}
            />
          ) : (
            <Button
              className={
                emailIsValid
                  ? classes.inviteButton
                  : classes.disabledInviteButton
              }
              disabled={!emailIsValid}
              onClick={() => addTeamMember()}
            >
              Invite
            </Button>
          )}
        </div>
      )}
      {props.addNew && <Divider className={classes.teamDivider} />}
      {props.addNew && (
        <Button
          onClick={() => props.setAddNew(false)}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      )}
    </div>
  );
};

export default React.memo(AccountDetails);
