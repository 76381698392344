import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import NewCenterTable from '../NewCenterTable';
import StudyTable from '../StudyTable';
import StudyLeadsTable from '../StudyLeadsTable';
import { Container } from '@material-ui/core';
import LocationsTable from '../LocationsTable';

import MuiStyleFunction from './StudyDashboard.style';
import { StudiesContext } from '../../../../contexts/Studies';
import { AuthContext } from '../../../../contexts/Auth';
import Master from '../../Master/Settings';
import MasterInquiries from '../../InquiriesTab/Inquiries';
import AdminOverViewTest from '../StudyOverview/AdminOverviewTest';
import CenterOverview from '../StudyOverview/CenterOverview';
import ExportPopup from './ExportPopup';
import StudyService from '../../../../Services/StudyService/StudyService';
import { Slide, useScrollTrigger } from '@mui/material';
import { StudyContext } from '../../../../contexts/StudyContext';
import { useNavigate, useParams } from 'react-router-dom';
import LeadsScreen from '../LeadsScreen';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 10,
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const StudyDashboard = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { studies } = useContext(StudiesContext);
  const { userData } = useContext(AuthContext);
  const { study, setStudy, setNctId } = useContext(StudyContext);
  const [page, setPage] = useState(null);
  const [openExportPoup, setOpenExportPoup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    let study = null;
    if (studies.length > 0) {
      for (let index = 0; index < studies.length; index++) {
        const potentialStudy = studies[index];
        if (id === potentialStudy.document.id) {
          study = potentialStudy;
        }
      }
      handleSetStudy(study);
      setNctId(id);
      setStudy({ ...study });
    }
  }, [studies]);

  const handleSetStudy = async (study) => {
    let studyDataWithUsersData = await StudyService.getStudyDataWithUsersData(
      study,
    );
    setStudy({ ...studyDataWithUsersData });
  };

  useEffect(() => {
    if (userData.type === 'master' || userData.type === 'support') {
      setPage(1);
    } else if (userData.type === 'center') {
      setPage(5);
    } else {
      setPage(1);
    }
  }, [userData]);

  const checkIfToShowNotification = () => {
    for (let index = 0; index < study.applications.length; index++) {
      const application = study.applications[index];
      const status = application.status[application.status.length - 1].status;
      if (
        (status === 'Applied' ||
          status === 'Different Date Needed' ||
          status === 'Schedule Appointment') &&
        !application.reviewed
      ) {
        return true;
      }
    }
  };

  return (
    <div>
      {openExportPoup && (
        <ExportPopup
          open={openExportPoup}
          setOpenExportPoup={setOpenExportPoup}
          study={study}
          userType={userData.type}
        />
      )}
      {study !== null && study !== undefined ? (
        <div>
          <div
            style={{
              paddingTop: 0,
              paddingBottom: mobile ? 160 : 40,
              width: '100%',
            }}
          >
            <HideOnScroll {...props}>
              <div
                style={{
                  position: 'sticky',
                  top: 64,
                  zIndex: 99,
                  background: 'white',
                }}
              >
                <Container maxWidth="lg">
                  {mobile ? (
                    <div
                      className={classes.backButton}
                      style={{ marginBottom: 20 }}
                      onClick={() => navigate(-1)}
                    >
                      <img
                        alt=""
                        src={require('../../../../Assets/Images/leftArrow.png')}
                        height={20.5}
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                        }}
                      />
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 17,
                          color: '#5A5A5A',
                          marginLeft: 8,
                          textTransform: 'none',
                          verticalAlign: 'middle',
                          fontWeight: 'normal',
                        }}
                      >
                        Clinical Studies
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                  <Typography
                    className={classes.pageTitle}
                    id={'studyTitle'}
                    variant="h2"
                  >
                    {study.studyData.customTitle}
                  </Typography>
                  {mobile ? (
                    <div
                      variant="h2"
                      className={classes.pageSubTitle}
                      style={{
                        fontFamily: "'sofia-pro', sans-serif",
                        marginTop: 12,
                      }}
                    >
                      {study.companyName}
                    </div>
                  ) : (
                    <div>
                      <Typography variant="h2" className={classes.pageSubTitle}>
                        {study.companyName}
                      </Typography>
                      <div className={classes.tableMenuSection}>
                        {(userData.type === 'master' ||
                          userData.type === 'support' ||
                          userData.type === 'study') && (
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              paddingLeft: 4,
                            }}
                          >
                            <Button onClick={() => setPage(1)}>
                              <Typography
                                variant="h2"
                                className={
                                  page === 1
                                    ? classes.selectedMenuButton
                                    : classes.menuButton
                                }
                              >
                                Overview
                              </Typography>
                            </Button>
                          </div>
                        )}
                        {(userData.type === 'master' ||
                          userData.type === 'support' ||
                          userData.type === 'center') && (
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              paddingLeft: 4,
                            }}
                          >
                            <Button
                              disabled={study && !study.withUsersData}
                              onClick={() => setPage(5)}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: 4,
                                }}
                              >
                                <Typography
                                  variant="h2"
                                  className={
                                    page === 5
                                      ? classes.selectedMenuButton
                                      : classes.menuButton
                                  }
                                >
                                  Inquiries
                                </Typography>
                                {study && !study.withUsersData ? (
                                  <CircularProgress
                                    style={{
                                      width: 14,
                                      height: 14,
                                      marginLeft: 4,
                                      marginTop: 4,
                                    }}
                                  />
                                ) : (
                                  <>
                                    {checkIfToShowNotification() && (
                                      <div
                                        style={{
                                          width: 6,
                                          height: 6,
                                          borderRadius: 3,
                                          background: 'rgb(240, 99, 99)',
                                          marginTop: 4,
                                        }}
                                      ></div>
                                    )}
                                  </>
                                )}
                              </div>
                            </Button>
                          </div>
                        )}
                        {(userData.type === 'master' ||
                          userData.type === 'support' ||
                          userData.type === 'study') && (
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              paddingLeft: 4,
                            }}
                          >
                            <Button onClick={() => setPage(3)}>
                              <Typography
                                variant="h2"
                                className={
                                  page === 3
                                    ? classes.selectedMenuButton
                                    : classes.menuButton
                                }
                              >
                                Locations
                              </Typography>
                            </Button>
                          </div>
                        )}
                        {(userData.type === 'master' ||
                          userData.type === 'support' ||
                          userData.type === 'center' ||
                          userData.type === 'study') && (
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              paddingLeft: 4,
                            }}
                          >
                            <Button
                              disabled={study && !study.withUsersData}
                              onClick={() => setPage(2)}
                            >
                              <Typography
                                variant="h2"
                                className={
                                  page === 2
                                    ? classes.selectedMenuButton
                                    : classes.menuButton
                                }
                              >
                                Applications
                              </Typography>
                              {study && !study.withUsersData && (
                                <CircularProgress
                                  style={{
                                    width: 14,
                                    height: 14,
                                    marginLeft: 4,
                                    marginTop: 4,
                                  }}
                                />
                              )}
                            </Button>
                          </div>
                        )}
                        {userData.type === 'master' && (
                          // userData.type === 'support' ||
                          // userData.type === 'center' ||
                          // userData.type === 'study'
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              paddingLeft: 4,
                            }}
                          >
                            <Button
                              disabled={study && !study.withUsersData}
                              onClick={() => setPage(6)}
                            >
                              <Typography
                                variant="h2"
                                className={
                                  page === 6
                                    ? classes.selectedMenuButton
                                    : classes.menuButton
                                }
                              >
                                Leads
                              </Typography>
                              {study && !study.withUsersData && (
                                <CircularProgress
                                  style={{
                                    width: 14,
                                    height: 14,
                                    marginLeft: 4,
                                    marginTop: 4,
                                  }}
                                />
                              )}
                            </Button>
                          </div>
                        )}
                        {(userData.type === 'master' ||
                          userData.type === 'support') && (
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              paddingLeft: 4,
                            }}
                          >
                            <Button onClick={() => setPage(0)}>
                              <Typography
                                variant="h2"
                                className={
                                  page === 0
                                    ? classes.selectedMenuButton
                                    : classes.menuButton
                                }
                              >
                                Settings
                              </Typography>
                            </Button>
                          </div>
                        )}
                        {/* {(userData.type === 'master' ||
                        userData.type === 'support') && (
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            paddingLeft: 4,
                          }}
                        >
                          <Button
                            onClick={() => setOpenExportPoup(true)}
                            startIcon={
                              <img
                                width='24px'
                                height='24px'
                                src={require('../../../../Assets/Images/exportIcon.png')}
                              />
                            }
                          >
                            <Typography
                              variant='h2'
                              className={classes.selectedMenuButton}
                            >
                              Export
                            </Typography>
                          </Button>
                        </div>
                      )} */}
                      </div>
                      <Divider
                        style={{
                          marginTop: 20,
                          marginBottom: 10,
                          height: 2,
                          backgroundColor: 'rgba(0, 0, 0, 0.14)',
                        }}
                      />
                    </div>
                  )}
                </Container>
              </div>
            </HideOnScroll>
            <Container maxWidth="lg">
              {page === 0 && <Master data={study} />}
              {page === 5 && <MasterInquiries data={study} />}
              {page === 1 &&
                study.studyData.isSponsored &&
                (userData.type === 'master' || userData.type === 'support') && (
                  <AdminOverViewTest study={study} />
                )}
              {/* {(page === 1 && study.studyData.isSponsored && (userData.type === 'study')) && (
                <StudyOverview study={study} />
              )}  */}
              {page === 1 &&
                study.studyData.isSponsored &&
                userData.type === 'study' && (
                  <AdminOverViewTest study={study} />
                )}
              {page === 1 &&
                !study.studyData.isSponsored &&
                (userData.type === 'master' ||
                  userData.type === 'study' ||
                  userData.type === 'support') && (
                  <AdminOverViewTest study={study} />
                )}
              {/* <BasicAdminOverview study={study} /> */}
              {page === 1 && userData.type === 'center' && (
                <CenterOverview study={study} />
              )}
              {page === 2 && userData.type === 'center' && (
                <NewCenterTable study={study} />
              )}
              {/* {(page === 2 && (userData.type === 'study' || userData.type === 'master')) && ( */}
              {page === 2 &&
                (userData.type === 'master' || userData.type === 'support') && (
                  <StudyTable study={study} />
                )}
              {page === 6 && <LeadsScreen study={study} />}
              {page === 2 && userData.type === 'study' && (
                <StudyLeadsTable study={study} />
              )}
              {page === 3 && (
                // <AdminOverview />
                <LocationsTable study={study} />
                // <StudyOverview study={study} />
              )}
              {/* <MTable /> */}
            </Container>
          </div>
        </div>
      ) : (
        <Container maxWidth="lg">
          <Skeleton
            variant="rect"
            width="140px"
            height="30px"
            style={{ marginTop: 25 }}
          />
          <Skeleton
            variant="rect"
            width="220px"
            height="45px"
            style={{ marginTop: 26 }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height="26px"
            style={{ marginTop: 26 }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height="2px"
            style={{ marginTop: 20, marginBottom: 32 }}
          />
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              xl={3}
              style={{ alignItems: 'center' }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{ alignItems: 'center' }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 164,
                      flexFlow: 'column',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: 28,
                      paddingTop: 28,
                      boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                      backgroundColor: 'white',
                      borderRadius: 8,
                    }}
                  >
                    {/* <Skeleton variant="rect" width="100%" height="100%"/> */}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{ alignItems: 'center' }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 164,
                      flexFlow: 'column',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: 28,
                      paddingTop: 28,
                      boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                      backgroundColor: '#4fb4e8',
                      borderRadius: 8,
                    }}
                  >
                    {/* <Skeleton variant="rect" width="100%" height="100%"/> */}
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                style={{ alignItems: 'center', marginTop: 24 }}
              >
                <div
                  style={{
                    width: '100%',
                    height: 283,
                    boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                    backgroundColor: 'white',
                    borderRadius: 8,
                    padding: 24,
                  }}
                >
                  {/* <Skeleton variant="rect" width="100%" height="100%"/> */}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              xl={4}
              style={{ alignItems: 'center' }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{ alignItems: 'center' }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: mobile ? 'auto' : 344,
                      boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                      backgroundColor: 'white',
                      borderRadius: 8,
                      padding: 24,
                    }}
                  >
                    {/* <Skeleton variant="rect" width="100%" height="100%"/> */}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{ alignItems: 'center' }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: mobile ? 'auto' : 344,
                      boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                      backgroundColor: 'white',
                      borderRadius: 8,
                      padding: 24,
                    }}
                  >
                    {/* <Skeleton variant="rect" width="100%" height="100%"/> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              xl={5}
              style={{ alignItems: 'center' }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{ alignItems: 'center' }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: mobile ? 'auto' : 344,
                      boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                      backgroundColor: 'white',
                      borderRadius: 8,
                      padding: 24,
                    }}
                  >
                    {/* <Skeleton variant="rect" width="100%" height="100%"/> */}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{ alignItems: 'center' }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: mobile ? 'auto' : 344,
                      boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                      backgroundColor: 'white',
                      borderRadius: 8,
                      padding: 24,
                    }}
                  >
                    {/* <Skeleton variant="rect" width="100%" height="100%"/> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop> */}
        </Container>
      )}
    </div>
  );
};

export default StudyDashboard;
