import React, {useContext, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

// const grayCloseIcon = require('../../Assets/Images/grayCloseIcon.png');

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: 32,
        paddingBottom: 60,
        opacity: mobile ? 1 : 1,
        height: '100%',
        position: 'relative'
    },
    dialogContainer: {
        padding: 0
    },
    dialogPaper: {
        padding: 0,
        opacity: mobile ? 1 : 1,
        position: 'relative'
    },
    closeIcon: {
        width: 28,
        height: 28,
        top: 20,
        right: 20,
        position: 'absolute',
        cursor: 'pointer'
    },
    contentWrapper: {
        display: 'flex',
        height: '100%',
        // flexWrap: 'wrap',
        flexDirection: 'column',
        paddingBottom: 80
    },
    title: {
        fontFamily: theme.typography.sofiaPro,
        fontWeight: 'bold',
        fontSize: 22,
        color: theme.palette.primary.dark,
        marginBottom: 12
    },
    text: {
        fontFamily: theme.typography.sofiaPro,
        fontWeight: 'regular',
        fontSize: 17,
        color: theme.palette.primary.dark,
        marginBottom: 24
    },
    closeIcon: {
        width: 28,
        height: 28,
        top: 20,
        right: 20,
        position: 'absolute',
        cursor: 'pointer'
    },
    fileContainer: {
        boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
        width: '100%', height: mobile ? 'calc(100% - 150px)' : 320,
        padding: 12,
        overflowY: 'scroll'
    },
    doneButton: {
        color: 'white',
        background: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        alignSelf: 'center',
        height: 48,
        borderRadius: 12,
        fontWeight: 'bold',
        textTransform: 'none',
        display: 'inline-block',
        width: 'calc(100% - 64px)',
        fontSize: 16,
        left: 32,
        position: 'absolute', bottom: 66
    },
    loading: {
        color: theme.palette.primary.main,
        alignSelf: 'center',
        height: 48,
        borderRadius: 12,
        fontWeight: 'bold',
        textTransform: 'none',
        display: 'inline-block',
        width: 'calc(100% - 64px)',
        fontSize: 16,
        left: 32,
        position: 'absolute', bottom: 66,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'cetner'
    },
    nevermindButton: {
        color: 'rgba(0,0,0,0.3)',
        background: 'transparent',
        '&:hover': {
            background: 'transparent',
        },
        alignSelf: 'center',
        height: 48,
        borderRadius: 12,
        fontWeight: 'bold',
        textTransform: 'none',
        display: 'inline-block',
        width: 'calc(100% - 64px)',
        fontSize: 16,
        left: 32,
        position: 'absolute', bottom: 12
    }
}))

export default function DialogComponent(props) {

    const classes = useStyles()

    const handleClose = () => {
        props.setShowBAADialog(false)
    };

    const agree = () => {
        props.setBaa(true)
        handleClose()
    }

    return (
        <Dialog
            open={props.showBAADialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            // classes={{container: classes.dialogContainer, paper: classes.dialogPaper}}
            fullWidth={true}
            fullScreen={mobile ? true : false}
            maxWidth={'sm'}
        >
            <DialogContent classes={{root: classes.dialogContent}}>
                {/* <img alt='' src={require('../../Assets/Images/grayCloseIcon.png')} className={classes.closeIcon} onClick={handleClose} /> */}
                <div className={classes.contentWrapper}>
                    {props.errorMessage === '' ? (
                        <>
                            <Typography className={classes.title}>BUSINESS ASSOCIATE AGREEMENT</Typography>
                            <Typography className={classes.text}>This will allow you to view health information from potential study participants.</Typography>
                            <div className={classes.fileContainer}>
                                <Typography>
                                    This Business Associate Agreement is entered into by and between Barrow Neurological Institute (“Business Associate”) and Know Healthtech, Inc. (“Covered Entity”) (collectively, “the Parties”) as of the date executed by or on behalf of an authorized representative of Covered Entity (“Effective Date”). 
                                </Typography>
                                <br/>
                                <Typography>
                                    <b>RECITALS</b>
                                </Typography>
                                <Typography>
                                    A. The Business Associate and Covered Entity have commenced a business relationship that is memorialized in a separate agreement (the “Underlying Contract”) that will involve the Use and/or Disclosure of protected health information of the Covered Entity;  
                                </Typography>
                                <br/>
                                <Typography>
                                    B. The Parties wish to enter into this Agreement in order for both parties to establish their respective compliance with the requirements of the Health Insurance Portability and Accountability Act of 1996 (HIPAA), the Health Information Technology for Economic and Clinical Health Act of 2009 (HITECH Act), and the 2013 Final HIPAA Omnibus Rule, including all amendments thereto and all rules and regulations promulgated thereunder (collectively, the “HIPAA Rules”); and 
                                </Typography>
                                <br/>
                                <Typography>
                                    C. The Parties wish to comply in all respects with the requirements of the HIPAA Rules.  
                                </Typography>
                                <br/>
                                <Typography>
                                    <b>NOW, THEREFORE, the Parties agree as follows:</b>
                                </Typography>
                                <br/>
                                <Typography>
                                    <b>1. Definitions. All capitalized terms used in this BAA but not defined herein shall have the meanings set forth in the HIPAA Rules.</b> 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.1 Not use or disclose protected health information other than as permitted or required by the Agreement or as required by law; 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.2 Use appropriate safeguards, and comply with Subpart C of 45 CFR Part 164 with respect to electronic protected health information, to prevent use or disclosure of protected health information other than as provided for by the Agreement; 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.3 Report to Covered Entity any use or disclosure of protected health information not provided for by the Agreement of which it becomes aware, including breaches of unsecured protected health information as required at 45 CFR 164.410, and any security incident of which it becomes aware; 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.4 In accordance with 45 CFR 164.502(e)(1)(ii) and 164.308(b)(2), if applicable, ensure that any subcontractors that create, receive, maintain, or transmit protected health information on behalf of the Business Associate agree to the same restrictions, conditions, and requirements that apply to the Business Associate with respect to such information; 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.5 Make available protected health information in a designated record set to the Covered Entity as necessary to satisfy Covered Entity’s obligations under 45 CFR 164.524; 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.6 Make any amendment(s) to protected health information in a designated record set as directed or agreed to by the Covered Entity pursuant to 45 CFR 164.526, or take other measures as necessary to satisfy Covered Entity’s obligations under 45 CFR 164.526; 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.7 Maintain and make available the information required to provide an accounting of disclosures to the Covered Entity as necessary to satisfy Covered Entity’s obligations under 45 CFR 164.528; 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.8 To the extent the Business Associate is to carry out one or more of Covered Entity’s obligation(s) under Subpart E of 45 CFR Part 164, comply with the requirements of Subpart E that apply to the Covered Entity in the performance of such obligation(s); and 
                                </Typography>
                                <br/>
                                <Typography>
                                    2.9 Make its internal practices, books, and records available to the Secretary for purposes of determining compliance with the HIPAA Rules. 
                                </Typography>
                                <br/>
                                <Typography>
                                    <b>3. Permitted Uses and Disclosures of Business Associate</b> 
                                </Typography>
                                <br/>
                                <Typography>
                                    3.1 Business Associate may use or disclose protected health information as necessary to perform the functions, activities, or services for, or on behalf of, the Covered Entity as specified in the Underlying Contract. 
                                </Typography>
                                <br/>
                                <Typography>
                                    3.2 Business Associate may use or disclose protected health information as required by law. 
                                </Typography>
                                <br/>
                                <Typography>
                                    3.3 Business Associate agrees to make uses and disclosures and requests for protected health information consistent with Covered Entity’s minimum necessary policies and procedures. 
                                </Typography>
                                <br/>
                                <Typography>
                                    3.4 Business Associate may not use or disclose protected health information in a manner that would violate Subpart E of 45 CFR Part 164 if done by Covered Entity. 
                                </Typography>
                                <br/>
                                <Typography>
                                    3.5 Business Associate may use PHI to provide Data Aggregation services as permitted by 45 CFR §164.504(e)(2)(i)(B). Business Associate agrees that such Data Aggregation services shall be provided to Covered Entity and other covered entities for which Business Associate serves in the capacity of business associate, to permit data analyses that relate to Health Care Operations of such covered entities. Business Associate further agrees that said services shall not be provided in a manner that would result in any impermissible Disclosure of Protected Health Information to another covered entity who was not the originator and/or lawful possessor of said PHI and that all such services shall involve De-Identified PHI pursuant to the applicable provisions of the Privacy Rule and not susceptible to identification of any individual.
                                </Typography>
                                <br/>
                                <Typography>
                                    3.6 Business Associate may De-identify PHI and use De-identified PHI for any purpose not prohibited by applicable law, statute or regulation, for the Permitted Uses listed above, including Data Aggregation services as permitted by 45 CFR §164.504(e)(2)(i)(B), including for any other permissible Data Aggregation relating to the business services and purposes of Business Associate, provided however that any PHI used in this manner for any of the afore-mentioned purposes shall be de-identified pursuant to the applicable provisions of the Privacy Rule and not susceptible to identification of any individual. 
                                </Typography>
                                <br/>
                                <Typography>
                                    <b>4. Term and Termination</b>
                                </Typography>
                                <br/>
                                <Typography>
                                    4.1 The term of this Agreement shall commence as of the Effective Date and shall terminate when the Underlying Contract terminates or at such later date that Business Associate ceases to provide the services set forth in the Underlying Contract.
                                </Typography>
                                <br/>
                                <Typography>
                                    4.2 Upon termination of this Agreement, for any reason, Business Associate shall return or destroy all protected health information received from Covered Entity, or created or received by Business Associate on behalf of Covered Entity, or otherwise in Business Associate’s possession. Business Associate shall retain no copies of protected health information in any form. 
                                </Typography>
                                <br/>
                                <Typography>
                                    <b>5. Miscellaneous</b>
                                </Typography>
                                <br/>
                                <Typography>
                                    5.1 Any ambiguity in this Agreement shall be resolved in favor of a meaning that permits the Parties to comply with HIPAA Rules. 
                                </Typography>
                                <br/>
                                <Typography>
                                    5.2 The Parties agree to take such action as is necessary to amend this Agreement from time to time as is necessary for compliance with the requirements of the HIPAA Rules and any other applicable law. 
                                </Typography>
                                <br/>
                                <Typography>
                                    5.3 A reference in this Agreement to a section in the HIPAA Rules means the section as in effect or as amended. 
                                </Typography>
                                <br/>
                                <Typography>
                                    IN WITNESS WHEREOF, the Parties have caused this Agreement to be signed and delivered by their duly authorized representatives as of the Effective Date. 
                                </Typography>
                                <br/>
                            </div>
                        </>
                    ) : (
                        <>
                            <Typography className={classes.title}>Error while accepting invitation</Typography>
                            <Typography className={classes.text}>{props.errorMessage}</Typography>
                        </>
                    )}
                    {props.isWaiting && (
                        <div className={classes.loading}>
                            <CircularProgress size={30} />
                        </div>
                    )}
                    {(!props.isWaiting && props.errorMessage === '') && (
                        <Button onClick={props.globalCenterLink ? props.signupUserWithGlobalLink : props.signupUser} className={classes.doneButton}>Agree</Button>
                    )}
                    <Button onClick={handleClose} className={classes.nevermindButton}>Close</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
