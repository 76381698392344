import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(MuiStyleFunction);

const AccountDetails = (props) => {
  const classes = useStyles();

  const { userData } = useContext(AuthContext);

  return (
    <div style={{ overflow: 'hidden' }}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Typography className={classes.settingTitle} variant="h2">
            Help
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(AccountDetails);
