import React, { createContext, useState, useEffect, useContext } from 'react';
import StudyService from '../Services/StudyService/StudyService';
import { AuthContext } from './Auth';

export const StudiesContext = createContext();

function StudiesContextProvider(props) {
  const { isAuthenticated, userType } = useContext(AuthContext);

  const [studies, setStudies] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated && studies.length === 0) {
      getStudies();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (studies.length > 0) setLoading(false);
  }, [studies]);

  const getStudies = async () => {
    setLoading(true);
    console.log('userType', userType);
    if (userType === 'master') {
      let sponsoredStudies = await StudyService.getAllAdminData();
      setStudies([...sponsoredStudies]);
    } else if (userType === 'center') {
      let centerStudies = await StudyService.getCenterStudies();
      setStudies(centerStudies);
    } else if (userType === 'study') {
      let studyStudies = await StudyService.getStudyStudies();
      setStudies(studyStudies);
    } else if (userType === 'support') {
      let studyStudies = await StudyService.getStudyStudies();
      setStudies(studyStudies);
    } else {
      let centerStudies = await StudyService.getCenterStudies();
      setStudies(centerStudies);
    }
  };

  const updateStudyApplication = (targetStudy, targetApplication, newData) => {
    const updatedStudies = studies.map((study) => {
      if (study.document.id === targetStudy.document.id) {
        const updatedApplications = study.applications.map((app) => {
          if (app.id === targetApplication.id) {
            return { ...app, ...newData };
          }
          return app;
        });
        return { ...study, applications: updatedApplications };
      }
      return study;
    });

    setStudies(updatedStudies);
    StudyService.updateApplicationByDoc(targetApplication.doc.ref, newData);
  };

  return (
    <StudiesContext.Provider
      value={{
        updateStudyApplication,
        studies: studies,
        getStudies: getStudies,
        setStudies: setStudies,
        companyName: companyName,
        setCompanyName: setCompanyName,
        loading: loading,
      }}
    >
      <div>{props.children}</div>
    </StudiesContext.Provider>
  );
}

export default StudiesContextProvider;
