import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Sponsored.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Card from './Card';
import SkeletonCard from './SkeletonCard';
import StudyService from '../../../Services/StudyService/StudyService';
import AppointmentsData from './AppointmentsData';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(MuiStyleFunction);

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const Home = (props) => {
  const { id } = useParams();

  const classes = useStyles();

  const { userData } = useContext(AuthContext);
  const { studies, getStudies, companyName } = useContext(StudiesContext);

  useEffect(() => {}, []);

  const [study, setStudy] = useState(null);
  const [studyId, setStudyId] = useState('');

  useEffect(() => {
    let studyId = id;
    let study = null;
    if (studies.length > 0) {
      for (let index = 0; index < studies.length; index++) {
        const potentialStudy = studies[index];
        if (studyId === potentialStudy.document.id) {
          study = potentialStudy;
        }
      }
      setStudy({ ...study });
      setStudyId(studyId);
    }
  }, [studies]);

  const updateStudies = async (ref, data) => {
    await StudyService.updateStudyByRef(ref, data);
    getStudies();
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <div
        style={{
          paddingTop: mobile ? 0 : 30,
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 90,
        }}
      >
        <Grid
          container
          direction="row"
          spacing={8}
          style={{ paddingTop: mobile ? 0 : 0 }}
        >
          {!mobile ? (
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <AppointmentsData study={study} />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <AppointmentsData study={study} />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(Home);
