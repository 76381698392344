import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TeamItem from './TeamItem';
import StudyService from '../../../Services/StudyService/StudyService';
import AddNewTeamMember from './AddNewTeamMember';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(MuiStyleFunction);

const AccountDetails = ({ data }) => {
  const classes = useStyles();

  const { userData } = useContext(AuthContext);

  const [team, setTeam] = useState([]);
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [addNew, setAddNew] = React.useState(false);

  useEffect(() => {
    getTeam();
  }, []);

  const getTeam = async () => {
    let team = await StudyService.getTeamByStudyRef(data.document.ref);
    setTeam(team);
    setLoadingTeam(false);
    return;
  };

  const updateMember = async (ref, data) => {
    await StudyService.updateTeamMemberByRef(ref, data);
    getTeam();
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
            <Typography className={classes.settingTitle} variant="h2">
              Team
            </Typography>
            {!addNew && (
              <img
                alt=""
                onClick={() => setAddNew(true)}
                src={require('../../../Assets/Images/addTeamMember.png')}
                style={{
                  height: 'auto',
                  height: 38,
                  marginTop: 0,
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
          <Divider />
          {loadingTeam && (
            <Skeleton width="100%" height="100px" style={{ marginTop: 8 }} />
          )}
          {loadingTeam && (
            <Skeleton width="100%" height="100px" style={{ marginTop: 8 }} />
          )}
          {loadingTeam && (
            <Skeleton width="100%" height="100px" style={{ marginTop: 8 }} />
          )}
          {team.map((data, index) => (
            <TeamItem
              type={'client'}
              key={index}
              lock={true}
              data={data}
              updateMember={updateMember}
              getTeam={getTeam}
            />
          ))}
          <AddNewTeamMember
            studyData={data}
            getTeam={getTeam}
            addNew={addNew}
            setAddNew={setAddNew}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(AccountDetails);
