import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './SignIn.style';
import { AuthContext } from '../../../contexts/Auth';

import firebase from '../../../Firebase';
import UserService from '../../../Services/UserService/UserService';
import InvitationService from '../../../Services/InvitationService/InvitationService';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import moment from 'moment';
import axios from 'axios';
import BAADialog from './BAADialog';
import Availability from './Availability';
import EmailService from '../../../Services/EmailService/EmailService';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(MuiStyleFunction);

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const Signin = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const classes = useStyles();
  const [isWaiting, setIsWaiting] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { authUser, setIsAuthenticated, setUserData, setAuthUser } =
    useContext(AuthContext);

  const [loadingInvitationData, setLoadingInvitationData] = useState(true);
  const [invitationData, setInvitationData] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [user, setUser] = useState(null);
  const [termsAndPrivacy, setTermsAndPrivacy] = useState(false);
  const [hippa, setHippa] = useState(false);
  const [baa, setBaa] = useState(false);

  const [agreement, setAgreement] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const [showBAADialog, setShowBAADialog] = useState(false);

  const [firstMember, setFirstMember] = useState(false);

  const [studyName, setStudyName] = useState('');

  const [centers, setCenters] = useState([]);

  const [selectedCenter, setSelectedCenter] = useState(null);

  const [userType, setUserType] = useState(null);

  const [globalCenterLink, setGlobalCenterLink] = useState(false);

  const [studyDocument, setStudyDocument] = useState(null);

  const [from, setFrom] = React.useState('');
  const [to, setTo] = React.useState('');
  const [day1, set1] = React.useState(true);
  const [day2, set2] = React.useState(true);
  const [day3, set3] = React.useState(true);
  const [day4, set4] = React.useState(true);
  const [day5, set5] = React.useState(true);
  const [day6, set6] = React.useState(true);

  const [notSpecified, setNotSpecified] = useState(true);

  useEffect(() => {
    if (authUser) {
      setEmail(authUser.email);
      setUserExists(true);
    }
  }, [authUser]);

  useEffect(() => {
    if (notSpecified) {
      set1(true);
      set2(true);
      set3(true);
      set4(true);
      set5(true);
      set6(true);
      setFrom('08:00 AM');
      setTo('05:00 PM');
    }
  }, [notSpecified]);

  const handleChangeCheckbox = (event) => {
    if (event.target.name === 'termsAndPrivacy') {
      setTermsAndPrivacy(event.target.checked);
    } else if (event.target.name === 'baa') {
      setHippa(event.target.checked);
    } else if (event.target.name === 'privacyPolicy') {
      setPrivacyPolicy(event.target.checked);
    } else if (event.target.name === 'agreement') {
      setAgreement(event.target.checked);
    }
  };

  const getAllCentersOfStudy = async (id) => {
    setGlobalCenterLink(true);
    setCenters(await InvitationService.getAllCentersOfStudy(id));
    setLoadingInvitationData(false);
  };

  const getStudyName = async (ref) => {
    let studyData = await InvitationService.getStudyDataByCenterRef(ref);
    setStudyDocument(studyData);
    setStudyName(studyData.data().customTitle);
  };

  useEffect(() => {
    if (centers.length > 0) {
      getStudyName(centers[0].ref);
      let params = new URLSearchParams(window.location.search);
      if (params.get('center') !== null) {
        centers.forEach((center) => {
          if (center.data().LocationFacility === params.get('center')) {
            setSelectedCenter(center);
          }
        });
      }
    }
  }, [centers]);

  const checkIfFirstInSelectedCenter = async () => {
    let firstInStudy = true;
    let teamMembers = await InvitationService.getTeamMemberByCenterDocument(
      selectedCenter,
    );
    if (teamMembers && teamMembers.length > 0) {
      for (let index = 0; index < teamMembers.length; index++) {
        const teamMember = teamMembers[index];
        if (teamMember.data().status === 'accepted') {
          firstInStudy = false;
        }
      }
    }
    if (firstInStudy) {
      setFirstMember(true);
    }
  };

  useEffect(() => {
    if (selectedCenter) {
      checkIfFirstInSelectedCenter();
    }
  }, [selectedCenter]);

  useEffect(() => {
    if (id.includes('NCT')) {
      getAllCentersOfStudy(id);
      setUserType('center');
    } else {
      getInvitationById(id);
    }
  }, []);

  const getInvitationById = async (id) => {
    let invitation = await InvitationService.getInvitationById(id);
    let user = await UserService.getUserByEmail(invitation.data().email);
    let firstInStudy = true;
    if (invitation.data().type === 'center') {
      setUserType('center');
      let teamMembers = await InvitationService.getTeamMemberByInvitationId(
        invitation.id,
      );
      if (teamMembers && teamMembers.length > 0) {
        for (let index = 0; index < teamMembers.length; index++) {
          const teamMember = teamMembers[index];
          if (teamMember.Id !== invitation.data().teamMemberId) {
            if (teamMember.data().status === 'accepted') {
              firstInStudy = false;
            }
          }
        }
      } else {
      }
    } else {
      setUserType('study');
    }
    if (firstInStudy) {
      setFirstMember(true);
    }
    setUser(user);
    if (user) setUserExists(true);
    setInvitationData(invitation);
    setLoadingInvitationData(false);
  };

  useEffect(() => {}, [isWaiting]);
  useEffect(() => {
    console.log('user', user);
  }, [user]);

  const handleChangeText = async (e, type) => {
    if (type === 'email') {
      setEmail(e.target.value.toLowerCase());
      let user = await UserService.getUserByEmail(e.target.value.toLowerCase());
      if (user) {
        setUserExists(true);
        setUser({ user: { ...user, uid: user.id, email: user.data().email } });
      } else {
        setUser(false);
      }
    } else if (type === 'password') {
      setPassword(e.target.value);
    } else if (type === 'confirmPassword') {
      setConfirmPassword(e.target.value);
    } else if (type === 'firstName') {
      setFirstName(e.target.value);
    } else if (type === 'lastName') {
      setLastName(e.target.value);
    }
  };

  const checkIfCanPresentBAA = () => {
    setErrorMessage('');
    if (password !== confirmPassword) {
      setErrorMessage('passowrds are not matched');
      return;
    }
    if (password.length < 8) {
      setErrorMessage(
        'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
      );
      alert(
        'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
      );
      return;
    } else {
      let strength = 0;
      if (password.match(/[a-z]+/)) {
        strength += 1;
      }
      if (password.match(/[A-Z]+/)) {
        strength += 1;
      }
      if (password.match(/[0-9]+/)) {
        strength += 1;
      }
      if (strength < 3) {
        setErrorMessage(
          'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
        );
        alert(
          'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
        );
        return;
      }
    }
    if (firstName === '') {
      alert('Error first name');
      return;
    }
    if (lastName === '') {
      alert('Error last name');
      return;
    }
    if (invitationData.data().type === 'center' && agreement === false) {
      alert('Please approve center aggrement');
      return;
    }
    if (invitationData.data().type === 'center' && privacyPolicy === false) {
      alert('Please approve Terms and Privacy Policy');
      return;
    }
    if (invitationData.data().type === 'study' && privacyPolicy === false) {
      alert('Please approve Terms and Privacy Policy');
      return;
    }
    if (invitationData.data().type === 'client' && agreement === false) {
      alert('Please approve client aggrement');
      return;
    }

    if (firstMember) {
      setShowBAADialog(true);
    } else {
      signupUser();
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const globalLinkcheckIfCanPresentBAA = () => {
    setErrorMessage('');
    if (!userExists) {
      if (password !== confirmPassword) {
        setErrorMessage('passowrds are not matched');
        return;
      }
      if (password.length < 8) {
        setErrorMessage(
          'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
        );
        alert(
          'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
        );
        return;
      } else {
        let strength = 0;
        if (password.match(/[a-z]+/)) {
          strength += 1;
        }
        if (password.match(/[A-Z]+/)) {
          strength += 1;
        }
        if (password.match(/[0-9]+/)) {
          strength += 1;
        }
        if (strength < 3) {
          setErrorMessage(
            'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
          );
          alert(
            'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
          );
          return;
        }
      }
      if (firstName === '') {
        alert('Error first name');
        return;
      }
      if (userType !== 'study' && globalCenterLink) {
        if (phone === '') {
          alert('Phone is required');
          return;
        }
      }
      if (userType !== 'study' && globalCenterLink) {
        if (role === '') {
          alert('Role is required');
          return;
        }
      }
      if (lastName === '') {
        alert('Error last name');
        return;
      }
      if (!validateEmail(email)) {
        alert('The Email is badly formatted');
        return;
      }
      if (
        !(studyDocument && studyDocument.data().availability) &&
        !isAvailabilityExists()
      ) {
        alert('The availability data is Empty');
        return;
      }
    }
    if (agreement === false) {
      alert('Please approve center aggrement');
      return;
    }
    if (privacyPolicy === false) {
      alert('Please approve Terms and Privacy Policy');
      return;
    }
    if (firstMember) {
      setShowBAADialog(true);
    } else {
      signupUserWithGlobalLink();
    }
  };

  const isAvailabilityExists = () => {
    if (day1 || day2 || day3 || day4 || day5 || day6) {
      if (from !== '' && to !== '') {
        return true;
      }
    }
    return false;
  };

  const addUserToCenter = async (user) => {
    let memberDoc = await InvitationService.createNewTeamMemberInCenter(
      selectedCenter.ref,
      email,
    );
    if (firstMember) {
      try {
        InvitationService.createBAAContractFile(
          user.user.uid,
          selectedCenter.data().LocationFacility,
          `${firstName} ${lastName}`,
          moment().format('LL'),
        );
        await InvitationService.updatedSignedBAA(memberDoc.id, email, 'center');
        EmailService.baaContract(
          user.user.email,
          selectedCenter.data().LocationFacility,
          firstName,
          moment().format('LL'),
        );
      } catch (error) {
        console.error('BAA failed', error);
      }
      // send email to center team
      let emails =
        await InvitationService.getAllEmailOfCompanyTeamFromCenterRef(
          selectedCenter.ref,
        );
      for (let index = 0; index < emails.length; index++) {
        const memberEmail = emails[index];
        EmailService.notifyClientOnNewCenterSignup(
          memberEmail,
          studyName,
          selectedCenter.data().LocationFacility,
        );
      }
    }
    return;
  };

  const signupUserWithGlobalLink = async () => {
    setErrorMessage('');
    setIsWaiting(true);
    if (userExists) {
      if (firebase.auth().currentUser) {
        // add to center
        await addUserToCenter(firebase.auth().currentUser);
        window.location.assign(
          window.location.origin + '/?invitation=accepted',
        );
      } else {
        // add and send to login
        await addUserToCenter(user);
        window.location.assign(
          window.location.origin + '/?invitation=accepted',
        );
      }
    } else {
      // signup
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (user) => {
          let userData = {
            email: user.user.email,
            firstName,
            lastName,
            phone,
            role,
            type: 'center',
          };
          await addUserToCenter(user);
          await UserService.createUser(user.user.uid, userData);
          window.location.assign(
            window.location.origin + '/?invitation=accepted',
          );
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          setErrorMessage(error.message);
          setIsWaiting(false);
        });
    }
  };

  const signupUser = async () => {
    setErrorMessage('');
    if (password !== confirmPassword) {
      setErrorMessage('passowrds are not matched');
      return;
    }
    if (password.length < 8) {
      setErrorMessage(
        'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
      );
      alert(
        'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
      );
      return;
    } else {
      let strength = 0;
      if (password.match(/[a-z]+/)) {
        strength += 1;
      }
      if (password.match(/[A-Z]+/)) {
        strength += 1;
      }
      if (password.match(/[0-9]+/)) {
        strength += 1;
      }
      if (strength < 3) {
        setErrorMessage(
          'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
        );
        alert(
          'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
        );
        return;
      }
    }
    if (firstName === '') {
      alert('Error first name');
      return;
    }
    if (userType !== 'study' && globalCenterLink) {
      if (phone === '') {
        alert('Phone is required');
        return;
      }
    }
    if (userType !== 'study' && globalCenterLink) {
      if (role === '') {
        alert('Role is required');
        return;
      }
    }
    if (lastName === '') {
      alert('Error last name');
      return;
    }
    if (invitationData.data().type === 'center' && agreement === false) {
      alert('Please approve center aggrement');
      return;
    }
    if (invitationData.data().type === 'center' && privacyPolicy === false) {
      alert('Please approve Terms and Privacy Policy');
      return;
    }
    if (invitationData.data().type === 'study' && privacyPolicy === false) {
      alert('Please approve Terms and Privacy Policy');
      return;
    }
    if (invitationData.data().type === 'client' && agreement === false) {
      alert('Please approve client aggrement');
      return;
    }
    setIsWaiting(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(invitationData.data().email, password)
      .then(async (user) => {
        let userData = {
          email: user.user.email,
          firstName,
          lastName,
          phone,
          role,
          type: invitationData.data().type,
        };
        await UserService.createUser(user.user.uid, userData);
        await InvitationService.acceptInvitationStatusTeam(
          invitationData.data().teamMemberId,
          invitationData.data().email,
          invitationData.data().type,
        );
        await InvitationService.acceptInvitationStatus(invitationData.id);
        if (invitationData.data().type === 'center' && firstMember) {
          await InvitationService.updatedSignedBAA(
            invitationData.data().teamMemberId,
            invitationData.data().email,
            invitationData.data().type,
          );
          await EmailService.baaContract(
            user.user.email,
            invitationData.data().centerName,
            firstName,
            moment().format('LL'),
          );
          // send email to center team
          let emails = await InvitationService.getAllEmailOfCompanyTeam(
            invitationData.id,
          );
          for (let index = 0; index < emails.length; index++) {
            const email = emails[index];
            await EmailService.notifyClientOnNewCenterSignup(
              email,
              invitationData.data().studyName,
              invitationData.data().centerName,
            );
          }
        }
        // navigate('/')
        window.location.assign(
          window.location.origin + '/?invitation=accepted',
        );
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setErrorMessage(error.message);
        setIsWaiting(false);
      });
  };

  const acceptInvitation = async () => {
    setIsWaiting(true);
    await InvitationService.acceptInvitationStatusTeam(
      invitationData.data().teamMemberId,
      invitationData.data().email,
      invitationData.data().type,
    );
    await InvitationService.acceptInvitationStatus(invitationData.id);
    setIsWaiting(false);
    navigate('/');
  };

  const open1 = () => {
    var win = window.open('https://knowrare.com/study-agreement', '_blank');
    win.focus();
  };

  const open2 = () => {
    var win = window.open('https://knowrare.com/study-terms', '_blank');
    win.focus();
  };

  const open4 = () => {
    var win = window.open('https://knowrare.com/terms', '_blank');
    win.focus();
  };

  const open3 = () => {
    var win = window.open('https://knowrare.com/privacy', '_blank');
    win.focus();
  };

  const handleChangePhone = (e) => {
    setPhone(
      e.replace(/-/g, '').replace('(', '').replace(')', '').replace(/ /g, ''),
    );
  };

  return (
    <div className={classes.root}>
      <BAADialog
        globalCenterLink={globalCenterLink}
        isWaiting={isWaiting}
        errorMessage={errorMessage}
        showBAADialog={showBAADialog}
        setShowBAADialog={setShowBAADialog}
        setBaa={setBaa}
        signupUser={signupUser}
        signupUserWithGlobalLink={signupUserWithGlobalLink}
      />
      {mobile ? (
        <div>
          {/* <img alt='' src={require('../../../Assets/Images/mobileTopLeft.png')} style={{width: 'auto', height: '25vh', position: 'fixed', top: 0, left: 0}}/>
                <img alt='' src={require('../../../Assets/Images/mobileTopRight.png')} style={{width: 'auto', height: '25vh', position: 'fixed', top: 0, right: 0}}/>
                <img alt='' src={require('../../../Assets/Images/mobileBottomRight.png')} style={{width: 'auto', height: '25vh', position: 'fixed', bottom: 0, right: 0}}/> */}
        </div>
      ) : (
        <div>
          <img
            alt=""
            src={require('../../../Assets/Images/desktopTopLeft.png')}
            style={{
              width: 'auto',
              height: '35vh',
              position: 'fixed',
              top: 0,
              left: 0,
            }}
          />
          <img
            alt=""
            src={require('../../../Assets/Images/desktopTopRight.png')}
            style={{
              width: 'auto',
              height: '35vh',
              position: 'fixed',
              top: 0,
              right: 0,
            }}
          />
          <img
            alt=""
            src={require('../../../Assets/Images/desktopBottomRight.png')}
            style={{
              width: 'auto',
              height: '35vh',
              position: 'fixed',
              bottom: 0,
              right: 0,
            }}
          />
        </div>
      )}
      {/* {isWaiting && (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" className={classes.backdropCircular} />
            </Backdrop>
            )} */}
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          xl={7}
          style={{ alignItems: 'center' }}
        >
          <center>
            <div className={classes.formContainer}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <img
                    alt=""
                    src={require('../../../Assets/Images/logo.png')}
                    style={{
                      height: 'auto',
                      width: mobile ? '85%' : '40%',
                      marginLeft: mobile ? -24 : -70,
                      marginBottom: mobile ? 24 : 50,
                    }}
                  />
                </Grid>
                {loadingInvitationData ? (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div
                      style={{
                        padding: 24,
                        backgroundColor: 'white',
                        borderRadius: 8,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        width: '100%',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        style={{ textAlign: 'left' }}
                      >
                        <Skeleton width={'15%'} height={20} />
                        <Skeleton
                          width={'70%'}
                          height={42}
                          style={{ marginTop: 8 }}
                        />
                        <Skeleton
                          width={'30%'}
                          height={32}
                          style={{ marginTop: 8 }}
                        />
                        <Skeleton
                          width={'100%'}
                          height={55}
                          style={{ marginTop: 24 }}
                        />
                        <Skeleton
                          width={'100%'}
                          height={55}
                          style={{ marginTop: 0 }}
                        />
                        <Skeleton
                          width={'100%'}
                          height={55}
                          style={{ marginTop: 0 }}
                        />
                        <Skeleton
                          width={'100%'}
                          height={55}
                          style={{ marginTop: 0 }}
                        />
                        <Skeleton
                          width={'100%'}
                          height={60}
                          style={{ marginTop: 0 }}
                        />
                      </Grid>
                    </div>
                  </Grid>
                ) : !globalCenterLink ? (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div
                      style={{
                        padding: 24,
                        backgroundColor: 'white',
                        borderRadius: 8,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        width: '100%',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        style={{ textAlign: 'left' }}
                      >
                        <Typography className={classes.nctTitle}>
                          {invitationData.data().NCTID}
                        </Typography>
                        {!userExists ? (
                          <Typography className={classes.studyTitle}>
                            Become an Admin for{' '}
                            {invitationData.data().studyName}
                          </Typography>
                        ) : (
                          !isWaiting && (
                            <Typography className={classes.studyTitle}>
                              {user.data()?.firstName}, Become an Admin for{' '}
                              {invitationData.data().studyName}
                            </Typography>
                          )
                        )}
                        <Typography className={classes.companyTitle}>
                          {invitationData.data().companyName}
                        </Typography>
                        {invitationData.data().type === 'center' && (
                          <Typography className={classes.centerTitle}>
                            {invitationData.data().centerName}
                          </Typography>
                        )}
                      </Grid>
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            label="First Name"
                            id="filled-start-adornment"
                            className={clsx(
                              classes.formControl,
                              classes.textField,
                            )}
                            // variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e) => handleChangeText(e, 'firstName')}
                            type={'text'}
                            error={errorEmail !== ''}
                            helperText={errorEmail}
                          />
                        </Grid>
                      )}
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            label="Last Name"
                            id="filled-start-adornment"
                            className={clsx(
                              classes.formControl,
                              classes.textField,
                            )}
                            // variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e) => handleChangeText(e, 'lastName')}
                            type={'text'}
                            error={errorEmail !== ''}
                            helperText={errorEmail}
                          />
                        </Grid>
                      )}
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            label="Email"
                            id="filled-start-adornment"
                            className={clsx(
                              classes.formControl,
                              classes.textField,
                            )}
                            // variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e) => handleChangeText(e, 'email')}
                            type={'email'}
                            value={invitationData.data().email}
                            disabled={!globalCenterLink}
                            error={errorEmail !== ''}
                            helperText={errorEmail}
                          />
                        </Grid>
                      )}
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            id="outlined-password-input"
                            label="Password"
                            fullWidth
                            type="password"
                            // variant="outlined"
                            onChange={(e) => handleChangeText(e, 'password')}
                            error={errorPassword !== ''}
                            helperText={errorPassword}
                          />
                        </Grid>
                      )}
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            id="outlined-password-input"
                            label="Confirm Password"
                            fullWidth
                            type="password"
                            // variant="outlined"
                            onChange={(e) =>
                              handleChangeText(e, 'confirmPassword')
                            }
                            error={errorPassword !== ''}
                            helperText={errorPassword}
                          />
                        </Grid>
                      )}
                      {invitationData.data().type === 'center' && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                          style={{
                            textAlign: 'left',
                            marginBottom: 12,
                            marginTop: 24,
                          }}
                        >
                          <FormControlLabel
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                            }}
                            control={
                              <Checkbox
                                checked={agreement}
                                onChange={handleChangeCheckbox}
                                name="agreement"
                              />
                            }
                          />
                          <div
                            style={{
                              width: 'calc(100% - 54px)',
                              cursor: 'pointer',
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: -12,
                              fontSize: 13,
                              fontFamily: "'sofia-pro', sans-serif",
                              color: 'rgba(0, 0, 0, 0.5)',
                            }}
                          >
                            I agree I have read and accepted Know Rare’s{' '}
                            <span style={{ color: '#4fb4e8' }} onClick={open1}>
                              <u>Study Center Agreement</u>
                            </span>{' '}
                            and{' '}
                            <span style={{ color: '#4fb4e8' }} onClick={open2}>
                              <u>Study Center Terms of Use</u>
                            </span>{' '}
                            granting permission to refer patients to your study
                            center for studies.
                          </div>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        style={{
                          textAlign: 'left',
                          marginBottom: 12,
                          marginTop: 24,
                        }}
                      >
                        <FormControlLabel
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                          control={
                            <Checkbox
                              checked={privacyPolicy}
                              onChange={handleChangeCheckbox}
                              name="privacyPolicy"
                            />
                          }
                        />
                        <div
                          style={{
                            width: 'calc(100% - 54px)',
                            cursor: 'pointer',
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: -12,
                            fontSize: 13,
                            fontFamily: "'sofia-pro', sans-serif",
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          I agree I have read and accepted Know Rare’s{' '}
                          <span style={{ color: '#4fb4e8' }} onClick={open3}>
                            <u>Privacy Policy and</u>
                          </span>{' '}
                          <span style={{ color: '#4fb4e8' }} onClick={open4}>
                            <u>Terms of Use</u>
                          </span>
                        </div>
                      </Grid>
                      {!userExists &&
                        invitationData.data().type === 'center' && (
                          <Grid item xs={12} sm={12} md={12} xl={12}>
                            {isWaiting ? (
                              <CircularProgress
                                size={30}
                                className={classes.loading}
                              />
                            ) : (
                              <Button
                                onClick={() => checkIfCanPresentBAA()}
                                className={classes.submit}
                                fullWidth
                              >
                                Continue
                              </Button>
                            )}
                          </Grid>
                        )}
                      {!userExists &&
                        invitationData.data().type === 'study' && (
                          <Grid item xs={12} sm={12} md={12} xl={12}>
                            {isWaiting ? (
                              <CircularProgress
                                size={30}
                                className={classes.loading}
                              />
                            ) : (
                              <Button
                                onClick={() => signupUser()}
                                className={classes.submit}
                                fullWidth
                              >
                                Continue
                              </Button>
                            )}
                          </Grid>
                        )}
                      {userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          {isWaiting ? (
                            <CircularProgress
                              size={30}
                              className={classes.loading}
                            />
                          ) : (
                            <Button
                              onClick={() => acceptInvitation()}
                              className={classes.submit}
                              fullWidth
                            >
                              Accept Invitation
                            </Button>
                          )}
                        </Grid>
                      )}
                      {errorMessage !== '' && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <div className={classes.errorMessage}>
                            {errorMessage}
                          </div>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div
                      style={{
                        padding: 24,
                        backgroundColor: 'white',
                        borderRadius: 8,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        width: '100%',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        style={{ textAlign: 'left' }}
                      >
                        <Typography className={classes.nctTitle}>
                          {id}
                        </Typography>
                        <Typography className={classes.studyTitle}>
                          Receive Referrals for {studyName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: '100%', textAlign: 'left' }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Study Center
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            style={{ width: '100%', textAlign: 'left' }}
                            value={
                              selectedCenter
                                ? selectedCenter.data().LocationFacility
                                : null
                            }
                            onChange={(e) => {
                              centers.forEach((element) => {
                                if (
                                  e.target.value ===
                                  element.data().LocationFacility
                                ) {
                                  setSelectedCenter(element);
                                }
                              });
                            }}
                          >
                            {centers.map((center, index) => (
                              <MenuItem
                                key={index}
                                value={center.data().LocationFacility}
                              >
                                {center.data().LocationFacility}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: '100%', textAlign: 'left' }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Role
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              fullWidth
                              style={{ width: '100%', textAlign: 'left' }}
                              value={role}
                              onChange={(e) => {
                                setRole(e.target.value);
                              }}
                            >
                              {['Coordinator/Team', 'Primary Investigator'].map(
                                (role, index) => (
                                  <MenuItem key={index} value={role}>
                                    {role}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            label="First Name"
                            id="filled-start-adornment"
                            className={clsx(
                              classes.formControl,
                              classes.textField,
                            )}
                            // variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e) => handleChangeText(e, 'firstName')}
                            type={'text'}
                            error={errorEmail !== ''}
                            helperText={errorEmail}
                          />
                        </Grid>
                      )}
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            label="Last Name"
                            id="filled-start-adornment"
                            className={clsx(
                              classes.formControl,
                              classes.textField,
                            )}
                            // variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(e) => handleChangeText(e, 'lastName')}
                            type={'text'}
                            error={errorEmail !== ''}
                            helperText={errorEmail}
                          />
                        </Grid>
                      )}
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <MuiPhoneNumber
                            style={{ width: '100%' }}
                            name="phone"
                            label="Phone Number"
                            data-cy="user-phone"
                            defaultCountry={'us'}
                            value={phone}
                            onChange={handleChangePhone}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <TextField
                          label="Email"
                          id="filled-start-adornment"
                          className={clsx(
                            classes.formControl,
                            classes.textField,
                          )}
                          disabled={authUser}
                          style={{ width: '100%' }}
                          onChange={(e) => handleChangeText(e, 'email')}
                          type={'email'}
                          value={email}
                          error={errorEmail !== ''}
                          helperText={errorEmail}
                        />
                      </Grid>
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            id="outlined-password-input"
                            label="Password"
                            fullWidth
                            type="password"
                            onChange={(e) => handleChangeText(e, 'password')}
                            error={errorPassword !== ''}
                            helperText={errorPassword}
                          />
                        </Grid>
                      )}
                      {!userExists && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            id="outlined-password-input"
                            label="Confirm Password"
                            fullWidth
                            type="password"
                            onChange={(e) =>
                              handleChangeText(e, 'confirmPassword')
                            }
                            error={errorPassword !== ''}
                            helperText={errorPassword}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        style={{
                          textAlign: 'left',
                          marginBottom: 12,
                          marginTop: 24,
                        }}
                      >
                        <FormControlLabel
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                          control={
                            <Checkbox
                              checked={agreement}
                              onChange={handleChangeCheckbox}
                              name="agreement"
                            />
                          }
                        />
                        <div
                          style={{
                            width: 'calc(100% - 54px)',
                            cursor: 'pointer',
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: -12,
                            fontSize: 13,
                            fontFamily: "'sofia-pro', sans-serif",
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          I agree I have read and accepted Know Rare’s{' '}
                          <span style={{ color: '#4fb4e8' }} onClick={open1}>
                            <u>Study Center Agreement</u>
                          </span>{' '}
                          and{' '}
                          <span style={{ color: '#4fb4e8' }} onClick={open2}>
                            <u>Study Center Terms of Use</u>
                          </span>{' '}
                          granting permission to refer patients to your study
                          center for studies.
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        style={{
                          textAlign: 'left',
                          marginBottom: 12,
                          marginTop: 24,
                        }}
                      >
                        <FormControlLabel
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                          control={
                            <Checkbox
                              checked={privacyPolicy}
                              onChange={handleChangeCheckbox}
                              name="privacyPolicy"
                            />
                          }
                        />
                        <div
                          style={{
                            width: 'calc(100% - 54px)',
                            cursor: 'pointer',
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: -12,
                            fontSize: 13,
                            fontFamily: "'sofia-pro', sans-serif",
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          I agree I have read and accepted Know Rare’s{' '}
                          <span style={{ color: '#4fb4e8' }} onClick={open3}>
                            <u>Privacy Policy and</u>
                          </span>{' '}
                          <span style={{ color: '#4fb4e8' }} onClick={open4}>
                            <u>Terms of Use</u>
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        {isWaiting ? (
                          <CircularProgress
                            size={30}
                            className={classes.loading}
                          />
                        ) : (
                          <Button
                            onClick={() => globalLinkcheckIfCanPresentBAA()}
                            className={classes.submit}
                            fullWidth
                          >
                            Continue
                          </Button>
                        )}
                      </Grid>
                      {errorMessage !== '' && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <div className={classes.errorMessage}>
                            {errorMessage}
                          </div>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          </center>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(Signin);
