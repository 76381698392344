import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './SignIn.style';
import { AuthContext } from '../../../contexts/Auth';
import firebase from '../../../Firebase';
import UserService from '../../../Services/UserService/UserService';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ResetPassword from './ResetPassword';
import clsx from 'clsx';
import AcceptedInvitationDialog from '../../../Components/Dialogs/AcceptedInvitationDialog';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(MuiStyleFunction);

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const Signin = (props) => {
  const classes = useStyles();
  const [isWaiting, setIsWaiting] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setIsAuthenticated, setUserData, setAuthUser } =
    useContext(AuthContext);
  const [openAcceptedInvitationDialog, setOpenAcceptedInvitationDialog] =
    useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    console.log('params', params.get('invitation'));
    if (params.get('invitation') === 'accepted') {
      setOpenAcceptedInvitationDialog(true);
    }
  }, []);

  const handleChangeText = (e, type) => {
    if (type === 'email') {
      setEmail(e.target.value.toLowerCase());
    } else if (type === 'password') {
      setPassword(e.target.value);
    }
  };

  const signinUser = () => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        setIsWaiting(true);
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(async (result) => {
            var user = result.user;
            console.log('user', user);
            const userData = await UserService.getUser();
            console.log('userData', userData);
            if (userData === null) {
              setIsWaiting(false);
              setErrorMessage('Not a user, sign up.');
            } else {
              // setIsWaiting(false)
              // setAuthUser(user)
              // setIsAuthenticated(true)
              // setUserData(userData)
            }
          })
          .catch(function (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log('error', error);
            setErrorMessage(error.message);
            setIsWaiting(false);
          });
        // return firebase.auth().signInWithEmailAndPassword(email, password);
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  };

  return (
    <div className={classes.root}>
      <AcceptedInvitationDialog
        open={openAcceptedInvitationDialog}
        setOpen={setOpenAcceptedInvitationDialog}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          xl={6}
          style={{ alignItems: 'center' }}
        >
          <center>
            <div className={classes.formContainerLogin}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <img
                    alt=""
                    src={require('../../../Assets/Images/logo.png')}
                    style={{
                      height: 'auto',
                      width: mobile ? '85%' : '90%',
                      marginLeft: 0,
                      marginBottom: 30,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <TextField
                    label="Email"
                    id="filled-start-adornment"
                    className={clsx(classes.formControl, classes.textField)}
                    variant="outlined"
                    style={{ width: '100%' }}
                    onChange={(e) => handleChangeText(e, 'email')}
                    type={'email'}
                    error={errorEmail !== ''}
                    helperText={errorEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <TextField
                    id="outlined-password-input"
                    label="Password"
                    fullWidth
                    type="password"
                    variant="outlined"
                    onChange={(e) => handleChangeText(e, 'password')}
                    error={errorPassword !== ''}
                    helperText={errorPassword}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  {isWaiting ? (
                    <CircularProgress size={30} className={classes.loading} />
                  ) : (
                    <Button
                      onClick={() => signinUser()}
                      className={classes.submit}
                      fullWidth
                    >
                      Login
                    </Button>
                  )}
                </Grid>
                {errorMessage !== '' && (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div className={classes.errorMessage}>{errorMessage}</div>
                  </Grid>
                )}
                <ResetPassword />
              </Grid>
            </div>
          </center>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(Signin);
