import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import StudyService from '../../../Services/StudyService/StudyService';
import firebase from '../../../Firebase.js';
import { StudiesContext } from '../../../contexts/Studies';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const options = ['Applied', 'Pending', 'Scheduled', 'Failed', 'Completed'];

export default function Status({
  status,
  getStyleForStatus,
  renameStatus,
  application,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const { getStudies } = React.useContext(StudiesContext);

  useEffect(() => {
    for (let index = 0; index < options.length; index++) {
      const statusItem = options[index];
      // console.log('status', status)
      if (renameStatus(status) === statusItem) {
        setSelectedIndex(index);
      }
    }
  }, []);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getFormattedStatus = (status) => {
    if (status === 'Failed') {
      return 'Not Eligible';
    } else if (status === 'Applied') {
      return 'Applied';
    } else if (status === 'Pending') {
      return 'Schedule Appointment';
    } else if (status === 'Scheduled') {
      return 'Confirmed Appointment';
    } else if (status === 'Completed') {
      return 'Completed';
    }
    return 'Applied';
  };

  const updateApplicationWithNewStatus = async (status) => {
    let statusArray = application.status;
    statusArray.push({
      status: getFormattedStatus(status),
      datetime: firebase.firestore.Timestamp.now(),
    });
    let data = { status: statusArray };
    await StudyService.updateApplicationByDoc(application.doc.ref, data);
    getStudies();
  };

  const handleMenuItemClick = async (event, index) => {
    if (index === 2 || index === 1) {
      if (!application.selectedDate || !application.selectedTime) {
        alert(
          "Can't be changed to Scheduled or Pending if the doctor didn't selected date and time",
        );
        return;
      }
    }
    setSelectedIndex(index);
    updateApplicationWithNewStatus(options[index]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div
        style={getStyleForStatus(options[selectedIndex])}
        // onClick={handleClickListItem}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {options[selectedIndex]}
          {/* <ExpandMoreRoundedIcon style={{marginLeft: 4}} /> */}
        </div>
      </div>
      {/* <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {options.map((option, index) => (
            <MenuItem
                key={index}
                disabled={index === selectedIndex}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
            >
                <div style={getStyleForStatus(option)} onClick={handleClickListItem}>
                    {option}
                </div>
            </MenuItem>
            ))}
        </Menu> */}
    </div>
  );
}
