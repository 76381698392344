import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Switch, FormControlLabel } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import StudyService from '../../../Services/StudyService/StudyService';
import MedicationsAutocomplete from './MedicationsAutocomplete';
import ConditionsAutocomplete from './ConditionsAutocomplete';
import ProceduresAutocomplete from './ProceduresAutocomplete';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import DescriptionPopup from './DescriptionPopup';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import EditAdditionalCriteria from './EditAdditionalCriteria';
import AddNewAdditionalCriteria from './AddNewAdditionalCriteria';

const useStyles = makeStyles(MuiStyleFunction);

const AccountDetails = ({ data }) => {
  const classes = useStyles();

  const { id } = useParams();

  const { userData } = useContext(AuthContext);

  const { studies, getStudies } = useContext(StudiesContext);

  const [openDescriptionPopup, setOpenDescriptionPopup] = useState(false);
  const [description, setDescription] = React.useState(
    data.studyData.description ? data.studyData.description : '',
  );
  const [additionalCriteria, setAdditionalCriteria] = React.useState(
    data.studyData.additionalCriteria ? data.studyData.additionalCriteria : [],
  );

  const [addNewAdditionalCriteria, setAddNewAdditionalCriteria] =
    React.useState(false);

  const [editAdditionalCriteria, setEditAdditionalCriteria] =
    React.useState(false);
  const [indexInEdit, setIndexInEdit] = React.useState(null);

  useEffect(() => {
    // if (!data.studyData.additionalCriteria || !data.studyData.description) {
    getStudyData();
    // }
  }, []);

  const getStudyData = async () => {
    var url =
      'https://clinicaltrials.gov/api/query/full_studies?fmt=JSON&expr=' +
      String(id);
    const res = await axios.get(url);
    var study = res.data.FullStudiesResponse.FullStudies[0].Study;
    console.log('study', study);
    if (!data.studyData.additionalCriteria) {
      let defaultAdditionalCriteria = [
        {
          question: 'What phase is the study currently in?',
          answer: `This study is currently a ${study.ProtocolSection.DesignModule.PhaseList.Phase[0]} study`,
          canBeDeleted: false,
        },
        {
          question: 'What type of study is this?',
          answer: `This study is a ${study.ProtocolSection.DesignModule.StudyType} study`,
          canBeDeleted: false,
        },
        {
          question: 'How do I know if I am eligible for this study?',
          answer: study.ProtocolSection.EligibilityModule.EligibilityCriteria,
          canBeDeleted: false,
        },
      ];
      updateStudy(data.document.ref, {
        additionalCriteria: defaultAdditionalCriteria,
      });
      setAdditionalCriteria(defaultAdditionalCriteria);
    }
    if (!data.studyData.description) {
      let defaultDescription =
        study.ProtocolSection.DescriptionModule.BriefSummary;
      updateStudy(data.document.ref, { description: defaultDescription });
      setDescription(defaultDescription);
    }
  };

  const updateStudy = async (ref, data) => {
    await StudyService.updateStudyByRef(ref, data);
    getStudies();
  };

  const saveDescription = (newValue) => {
    setDescription(newValue);
    setOpenDescriptionPopup(false);
    updateStudy(data.document.ref, { description: newValue });
  };

  const updateAdditionalCriteria = (newQuestion, newAnswer) => {
    let newAdditionalCriteria = additionalCriteria;
    newAdditionalCriteria[indexInEdit].answer = newAnswer;
    newAdditionalCriteria[indexInEdit].question = newQuestion;
    setAdditionalCriteria(newAdditionalCriteria);
    setEditAdditionalCriteria(false);
    updateStudy(data.document.ref, {
      additionalCriteria: newAdditionalCriteria,
    });
  };

  const addNewAdditionalCriteriaFunc = (newQuestion, newAnswer) => {
    let newAdditionalCriteria = additionalCriteria;
    newAdditionalCriteria.push({
      question: newQuestion,
      answer: newAnswer,
    });
    setAdditionalCriteria(newAdditionalCriteria);
    setAddNewAdditionalCriteria(false);
    updateStudy(data.document.ref, {
      additionalCriteria: newAdditionalCriteria,
    });
  };

  const deleteAdditionalCriteria = () => {
    let newAdditionalCriteria = additionalCriteria;
    newAdditionalCriteria.splice(indexInEdit, 1);
    setAdditionalCriteria(newAdditionalCriteria);
    setEditAdditionalCriteria(false);
    updateStudy(data.document.ref, {
      additionalCriteria: newAdditionalCriteria,
    });
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      {editAdditionalCriteria && (
        <EditAdditionalCriteria
          open={editAdditionalCriteria}
          setOpen={setEditAdditionalCriteria}
          deleteAdditionalCriteria={deleteAdditionalCriteria}
          additionalCriteria={additionalCriteria}
          updateAdditionalCriteria={updateAdditionalCriteria}
          indexInEdit={indexInEdit}
        />
      )}
      {addNewAdditionalCriteria && (
        <AddNewAdditionalCriteria
          open={addNewAdditionalCriteria}
          setOpen={setAddNewAdditionalCriteria}
          addNewAdditionalCriteria={addNewAdditionalCriteriaFunc}
        />
      )}
      <DescriptionPopup
        open={openDescriptionPopup}
        setOpen={setOpenDescriptionPopup}
        description={description}
        saveDescription={saveDescription}
      />
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
            <Typography className={classes.settingTitle} variant="h2">
              Study Listing
            </Typography>
          </div>
          <Divider />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignItems: 'center',
              marginTop: 32,
            }}
          >
            <TextField
              disabled
              id="standard-basic"
              label="Description"
              style={{ flexGrow: 1, marginTop: 0 }}
              fullWidth
              className={classes.textField}
              value={description}
            />
            <img
              onClick={() => setOpenDescriptionPopup(true)}
              src={require('../../../Assets/Images/editTextIcon.png')}
              alt=""
              style={{
                width: 32,
                height: 32,
                marginLeft: 24,
                cursor: 'pointer',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 70,
              marginTop: 24,
            }}
          >
            <Typography className={classes.settingTitle} variant="h2">
              Additional Criteria
            </Typography>
          </div>
          <Divider />
          {additionalCriteria.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                alignItems: 'center',
                marginTop: 32,
              }}
            >
              <TextField
                disabled
                id="standard-basic"
                label={item.question}
                style={{ flexGrow: 1, marginTop: 0 }}
                fullWidth
                className={classes.textField}
                value={item.answer}
              />
              <img
                onClick={() => {
                  setEditAdditionalCriteria(true);
                  setIndexInEdit(index);
                }}
                src={require('../../../Assets/Images/editTextIcon.png')}
                alt=""
                style={{
                  width: 32,
                  height: 32,
                  marginLeft: 24,
                  cursor: 'pointer',
                }}
              />
            </div>
          ))}
          <img
            onClick={() => {
              setAddNewAdditionalCriteria(true);
            }}
            src={require('../../../Assets/Images/addAdditionalCriteriaButton.png')}
            alt=""
            style={{
              width: 'auto',
              height: 28,
              marginTop: 24,
              cursor: 'pointer',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(AccountDetails);
