import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
import SettingStyle from './Settings.style';
import { CircularProgress, IconButton, makeStyles, TextField } from '@material-ui/core';
import StudyService from '../../../Services/StudyService/StudyService';
import TeamItem from './TeamItem';
import AddNewTeamMemberFromPopup from './AddNewTeamMemberFromPopup';
import Location from './Location'
import { TramRounded } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(SettingStyle)

export default function AlertDialogSlide(props) {

    const classes =  useStyles()    

    const [team, setTeam] = useState([])
    const [loadingTeam, setLoadingTeam] = useState(true)
    const [addNew, setAddNew] = React.useState(false);
    const [location, setLocation] = useState(null)
    const [name, setName] = useState(null)
    const [facility, setFacility] = useState(null)
    const [canAdd, setCanAdd] = useState(false)
    const [adding, setAdding] = useState(false)

    const handleClose = () => {
        props.setOpenAddNewStudyCenter(false)
    };

    useEffect(() => {
        if (name && location) {
            setCanAdd(true)
        } else {
            setCanAdd(false)
        }
    }, [name, location])

    const addNewStudy = async () => {
        if (adding) return
        let newStudyData = {
            LocationFacility: name,
            LocationAddress: location.description,
            status: 'paused',
            addedManually: true
        }
        setAdding(true)
        await StudyService.addNewStudyCenterByRef(props.data.document.ref, newStudyData)
        // await StudyService.addNewStudy(props.data.companyName, props.data.document.ref.id, newStudyData)
        await props.getCenters()
        setAdding(false)
        handleClose()
    }

    return (
        <div>
            <Dialog
                open={props.open}
                fullWidth={true}
                maxWidth={'sm'}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-described by="alert-dialog-slide-description"
                style={{minWidth: 490, minHeight: 450}}
            >
                {console.log('xx')}
                <DialogContent style={{position: 'relative'}}>
                    <IconButton style={{position: 'absolute', right: 16, top: 16}} onClick={handleClose}>
                        <img alt='' src={require('../../../Assets/Images/grayXIcon.png')} width={14} height={14} />
                    </IconButton>
                    <div style={{minHeight: 380}}>
                        <Typography className={classes.StudyCenterNameTeamPopup}>Add New Study Center</Typography>
                        {/* <Typography className={classes.StudyCenterLocationTeamPopup}>{props.data.data().LocationAddress}</Typography> */}
                        <Typography className={classes.AddNewStudyCenterLabel}>STUDY CENTER NAME</Typography>
                        <TextField fullWidth style={{marginTop: 12}} onChange={(e) => setName(e.target.value)} />
                        {/* <Typography className={classes.AddNewStudyCenterLabel}>STUDY CENTER FACILITY</Typography>
                        <TextField fullWidth style={{marginTop: 12}} onChange={(e) => setFacility(e.target.value)} /> */}
                        <Typography className={classes.AddNewStudyCenterLabel}>STUDY CENTER ADDRESS</Typography>
                        <Location setLocation={setLocation} />
                    </div>
                    {canAdd ? (
                        <Button className={classes.addNewStudyCenter} onClick={()=> addNewStudy()}>
                            {adding ? (<CircularProgress size={24} style={{color: 'white'}} />) : 'Add Study Center' }
                        </Button>
                    ) : (
                        <Button className={classes.addNewStudyCenter} disabled style={{opacity: 0.5}}>
                            Add Study Center
                        </Button>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}
