import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
// import Map from '../Map';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';

import Chart from './Chart';
import Icon from './Icon';
import ApplicationDialog from './ApplicationDialog';
// import Apply from '../Apply/Apply';
// import ApplyBasic from '../Apply/ApplyBasic';
import axios from 'axios';
import { PieChart } from 'react-minimal-pie-chart';
import PChart from 'react-google-charts';
import Map from './Map';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const styles = (theme) => ({
  root: {
    'flexGrow': 1,
    '& .MuiButton-root': {
      '&:hover': {
        backgroundColor: theme.palette.primary.secondary,
      },
    },
  },
  pageTitle: {
    // fontSize: 40,
    fontSize: mobile ? 26 : 28,
    color: theme.palette.primary.dark,
    textAlign: 'left',
    marginTop: '2%',
  },
  contentTitle: {
    fontSize: mobile ? 22 : 24,
    color: theme.palette.primary.dark,
    textAlign: 'left',
  },
  contentSubTitle: {
    textAlign: 'left',
    color: '#4fb4e8',
    marginTop: 32,
    fontSize: mobile ? 12 : 14,
  },
  backButton: {
    marginTop: 25,
    cursor: 'pointer',
  },
  pageSubTitle: {
    fontSize: 22,
    color: theme.palette.primary.dark,
    textAlign: 'left',
    display: 'inline-block',
    fontWeight: 'normal',
    opacity: 0.5,
    verticalAlign: 'middle',
    marginTop: 25,
  },
  iconBackground: {
    display: 'inline-block',
    width: 40,
    height: 40,
    borderRadius: '50%',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #4fb4e8',
  },
  icon: {
    color: theme.palette.primary.main,
    height: 20,
    width: 20,
    marginLeft: 10,
    marginTop: 10,
  },
  nctIcon: {
    color: theme.palette.primary.main,
    height: 'auto',
    width: 30,
    marginLeft: 5,
    marginTop: 14,
  },
  iconTitle: {
    marginTop: 24,
    fontSize: 12,
    color: theme.palette.primary.main,
    // fontFamily: 'arial',
    textAlign: 'left',
  },
  iconSubTitle: {
    fontSize: 18,
    color: theme.palette.primary.gray,
    marginTop: 2,
    textAlign: 'left',
  },
  contect: {
    fontSize: mobile ? 17 : 18,
    color: ' #31313',
  },
  sectionTitle: {
    fontSize: 14,
    color: '#4fb4e8',
    textAlign: 'left',
    marginBottom: 12,
    letterSpacing: '2.92px',
    textTransform: 'uppercase',
  },
  mobileApply: {
    height: 48,
    borderRadius: 12,
    backgroundColor: '#4fb4e8',
    color: 'white',
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
    textTransform: 'none',
    display: 'inline-block',
    width: 'calc(100% - 24px)',
    fontSize: 16,
    left: 12,
    position: 'fixed',
    bottom: 74,
    zIndex: 99999999999,
  },
  noAppointmentsTitle: {
    textAlign: 'center',
    color: '#B0B0B0',
    fontSize: mobile ? 15 : 17,
    fontWeight: 600,
    paddingTop: 22,
  },
  appointmentTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 4,
  },
  appointmentSubTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    opacity: 0.5,
    marginTop: 4,
  },
  noAppointmentsBox: {
    width: '100%',
    height: 64,
    backgroundColor: '#F4F4F4',
    borderRadius: 10,
    marginTop: 24,
  },
  appointmentsBox: {
    width: '100%',
    height: 64,
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    borderRadius: 10,
    padding: 8,
    marginTop: 24,
    cursor: 'pointer',
  },
  timeBox: {
    backgroundColor: '#4fb4e8',
    borderRadius: 8,
    textAlign: 'center',
    color: 'white',
    width: 86,
    marginTop: 9,
    float: 'right',
    padding: 8,
    fontSize: 14,
  },
  apply: {
    'height': 54,
    'borderRadius': 10,
    'backgroundColor': '#4fb4e8',
    'color': 'white',
    'fontWeight': 'bold',
    'marginTop': 20,
    'textTransform': 'none',
    'display': 'inline-block',
    'width': '100%',
    'fontSize': 16,
    '&:hover': {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  number: {
    fontSize: 48,
    fontWeight: 'bold',
    color: 'rgb(75,75,75)',
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 900,
  },
  colorNumber: {
    fontSize: 64,
    color: '#4fb4e8',
    fontWeight: 'bold',
    textAlign: 'center',
    fontWeight: 900,
  },
  numberDescription: {
    fontSize: 16,
    color: 'white',
    color: theme.palette.primary.dark,
    opacity: 1,
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 500,
  },
  whiteNumber: {
    fontSize: 64,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontWeight: 900,
  },
  whiteNumberDescription: {
    fontSize: 16,
    color: '#fff',
    opacity: 1,
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 500,
  },
  boxTitle: {
    fontSize: 16,
  },
  chip: {
    height: 33,
    borderRadius: '16.5',
    backgroundColor: '#4fb4e830',
    color: '#4fb4e8',
    fontFamily: "'sofia-pro', sans-serif",
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'upper',
    paddingLeft: 24,
    paddingRight: 24,
    marginRight: 14,
    marginTop: 16,
  },
});

class StudyListings extends React.Component {
  state = {
    openApply: false,
    study: null,
    lat: null,
    lng: null,
    studyId: null,
    studyZip: null,
    sponsored: false,
    study: 1,
    openApplication: false,
    applicationId: 'ivAFueQZT8Aect0Y5Jzs',
  };

  getCoordinates = async (Location) => {
    const address = Location + ', United States';
    // var link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURI(address) + '&components=postal_code:' + this.state.location + "&key=AIzaSyBU_iDguwAqFTyYxbKRNR87Xn4SuHYGWH4"
    var link =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      Location +
      ':US&key=AIzaSyBU_iDguwAqFTyYxbKRNR87Xn4SuHYGWH4';
    const response = await axios.get(link);
    if (response.data.results.length > 0) {
      var coordinates = {
        lat: response.data.results[0].geometry.location.lat,
        lng: response.data.results[0].geometry.location.lng,
      };
      return coordinates;
    }
  };

  getData = async (id, zip) => {
    var url =
      'https://clinicaltrials.gov/api/query/full_studies?fmt=JSON&expr=' +
      String(id);
    const res = await axios.get(url);
    var study = res.data.FullStudiesResponse.FullStudies[0].Study;
    var locationIndex = null;
    var locations =
      study.ProtocolSection.ContactsLocationsModule.LocationList.Location;
    for (let index = 0; index < locations.length; index++) {
      const location = locations[index];
      if (location.LocationZip === zip) {
        locationIndex = index;
      }
    }
    // if (locationIndex === null) {alert('null')}
    // var location = study.ProtocolSection.ContactsLocationsModule.LocationList.Location[this.state.locationIndex].LocationCity + ', ' + study.ProtocolSection.ContactsLocationsModule.LocationList.Location[this.state.locationIndex].LocationState + ', ' + study.ProtocolSection.ContactsLocationsModule.LocationList.Location[this.state.locationIndex].LocationZip
    var location =
      study.ProtocolSection.ContactsLocationsModule.LocationList.Location[
        locationIndex
      ].LocationZip;
    var coordinates = await this.getCoordinates(location);
    this.setState(
      {
        lat: coordinates.lat,
        lng: coordinates.lng,
        study: study,
        locationIndex: locationIndex,
      },
      () => {
        this.setState({ update: true });
      },
    );
  };

  componentDidMount() {
    this.setState({
      studyId: this.props.match.params.id,
      studyZip: this.props.match.params.zip,
      sponsored: this.props.match.params.id === 'NCT03920293' ? true : false,
    });
  }

  openApply = () => {
    this.setState({ openApply: true });
  };

  getTags = () => {
    var condition =
      this.state.study.ProtocolSection.ConditionsModule.ConditionList
        .Condition[0];
    var phase =
      this.state.study.ProtocolSection.DesignModule.PhaseList.Phase[0];
    var nct = this.state.study.ProtocolSection.IdentificationModule.NCTId;
    return [condition, phase, nct];
  };

  getPart1 = () => {
    var text =
      this.state.study.ProtocolSection.EligibilityModule.EligibilityCriteria;
    var endAt = text.toLowerCase().indexOf('exclusion crit');
    return text.substring(20, endAt);
  };

  getPart2 = () => {
    var text =
      this.state.study.ProtocolSection.EligibilityModule.EligibilityCriteria;
    var startAt = text.toLowerCase().indexOf('exclusion criteria');
    return text.substring(startAt + 20);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ApplicationDialog main={this} />
        {this.state.study !== null ? (
          <div>
            <div
              style={{
                paddingTop: 0,
                paddingBottom: mobile ? 160 : 40,
                width: '100%',
                marginTop: 24,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  xl={3}
                  style={{ alignItems: 'center' }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 195,
                          boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                          backgroundColor: 'white',
                          borderRadius: 8,
                          paddingTop: 28,
                        }}
                      >
                        <Typography
                          variant="h4"
                          className={classes.colorNumber}
                        >
                          214
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.numberDescription}
                        >
                          Applied
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 195,
                          boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                          backgroundColor: 'white',
                          borderRadius: 8,
                          paddingTop: 28,
                        }}
                      >
                        <Typography
                          variant="h4"
                          className={classes.colorNumber}
                        >
                          50
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.numberDescription}
                        >
                          Scheduled
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 195,
                          boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                          backgroundColor: '#4fb4e8',
                          paddingTop: 28,
                          borderRadius: 8,
                        }}
                      >
                        <Typography
                          variant="h4"
                          className={classes.whiteNumber}
                        >
                          12
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.whiteNumberDescription}
                        >
                          Completed
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  xl={9}
                  style={{ alignItems: 'center' }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      xl={6}
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 324,
                          boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                          backgroundColor: 'white',
                          borderRadius: 8,
                          padding: 24,
                        }}
                      >
                        <Typography className={classes.boxTitle} variant="h2">
                          Applications
                        </Typography>
                        <Chart />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      xl={6}
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 324,
                          boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                          backgroundColor: 'white',
                          borderRadius: 8,
                          padding: 24,
                        }}
                      >
                        <Typography className={classes.boxTitle} variant="h2">
                          Locations
                        </Typography>
                        <div
                          style={{
                            width: '40%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          <Typography
                            variant="h2"
                            style={{
                              fontSize: 12,
                              fontWeight: 900,
                              color: '#4fb4e8',
                              marginTop: 32,
                              letterSpacing: '0.3em',
                            }}
                          >
                            TOP AREAS
                          </Typography>
                          <div
                            style={{
                              marginTop: 16,
                              width: '80%',
                              height: 1,
                              backgroundColor: '#DBDBDB',
                            }}
                          ></div>
                          <Typography
                            variant="h2"
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: '#4A4A4A',
                              marginTop: 16,
                              width: '80%',
                            }}
                          >
                            Arkansas
                            <span
                              style={{
                                marginLeft: 0,
                                color: 'rgba(74, 74, 74, 0.5)',
                                float: 'right',
                              }}
                            >
                              24
                            </span>
                          </Typography>
                          <Typography
                            variant="h2"
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: '#4A4A4A',
                              marginTop: 16,
                              width: '80%',
                            }}
                          >
                            California
                            <span
                              style={{
                                marginLeft: 0,
                                color: 'rgba(74, 74, 74, 0.5)',
                                float: 'right',
                              }}
                            >
                              4
                            </span>
                          </Typography>
                          <Typography
                            variant="h2"
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: '#4A4A4A',
                              marginTop: 16,
                              width: '80%',
                            }}
                          >
                            New York
                            <span
                              style={{
                                marginLeft: 0,
                                color: 'rgba(74, 74, 74, 0.5)',
                                float: 'right',
                              }}
                            >
                              3
                            </span>
                          </Typography>
                          <Typography
                            variant="h2"
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: '#4A4A4A',
                              marginTop: 16,
                              width: '80%',
                            }}
                          >
                            Texas
                            <span
                              style={{
                                marginLeft: 0,
                                color: 'rgba(74, 74, 74, 0.5)',
                                float: 'right',
                              }}
                            >
                              2
                            </span>
                          </Typography>
                          <Typography
                            variant="h2"
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: '#4A4A4A',
                              marginTop: 16,
                              width: '80%',
                            }}
                          >
                            Lousiana
                            <span
                              style={{
                                marginLeft: 0,
                                color: 'rgba(74, 74, 74, 0.5)',
                                float: 'right',
                              }}
                            >
                              1
                            </span>
                          </Typography>
                        </div>
                        <div style={{ width: '60%', display: 'inline-block' }}>
                          <Map />
                        </div>
                      </div>
                      <div
                        style={{
                          width: 'calc(100% - 48px)',
                          marginLeft: 24,
                          height: 18,
                          background:
                            'linear-gradient(270deg, #783131 0%, #A84545 17.71%, #D85959 36.98%, #4fb4e8 53.12%, #F49292 70.83%, #F7B1B1 85.94%, #FAD0D0 100%)',
                          paddingLeft: 24,
                          paddingRight: 24,
                          marginTop: -46,
                        }}
                      ></div>
                      <div
                        style={{
                          width: 'calc(100% - 48px)',
                          marginLeft: 24,
                          height: 18,
                          paddingLeft: 0,
                          paddingRight: 0,
                          marginTop: 0,
                        }}
                      >
                        <div style={{ display: 'inline-block', float: 'left' }}>
                          <Typography
                            variant="h2"
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: 'rgba(74, 74, 74, 0.5)',
                            }}
                          >
                            0
                          </Typography>
                        </div>
                        <div
                          style={{ display: 'inline-block', float: 'right' }}
                        >
                          <Typography
                            variant="h2"
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: 'rgba(74, 74, 74, 0.5)',
                            }}
                          >
                            100
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      xl={4}
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 283,
                          boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                          backgroundColor: 'white',
                          borderRadius: 8,
                          padding: 24,
                        }}
                      >
                        <Typography className={classes.boxTitle} variant="h2">
                          Gender
                        </Typography>
                        <div
                          style={{
                            display: 'inline-block',
                            width: '60%',
                            verticalAlign: 'top',
                            marginTop: 12,
                          }}
                        >
                          <PieChart
                            animate={false}
                            label={({ dataEntry }) => dataEntry.value + '%'}
                            labelStyle={{
                              fontSize: 14,
                              fontFamily: "'sofia-pro', sans-serif",
                              fontWeight: 600,
                              color: '#FFFFF',
                            }}
                            style={{
                              color: 'white',
                              color: '#FFFFFF',
                              height: 178,
                              marginTop: 10,
                            }}
                            data={[
                              { title: 'Male', value: 35, color: '#4fb4e8' },
                              {
                                title: 'Female',
                                value: 63,
                                color: '#4fb4e830',
                              },
                            ]}
                          />
                        </div>
                        <div
                          style={{
                            width: '40%',
                            marginRight: 0,
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginTop: 24,
                          }}
                        >
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e8',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              Male
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e830',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              Female
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      xl={4}
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 283,
                          boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                          backgroundColor: 'white',
                          borderRadius: 8,
                          padding: 24,
                        }}
                      >
                        <Typography className={classes.boxTitle} variant="h2">
                          Diagnosed
                        </Typography>
                        <div
                          style={{
                            display: 'inline-block',
                            width: '60%',
                            verticalAlign: 'top',
                            marginTop: 12,
                          }}
                        >
                          <PieChart
                            // style={{boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)'}}
                            animate={false}
                            labelStyle={{
                              fontSize: 14,
                              // boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.2)',
                              fontFamily: "'sofia-pro', sans-serif",
                              fontWeight: 600,
                              fontColor: '#FFFFF',
                              color: 'white',
                              background: 'white',
                              backgroundColor: 'white',
                            }}
                            label={({ dataEntry }) => dataEntry.value + '%'}
                            style={{
                              color: 'white',
                              height: 178,
                              marginTop: 10,
                            }}
                            data={[
                              {
                                title: 'Female',
                                value: 35,
                                color: '#4fb4e830',
                              },
                              { title: 'Male', value: 65, color: '#4fb4e8' },
                            ]}
                          />
                        </div>
                        <div
                          style={{
                            width: '40%',
                            marginRight: 0,
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginTop: 24,
                          }}
                        >
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e8',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              Yes
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e830',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              No
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      xl={4}
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 283,
                          boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                          backgroundColor: 'white',
                          borderRadius: 8,
                          padding: 24,
                        }}
                      >
                        <Typography className={classes.boxTitle} variant="h2">
                          Age
                        </Typography>
                        <div
                          style={{
                            display: 'inline-block',
                            width: '60%',
                            verticalAlign: 'top',
                            marginTop: 12,
                          }}
                        >
                          <PieChart
                            // style={{boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)'}}
                            animate={false}
                            style={{
                              color: 'white',
                              height: 150,
                              marginTop: 10,
                            }}
                            data={[
                              {
                                title: 'Female',
                                value: 12.5,
                                color: '#FDE6E6',
                              },
                              { title: 'Male', value: 12.5, color: '#F7B1B1' },
                              { title: 'Male', value: 12.5, color: '#F38382' },
                              { title: 'Male', value: 12.5, color: '#D87877' },
                              { title: 'Male', value: 12.5, color: '#C0504F' },
                              { title: 'Male', value: 37.5, color: '#A66262' },
                            ]}
                          />
                        </div>
                        <div
                          style={{
                            width: '40%',
                            marginRight: 0,
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginTop: 24,
                          }}
                        >
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                              marginTop: 8,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e8',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              Under 10
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                              marginTop: 8,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e830',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              11-18
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                              marginTop: 8,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e8',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              19-29
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                              marginTop: 8,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e830',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              56-74
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                              marginTop: 8,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e8',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              75+
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 12,
                              marginTop: 8,
                            }}
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                borderRadius: 7,
                                backgroundColor: '#4fb4e830',
                              }}
                            ></div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: '0.451506',
                                fontFamily: "'sofia-pro', sans-serif",
                                marginTop: 8,
                                color: '#263238',
                                opacity: 0.5,
                              }}
                            >
                              30-55
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={5} xl={5} style={{alignItems: 'center'}}>
                        <div style={{width: mobile ? '100vw' : '100%', marginLeft: mobile ? -20 : 0, boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.3)', borderRadius: 10, paddingBottom: 20}}>
                            <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top', height: 220, position: 'relative'}}>
                                {(this.state.lat !== null && this.state.lng) && (
                                  <Map main={this} google={window.google} lat={this.state.lat} lng={this.state.lng} borderRadius={mobile ? '0' : '12px 12px 0 0'}/>
                                )}
                            </div>
                            <div style={{paddingTop: 0, paddingLeft: 20, paddingRight: 20, paddingButton: 22  }}>
                                <Typography variant="h4" className={classes.iconTitle}>
                                    NAME
                                </Typography>
                                <div className={classes.iconSubTitle}>
                                  {this.state.study.ProtocolSection.ContactsLocationsModule.LocationList.Location[this.state.locationIndex].LocationFacility}
                                </div>
                                <Typography variant="h4" className={classes.iconTitle}>
                                    ADDRESS
                                </Typography>
                                <div className={classes.iconSubTitle}>
                                    {this.state.study.ProtocolSection.ContactsLocationsModule.LocationList.Location[this.state.locationIndex].LocationCity + ', ' + this.state.study.ProtocolSection.ContactsLocationsModule.LocationList.Location[this.state.locationIndex].LocationState + ', ' + this.state.study.ProtocolSection.ContactsLocationsModule.LocationList.Location[this.state.locationIndex].LocationZip}
                                </div>
                                {!mobile && (
                                  <Button onClick={()=> this.setState({openApply: true})} className={classes.apply}>Apply</Button>
                                )}
                            </div>
                        </div>
                        <div style={{marginTop: 6}}>
                          {this.getTags().map((item) => (
                            <Chip label={item} className={classes.chip} />
                          ))}
                        </div>
                    </Grid> */}
              {/* </Grid> */}
              {/* </div> */}
            </div>
          </div>
        ) : (
          <div>
            <Grid container direction="row" alignItems="flex-start" spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                style={{ alignItems: 'left' }}
              >
                <Skeleton
                  variant="rect"
                  width={120}
                  height={30}
                  style={{ marginTop: 50 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                style={{ alignItems: 'center' }}
              >
                <Skeleton
                  variant="rect"
                  width={'70%'}
                  height={50}
                  style={{ marginTop: 20 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                style={{ alignItems: 'center' }}
              >
                <Skeleton
                  variant="rect"
                  width={'20%'}
                  height={30}
                  style={{ marginTop: 20 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                style={{ alignItems: 'center' }}
              >
                <Skeleton
                  variant="rect"
                  width={'100%'}
                  height={4}
                  style={{ marginTop: 40 }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="flex-start" spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                xl={7}
                style={{ alignItems: 'left' }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={0}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="rect"
                      width={'22%'}
                      height={30}
                      style={{ marginTop: 40 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    xl={6}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="circle"
                      width={60}
                      height={60}
                      style={{
                        marginTop: 40,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    />
                    <Skeleton
                      variant="text"
                      width={110}
                      style={{
                        marginTop: 38,
                        marginLeft: 14,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    xl={6}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="circle"
                      width={60}
                      height={60}
                      style={{
                        marginTop: 40,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    />
                    <Skeleton
                      variant="text"
                      width={110}
                      style={{
                        marginTop: 38,
                        marginLeft: 14,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="rect"
                      width={'22%'}
                      height={30}
                      style={{ marginTop: 60 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'15%'}
                      style={{ marginTop: 40 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'50%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'15%'}
                      style={{ marginTop: 40 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'80%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <Skeleton
                      variant="text"
                      width={'50%'}
                      style={{ marginTop: 14 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                xl={5}
                style={{ alignItems: 'left' }}
              >
                <Skeleton
                  variant="rect"
                  width={'100%'}
                  height={350}
                  style={{ marginTop: 14, marginTop: 40 }}
                />
                <Skeleton
                  variant="rect"
                  width={'100%'}
                  height={50}
                  style={{ marginTop: 14, marginTop: 20 }}
                />
                <Skeleton
                  variant="rect"
                  width={'30%'}
                  height={32}
                  style={{
                    marginTop: 14,
                    marginTop: 20,
                    display: 'inline-block',
                    marginLeft: 0,
                  }}
                />
                <Skeleton
                  variant="rect"
                  width={'20%'}
                  height={32}
                  style={{
                    marginTop: 14,
                    marginTop: 20,
                    display: 'inline-block',
                    marginLeft: 12,
                  }}
                />
                <Skeleton
                  variant="rect"
                  width={'10%'}
                  height={32}
                  style={{
                    marginTop: 14,
                    marginTop: 20,
                    display: 'inline-block',
                    marginLeft: 12,
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(StudyListings),
);
