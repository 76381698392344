import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { Menu, MenuItem } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import NewCenterTable from '../NewCenterTable';
import StudyTable from '../StudyTable';
import StudyOverview from '../StudyOverview/StudyOverview';
import AdminOverview from '../AdminOverview';
import LocationsTable from '../LocationsTable';

import MuiStyleFunction from './StudyDashboard.style';
import StudyDashboardSkeleton from './StudyDashboardSkeleton';
import { StudiesContext } from '../../../../contexts/Studies';
import { AuthContext } from '../../../../contexts/Auth';
import { FilterContext } from '../../../../contexts/Filter';
import Master from '../../Master/Settings';
import AdminOverViewTest from '../StudyOverview/AdminOverviewTest';
import BasicAdminOverview from '../StudyOverview/BasicAdminOverview';
import ExportPopup from './ExportPopup';
import InquiriesIcon from '@material-ui/icons/RecentActorsRounded';
import AppointmentsIcon from '@material-ui/icons/EventRounded';
import OverviewIcon from '@material-ui/icons/DashboardRounded';
import LeadsIcon from '@material-ui/icons/FormatListBulletedRounded';
// import Inquiries from '../../Inquiries/Inquiries';
import Inquiries from '../../InquiriesTab/Inquiries';
import Appointments from '../../Appointments/Appointments';
import MapIcon from '@material-ui/icons/MapRounded';
import DotsIcon from '@material-ui/icons/MoreHorizRounded';
import PinLocationIcon from '@material-ui/icons/RoomRounded';
import StudyService from '../../../../Services/StudyService/StudyService';
import {
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
} from '@material-ui/core';
const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

const FilterExportMenu = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFilter = () => {
    handleClose();
    props.setOpenFilter(true);
  };

  const openExport = () => {
    handleClose();
    props.setOpenExportPoup(true);
  };

  return (
    <div>
      <IconButton onClick={handleClick} style={{ padding: 0, marginTop: 0 }}>
        <DotsIcon size={32} style={{ width: 32 }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => openFilter()}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
            className={classes.liveStatusBox}
            onClick={handleClick}
          >
            <Typography
              variant="h4"
              className={classes.status}
              style={{ fontSize: 20 }}
            >
              Filter
            </Typography>
          </div>
        </MenuItem>
        {/* <MenuItem onClick={() => openExport()}>
          <div style={{display: 'flex', alignContent: 'center', alignItems: 'center'}} className={classes.pausedStatusBox} onClick={handleClick}>
            <Typography variant="h4" className={classes.status} style={{fontSize: 20}}>Export</Typography> 
          </div>
        </MenuItem> */}
      </Menu>
    </div>
  );
};

const StudyDashboardMobile = (props) => {
  const classes = useStyles();

  const { studies } = useContext(StudiesContext);
  const { userData } = useContext(AuthContext);
  const { openFilter, setOpenFilter } = useContext(FilterContext);

  const [openApply, setOpenApply] = useState(false);
  const [study, setStudy] = useState(null);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [studyId, setStudyId] = useState('');
  const [studyZip, setStudyZip] = useState('');
  const [sponsored, setSponsored] = useState(false);
  const [page, setPage] = useState(null);
  const [openExportPoup, setOpenExportPoup] = useState(false);

  useEffect(() => {
    window.Intercom('shutdown');
    let studyId = props.match.params.id;
    let study = null;
    if (studies.length > 0) {
      for (let index = 0; index < studies.length; index++) {
        const potentialStudy = studies[index];
        if (studyId === potentialStudy.document.id) {
          study = potentialStudy;
        }
      }
      handleSetStudy(study);
      setStudyId(studyId);
    }
  }, [studies]);

  const handleSetStudy = async (study) => {
    let studyDataWithUsersData = await StudyService.getStudyDataWithUsersData(
      study,
    );
    setStudy(studyDataWithUsersData);
  };

  useEffect(() => {
    if (userData.type === 'master' || userData.type === 'support') {
      setPage(0);
    } else {
      if (mobile) {
        setPage(0);
      } else {
        setPage(1);
      }
    }
  }, [userData]);

  const getPageTitle = () => {
    if (userData.type === 'master' || userData.type === 'support') {
      if (page === 0) {
        return 'Overview';
      } else if (page === 1) {
        return 'Inquries';
      } else if (page === 2) {
        return 'Applications';
      } else if (page === 3) {
        return 'Locations';
      }
    } else if (userData.type === 'study') {
      if (page === 0) {
        return 'Overview';
      } else if (page === 1) {
        return 'Locations';
      }
    } else if (userData.type === 'center') {
      if (page === 0) {
        return 'Inquiries';
      } else if (page === 1) {
        return 'Virtual Visits';
      } else if (page === 2) {
        return 'Overview';
      } else if (page === 3) {
        return 'Applications';
      }
    }
  };

  return (
    <div>
      {mobile &&
        (userData.type === 'master' || userData.type === 'support') &&
        !openFilter &&
        !openExportPoup && (
          <BottomNavigation
            value={page}
            onChange={(event, newValue) => {
              setPage(newValue);
            }}
            showLabels
            style={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
            }}
            className={'bottomNavigation'}
          >
            <BottomNavigationAction
              style={{ maxWidth: '25%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Overview"
              icon={<OverviewIcon style={{ maxWidth: '33%' }} />}
            />
            <BottomNavigationAction
              style={{ maxWidth: '25%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Inquiries"
              icon={<InquiriesIcon style={{ maxWidth: '33%' }} />}
            />
            <BottomNavigationAction
              style={{ maxWidth: '25%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Applications"
              icon={<LeadsIcon style={{ maxWidth: '33%' }} />}
            />
            <BottomNavigationAction
              style={{ maxWidth: '25%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Locations"
              icon={<PinLocationIcon style={{ maxWidth: '33%' }} />}
            />
          </BottomNavigation>
        )}
      {mobile &&
        userData.type === 'center' &&
        !openFilter &&
        !openExportPoup && (
          <BottomNavigation
            value={page}
            onChange={(event, newValue) => {
              setPage(newValue);
            }}
            showLabels
            style={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
            }}
            className={'bottomNavigation'}
          >
            <BottomNavigationAction
              style={{ maxWidth: '25%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Inquiries"
              icon={<InquiriesIcon style={{ maxWidth: '33%' }} />}
            />
            <BottomNavigationAction
              style={{ maxWidth: '25%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Visits"
              icon={<AppointmentsIcon style={{ maxWidth: '33%' }} />}
            />
            <BottomNavigationAction
              style={{ maxWidth: '25%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Overview"
              icon={<OverviewIcon style={{ maxWidth: '33%' }} />}
            />
            <BottomNavigationAction
              style={{ maxWidth: '25%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Applications"
              icon={<LeadsIcon style={{ maxWidth: '33%' }} />}
            />
          </BottomNavigation>
        )}
      {mobile &&
        userData.type === 'study' &&
        !openFilter &&
        !openExportPoup && (
          <BottomNavigation
            value={page}
            onChange={(event, newValue) => {
              setPage(newValue);
            }}
            showLabels
            style={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              zIndex: 1,
            }}
            className={'bottomNavigation'}
          >
            <BottomNavigationAction
              style={{ maxWidth: '40%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Overview"
              icon={<OverviewIcon style={{ maxWidth: '33%' }} />}
            />
            <BottomNavigationAction
              style={{ maxWidth: '40%' }}
              classes={{ label: classes.bottomNavLabel }}
              label="Locations"
              icon={<PinLocationIcon style={{ maxWidth: '33%' }} />}
            />
          </BottomNavigation>
        )}

      <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 80 }}>
        {getPageTitle() !== 'Inquries' && (
          <div
            style={{ display: 'flex', paddingBottom: 24, alignItems: 'center' }}
          >
            <Typography className={classes.mobileTitle} variant="h2">
              {getPageTitle()}
            </Typography>
            <FilterExportMenu
              setOpenFilter={setOpenFilter}
              setOpenExportPoup={setOpenExportPoup}
            />
          </div>
        )}
        {openExportPoup && (
          <ExportPopup
            open={openExportPoup}
            setOpenExportPoup={setOpenExportPoup}
            study={study}
            userType={userData.type}
          />
        )}
        {study !== null &&
          (userData.type === 'master' || userData.type === 'support') && (
            <div>
              {page === 1 && <Inquiries data={study} />}
              {page === 0 && <AdminOverViewTest study={study} />}
              {page === 2 && <StudyTable study={study} />}
              {page === 3 && <LocationsTable study={study} />}
            </div>
          )}
        {study !== null && userData.type === 'study' && (
          <div>
            {page === 0 && <AdminOverViewTest study={study} />}
            {page === 1 && <LocationsTable study={study} />}
          </div>
        )}
        {study !== null && userData.type === 'center' && (
          <div>
            {page === 0 && <Inquiries study={study} />}
            {page === 1 && <Appointments study={study} />}
            {page === 2 && <StudyOverview study={study} />}
            {page === 3 && <NewCenterTable study={study} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudyDashboardMobile;

{
  /* <div style={{paddingTop: 0, paddingBottom: mobile ? 160 : 40, width: '100%'}}>
              <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
                  {page === 0 && (
                    <Master data={study} />
                  )} 
                  {(page === 1 && study.studyData.isSponsored && (userData.type === 'master' || userData.type === 'study')) && (
                    <AdminOverViewTest study={study} />
                  )} 
                  {(page === 1 && !study.studyData.isSponsored && (userData.type === 'master' || userData.type === 'study')) && (
                    <BasicAdminOverview study={study} />
                  )} 
                  {(page === 1 && userData.type === 'center') && (
                    <StudyOverview study={study} />
                  )} 
                  {(page === 2 && userData.type === 'center') && (
                      <NewCenterTable study={study}  />
                  )} 
                  {(page === 2 && (userData.type === 'master')) && (
                      <StudyTable study={study}  />
                  )} 
                  {page === 3 && (
                    <LocationsTable study={study} />
                  )} 
                </Grid> 
              </Grid> 
            </div> */
}
