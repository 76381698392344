import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import StudyService from '../../../Services/StudyService/StudyService';
import { StudyContext } from '../../../contexts/StudyContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const options = ['New', 'Viewed'];

export default function Stage({
  stage,
  getStyleForStatus,
  renameStatus,
  application,
  editable = true,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const { getCenters } = React.useContext(StudyContext);

  useEffect(() => {
    for (let index = 0; index < options.length; index++) {
      const stageItem = options[index];
      if (renameStatus(stage) === stageItem) {
        setSelectedIndex(index);
      }
    }
  }, []);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const updateApplicationWithNewStage = async (stage) => {
    let data = { stage: stage };
    await StudyService.updateApplicationByDoc(application.doc.ref, data);
    getCenters();
  };

  const handleMenuItemClick = async (event, index) => {
    setSelectedIndex(index);
    updateApplicationWithNewStage(options[index]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div
        style={getStyleForStatus(options[selectedIndex])}
        onClick={editable ? handleClickListItem : null}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {options[selectedIndex]}
          {editable && <ExpandMoreRoundedIcon style={{ marginLeft: 4 }} />}
        </div>
      </div>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            disabled={index === selectedIndex}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <div
              style={getStyleForStatus(option)}
              onClick={handleClickListItem}
            >
              {option}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
