import {
  AppBar,
  Container,
  Hidden,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getSharableData } from '../../../Services/ApplicationService/ApplicationService';
import LeadsTable from './LeadsTable';
import LoadingComponent from '../../../Components/Loading/Loading';
import { Divider } from 'antd';
import moment from 'moment';

const SharableLinkContainer = (props) => {
  const { id } = useParams();

  const [res, setRes] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      fetchSharableData();
    }
  }, [id]);

  const fetchSharableData = async () => {
    const res = await getSharableData(id);
    setRes(res.data);
    setLoading(false);
  };

  return (
    <Container maxWidth="xl">
      <AppBar sx={{ bgcolor: 'white' }} elevation={0}>
        <Toolbar>
          <Link to="/">
            <img
              alt=""
              src={require('../../../Assets/Images/logo.png')}
              style={{
                height: 32,
                marginTop: 12,
                marginLeft: 0,
                width: 'auto',
                cursor: 'pointer',
              }}
            />
          </Link>
          <div style={{ flexGrow: 1 }}></div>
          <Hidden smDown>
            <>
              {res &&
                res.status === 'valid' &&
                res.data.expiration !== 'onetime' && (
                  <div style={{ color: 'black' }}>
                    The link will expire{' '}
                    {moment(
                      res.data.expirationTimestamp._seconds * 1000,
                    ).fromNow()}
                  </div>
                )}
            </>
          </Hidden>
          <Hidden mdUp>
            <>
              {res &&
                res.status === 'valid' &&
                res.data.expiration !== 'onetime' && (
                  <div style={{ color: 'black' }}>
                    Exp{' '}
                    {moment(
                      res.data.expirationTimestamp._seconds * 1000,
                    ).fromNow()}
                  </div>
                )}
            </>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {loading && <LoadingComponent />}
      {res && res.status === 'valid' ? (
        <>
          {res.data.type === 'application' ? (
            <Stack direction={'column'} spacing={2} sx={{ mt: 6, pb: 3 }}>
              <Typography sx={{ fontSize: { xs: 24, md: 32 }, color: 'black' }}>
                {res.data.data.studyName}
              </Typography>
              <Typography sx={{ fontSize: { xs: 18, md: 22 }, color: 'black' }}>
                {res.data.data.companyName}
              </Typography>
              <Divider
                style={{
                  height: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.14)',
                }}
              />

              <LeadsTable
                leads={[res.data.data]}
                audience={res.data?.audience || 'all'}
              />
              <div>
                * By viewing this page, you agree that: The information
                accessible is intended solely for the purpose for which it has
                been provided. Any use of this information for any other purpose
                is strictly prohibited. You are required to treat all
                information obtained through the page as confidential and
                proprietary and have a duty to protect the confidentiality of
                the information. You may not disclose, share, or otherwise
                disseminate this information to any third party without explicit
                authorization from
              </div>
            </Stack>
          ) : (
            <Stack direction={'column'} spacing={2} sx={{ mt: 6, pb: 3 }}>
              <Typography sx={{ fontSize: { xs: 24, md: 32 }, color: 'black' }}>
                {res.data.data[0].studyName}
              </Typography>
              <Typography sx={{ fontSize: { xs: 18, md: 22 }, color: 'black' }}>
                {res.data.data[0].companyName}
              </Typography>
              <Divider
                style={{
                  height: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.14)',
                }}
              />
              <LeadsTable
                leads={res.data.data}
                audience={res.data?.audience || 'all'}
              />
              <div>
                * By viewing this page, you agree that: The information
                accessible is intended solely for the purpose for which it has
                been provided. Any use of this information for any other purpose
                is strictly prohibited. You are required to treat all
                information obtained through the page as confidential and
                proprietary and have a duty to protect the confidentiality of
                the information. You may not disclose, share, or otherwise
                disseminate this information to any third party without explicit
                authorization from
              </div>
            </Stack>
          )}
        </>
      ) : (
        <>
          {loading === false && (
            <Stack
              direction={'column'}
              spacing={2}
              sx={{ pl: 3, pr: 3, mt: 6, textAlign: 'center' }}
            >
              <Typography
                sx={{ fontSize: { xs: 18, md: 22 }, mt: 12, color: 'black' }}
              >
                This link is no longer valid
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 18, md: 22 }, mt: 3, color: 'black' }}
              >
                Please contact our team for help getting access:
                team@knowrare.com
              </Typography>
            </Stack>
          )}
        </>
      )}
    </Container>
  );
};

export default React.memo(SharableLinkContainer);
