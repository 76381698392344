import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Sponsored.style';
import { AuthContext } from '../../../contexts/Auth';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from './Card';
import SkeletonCard from './SkeletonCard';
import StudyService from '../../../Services/StudyService/StudyService';
import { Button } from '@material-ui/core';
import AddNewStudyPopup from './AddNewStudyPopup';
import { StudiesContext } from '../../../contexts/Studies';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(MuiStyleFunction);

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const Sponsored = (props) => {
  const navigate = useNavigate();

  const classes = useStyles();

  const { userData, userType } = useContext(AuthContext);
  const { studies, getStudies } = useContext(StudiesContext);

  const [studiesToShow, setStudiesToShow] = useState([]);
  const [search, setSearch] = useState('');

  const [openAddNewStudy, setOpenAddNewStudy] = useState(false);

  const getSponsoredStudies = () => {
    let relevants = [];
    for (let index = 0; index < studies.length; index++) {
      const study = studies[index];
      if (study.studyData?.isSponsored) {
        relevants.push(study);
      }
    }
    setStudiesToShow(relevants);
    return relevants;
  };

  useEffect(() => {
    getSponsoredStudies();
  }, [studies]);

  const updateStudies = async (ref, data) => {
    await StudyService.updateStudyByRef(ref, data);
    getStudies();
  };

  const handleChangeSearch = (value) => {
    setSearch(value);
    if (value === '') {
      getSponsoredStudies();
      return;
    }
    let matchedStudies = [];
    for (let index = 0; index < getSponsoredStudies().length; index++) {
      const study = getSponsoredStudies()[index];
      let NCTID = study.document.id.toLowerCase();
      let companyName = study.companyName.toLowerCase();
      let studyName = study.studyData.customTitle.toLowerCase();
      if (
        NCTID.includes(value.toLowerCase()) ||
        companyName.includes(value.toLowerCase()) ||
        studyName.includes(value.toLowerCase())
      ) {
        matchedStudies.push(study);
      }
    }
    setStudiesToShow(matchedStudies);
  };

  return (
    <div>
      <AddNewStudyPopup
        open={openAddNewStudy}
        setOpenAddNewStudy={setOpenAddNewStudy}
      />
      <div style={{ paddingBottom: 90 }}>
        <Container maxWidth="lg">
          {!mobile && (
            <div style={{ width: '100%', height: 70 }}>
              {userType === 'master' && (
                <Typography className={classes.adminLabel} variant="h2">
                  ADMIN
                </Typography>
              )}
              {userType === 'support' && (
                <Typography className={classes.adminLabel} variant="h2">
                  SUPPORT
                </Typography>
              )}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  alignContent: 'center',
                  gap: 16,
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <Typography className={classes.pageTitle} variant="h2">
                    {userData?.firstName} {userData?.lastName}
                  </Typography>
                </div>
                <input
                  value={search}
                  placeholder="Search NCT ID, company, or study"
                  className={classes.searchInput}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
                {userType === 'master' && (
                  <Button
                    onClick={() => navigate('/home/widget')}
                    className={classes.addNewStudyButton}
                  >
                    Widget Maker
                  </Button>
                )}
                {userType === 'master' && (
                  <Button
                    onClick={() => setOpenAddNewStudy(true)}
                    className={classes.addNewStudyButton}
                  >
                    <img
                      alt=""
                      src={require('../../../Assets/Images/whitePlusIconn.png')}
                      style={{ marginRight: 8 }}
                    />
                    Add New Study
                  </Button>
                )}
              </div>
            </div>
          )}
          {!mobile && (
            <div>
              <Divider
                style={{
                  marginTop: 30,
                  marginBottom: 30,
                  height: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.14)',
                }}
              />
            </div>
          )}
        </Container>
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={4}>
            {studiesToShow.map((item, i) => (
              <Card key={i} i={i} data={item} updateStudies={updateStudies} />
            ))}
            {studies.length === 0 && <SkeletonCard />}
            {studies.length === 0 && <SkeletonCard />}
            {studies.length === 0 && <SkeletonCard />}
            {studies.length === 0 && <SkeletonCard />}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default React.memo(Sponsored);
