import axios from 'axios';

export default async function fetchLeads(url, params = {}) {
  try {
    let response = await axios.post(
      'https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net/fetchZendeskLeads',
      {
        url,
        params,
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error calling function', error);
    return null;
  }
}
