import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FileDrop } from 'react-file-drop';
import { makeStyles } from '@material-ui/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import './dnd.css';
import { CircularProgress } from '@material-ui/core';
import ApplicationService from '../../../../Services/ApplicationService/ApplicationService';
import StudyService from '../../../../Services/StudyService/StudyService';
import { SnackbarContext } from '../../../../contexts/Snackbar';
import { StudiesContext } from '../../../../contexts/Studies';

const useStyles = makeStyles((theme) => ({
  coloredIcon: {
    color: theme.palette.primary.main,
    width: 64,
    height: 64,
  },
  loader: {
    color: theme.palette.primary.main,
    width: 48,
    height: 48,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
    color: '#4A4A4A',
    marginTop: 24,
  },
  filename: {
    fontSize: 10,
    color: '#4A4A4A',
  },
  subtitle: {
    fontSize: 15,
    width: '100%',
    textAlign: 'center',
    color: '#C4C4C4',
    marginTop: 24,
    paddingLeft: 16,
    paddingRight: 16,
  },
  uploadBox: {
    backgroundColor: '#C4C4C460',
    borderRadius: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 103,
    cursor: 'pointer',
  },
  boxTitle: {
    fontSize: 14,
    color: '#4A4A4A70',
    textAlign: 'center',
  },
  saveButton: {
    'width': '100%',
    'background': theme.palette.primary.main,
    'color': 'white',
    'borderRadius': 10,
    'height': 44,
    'fontWeight': 'bold',
    'textTransform': 'none',
    '&:hover': {
      background: theme.palette.primary.main,
    },
    '&:disabled': {
      color: 'white',
      opacity: 0.6,
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  'position': 'relative',
  'root': {
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    position: 'relative',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  open,
  setOpen,
  application,
  study,
}) {
  const classes = useStyles();

  const { studies, setStudies } = React.useContext(StudiesContext);
  const { show } = React.useContext(SnackbarContext);

  const [files, setFiles] = React.useState([]);
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const fileInputRef = React.useRef(null);

  const saveNewRecordsLink = async (link) => {
    let currentApplicationId = application.id;
    let updatedStudiesArray = studies;
    for (let index = 0; index < updatedStudiesArray.length; index++) {
      let currentStudy = updatedStudiesArray[index];
      if (currentStudy.document.id === study.document.id) {
        for (
          let applicationIndex = 0;
          applicationIndex < currentStudy.applications.length;
          applicationIndex++
        ) {
          let app = currentStudy.applications[applicationIndex];
          if (app.id === currentApplicationId) {
            app.records = { links: [link] };
            currentStudy.applications[applicationIndex] = app;
            updatedStudiesArray[index] = currentStudy;
            setStudies([...updatedStudiesArray]);
            show('success', 'Saved');
          }
        }
      }
    }
    await StudyService.updateApplicationByDoc(application.doc.ref, {
      records: { links: [link] },
    });
    setUploadingFile(false);
    setOpen(false);
  };

  const uploadFile = async () => {
    setUploadingFile(true);
    let fileLink = await ApplicationService.uploadRecords(
      application.id,
      files[0],
    );
    // update application record with the file name
    saveNewRecordsLink(fileLink);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFileInputChange = (event) => {
    const { files } = event.target;
    // do something with your files...
    setFiles(files);
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
      />
      <BootstrapDialog
        onClose={handleClose}
        maxWidth="xs"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          onClick={handleClose}
          style={{
            right: 8,
            position: 'absolute',
            top: 8,
            width: 48,
          }}
        >
          <CloseIcon />
        </IconButton>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography variant="h2" className={classes.title}>
            Attach Medical Records
          </Typography>
          <Typography variant="h2" className={classes.subtitle}>
            Click to upload, or drag and drop the records PDF pertaining to this
            patient here.
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          {files.length === 0 ? (
            <FileDrop
              className={classes.uploadBox}
              onDrop={(files, event) => setFiles(files)}
              onTargetClick={onTargetClick}
            >
              <Typography variant="h2" className={classes.boxTitle}>
                Click to upload
              </Typography>
              <Typography variant="h2" className={classes.boxTitle}>
                or drag and drop
              </Typography>
            </FileDrop>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 12,
                flexFlow: 'column',
              }}
            >
              <InsertDriveFileIcon className={classes.coloredIcon} />
              <Typography variant="h2" className={classes.filename}>
                {files[0].name}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {uploadingFile ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress className={classes.loader} />
            </div>
          ) : (
            <Button
              autoFocus
              onClick={uploadFile}
              disabled={files.length === 0}
              className={classes.saveButton}
            >
              Save Records
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
