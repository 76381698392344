import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Switch } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import StudyService from '../../../Services/StudyService/StudyService';
import MedicationsAutocomplete from './MedicationsAutocomplete';
import ProceduresAutocomplete from './ProceduresAutocomplete';
import SymptomsAutocomplete from './SymptomsAutocomplete';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TravelPolicyPopup from './TravelPolicyPopup';
import Diseases from './DiseasesTextField';
import DatePickerMobile from '../../App/Study/DatePicker';
import moment from 'moment';

const useStyles = makeStyles(MuiStyleFunction);

const Status = ({ data, updateStudy }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const select = (ref, data) => {
    updateStudy(ref, data);
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
        }}
        className={
          data.studyData.status === 'Live'
            ? classes.liveStatusBox
            : data.studyData.status === 'Done'
            ? classes.doneStatusBox
            : classes.pausedStatusBox
        }
        onClick={handleClick}
      >
        <Typography variant="h4" className={classes.status}>
          {data.studyData.status}
        </Typography>
        <img
          alt=""
          className={classes.status}
          src={require('../../../Assets/Images/whiteArrowDown.png')}
          width={8.41}
          height={9.13}
          style={{ marginLeft: 5 }}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => select(data.document.ref, { status: 'Live' })}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
            className={classes.liveStatusBox}
            onClick={handleClick}
          >
            <Typography variant="h4" className={classes.status}>
              Live
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => select(data.document.ref, { status: 'Paused' })}
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
            className={classes.pausedStatusBox}
            onClick={handleClick}
          >
            <Typography variant="h4" className={classes.status}>
              Paused
            </Typography>
          </div>
        </MenuItem>
        <MenuItem onClick={() => select(data.document.ref, { status: 'Done' })}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
            className={classes.doneStatusBox}
            onClick={handleClick}
          >
            <Typography variant="h4" className={classes.status}>
              Done
            </Typography>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 27,
    padding: 0,
  },
  switchBase: {
    'padding': 1,
    '&$checked': {
      'transform': 'translateX(16px)',
      'color': theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const AccountDetails = ({ data }) => {
  const classes = useStyles();

  const { userData } = useContext(AuthContext);

  const { studies, getStudies } = useContext(StudiesContext);

  const [customTitle, setCustomTitle] = useState(null);
  const [diseaseName, setDiseaseName] = useState(
    data.studyData.diseaseName ? data.studyData.diseaseName : '',
  );
  const [zendeskDisease, setZendeskDisease] = useState(
    data.studyData?.zendeskDisease || '',
  );
  const [zendeskStartDate, setZendeskStartDate] = useState(
    data.studyData?.zendeskStartDate,
  );
  const [docusignID, setDocusignID] = useState(null);
  const [isSponsored, setIsSponsored] = useState(data.studyData.isSponsored);
  const [isDiagnosisRequired, setIsDiagnosisRequired] = useState(
    data.studyData.isDiagnosisRequired
      ? data.studyData.isDiagnosisRequired
      : false,
  );
  const [otherTrials, setOtherTrials] = useState(
    data.studyData.otherTrials ? data.studyData.otherTrials : '',
  );

  const [openTravelPolicyPopup, setOpenTravelPolicyPopup] = useState(false);

  const [informedConsentUrlEnglish, setInformedConsentUrlEnglish] = useState(
    data.studyData.informedConsentUrlEnglish
      ? data.studyData.informedConsentUrlEnglish
      : '',
  );
  const [informedConsentUrlFrench, setInformedConsentUrlFrench] = useState(
    data.studyData.informedConsentUrlFrench
      ? data.studyData.informedConsentUrlFrench
      : '',
  );
  const [informedConsentUrlItalian, setInformedConsentUrlItalian] = useState(
    data.studyData.informedConsentUrlItalian
      ? data.studyData.informedConsentUrlItalian
      : '',
  );
  const [informedConsentUrlGerman, setInformedConsentUrlGerman] = useState(
    data.studyData.informedConsentUrlGerman
      ? data.studyData.informedConsentUrlGerman
      : '',
  );
  const [informedConsentUrlNorwegian, setInformedConsentUrlNorwegian] =
    useState(
      data.studyData.informedConsentUrlNorwegian
        ? data.studyData.informedConsentUrlNorwegian
        : '',
    );
  const [informedConsentUrlSpanish, setInformedConsentUrlSpanish] = useState(
    data.studyData.informedConsentUrlSpanish
      ? data.studyData.informedConsentUrlSpanish
      : '',
  );

  const [travelPolicy, setTravelPolicy] = React.useState(
    data.studyData.travelPolicy ? data.studyData.travelPolicy : '',
  );

  const [inclusionMedications, setInclusionMedications] = React.useState(
    data.studyData.inclusionMedications
      ? data.studyData.inclusionMedications
      : [],
  );
  const [inclusionConditions, setInclusionConditions] = React.useState(
    data.studyData.inclusionConditions
      ? data.studyData.inclusionConditions
      : [],
  );
  const [inclusionProcedures, setInclusionProcedures] = React.useState(
    data.studyData.inclusionProcedures
      ? data.studyData.inclusionProcedures
      : [],
  );
  const [inclusionSymptoms, setInclusionSymptoms] = React.useState(
    data.studyData.inclusionSymptoms ? data.studyData.inclusionSymptoms : [],
  );

  const [exclusionMedications, setExclusionMedications] = React.useState(
    data.studyData.exclusionMedications
      ? data.studyData.exclusionMedications
      : [],
  );
  const [exclusionConditions, setExclusionConditions] = React.useState(
    data.studyData.exclusionConditions
      ? data.studyData.exclusionConditions
      : [],
  );
  const [exclusionProcedures, setExclusionProcedures] = React.useState(
    data.studyData.exclusionProcedures
      ? data.studyData.exclusionProcedures
      : [],
  );
  const [exclusionSymptoms, setExclusionSymptoms] = React.useState(
    data.studyData.exclusionSymptoms ? data.studyData.exclusionSymptoms : [],
  );

  useEffect(() => {
    setCustomTitle(data.studyData.customTitle);
    setZendeskDisease(data.studyData?.zendeskDisease);
    setZendeskStartDate(data.studyData?.zendeskStartDate);
    if (data.studyData.docusignID) {
      setDocusignID(data.studyData.docusignID);
    }

    // INFORMED CONSENT URL's
    // if (data.studyData.informedConsentUrlEnglish) {
    //   setInformedConsentUrlEnglish(data.studyData.informedConsentUrlEnglish)
    // }
    // if (data.studyData.informedConsentUrlFrench) {
    //   setInformedConsentUrlFrench(data.studyData.informedConsentUrlFrench)
    // }
    // if (data.studyData.informedConsentUrlItalian) {
    //   setInformedConsentUrlItalian(data.studyData.informedConsentUrlItalian)
    // }
    // if (data.studyData.informedConsentUrlGerman) {
    //   setInformedConsentUrlGerman(data.studyData.informedConsentUrlGerman)
    // }
    // if (data.studyData.informedConsentUrlNorwegian) {
    //   setInformedConsentUrlNorwegian(data.studyData.informedConsentUrlNorwegian)
    // }
    // if (data.studyData.informedConsentUrlSpanish) {
    //   setInformedConsentUrlSpanish(data.studyData.informedConsentUrlSpanish)
    // }

    // setIsSponsored(data.studyData.isSponsored)
    // setIsDiagnosisRequired(data.studyData.isDiagnosisRequired ? data.studyData.isDiagnosisRequired : false)
    // setOtherTrials(data.studyData.otherTrials)
    // if (data.studyData.medications) {
    //   setMedications(data.studyData.medications)
    // }
    // if (data.studyData.procedures) {
    //   setProcedures(data.studyData.procedures)
    // }
    // if (data.studyData.conditions) {
    //   setConditions(data.studyData.conditions)
    // }
  }, []);

  useEffect(() => {
    if (zendeskDisease && zendeskDisease !== data.studyData?.zendeskDisease) {
      updateStudy(data.document.ref, {
        zendeskDisease,
      });
    }
  }, [zendeskDisease]);

  useEffect(() => {
    if (
      zendeskStartDate &&
      zendeskStartDate !== data.studyData?.zendeskStartDate
    ) {
      updateStudy(data.document.ref, {
        zendeskStartDate,
      });
    }
  }, [zendeskStartDate]);

  useEffect(() => {
    if (
      informedConsentUrlEnglish &&
      informedConsentUrlEnglish !== data.studyData.informedConsentUrlEnglish
    ) {
      updateStudy(data.document.ref, {
        informedConsentUrlEnglish: informedConsentUrlEnglish,
      });
    }
  }, [informedConsentUrlEnglish]);

  useEffect(() => {
    if (
      informedConsentUrlFrench &&
      informedConsentUrlFrench !== data.studyData.informedConsentUrlFrench
    ) {
      updateStudy(data.document.ref, {
        informedConsentUrlFrench: informedConsentUrlFrench,
      });
    }
  }, [informedConsentUrlFrench]);

  useEffect(() => {
    if (
      informedConsentUrlItalian &&
      informedConsentUrlItalian !== data.studyData.informedConsentUrlItalian
    ) {
      updateStudy(data.document.ref, {
        informedConsentUrlItalian: informedConsentUrlItalian,
      });
    }
  }, [informedConsentUrlItalian]);

  useEffect(() => {
    if (
      informedConsentUrlGerman &&
      informedConsentUrlGerman !== data.studyData.informedConsentUrlGerman
    ) {
      updateStudy(data.document.ref, {
        informedConsentUrlGerman: informedConsentUrlGerman,
      });
    }
  }, [informedConsentUrlGerman]);

  useEffect(() => {
    if (
      informedConsentUrlNorwegian &&
      informedConsentUrlNorwegian !== data.studyData.informedConsentUrlNorwegian
    ) {
      updateStudy(data.document.ref, {
        informedConsentUrlNorwegian: informedConsentUrlNorwegian,
      });
    }
  }, [informedConsentUrlNorwegian]);

  useEffect(() => {
    if (
      informedConsentUrlSpanish &&
      informedConsentUrlSpanish !== data.studyData.informedConsentUrlSpanish
    ) {
      updateStudy(data.document.ref, {
        informedConsentUrlSpanish: informedConsentUrlSpanish,
      });
    }
  }, [informedConsentUrlSpanish]);

  useEffect(() => {
    if (data.studyData.inclusionConditions) {
      if (
        inclusionConditions.toString() !==
        data.studyData.inclusionConditions.toString()
      ) {
        updateStudy(data.document.ref, {
          inclusionConditions: inclusionConditions,
        });
      }
    } else {
      updateStudy(data.document.ref, { inclusionConditions: [] });
    }
  }, [inclusionConditions]);

  useEffect(() => {
    if (data.studyData.exclusionConditions) {
      if (
        exclusionConditions.toString() !==
        data.studyData.exclusionConditions.toString()
      ) {
        updateStudy(data.document.ref, {
          exclusionConditions: exclusionConditions,
        });
      }
    } else {
      updateStudy(data.document.ref, { exclusionConditions: [] });
    }
  }, [exclusionConditions]);

  useEffect(() => {
    if (data.studyData.inclusionProcedures) {
      if (
        inclusionProcedures.toString() !==
        data.studyData.inclusionProcedures.toString()
      ) {
        updateStudy(data.document.ref, {
          inclusionProcedures: inclusionProcedures,
        });
      }
    } else {
      updateStudy(data.document.ref, { inclusionProcedures: [] });
    }
  }, [inclusionProcedures]);

  useEffect(() => {
    if (data.studyData.exclusionProcedures) {
      if (
        exclusionProcedures.toString() !==
        data.studyData.exclusionProcedures.toString()
      ) {
        updateStudy(data.document.ref, {
          exclusionProcedures: exclusionProcedures,
        });
      }
    } else {
      updateStudy(data.document.ref, { exclusionProcedures: [] });
    }
  }, [exclusionProcedures]);

  useEffect(() => {
    if (data.studyData.inclusionSymptoms) {
      if (
        inclusionSymptoms.toString() !==
        data.studyData.inclusionSymptoms.toString()
      ) {
        updateStudy(data.document.ref, {
          inclusionSymptoms: inclusionSymptoms,
        });
      }
    } else {
      updateStudy(data.document.ref, { inclusionSymptoms: [] });
    }
  }, [inclusionSymptoms]);

  useEffect(() => {
    if (data.studyData.exclusionSymptoms) {
      if (
        exclusionSymptoms.toString() !==
        data.studyData.exclusionSymptoms.toString()
      ) {
        updateStudy(data.document.ref, {
          exclusionSymptoms: exclusionSymptoms,
        });
      }
    } else {
      updateStudy(data.document.ref, { exclusionSymptoms: [] });
    }
  }, [exclusionSymptoms]);

  useEffect(() => {
    if (data.studyData.inclusionMedications) {
      if (
        inclusionMedications.toString() !==
        data.studyData.inclusionMedications.toString()
      ) {
        updateStudy(data.document.ref, {
          inclusionMedications: inclusionMedications,
        });
      }
    } else {
      updateStudy(data.document.ref, { inclusionMedications: [] });
    }
  }, [inclusionMedications]);

  useEffect(() => {
    if (data.studyData.exclusionMedications) {
      if (
        exclusionMedications.toString() !==
        data.studyData.exclusionMedications.toString()
      ) {
        updateStudy(data.document.ref, {
          exclusionMedications: exclusionMedications,
        });
      }
    } else {
      updateStudy(data.document.ref, { exclusionMedications: [] });
    }
  }, [exclusionMedications]);

  useEffect(() => {
    if (isSponsored !== null && isSponsored !== data.studyData.isSponsored) {
      updateStudy(data.document.ref, { isSponsored: isSponsored });
    }
  }, [isSponsored]);

  useEffect(() => {
    if (
      isDiagnosisRequired !== null &&
      isDiagnosisRequired !== data.studyData.isDiagnosisRequired
    ) {
      updateStudy(data.document.ref, {
        isDiagnosisRequired: isDiagnosisRequired,
      });
    }
  }, [isDiagnosisRequired]);

  useEffect(() => {
    if (otherTrials !== null && otherTrials !== data.studyData.otherTrials) {
      updateStudy(data.document.ref, { otherTrials: otherTrials });
    }
  }, [otherTrials]);

  useEffect(() => {
    if (customTitle !== null && customTitle !== data.studyData.customTitle) {
      updateStudy(data.document.ref, { customTitle: customTitle });
    }
  }, [customTitle]);

  useEffect(() => {
    if (customTitle !== null && customTitle !== data.studyData.diseaseName) {
      updateStudy(data.document.ref, { diseaseName: diseaseName });
    }
  }, [diseaseName]);

  useEffect(() => {
    if (docusignID && docusignID !== data.studyData.docusignID) {
      updateStudy(data.document.ref, { docusignID: docusignID });
    }
  }, [docusignID]);

  const handleChangeSwitch = (event) => {
    if (event.target.name === 'isSponsored') {
      setIsSponsored(event.target.checked);
    } else if (event.target.name === 'isDiagnosisRequired') {
      setIsDiagnosisRequired(event.target.checked);
    }
  };

  const updateStudy = async (ref, data) => {
    await StudyService.updateStudyByRef(ref, data);
    getStudies();
  };

  const changeCustomTitle = (newTitle) => {
    setCustomTitle(newTitle);
  };

  const changeDiseaseName = (newTitle) => {
    setDiseaseName(newTitle);
  };

  const changeDocusignID = (newDocusignID) => {
    setDocusignID(newDocusignID);
  };

  const saveTravelPolicy = (newValue) => {
    setTravelPolicy(newValue);
    setOpenTravelPolicyPopup(false);
    updateStudy(data.document.ref, { travelPolicy: newValue });
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <TravelPolicyPopup
        open={openTravelPolicyPopup}
        setOpen={setOpenTravelPolicyPopup}
        travelPolicy={travelPolicy}
        saveTravelPolicy={saveTravelPolicy}
      />
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
            <Typography className={classes.settingTitle} variant="h2">
              Study Settings
            </Typography>
          </div>
          <Divider />
          <TextField
            label="Custom Study Name"
            fullWidth
            className={classes.textField}
            value={customTitle ? customTitle : ''}
            onChange={(e) => changeCustomTitle(e.target.value)}
          />
          <div style={{ width: '100%' }}>
            <Diseases
              disease={diseaseName}
              setDisease={setDiseaseName}
              style={'standard'}
            />
          </div>
          {/* <TextField label="Disease Name" fullWidth className={classes.textField} value={diseaseName} onChange={(e) => changeDiseaseName(e.target.value)} /> */}
          <div
            style={{
              height: 70,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography className={classes.statusLabel}>Status</Typography>
            <Status data={data} updateStudy={updateStudy} />
          </div>
          <div
            style={{
              height: 70,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography className={classes.statusLabel}>
              Diagnosis Required
            </Typography>
            <IOSSwitch
              checked={isDiagnosisRequired}
              onChange={handleChangeSwitch}
              name="isDiagnosisRequired"
            />
          </div>
          <div
            style={{
              height: 70,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography className={classes.statusLabel}>Sponsored</Typography>
            <IOSSwitch
              checked={isSponsored}
              onChange={handleChangeSwitch}
              name="isSponsored"
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignItems: 'center',
            }}
          >
            <TextField
              disabled
              label="Travel Policy"
              style={{ flexGrow: 1, marginTop: 0 }}
              fullWidth
              className={classes.textField}
              value={travelPolicy}
            />
            <img
              onClick={() => setOpenTravelPolicyPopup(true)}
              src={require('../../../Assets/Images/editTextIcon.png')}
              alt=""
              style={{
                width: 32,
                height: 32,
                marginLeft: 24,
                cursor: 'pointer',
              }}
            />
          </div>
          <Accordion
            style={{ marginTop: 24 }}
            elevation={0}
            //  classes={{root: classes.accordion}}
          >
            <AccordionSummary
              // classes={{root: classes.accordionSummary}}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography className={classes.statusLabel}>
                Informed Consent URL
              </Typography>
            </AccordionSummary>
            <AccordionDetails
            // classes={{root: classes.accordionDetails}}
            >
              <TextField
                label="Informed Consent URL - English"
                fullWidth
                className={classes.textField}
                style={{ marginTop: 0 }}
                value={informedConsentUrlEnglish}
                onChange={(e) => setInformedConsentUrlEnglish(e.target.value)}
              />
              <TextField
                label="Informed Consent URL - French"
                fullWidth
                className={classes.textField}
                style={{ marginTop: 24 }}
                value={informedConsentUrlFrench}
                onChange={(e) => setInformedConsentUrlFrench(e.target.value)}
              />
              <TextField
                label="Informed Consent URL - Italian"
                fullWidth
                className={classes.textField}
                style={{ marginTop: 24 }}
                value={informedConsentUrlItalian}
                onChange={(e) => setInformedConsentUrlItalian(e.target.value)}
              />
              <TextField
                label="Informed Consent URL - German"
                fullWidth
                className={classes.textField}
                style={{ marginTop: 24 }}
                value={informedConsentUrlGerman}
                onChange={(e) => setInformedConsentUrlGerman(e.target.value)}
              />
              <TextField
                label="Informed Consent URL - Norwegian"
                fullWidth
                className={classes.textField}
                style={{ marginTop: 24 }}
                value={informedConsentUrlNorwegian}
                onChange={(e) => setInformedConsentUrlNorwegian(e.target.value)}
              />
              <TextField
                label="Informed Consent URL - Spanish"
                fullWidth
                className={classes.textField}
                style={{ marginTop: 24 }}
                value={informedConsentUrlSpanish}
                onChange={(e) => setInformedConsentUrlSpanish(e.target.value)}
              />
            </AccordionDetails>
          </Accordion>
          <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
            <Typography className={classes.settingTitle} variant="h2">
              Custom Criteria
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 50 }}>
            <Typography
              className={classes.settingTitle}
              style={{ color: '#4fb4e8', fontSize: 14 }}
              variant="h2"
            >
              INCLUSION
            </Typography>
          </div>
          <Divider />
          <div
            style={{
              minHeight: 70,
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 12,
            }}
          >
            <MedicationsAutocomplete
              updateMedications={setInclusionMedications}
              medications={inclusionMedications}
            />
          </div>
          <div
            style={{
              minHeight: 70,
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 12,
            }}
          >
            <SymptomsAutocomplete
              updateSymptoms={setInclusionSymptoms}
              symptoms={inclusionSymptoms}
            />
          </div>
          <div
            style={{
              minHeight: 70,
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 12,
            }}
          >
            <ProceduresAutocomplete
              updateProcedures={setInclusionProcedures}
              procedures={inclusionProcedures}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 50 }}>
            <Typography
              className={classes.settingTitle}
              style={{ color: '#4fb4e8', fontSize: 14 }}
              variant="h2"
            >
              EXCLUSION
            </Typography>
          </div>
          <Divider />
          <div
            style={{
              minHeight: 70,
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 12,
            }}
          >
            <MedicationsAutocomplete
              updateMedications={setExclusionMedications}
              medications={exclusionMedications}
            />
          </div>
          <div
            style={{
              minHeight: 70,
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 12,
            }}
          >
            <SymptomsAutocomplete
              updateSymptoms={setExclusionSymptoms}
              symptoms={exclusionSymptoms}
            />
          </div>
          <div
            style={{
              minHeight: 70,
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 12,
            }}
          >
            <ProceduresAutocomplete
              updateProcedures={setExclusionProcedures}
              procedures={exclusionProcedures}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
            <Typography className={classes.settingTitle} variant="h2">
              Zendesk Leads
            </Typography>
          </div>
          <div
            style={{
              minHeight: 70,
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 12,
            }}
          >
            <TextField
              label="Disease"
              fullWidth
              value={zendeskDisease}
              onChange={(e) => setZendeskDisease(e.target.value)}
            />
          </div>
          <div
            style={{
              minHeight: 70,
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 12,
            }}
          >
            <DatePickerMobile
              date={zendeskStartDate || ''}
              setDate={(d) => setZendeskStartDate(d)}
              label={'Start date'}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountDetails;
