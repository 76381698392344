import firebase, { storage, analytics } from '../../Firebase';
import axios from 'axios';
import UserService from '../UserService/UserService';
import EmailService from '../EmailService/EmailService';

export default {
  getInvitationById,
  acceptInvitationStatus,
  acceptInvitationStatusTeam,
  getTeamMemberByInvitationId,
  createInvitation,
  getAllEmailOfCompanyTeam,
  updatedSignedBAA,
  getAllCentersOfStudy,
  getTeamMemberByCenterDocument,
  getStudyDataByCenterRef,
  createNewTeamMemberInCenter,
  getAllEmailOfCompanyTeamFromCenterRef,
  createBAAContractFile,
};

const db = firebase.firestore();

async function createNewTeamMemberInCenter(ref, email) {
  return ref
    .collection('team')
    .add({
      email: email,
      role: 'Admin',
      status: 'accepted',
    })
    .then(async (docRef) => {
      return docRef;
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
      return false;
    });
}

async function getStudyDataByCenterRef(ref) {
  let splitedPath = ref.path.split('/');
  let studyData = await db
    .collection(splitedPath[0])
    .doc(splitedPath[1])
    .collection(splitedPath[2])
    .doc(splitedPath[3])
    .get();
  return studyData;
}

async function createInvitation(
  NCTID,
  companyName,
  email,
  studyName,
  teamMemberId,
  type,
  centerName = null
) {
  let data = {
    NCTID: NCTID,
    companyName: companyName,
    email: email,
    status: 'pending',
    studyName: studyName,
    teamMemberId: teamMemberId,
    type: type,
    centerName: '',
  };
  if (centerName) {
    data.centerName = centerName;
  }
  let invitation = await db.collection('invitations').add(data);
  if (type === 'center') {
    EmailService.centerWelcomeMessage(
      email,
      invitation.id,
      companyName,
      studyName
    );
  } else {
    EmailService.studyWelcomeMessage(email, invitation.id, studyName);
  }
  return invitation;
}

async function getAllCentersOfStudy(NCTID) {
  let centers = await db.collectionGroup('centers').get();
  let relevantCenters = [];
  centers.docs.forEach((center) => {
    let centerNCTID = center.ref.parent.parent.id;
    if (centerNCTID === NCTID) {
      relevantCenters.push(center);
    }
  });
  return relevantCenters;
}

async function getTeamMemberByCenterDocument(doc) {
  let team = await doc.ref.collection('team').get();
  return team.docs;
}

async function getTeamMemberByInvitationId(id) {
  let team = await db
    .collectionGroup('team')
    .where('invitationId', '==', id)
    .get();
  let path = team.docs[0].ref.parent.parent.path.split('/');
  let teamMembers = await db
    .collection('companies')
    .doc(path[1])
    .collection('studies')
    .doc(path[3])
    .collection('centers')
    .doc(path[5])
    .collection('team')
    .get();
  return teamMembers.docs;
}

async function getAllEmailOfCompanyTeamFromCenterRef(ref) {
  let splitedPath = ref.path.split('/');
  let teamMembers = (
    await db
      .collection(splitedPath[0])
      .doc(splitedPath[1])
      .collection(splitedPath[2])
      .doc(splitedPath[3])
      .collection('team')
      .get()
  ).docs;
  let emails = [];
  teamMembers.forEach((teamMember) => {
    if (teamMember.data().status === 'accepted') {
      emails.push(teamMember.data().email);
    }
  });
  return emails;
}

async function getAllEmailOfCompanyTeam(id) {
  let team = await db
    .collectionGroup('team')
    .where('invitationId', '==', id)
    .get();
  let path = team.docs[0].ref.parent.parent.path.split('/');
  let emails = [];
  let teamMembers = (
    await db
      .collection('companies')
      .doc(path[1])
      .collection('studies')
      .doc(path[3])
      .collection('team')
      .get()
  ).docs;
  teamMembers.forEach((teamMember) => {
    if (teamMember.data().status === 'accepted') {
      emails.push(teamMember.data().email);
    }
  });
  return emails;
}

async function getInvitationById(id) {
  let invitation = await db.collection('invitations').doc(id).get();
  return invitation;
}

async function acceptInvitationStatusTeam(id, email, type) {
  let invitations = await db
    .collectionGroup('team')
    .where('email', '==', email)
    .get();
  for (let index = 0; index < invitations.docs.length; index++) {
    const invitation = invitations.docs[index];
    if (invitation.id === id) {
      invitation.ref.update({ status: 'accepted' });
    }
  }
  return true;
}

async function updatedSignedBAA(id, email, type) {
  let invitations = await db
    .collectionGroup('team')
    .where('email', '==', email)
    .get();
  for (let index = 0; index < invitations.docs.length; index++) {
    const invitation = invitations.docs[index];
    if (invitation.id === id) {
      let studyPath = invitation.ref.parent.parent.path.split('/');
      await db
        .collection(studyPath[0])
        .doc(studyPath[1])
        .collection(studyPath[2])
        .doc(studyPath[3])
        .collection(studyPath[4])
        .doc(studyPath[5])
        .update({
          signedBAA: true,
        });
    }
  }
  return true;
}

async function acceptInvitationStatus(id) {
  let invitation = await db
    .collection('invitations')
    .doc(id)
    .update({ status: 'accepted' });
  return true;
}

async function createBAAContractFile(
  userId,
  studyCenterName,
  studyCenterUserName,
  date
) {
  try {
    let data = {
      userId,
      studyCenterName,
      studyCenterUserName,
      date,
    };
    axios.post(
      `https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net/createBAAContractFile`,
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (error) {
    throw error;
  }
}
