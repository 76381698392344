import React, { createContext, useState, useEffect, useContext } from 'react';

export const SnackbarContext = createContext();

function SnackbarContextProvider(props) {

    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [type, setType] = React.useState('');
    const [message, setMessage] = React.useState('');

    const show = (type, message) => {
        setType(type);
        setMessage(message)
        setShowSnackbar(true);
    };

    const hide = () => {
        setType('');
        setMessage('')
        setShowSnackbar(false);
    };
    
    return (
        <SnackbarContext.Provider value={{
            show: show,
            hide: hide,
            message: message,
            type: type,
            showSnackbar: showSnackbar
        }}>
            <div>{props.children}</div>
        </SnackbarContext.Provider>
    );
}
 
export default SnackbarContextProvider;
