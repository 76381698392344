import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import StudyCenterItem from './StudyCenterItem';
import StudyService from '../../../Services/StudyService/StudyService';
import AddNewTeamMember from './AddNewTeamMember';
import Skeleton from '@material-ui/lab/Skeleton';
import AddNewStudyCenterPopup from './AddNewStudyCenterPopup';
import { StudyContext } from '../../../contexts/StudyContext';

const useStyles = makeStyles(MuiStyleFunction);

const AccountDetails = ({ data }) => {
  const classes = useStyles();

  const { centers, getCenters, otherCenters, loading } =
    useContext(StudyContext);

  const [team, setTeam] = useState([]);
  const [addNew, setAddNew] = React.useState(false);

  const [openAddNewStudyCenter, setOpenAddNewStudyCenter] =
    React.useState(false);

  useEffect(() => {
    getCenters();
  }, []);

  const getTeam = async () => {
    let team = await StudyService.getTeamByStudyRef(data.document.ref);
    setTeam(team);
    return;
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      {openAddNewStudyCenter && (
        <AddNewStudyCenterPopup
          open={openAddNewStudyCenter}
          setOpenAddNewStudyCenter={setOpenAddNewStudyCenter}
          getCenters={getCenters}
          data={data}
        />
      )}
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
            <Typography className={classes.settingTitle} variant="h2">
              <span className={classes.settingTitleStudiesNumber}>
                {centers.length + otherCenters.length}
              </span>{' '}
              Study Centers
            </Typography>
            {!addNew && (
              <img
                alt=""
                src={require('../../../Assets/Images/addStudyCenter.png')}
                style={{
                  height: 'auto',
                  height: 38,
                  marginTop: 0,
                  cursor: 'pointer',
                }}
                onClick={() => setOpenAddNewStudyCenter(true)}
              />
            )}
          </div>
          <Divider />
          {centers.map((itemData, index) => (
            <StudyCenterItem
              key={index}
              lock={true}
              studyData={data}
              data={itemData}
              getCenters={getCenters}
              type={'exists'}
            />
          ))}
          {otherCenters.map((itemData, index) => (
            <StudyCenterItem
              key={index}
              lock={true}
              studyData={data}
              data={itemData}
              getCenters={getCenters}
              type={'new'}
            />
          ))}
          {loading &&
            [1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                width="100%"
                height="100px"
                style={{ marginTop: 8 }}
              />
            ))}
          <AddNewTeamMember
            studyData={data}
            getTeam={getTeam}
            addNew={addNew}
            setAddNew={setAddNew}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(AccountDetails);
