const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

export default (theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiButton-root': {
      '&:hover': {
        backgroundColor: theme.palette.primary.secondary,
      },
    },
  },
  pageTitle: {
    // fontSize: 40,
    fontSize: mobile ? 26 : 38,
    color: theme.palette.primary.dark,
    textAlign: 'left',
    marginTop: '2%',
  },
  locationProgressLine: {
    marginTop: 12,
    width: '90%',
    height: 32,
    borderRadius: 8,
    display: 'flex',
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontFamily: "'sofia-pro', sans-serif",
    fontSize: 12,
    fontWeight: 'bold',
  },
  locationName: {
    color: '#4A4A4A',
    fontFamily: "'sofia-pro', sans-serif",
    fontSize: 12,
    fontWeight: 'bold',
    opacity: 0.6,
    marginTop: 12,
  },
  locationState: {
    color: '#4A4A4A',
    fontFamily: "'sofia-pro', sans-serif",
    fontSize: 12,
    fontWeight: 'bold',
    opacity: 1,
    marginTop: 4,
  },
  contentTitle: {
    fontSize: mobile ? 22 : 24,
    color: theme.palette.primary.dark,
    textAlign: 'left',
  },
  contentSubTitle: {
    textAlign: 'left',
    color: '#4fb4e8',
    marginTop: 32,
    fontSize: mobile ? 12 : 14,
  },
  backButton: {
    marginTop: 25,
    cursor: 'pointer',
  },
  pageSubTitle: {
    fontSize: 22,
    color: theme.palette.primary.dark,
    textAlign: 'left',
    display: 'inline-block',
    fontWeight: 'normal',
    verticalAlign: 'middle',
    opacity: 0.5,
    marginTop: 25,
  },
  menuButton: {
    color: theme.palette.primary.dark,
    opacity: 0.4,
    textAlign: 'left',
    display: 'inline-block',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: 16,
    verticalAlign: 'middle',
  },
  selectedMenuButton: {
    color: '#4fb4e8',
    textAlign: 'left',
    display: 'inline-block',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: 16,
    verticalAlign: 'middle',
  },
  tableMenuSection: {
    fontSize: 22,
    color: theme.palette.primary.dark,
    textAlign: 'right',
    display: 'inline-block',
    fontWeight: 'normal',
    verticalAlign: 'middle',
    marginTop: 0,
    float: 'right',
  },
  iconBackground: {
    display: 'inline-block',
    width: 40,
    height: 40,
    borderRadius: '50%',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #4fb4e8',
  },
  icon: {
    color: theme.palette.primary.main,
    height: 20,
    width: 20,
    marginLeft: 10,
    marginTop: 10,
  },
  nctIcon: {
    color: theme.palette.primary.main,
    height: 'auto',
    width: 30,
    marginLeft: 5,
    marginTop: 14,
  },
  iconTitle: {
    marginTop: 24,
    fontSize: 12,
    color: theme.palette.primary.main,
    // fontFamily: 'arial',
    textAlign: 'left',
  },
  iconSubTitle: {
    fontSize: 18,
    color: theme.palette.primary.gray,
    marginTop: 2,
    textAlign: 'left',
  },
  contect: {
    fontSize: mobile ? 17 : 18,
    color: ' #31313',
  },
  sectionTitle: {
    fontSize: 14,
    color: '#4fb4e8',
    textAlign: 'left',
    marginBottom: 12,
    letterSpacing: '2.92px',
    textTransform: 'uppercase',
  },
  mobileTitle: {
    fontSize: 22,
    color: theme.palette.primary.dark,
    paddingBottom: 24,
  },
  mobileApply: {
    height: 48,
    borderRadius: 12,
    backgroundColor: '#4fb4e8',
    color: 'white',
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
    textTransform: 'none',
    display: 'inline-block',
    width: 'calc(100% - 24px)',
    fontSize: 16,
    left: 12,
    position: 'fixed',
    bottom: 74,
    zIndex: 99999999999,
  },
  noAppointmentsTitle: {
    textAlign: 'center',
    color: '#B0B0B0',
    fontSize: mobile ? 15 : 17,
    fontWeight: 600,
    paddingTop: 22,
  },
  noAppointmentsBox: {
    width: '100%',
    height: 64,
    backgroundColor: '#F4F4F4',
    borderRadius: 10,
    marginTop: 24,
  },
  apply: {
    height: 54,
    borderRadius: 10,
    backgroundColor: '#4fb4e8',
    color: 'white',
    fontWeight: 'bold',
    marginTop: 20,
    textTransform: 'none',
    display: 'inline-block',
    width: '100%',
    fontSize: 16,
    '&:hover': {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  top5Title: {
    fontFamily: theme.typography.main,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: 14,
    marginTop: 24,
    marginBottom: 12,
  },
  top5Label: {
    fontFamily: theme.typography.main,
    color: '#4A4A4A',
    fontWeight: 600,
    fontSize: 12,
    overflowWrap: 'anywhere',
    // marginTop: 12,
  },
  top5Value: {
    fontFamily: theme.typography.main,
    color: '#4A4A4A60',
    fontWeight: 600,
    fontSize: 12,
    // marginTop: 12,
  },
  number: {
    fontSize: 48,
    fontWeight: 'bold',
    color: 'rgb(75,75,75)',
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 900,
  },
  colorNumber: {
    fontSize: 64,
    color: '#4fb4e8',
    fontWeight: 'bold',
    textAlign: 'center',
    fontWeight: 900,
  },
  numberDescription: {
    fontSize: 16,
    color: 'white',
    color: theme.palette.primary.dark,
    opacity: 1,
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 500,
  },
  whiteNumber: {
    fontSize: 64,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontWeight: 900,
  },
  whiteNumberDescription: {
    fontSize: 16,
    color: '#fff',
    opacity: 1,
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 500,
  },
  boxTitle: {
    fontSize: 16,
  },
  chip: {
    height: 33,
    borderRadius: '16.5',
    backgroundColor: '#4fb4e830',
    color: '#4fb4e8',
    fontFamily: "'sofia-pro', sans-serif",
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'upper',
    paddingLeft: 24,
    paddingRight: 24,
    marginRight: 14,
    marginTop: 16,
  },
  appointmentsBox: {
    width: '100%',
    height: 64,
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    borderRadius: 10,
    padding: 8,
    marginTop: 24,
    cursor: 'pointer',
  },
  timeBox: {
    backgroundColor: '#4fb4e8',
    borderRadius: 8,
    textAlign: 'center',
    color: 'white',
    width: 86,
    marginTop: 9,
    float: 'right',
    padding: 8,
    fontSize: 14,
  },
  appointmentTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 4,
  },
  appointmentSubTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    opacity: 0.5,
    marginTop: 4,
  },
});
