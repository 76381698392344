

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

export default (theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiBottomNavigationAction-label': {
          fontFamily: 'arial',
          color: 'blue',
          fontSize: 30
        },
        '& .MuiBottomNavigationAction-root': {
          fontFamily: 'arial',
          color: 'blue',
          fontSize: 30
        },
        '& .MuiBottomNavigation-root': {
          fontFamily: 'arial',
          fontSize: 30
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
      profileButton: {
        marginLeft: 35,
        // marginRight: 17.5,
        marginTop: 18,
        width: 50, height: 50
      },
      logoutButton: {
        // marginLeft: 35,
        marginRight: 17.5,
        marginTop: 18,
        width: 50, height: 50
      },
      profileButtonMobile: {
        width: 50, height: 50,
        position: 'fixed',
        top: 10,
        right: 15
      },
      menuButton: {
        textAlign: 'left',
        fontFamily: "'sofia-pro', sans-serif",
        fontSize: 17,
        marginTop: 10,
        cursor: 'pointer',
        marginBottom: 10
      },
      tabs: {
        fontFamily: 'arial',
        '&:selected': {
          fontFamily: 'arial'
        },
    },
});