import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import React, { useState } from 'react';
import AddNewRecordDialog from './AddNewRecordDialog';
import {default as MButton} from '@material-ui/core/Button';
import { Popover, Button } from 'antd';

const useStyles = makeStyles((theme) => ({
    grayIcon: {
        color: '#4A4A4A60'
    },
    coloredIcon: {
        color: theme.palette.primary.main
    }
}))

const RecordComponent = ({records}) => {

    const classes = useStyles()
    const [openUploadDialog, setOpenUploadDialog] = React.useState(false)

    return (
        <>
            <AddNewRecordDialog open={openUploadDialog} setOpen={setOpenUploadDialog} study={records.study} application={records.application} />
            {records.links.length === 0 ? (
                <IconButton onClick={()=> setOpenUploadDialog(true)}>
                    <InsertDriveFileIcon className={classes.grayIcon} />
                </IconButton>
            ) : (
                <Popover placement="top" style={{borderRadius: 10}}
                    content={()=> (
                        <div style={{color: '#4A4A4A', display: 'flex', gap:12, fontSize: 13, fontWeight: 'normal', letterSpacing: 0.24, fontFamily: "'sofia-pro', sans-serif", maxWidth: 340}}>
                            <MButton style={{marginTop: 12, textTransform: 'none'}} onClick={()=> window.open(records.links[0], '_blank')}>Open</MButton>
                            <MButton style={{marginTop: 12, textTransform: 'none'}} onClick={()=> setOpenUploadDialog(true)}>Replace</MButton>
                        </div>
                    )} trigger="click">
                    <IconButton>
                        <InsertDriveFileIcon className={classes.coloredIcon} />
                    </IconButton>
                </Popover>
            )}
        </>
    );
}
 
export default RecordComponent;