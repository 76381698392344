import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Button as AntButton } from 'antd';
import {
  CircularProgress,
  IconButton,
  Input,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import { shareApplications } from '../../Services/ApplicationService/ApplicationService';
import { SnackbarContext } from '../../contexts/Snackbar';
import { validateEmail } from '../../utils/validators';
import { FileCopyRounded } from '@material-ui/icons';
import { default as MButton } from '@material-ui/core/Button';

export default function ShareApplicationsDialog({ applications }) {
  const { show } = React.useContext(SnackbarContext);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [expiration, setExpiration] = React.useState('onetime');
  const [email, setEmail] = React.useState('');
  const [center, setCenter] = React.useState('');
  const [centers, setCenters] = React.useState('');
  const [audience, setAudience] = React.useState('all');
  const [sharableLinkId, setSharableLinkId] = React.useState(null);

  React.useEffect(() => {
    let tempCenters = applications.map((a) => a.studyLocation);
    let uniqueCenters = [...new Set(tempCenters)];
    setCenters(uniqueCenters);
  }, [applications]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const share = async () => {
    setLoading(true);
    let applicationsToShare = applications;
    if (audience === 'center') {
      applicationsToShare = applications.filter(
        (application) => application.studyLocation === center
      );
    }
    for (let index = 0; index < applicationsToShare.length; index++) {
      const a = applicationsToShare[index];
      delete a.doc;
    }
    shareApplications({
      data: applicationsToShare,
      email,
      expiration,
      audience,
    })
      .catch((e) => {
        show('error', e.toString());
      })
      .then((id) => {
        console.log('id', id);
        setSharableLinkId(id);
        show('success', 'Sharable link created');
      })
      .finally(() => setLoading(false));
  };

  const copyLinkToClipboard = () => {
    let url = `https://connect.knowrare.com/sharable-link/${sharableLinkId}`;
    navigator.clipboard.writeText(url);
    show('success', 'Link copied');
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          paddingLeft: 12,
        }}
      >
        <MButton
          style={{
            width: 76,
            marginTop: 16,
            height: 38,
            borderRadius: 8,
            backgroundColor: '#4fb4e830',
            color: '#4fb4e8',
            fontSize: 14,
            fontWeight: 'bold',
            textTransform: 'none',
          }}
          onClick={() => setOpen(true)}
        >
          Share
        </MButton>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Share applications</DialogTitle>
        {sharableLinkId ? (
          <DialogContent>
            <DialogContentText>Shable link created</DialogContentText>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <DialogContentText>
                {`https://connect.knowrare.com/sharable-link/${sharableLinkId}`}
              </DialogContentText>
              <IconButton onClick={copyLinkToClipboard}>
                <FileCopyRounded />
              </IconButton>
            </Stack>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText>
              Share applications with an exteranal user
            </DialogContentText>
            <Stack
              direction={'column'}
              gap={2}
              spacing={1}
              sx={{ mt: 3, mb: 3 }}
            >
              <TextField
                onChange={(e) => setEmail(e.target.value)}
                type='email'
                variant='outlined'
                label={'Email'}
                value={email}
                fullWidth
              ></TextField>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Expiration
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={expiration}
                  label='Expiration'
                  onChange={(e) => setExpiration(e.target.value)}
                >
                  <MenuItem value='onetime'>After one time</MenuItem>
                  <MenuItem value='1day'>After one day</MenuItem>
                  <MenuItem value='1week'>After one week</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  All / By Center / Client
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={audience}
                  label='All / By Center'
                  onChange={(e) => setAudience(e.target.value)}
                >
                  <MenuItem value='all'>All leads</MenuItem>
                  <MenuItem value='center'>Specific center</MenuItem>
                  <MenuItem value='client'>Client</MenuItem>
                </Select>
              </FormControl>
              {audience === 'center' && (
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Center</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={center}
                    label='Center'
                    onChange={(e) => setCenter(e.target.value)}
                  >
                    {centers.map((_center) => (
                      <MenuItem key={_center} value={_center}>
                        {_center}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Stack>
          </DialogContent>
        )}
        <DialogActions>
          {!sharableLinkId && (
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant='contained'
                  onClick={share}
                  disabled={!validateEmail(email)}
                >
                  Share
                </Button>
              )}
            </>
          )}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
