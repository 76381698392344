import React, { useContext, useEffect } from 'react';
import { Route, Navigate, Outlet, Routes, useNavigate } from 'react-router-dom';
import Loading from './Components/Loading/Loading';
import { AuthContext } from './contexts/Auth';

import SignIn from './Pages/Login/SignIn/SignIn';
import SignUp from './Pages/Login/SignIn/Signup';
import Auth from './Pages/Login/Auth';
import './App.css';
import Snackbar from './Components/Snackbar/Snackbar';
import PageExplorerAlert from './Pages/PageExplorerAlert';
import SharableLinkContainer from './Pages/App/SharableLink/SharableLinkContainer';
import HomeLayout from './Pages/App/Home/HomeLayout';
import Sponsored from './Pages/App/Sponsored/Sponsored';
import Basic from './Pages/App/Basic/Basic';
import Studies from './Pages/App/Client/Studies';
import StudyDashboardMobile from './Pages/App/Study/StudyDashboard/StudyDashboardMobile';
import StudyDashboard from './Pages/App/Study/StudyDashboard/StudyDashboard';
import Widget from './Pages/Widget/Widget';
import firebase from './Firebase';
import UserService from './Services/UserService/UserService';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

function App() {
  const {
    isAuthenticated,
    userData,
    setAuthUser,
    setUserData,
    setUserType,
    setIsAuthenticated,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      const accountInfo = !!user ? await UserService.getUser() : null;
      if (accountInfo) {
        setAuthUser(user);
        if (accountInfo.userData.type) {
          setUserData(accountInfo.userData);
          setUserType(accountInfo.userType);
        } else {
          setUserData({ ...accountInfo.userData, type: 'center' });
          setUserType('center');
        }
        setIsAuthenticated(true);
      } else {
        setAuthUser(user);
        setUserData(null);
        setUserType(null);
        setIsAuthenticated(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (window.location.href.includes('/login?goto=')) {
        var path = window.location.href;
        navigate(path.split('/login?goto=')[1]);
      } else {
        if (
          !window.location.pathname.includes('/home') &&
          !window.location.pathname.includes('/sharable-link')
        ) {
          navigate('/home/sponsored');
        }
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userData) {
      window.Intercom('boot', {
        app_id: 'wzfzn9ui',
        name: `${userData.firstName} ${userData.lastName}`,
        email: userData.email,
      });
      window.Intercom('update');
    }
  }, [userData]);

  const isIE = () => {
    const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); //IE 11

    return msie > 0 || trident > 0;
  };

  useEffect(() => {
    if (isIE()) {
      navigate('/explorer-alert');
    }
  }, []);

  const ProtectedRoute = ({ isAuthenticated }) => {
    if (isAuthenticated !== true) {
      const params = [
        '?goto=',
        window.location.pathname,
        window.location.search,
      ]
        .filter((a) => !!a)
        .filter((_, __, arr) => arr.length > 1)
        .join('');
      return <Navigate to={'/login' + params} replace />;
    }

    return <Outlet />;
  };

  return (
    <div
      className="App"
      style={{
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      <Snackbar />
      {isAuthenticated === null ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/_/auth/*" element={<Auth />} />
          <Route
            path="/home"
            element={<ProtectedRoute isAuthenticated={isAuthenticated} />}
          >
            <Route element={<HomeLayout />}>
              <Route path="sponsored" element={<Sponsored />} />
              <Route path="basic" element={<Basic />} />
              <Route path="studies" element={<Studies />} />
              <Route
                path="study/:id"
                element={mobile ? <StudyDashboardMobile /> : <StudyDashboard />}
              />
              <Route path="widget" element={<Widget />} />
              <Route path="*" element={<Navigate to="home/sponsored" />} />
              <Route path="" element={<Navigate to="home/sponsored" />} />
            </Route>
          </Route>
          <Route path="invitation/:id" element={<SignUp />} />
          <Route path="sharable-link/:id" element={<SharableLinkContainer />} />
          <Route path="explorer-alert" element={<PageExplorerAlert />} />
          <Route path="*" element={<Navigate to="/home/sponsored" />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
