import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';

import firebase from '../../../../Firebase.js';
import StudyService from '../../../../Services/StudyService/StudyService';
import UserService from '../../../../Services/UserService/UserService';
import EmailService from '../../../../Services/EmailService/EmailService';

import { StudiesContext } from '../../../../contexts/Studies';
import { SnackbarContext } from '../../../../contexts/Snackbar';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.typography.main,
    fontSize: 26,
    color: '#4A4A4A',
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 12,
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
    width: '100%',
  },
  text: {
    fontFamily: theme.typography.main,
    fontSize: 18,
    textAlign: 'center',
    color: '#4A4A4A',
  },
  reschedule: {
    width: '100%',
    borderRadius: 10,
    background: '#E7E6E6',
    color: '#4A4A4A',
    fontWeight: 'bold',
    fontSize: 14,
    height: 44,
    textTransform: 'none',
    marginTop: 70,
  },
  meetingLink: {
    marginTop: 12,
    height: 44,
    fontWeight: 'bold',
    width: '50%',
    borderRadius: 10,
    fontSize: 14,
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  cancel: {
    width: '100%',
    borderRadius: 10,
    background: 'transparent',
    color: '#4fb4e8',
    fontWeight: 'bold',
    fontSize: 14,
    height: 44,
    textTransform: 'none',
    marginTop: 12,
  },
  container: {
    position: 'relative',
    padding: 24,
  },
  closeIcon: {
    right: 24,
    top: 24,
    width: 28,
    height: 28,
    position: 'absolute',
  },
}));

export default function MaxWidthDialog({
  open,
  setOpen,
  patientName,
  date,
  time,
  application,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { studies, getStudies } = useContext(StudiesContext);

  const { show } = useContext(SnackbarContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleReschedule = async () => {
    setLoading(true);
    let applicationData = application;
    var status = applicationData.status;
    status.push({
      status: 'Different Date Needed',
      datetime: firebase.firestore.Timestamp.now(),
    });
    var dataToUpdate = { status: status };
    // console.log('dataToUpdate',dataToUpdate)
    const response = await StudyService.updateApplicationByDoc(
      applicationData.doc.ref,
      dataToUpdate,
    );
    await getStudies();
    setLoading(false);
    navigate('/home/schedule/' + applicationData.doc.id);
    // EmailService.patientIneligible(
    //   applicationData.userData.email,
    //   applicationData.userData.firstName,
    //   sponsoredTitle !== null ? sponsoredTitle : study.ProtocolSection.IdentificationModule.BriefTitle,
    //   applicationData.doc.id
    // )
  };

  const copyPatientMeetingLink = () => {
    let link = `https://app.knowrare.com/meeting/${application.doc.id}`;
    navigator.clipboard.writeText(link);
    handleClose();
    show('success', 'Patient meeting link copied to clipboard');
  };

  const copyStudyMeetingLink = () => {
    let link = `https://connect.knowrare.com/home/meeting/${application.doc.id}`;
    navigator.clipboard.writeText(link);
    handleClose();
    show('success', 'Study meeting link copied to clipboard');
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div className={classes.container}>
          <Typography className={classes.title}>Your visit with</Typography>
          <Typography className={classes.title} style={{ marginTop: 0 }}>
            {patientName}
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.text}>
            You have a virtual visit at
          </Typography>
          <Typography className={classes.text}>
            <b>{time}</b>, on <b>{date}</b>
          </Typography>
          <Button
            className={classes.reschedule}
            onClick={handleReschedule}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              'Reschedule'
            )}
          </Button>
          <div style={{ width: '100%', display: 'flex' }}>
            <Button
              className={classes.meetingLink}
              onClick={copyPatientMeetingLink}
              disabled={loading}
            >
              Patient Meeting Link
            </Button>
            <Button
              className={classes.meetingLink}
              onClick={copyStudyMeetingLink}
              disabled={loading}
            >
              Study Meeting Link
            </Button>
          </div>
          {/* <Button className={classes.cancel}>
                Cancel Appointment
            </Button> */}
        </div>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <img
            alt=""
            src={require('../../../../Assets/Images/grayXIcon.png')}
            height="14px"
            width="14px"
          />
        </IconButton>
      </Dialog>
    </React.Fragment>
  );
}
