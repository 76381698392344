import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import MuiStyleFunction from './Card.style';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useNavigate } from 'react-router-dom';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

const Status = ({ data, updateStudies }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const select = (ref, data) => {
    updateStudies(ref, data);
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
        }}
        className={
          data.studyData.status === 'Live'
            ? classes.liveStatusBox
            : data.studyData.status === 'Done'
            ? classes.doneStatusBox
            : classes.pausedStatusBox
        }
        onClick={handleClick}
      >
        <Typography variant="h4" className={classes.status}>
          {data.studyData.status}
        </Typography>
        <img
          alt=""
          className={classes.status}
          src={require('../../../Assets/Images/whiteArrowDown.png')}
          width={8.41}
          height={9.13}
          style={{ marginLeft: 5 }}
        />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => select(data.document.ref, { status: 'Live' })}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
            className={classes.liveStatusBox}
            onClick={handleClick}
          >
            <Typography variant="h4" className={classes.status}>
              Live
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => select(data.document.ref, { status: 'Paused' })}
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
            className={classes.pausedStatusBox}
            onClick={handleClick}
          >
            <Typography variant="h4" className={classes.status}>
              Paused
            </Typography>
          </div>
        </MenuItem>
        <MenuItem onClick={() => select(data.document.ref, { status: 'Done' })}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
            className={classes.doneStatusBox}
            onClick={handleClick}
          >
            <Typography variant="h4" className={classes.status}>
              Done
            </Typography>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

const Card = ({ data, updateStudies }) => {
  const navigate = useNavigate();

  const classes = useStyles();

  const shortcutText = (text, max) => {
    if (text[0].length > max) {
      var res = text[0].substring(0, max);
      return res + '...';
    } else {
      return text[0];
    }
  };

  const navigateToStudy = () => {
    navigate('/home/study/' + data.document.id);
  };

  return (
    <Grid item xs={12} sm={4} md={4} xl={3}>
      <div className={classes.clinical} style={{ position: 'relative' }}>
        <div
          style={{
            paddingTop: 22,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 22,
          }}
        >
          {/* <IconButton style={{position: 'absolute', top: 18, right: 12}}><img alt='' style={{width: 16, height: 16}} src={require('../../../Assets/Images/verticleMenu.png')}/></IconButton> */}
          <Typography variant="h4" className={classes.nctid}>
            {data.document.id}
          </Typography>
          <Typography variant="h4" className={classes.CardTitle}>
            {data.studyData.customTitle}
          </Typography>
          <Typography variant="h4" className={classes.CardSubTitle}>
            {data.companyName}
          </Typography>
          <Divider style={{ marginTop: 12 }} />
          <div style={{ width: '100%' }}>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                display: 'inline-block',
                paddingBottom: 24,
              }}
            >
              <Typography variant="h4" className={classes.number}>
                {data.applicants}
              </Typography>
              <Typography variant="h4" className={classes.numberDescription}>
                Applications
              </Typography>
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                display: 'inline-block',
                borderLeft: '1px solid #E0E0E0',
                paddingBottom: 24,
              }}
            >
              <Typography variant="h4" className={classes.colorNumber}>
                {data.completed}
              </Typography>
              <Typography variant="h4" className={classes.numberDescription}>
                Completed
              </Typography>
            </div>
          </div>
          <Divider />
          <div
            style={{
              height: 54,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" className={classes.statusLabel}>
              Status
            </Typography>
            <Status data={data} updateStudies={updateStudies} />
          </div>
          <Divider />
          <Button onClick={() => navigateToStudy()} className={classes.apply}>
            View Dashboard
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default Card;
