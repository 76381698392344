import React, { useContext, useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import '../../../../node_modules/antd/dist/antd.css';
import { Table, Tag, Space } from 'antd';
import { Popover, Button } from 'antd';
import { Box, IconButton } from '@material-ui/core';
import moment from 'moment';
import Status from './Status';
import Centers from './Centers';
import FilterDrawer from './FilterDrawer';
import { FilterContext } from '../../../contexts/Filter';
import { Typography } from '@material-ui/core';
import EditSymptomsDialog from './Leads/EditSymptomsDialog.js';
import EditMedicationsDialog from './Leads/EditMedicationsDialog.js';
import EditProceduresDialog from './Leads/EditProceduresDialog.js';
import { default as MButton } from '@material-ui/core/Button';
import RecordComponent from '../Components/RecordComponent/RecordComponent';
import ShareApplicationDialog from '../../../Components/Dialogs/ShareApplicationDialog';
import ShareApplicationsDialog from '../../../Components/Dialogs/ShareApplicationsDialog';
import { Stack, Tooltip } from '@mui/material';
import {
  CloudUpload,
  Edit,
  FileCopyRounded,
  InsertDriveFile,
  InsertDriveFileRounded,
} from '@material-ui/icons';
import EditZendeskId from './Leads/EditZendeskId';
import Stage from './Stage';
import { StudyContext } from '../../../contexts/StudyContext';
import { FileUpload, UploadFileRounded } from '@mui/icons-material';
import UploadFile from './UploadFile.js';

const { Column, ColumnGroup } = Table;

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "'sofia-pro', sans-serif",
  },
  mobileTitle: {
    fontSize: 22,
    color: theme.palette.primary.dark,
    paddingBottom: 24,
  },
  searchInput: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    maxWidth: 350,
    marginBottom: 16,
    height: 38,
    borderRadius: 10,
    color: '#4A4A4A',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: theme.typography.main,
    border: 'none',
    paddingRight: 8,
    paddingLeft: 8,
    // marginTop: 6,
    paddingRight: 32,
    marginTop: 16,
  },
  filterButton: {
    width: 76,
    marginTop: 16,
    height: 38,
    borderRadius: 8,
    backgroundColor: '#4fb4e830',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.main,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none',
  },
}));

const StudyTable = (props) => {
  const classes = useStyle();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [applicationsToShow, setApplicationsToShow] = useState([]);
  const [search, setSearch] = useState('');
  const [openEditSymptomsDialog, setOpenEditSymptomsDialog] = useState(false);
  const [openEditMedicationsDialog, setOpenEditMedicationsDialog] =
    useState(false);
  const [openEditProceduresDialog, setOpenEditProceduresDialog] =
    useState(false);
  const [applicationInEdit, setApplicationInEdit] = useState(null);

  const [loadingTable, setLoadingTable] = useState(false);

  const { openFilter, setOpenFilter } = useContext(FilterContext);
  const { nctId } = useContext(StudyContext);

  const renameStatus = (status) => {
    if (status === 'Applied') {
      return 'Applied';
    } else if (status === 'New') {
      return 'New';
    } else if (status === 'Viewed') {
      return 'Viewed';
    } else if (status === 'Schedule Appointment') {
      return 'Applied';
    } else if (status === 'Confirmed Appointment') {
      return 'Scheduled';
    } else if (status === 'Completed') {
      return 'Completed';
    } else if (status === 'Not Eligible') {
      return 'Failed';
    } else if (status === 'Pending') {
      return 'Pending';
    } else if (status === 'TBD') {
      return 'Post Call';
    } else {
      return 'Applied';
    }
  };

  const getAgeFromDate = (date) => {
    // if (nctId === 'NCT05291091') return 'N/A';
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    return moment().diff(formattedDate, 'years', false);
  };

  const [columns, setColumns] = useState([
    {
      title: 'Date',
      dataIndex: 'appliedAt',
      width: 130,
      fixed: mobile ? 'null' : 'left',
      sorter: {
        compare: (a, b) => a.appliedAt.seconds - b.appliedAt.seconds,
      },
      render: (result) => (
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#4A4A4A',
          }}
        >
          {result.format}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: mobile ? 'null' : 'left',
      width: 200,
      sorter: {
        compare: (a, b) => a.status.status.length - b.status.status.length,
      },
      render: (status) => (
        <>
          <Status
            status={status.status}
            application={status.application}
            getStyleForStatus={getStyleForStatus}
            renameStatus={renameStatus}
          />
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
      fixed: mobile ? 'null' : 'left',
      render: (name) => (
        <>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {name.name}
          </div>
          <Popover
            placement="top"
            style={{ borderRadius: 10 }}
            title={() => (
              <div
                style={{
                  color: '#4fb4e8',
                  fontSize: 11,
                  fontWeight: 900,
                  letterSpacing: 1,
                  fontFamily: "'sofia-pro', sans-serif",
                }}
              >
                Patient ID
              </div>
            )}
            content={() => (
              <div
                style={{
                  color: '#4A4A4A',
                  fontSize: 13,
                  fontWeight: 'normal',
                  letterSpacing: 0.24,
                  fontFamily: "'sofia-pro', sans-serif",
                  maxWidth: 340,
                }}
              >
                {name.id}
              </div>
            )}
            trigger="hover"
          >
            <Button
              style={{
                border: 'none',
                padding: 0,
                backgroundColor: 'none',
                background: 'none',
                width: 24,
                height: 24 * 1.125,
                marginLeft: 4,
                display: 'inline-block',
                verticalAlign: 'middle',
                marginTop: 0,
              }}
            >
              <img
                alt=""
                src={require('../../../Assets/Images/infoIcon.png')}
                style={{ width: 14, height: 14 * 1.125 }}
              />
            </Button>
          </Popover>
        </>
      ),
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      width: 200,
      sorter: {
        compare: (a, b) => a.stage.stage.length - b.stage.stage.length,
      },
      render: (data) => (
        <>
          <Stage
            stage={data.stage}
            application={data.application}
            getStyleForStatus={getStyleForStatus}
            renameStatus={renameStatus}
          />
        </>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 160,
    },
    {
      title: 'Study Center',
      dataIndex: 'studyCenter',
      width: 250,
      render: (center) => (
        <>
          <Centers
            study={props.study}
            center={center.center}
            application={center.application}
            renameStatus={renameStatus}
          />
        </>
      ),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      width: 100,
      sorter: {
        compare: (a, b) => a.gender.length - b.gender.length,
      },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      width: 100,
      sorter: {
        compare: (a, b) => parseInt(a.age) - parseInt(b.age),
      },
    },
    {
      title: 'Diagnosed',
      dataIndex: 'diagnosed',
      width: 120,
      render: (result) => (
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#4A4A4A',
          }}
        >
          {result.diagnosed ? 'Yes' : 'No'}
        </div>
      ),
    },
    {
      title: 'Symptoms',
      dataIndex: 'symptoms',
      width: 120,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.symptoms.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    SYMPTOMS
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.symptoms.map((symptom, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {symptom}
                      </div>
                    ))}
                    <MButton
                      style={{ marginTop: 12, textTransform: 'none' }}
                      onClick={() => {
                        setOpenEditSymptomsDialog(true);
                        setApplicationInEdit(result.application);
                      }}
                    >
                      Edit
                    </MButton>
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Medications',
      dataIndex: 'medications',
      width: 120,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.medications.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    MEDICATIONS
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.medications.map((medication, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {medication}
                      </div>
                    ))}
                    <MButton
                      style={{ marginTop: 12, textTransform: 'none' }}
                      onClick={() => {
                        setOpenEditMedicationsDialog(true);
                        setApplicationInEdit(result.application);
                      }}
                    >
                      Edit
                    </MButton>
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Zendesk ID',
      dataIndex: 'zendeskId',
      width: 260,
      render: (value) => (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <div>{value.application?.zendeskId || 'N/A'}</div>
          <EditZendeskId application={value.application} study={value.study} />
        </Stack>
      ),
    },
    // {
    //     title: 'Email',
    //     dataIndex: 'email',
    //     width: 220,
    // },
    // {
    //     title: 'Phone',
    //     width: 160,
    //     dataIndex: 'phone',
    // },
    // {
    //     title: 'Procedures',
    //     dataIndex: 'procedures',
    //     width: 120,
    //     render: result => (
    //         <>
    //             {result.length === 0 ? (
    //                 <div style={{fontStyle: 'italic', color: 'gray'}}>None</div>
    //             ) : (
    //                 <>
    //                 <div style={{display: 'inline-block', verticalAlign: 'middle', color: '#4fb4e8'}}>{result.length}</div>
    //                     <Popover placement="top" style={{borderRadius: 10}}
    //                             title={()=> (
    //                                 <div style={{color: '#4fb4e8', fontSize: 11, fontWeight: 900, letterSpacing: 1, fontFamily: "'sofia-pro', sans-serif"}}>
    //                                     Procedures
    //                                 </div>
    //                             )}
    //                             content={()=> (
    //                                 <div style={{color: '#4A4A4A', fontSize: 13, fontWeight: 'normal', letterSpacing: 0.24, fontFamily: "'sofia-pro', sans-serif", maxWidth: 340}}>
    //                                     {result.map((procedure, index) => (
    //                                         <div key={index} style={{width: '100%', padding: 6}}>
    //                                             {procedure}
    //                                         </div>
    //                                     ))}
    //                                 </div>
    //                             )} trigger="hover">
    //                         <Button style={{border: 'none', padding: 0, backgroundColor: 'none', background: 'none', width: 24, height: 24 * 1.125, marginLeft: 4, display: 'inline-block', verticalAlign: 'middle', marginTop: 0 }}>
    //                             {/* <IconButton style={{display: 'inline-block', verticalAlign: 'middle', width: 24, height: 24 * 1.125, marginTop: 4, marginLeft: 4}}> */}
    //                                 <img alt='' src={require('../../../Assets/Images/infoIcon.png')} style={{width: 14, height: 14 * 1.125}}/>
    //                             {/* </IconButton> */}
    //                         </Button>
    //                     </Popover>
    //                 </>
    //             )}
    //         </>
    //       ),
    // },
    {
      title: 'Procedures',
      dataIndex: 'procedures',
      width: 160,
      render: (result) => (
        <>
          {result.length === 0 ? (
            <div style={{ fontStyle: 'italic', color: 'gray' }}>None</div>
          ) : (
            <>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  color: '#4fb4e8',
                }}
              >
                {result.procedures.length}
              </div>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    PROCEDURES
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.procedures.map((procedure, index) => (
                      <div key={index} style={{ width: '100%', padding: 6 }}>
                        {procedure}
                      </div>
                    ))}
                    <MButton
                      style={{ marginTop: 12, textTransform: 'none' }}
                      onClick={() => {
                        setOpenEditProceduresDialog(true);
                        setApplicationInEdit(result.application);
                      }}
                    >
                      Edit
                    </MButton>
                  </div>
                )}
                trigger="hover"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24 * 1.125,
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: 0,
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../Assets/Images/infoIcon.png')}
                    style={{ width: 14, height: 14 * 1.125 }}
                  />
                </Button>
              </Popover>
            </>
          )}
        </>
      ),
    },
    // {
    //     title: 'Travel Assist',
    //     dataIndex: 'travelAssist',
    //     width: 130,
    // },
    // {
    //     title: 'Other Trials',
    //     dataIndex: 'otherTrials',
    //     width: 130,
    // },
    // {
    //   title: 'ICF Reviewed',
    //   dataIndex: 'icfReviewed',
    //   width: 170,
    // },
    {
      title: 'Notes',
      dataIndex: 'notes',
      width: 170,
      render: (result) => (
        <>
          {result === undefined || result === null || result === '' ? (
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              {'Not Added'}
            </div>
          ) : (
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <Popover
                placement="top"
                style={{ borderRadius: 10 }}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'sofia-pro', sans-serif",
                    }}
                  >
                    NOTE
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'sofia-pro', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result}
                  </div>
                )}
                trigger="click"
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24,
                    marginTop: 0,
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../Assets/Images/blackNoteIcon.png')}
                    width={24}
                    height={24}
                  />
                </Button>
              </Popover>
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Records',
      dataIndex: 'files',
      width: 170,
      render: (result) => (
        <>
          {result && result.files.length > 0 ? (
            <Box sx={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <Tooltip title="Download Records">
                <IconButton type="link" href={result.files[0]} target="_blank">
                  <InsertDriveFile />
                </IconButton>
              </Tooltip>
              <UploadFile
                application={result?.application}
                study={props.study}
              />
            </Box>
          ) : (
            <Stack>
              <UploadFile
                application={result?.application}
                study={props.study}
              />
            </Stack>
          )}
        </>
      ),
    },
    {
      title: 'Share',
      dataIndex: 'share',
      width: 170,
      render: (application) => (
        <>
          <ShareApplicationDialog application={application} />
        </>
      ),
    },
  ]);

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return str;
  };

  useEffect(() => {
    let study = props.study;
    let applications = study.applications;
    setApplicationsToShow(applications);
  }, [props.study]);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < applicationsToShow.length; index++) {
      const application = applicationsToShow[index];
      data.push({
        key: index + 1,
        appliedAt: {
          format: moment(application.appliedAt.seconds * 1000).format('ll'),
          seconds: application.appliedAt.seconds,
        },
        patientId: application.userId,
        files: { files: application.files || [], application: application },
        zendeskId: { application, study: props.study },
        age: getAgeFromDate(application.userData.birthDate),
        ethnicity: application.userData.ethnicity,
        name: {
          name:
            application.userData.firstName +
            ' ' +
            application.userData.lastName,
          id: application.userData.firebaseId.substring(0, 9),
        },
        email: application.userData.email,
        phone: formatPhoneNumber(application.userData.phone),
        gender: application.userData.gender,
        location: application.userData.locationData.description,
        // location: application.nearestLocationData.locationData.address_components[0].long_name + ', ' +  application.nearestLocationData.locationData.address_components[0].short_name,
        diagnosed: {
          diagnosed: application.userData.diagnosedByDoctor,
          diagnosedAge: application.userData.diagnosedAge,
        },
        diagnosedAge: application.userData.diagnosedAge,
        studyCenter: {
          center: application.studyLocation,
          application: application,
        },
        status: {
          status: application.status[application.status.length - 1].status,
          application: application,
        },
        stage: {
          stage: application.stage || 'New',
          application: application,
        },
        // note: { status: application.status[application.status.length - 1] },
        notes: application?.notes,
        // note: application.status[application.status.length - 1].note,
        visitResults: application.exitSurvey
          ? application.exitSurvey
          : 'Not Accepted',
        icfReviewed: application.icfReviewed === true ? 'Yes' : 'No',
        symptoms: { symptoms: application.symptoms, application: application },
        medications: {
          medications: application.medications,
          application: application,
        },
        procedures: {
          procedures: getProceduresAsArray(application.procedures),
          application: application,
        },
        conditions: application.userData.otherConditions,
        comments: 'icon',
        utm: application.utm ? application.utm : 'platform',
        travelAssist: application.userData.willingToTravel ? 'Yes' : 'No',
        otherTrials: application.userData.startNewMedicationInTheLast6Month
          ? 'Yes'
          : 'No',
        records: application.records
          ? {
              study: props.study,
              application: application,
              ...application.records,
            }
          : { study: props.study, application: application, links: [] },
        share: application,
      });
    }
    const sortedData = data.sort(
      (a, b) => b.appliedAt.seconds - a.appliedAt.seconds,
    );
    setData(sortedData);
    setFilteredData(sortedData);
    setLoadingTable(false);
  }, [applicationsToShow]);

  const getProceduresAsArray = (procedures) => {
    var objectConstructor = {}.constructor;
    if (procedures.constructor === objectConstructor) {
      let proceduresArray = [];
      for (let index = 0; index < Object.keys(procedures).length; index++) {
        const procedure = Object.keys(procedures)[index];
        if (procedures[procedure]) {
          proceduresArray.push(procedure);
        }
      }
      return proceduresArray;
    } else {
      return procedures;
    }
  };

  const getStyleForStatus = (status) => {
    if (status === 'Applied') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4fb4e830',
        color: '#4fb4e8',
        textAlign: 'center',
        paddingTop: 2,
      };
    }
    if (status === 'New') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4fb4e830',
        color: '#4fb4e8',
        textAlign: 'center',
        paddingTop: 2,
      };
    }
    if (status === 'Viewed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DBDBDB',
        color: '#4fb4e8',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Completed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DDF5DD',
        color: '#57CE54',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Failed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4A4A4A',
        color: 'white',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Confirmed Appointment') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Schedule Appointment') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Pending') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DBDBDB',
        color: '#4A4A4A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'sofia-pro', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {};

  useEffect(() => {
    handleChangeSearch(search);
  }, [search]);

  const timer = React.useRef(null);

  const handleChangeSearchInput = (value) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      // your logic
      setLoadingTable(true);
      setSearch(value);
    }, 500);
  };

  const handleChangeSearch = (value) => {
    let study = props.study;
    if (value === '') {
      setApplicationsToShow(study.applications);
      return;
    }
    let matchedApplications = [];
    for (let index = 0; index < study.applications.length; index++) {
      const application = study.applications[index];
      let patientName =
        application.userData.firstName.toLowerCase() +
        ' ' +
        application.userData.lastName.toLowerCase();
      let studyAddress = application.studyAddress.toLowerCase();
      let studyName = application.studyLocation.toLowerCase();
      let email = application.userData.email.toLowerCase();
      if (
        patientName.includes(value.toLowerCase()) ||
        studyAddress.includes(value.toLowerCase()) ||
        studyName.includes(value.toLowerCase()) ||
        email.includes(value.toLowerCase())
      ) {
        matchedApplications.push(application);
      }
    }
    setApplicationsToShow(matchedApplications);
  };

  return (
    <div style={{ fontFamily: "'sofia-pro', sans-serif", color: '#4fb4e8' }}>
      {/* <Typography className={classes.mobileTitle} variant="h2">Leads</Typography> */}
      <FilterDrawer
        includeStudyCenter={true}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        data={data ? data : []}
        setFilteredData={setFilteredData}
      />
      {openEditSymptomsDialog && (
        <EditSymptomsDialog
          open={true}
          setOpen={setOpenEditSymptomsDialog}
          study={props.study}
          application={applicationInEdit}
        />
      )}
      {openEditMedicationsDialog && (
        <EditMedicationsDialog
          open={true}
          setOpen={setOpenEditMedicationsDialog}
          study={props.study}
          application={applicationInEdit}
        />
      )}
      {openEditProceduresDialog && (
        <EditProceduresDialog
          open={true}
          setOpen={setOpenEditProceduresDialog}
          study={props.study}
          application={applicationInEdit}
        />
      )}
      <div style={{ flexGrow: 1, display: 'flex' }}>
        <input
          placeholder="Search"
          className={classes.searchInput}
          onChange={(e) => handleChangeSearchInput(e.target.value)}
        />
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            paddingLeft: 12,
          }}
        >
          <MButton
            className={classes.filterButton}
            onClick={() => setOpenFilter(true)}
          >
            Filter
          </MButton>
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <ShareApplicationsDialog applications={applicationsToShow} />
      </div>
      <Table
        loading={loadingTable}
        pagination={['bottomLeft', 'bottomRight']}
        scroll={{ x: mobile ? '100%' : 1545 }}
        columns={columns}
        dataSource={filteredData}
        onChange={onChange}
        style={{ marginTop: mobile ? 0 : 12, color: '#4fb4e8' }}
      />
    </div>
  );
};

export default StudyTable;
