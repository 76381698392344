import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  notEligible: {
    'fontFamily': "'sofia-pro', sans-serif",
    'borderRadius': 8,
    'backgroundColor': 'transparent',
    'color': '#4A4A4A50',
    'textTransform': 'none',
    'fontWeight': 'bold',
    'fontSize': 16,
    // boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.08)',
    'width': '100%',
    'height': 42,
    'marginTop': 12,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#4A4A4A',
    },
  },
  scheduleButton: {
    'fontFamily': "'sofia-pro', sans-serif",
    'borderRadius': 8,
    'backgroundColor': '#4fb4e8',
    'color': '#FFFFFF',
    'textTransform': 'none',
    'fontWeight': 'bold',
    'fontSize': 16,
    'marginTop': 32,
    'boxShadow': '0px 2px 12px rgba(51, 51, 51, 0.08)',
    // marginTop: 12,
    'width': '100%',
    'height': 42,
    '&:hover': {
      backgroundColor: '#4fb4e8',
    },
  },
  pageSubTitle: {
    fontSize: 15,
    color: theme.palette.primary.dark,
    textAlign: 'left',
    marginTop: 12,
    fontFamily: "'sofia-pro', sans-serif",
  },
  pageTitle: {
    // fontSize: 40,
    fontSize: mobile ? 26 : 26,
    color: theme.palette.primary.dark,
    fontWeight: 600,
    textAlign: 'center',
  },
  text: {
    // fontSize: 40,
    fontSize: mobile ? 18 : 18,
    color: theme.palette.primary.dark,
    textAlign: 'center',
    fontWeight: 400,
    marginTop: 24,
  },
  sectionTitle: {
    fontSize: 14,
    color: '#4fb4e8',
    textAlign: 'left',
    marginBottom: 12,
    letterSpacing: '2.92px',
    textTransform: 'uppercase',
  },
  summaryTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: 17,
    color: 'black',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '60%',
  },
  summaryValue: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: 17,
    color: 'rgba(60, 60, 60, 0.6)',
    display: 'inline-block',
    width: '40%',
    verticalAlign: 'middle',
    textAlign: 'right',
  },
}));

export default function AlertDialogSlide(props) {
  const navigate = useNavigate();

  const classes = useStyles();

  const handleClose = () => {
    props.setOpen(false);
  };

  const getMapImage = () => {
    return (
      'https://maps.googleapis.com/maps/api/staticmap?center=' +
      props.applicationData.nearestLocationData.locationCoordnates.lat +
      ',' +
      props.applicationData.nearestLocationData.locationCoordinates.lng +
      '&zoom=16&scale=false&size=480x171&maptype=roadmap&key=AIzaSyAPsUbMiz7op2lKfGv9_NL1cTMRhgN_jz4&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C' +
      props.applicationData.nearestLocationData.locationCoordinates.lat +
      ',' +
      props.applicationData.nearestLocationData.locationCoordinates.lng
    );
  };

  const getAddress = () => {
    return props.applicationData.studyAddress;
  };

  const navigateToReminder = () => {
    navigate('/home/schedule/' + props.applicationData.id);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ marginTop: -80, padding: 0 }}
        fullWidth={'xs'}
        maxWidth={'xs'}
      >
        <DialogContent style={{ padding: 0 }}>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        </DialogContent>
        <div
          style={{
            width: '100%',
            padding: 32,
            paddingBottom: 0,
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h2" className={classes.pageTitle}>
            Still haven’t scheduled with{' '}
            {props.applicationData.userData.firstName +
              ' ' +
              props.applicationData.userData.lastName}
            ?
          </Typography>
          <Typography variant="h2" className={classes.text}>
            You have suggested a virtual visit at{' '}
            <b>{props.applicationData.selectedTime}</b>, on{' '}
            <b>
              {moment(
                new Date(props.applicationData.selectedDate.seconds * 1000),
              ).format('LL')}
            </b>
          </Typography>
        </div>
        <div style={{ width: '100%', padding: 32 }}>
          <Button
            onClick={() => navigateToReminder()}
            className={classes.scheduleButton}
            fullWidth
          >
            Remind {props.applicationData.userData.firstName}
          </Button>
          <Button
            onClick={() => handleClose()}
            className={classes.notEligible}
            fullWidth
          >
            Nevermind
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
