import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Home.style';

import firebase from '../../../Firebase';
import PropTypes from 'prop-types';
import { AppBar, Container, Toolbar, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Outlet, useNavigate } from 'react-router-dom';
import TopMenu from '../../../Components/TopMenu/TopMenu';
import SettingMenu from './SettingMenu';
import { AuthContext } from '../../../contexts/Auth';
import { StudiesContext } from '../../../contexts/Studies';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(MuiStyleFunction);

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const HomeLayout = (props) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const classes = useStyles();

  const { userData, userType } = useContext(AuthContext);
  const { studies, setStudies } = useContext(StudiesContext);

  const [currentPage, setCurrentPage] = useState(0);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {}, [studies]);

  const logout = () => {
    setStudies([]);
    firebase
      .auth()
      .signOut()
      .then((result) => {});
  };

  useEffect(() => {
    if (mobile) {
      if (window.location.pathname.includes('/home/meeting')) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%',
      }}
    >
      {mobile && showHeader && (
        <AppBar
          position="static"
          elevation={0}
          style={{
            backgroundColor: 'white',
            boxShadow: '0px 2px 14px -4px rgba(0, 0, 0, 0.12)',
            height: 80,
          }}
        >
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <img
                alt=""
                src={require(userType === 'master'
                  ? '../../../Assets/Images/logo.png'
                  : '../../../Assets/Images/logo.png')}
                onClick={() => {
                  navigate('/home/sponsored');
                }}
                style={{
                  height: 32,
                  marginTop: 12,
                  marginLeft: 0,
                  width: 'auto',
                  cursor: 'pointer',
                }}
              />
            </Typography>
            <SettingMenu logout={logout} userData={userData} />
          </Toolbar>
        </AppBar>
      )}
      {!mobile && (
        <AppBar
          elevation={0}
          style={{ backgroundColor: 'white', position: 'sticky', top: 0 }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <Typography variant="h6" className={classes.title}>
                <img
                  alt=""
                  src={require(userType === 'master'
                    ? '../../../Assets/Images/logo.png'
                    : '../../../Assets/Images/logo.png')}
                  style={{
                    height: 32,
                    marginTop: 16,
                    marginLeft: 0,
                    width: 'auto',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/home/sponsored')}
                />
              </Typography>
              <div style={{ float: 'right' }}>
                <TopMenu
                  logout={logout}
                  style={{ float: 'right' }}
                  setCurrentPage={setCurrentPage}
                  userData={userData}
                  currentPage={currentPage}
                  type={userType}
                />
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      )}
      <div style={{ alignItems: 'center', paddingTop: mobile ? 24 : 24 }}>
        <Outlet />
      </div>
    </div>
  );
};

export default React.memo(HomeLayout);
