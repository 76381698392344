import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    maxWidth: '100%',
    // marginTop: 8
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const medications = [
  'None',
  'Prednisone',
  'Azathioprine (Imuran)',
  'Mycophenylate Mofetil (CellCept)',
  'Tacrolimus (Prograf)',
  'Methotrexate',
  'Cyclosporine (Sandimmune, Neoral)',
  'Cyclophosphamide (Cytoxan, Neosar)',
  'Rituximab (Rituxan)',
  'Eculizumab (Soliris)',
  'Mestinon (Pyridostigmine)'
];

export default function MultipleSelect({setMedicationsArray, medicationsArray}) {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event) => {
    let arr = event.target.value
    if (arr.includes('None')) {
      if (arr[arr.length - 1] === 'None') {
        setMedicationsArray(['None'])
        return
      } else {
        arr.splice(event.target.value.indexOf('None'), 1)
        setMedicationsArray(arr);
        return
      }
    } 
    setMedicationsArray(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={medicationsArray}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          displayEmpty
        >
          <MenuItem key={0} value={
            []
          } disabled>
            Select all that apply (optional)
          </MenuItem>
          {medications.map((medication) => (
            <MenuItem key={medication} value={medication}>
              <Checkbox checked={medicationsArray.indexOf(medication) > -1} />
              <ListItemText primary={medication} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
