import React, { useContext, useEffect, useState } from 'react';
import '../../../../node_modules/antd/dist/antd.css';
import fetchLeads from '../../../Services/LeadsService/LeadsService';
import { Button, Popover, Table } from 'antd';
import moment from 'moment';
import { StudyContext } from '../../../contexts/StudyContext.js';
import StatusIndicator from '../../../Components/common/StatusIndicator.jsx';

const columns = [
  {
    title: 'Created at',
    dataIndex: 'created_at',
    width: 150,
  },
  {
    title: 'Zendesk Id',
    dataIndex: 'id',
    width: 120,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 120,
  },
  // {
  //   title: 'Email',
  //   dataIndex: 'email',
  // },
  // {
  //   title: 'Mobile',
  //   dataIndex: 'mobile',
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (value) => <StatusIndicator status={value} />,
    width: 120,
  },
  {
    title: 'Called',
    dataIndex: 'contacted',
    width: 80,
  },
  {
    title: 'Emailed',
    dataIndex: 'contacted',
    width: 80,
  },
  {
    title: 'Texted',
    dataIndex: 'contacted',
    width: 80,
  },
  {
    title: 'Unqualified/Pending Notes',
    dataIndex: 'Unqualified/Pending Notes',
    width: 80,
    render: (result) => (
      <>
        {result === undefined || result === null || result === '' ? (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {'N/A'}
          </div>
        ) : (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <Popover
              placement="top"
              style={{ borderRadius: 10 }}
              title={() => (
                <div
                  style={{
                    color: '#4fb4e8',
                    fontSize: 11,
                    fontWeight: 900,
                    letterSpacing: 1,
                    fontFamily: "'sofia-pro', sans-serif",
                  }}
                >
                  NOTE
                </div>
              )}
              content={() => (
                <div
                  style={{
                    color: '#4A4A4A',
                    fontSize: 13,
                    fontWeight: 'normal',
                    letterSpacing: 0.24,
                    fontFamily: "'sofia-pro', sans-serif",
                    maxWidth: 340,
                  }}
                >
                  {result}
                </div>
              )}
              trigger="click"
            >
              <Button
                style={{
                  border: 'none',
                  padding: 0,
                  backgroundColor: 'none',
                  background: 'none',
                  width: 24,
                  height: 24,
                  marginTop: 0,
                }}
              >
                <img
                  alt=""
                  src={require('../../../Assets/Images/blackNoteIcon.png')}
                  width={24}
                  height={24}
                />
              </Button>
            </Popover>
          </div>
        )}
      </>
    ),
  },
  // {
  //   title: 'Disease',
  //   dataIndex: 'Disease State of Interest',
  //   width: 150,
  // },
  // {
  //   title: 'Updated at',
  //   dataIndex: 'updated_at',
  // },
  // {
  //   title: 'Age of Diagnosis',
  //   dataIndex: 'Age of Diagnosis',
  // },
];

const LeadsScreen = (props) => {
  const { study, leads, deals, loading } = useContext(StudyContext);

  useEffect(() => {
    console.log('study', study);
  }, [study]);

  const removeDuplicates = (array, property) => {
    // Create an object to store unique objects based on the specified property
    const uniqueObjectMap = {};

    // Filter the array to keep only the first occurrence of each unique id
    const uniqueArray = array.filter((obj) => {
      const key = obj[property];
      // If the key (id) is not already in the map, add it and return true to keep the object
      if (!uniqueObjectMap[key]) {
        uniqueObjectMap[key] = true;
        return true;
      }
      // If the key (id) is already in the map, return false to filter out the duplicate object
      return false;
    });

    return uniqueArray;
  };

  return (
    <div style={{ fontFamily: "'sofia-pro', sans-serif", color: '#4fb4e8' }}>
      <Table
        loading={loading}
        // pagination={['bottomLeft', 'bottomRight']}
        scroll={{ x: 1545 }}
        columns={columns}
        dataSource={[
          ...removeDuplicates(deals, 'id'),
          ...removeDuplicates(leads, 'id'),
        ].sort(
          (a, b) =>
            moment(b.created_at).valueOf() - moment(a.created_at).valueOf(),
        )}
        pagination={{
          pageSize: 10, // Set the number of rows per page
          alpageSizeOptions: ['10'],
        }}
      />
    </div>
  );
};

export default LeadsScreen;
