import React, { createContext, useState } from 'react';
import StudyService from '../Services/StudyService/StudyService';
import fetchLeads from '../Services/LeadsService/LeadsService';
import moment from 'moment';

export const StudyContext = createContext();

function StudyContextProvider(props) {
  const [centers, setCenters] = useState([]);
  const [nctId, setNctId] = useState(null);
  const [studyRef, setStudyRef] = useState(null);
  const [study, setStudy] = useState(null);
  const [otherCenters, setOtherCenters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leads, setLeads] = useState([]);
  const [deals, setDeals] = useState([]);

  const removeDuplicates = (array, property) => {
    // Create an object to store unique objects based on the specified property
    const uniqueObjectMap = {};

    // Filter the array to keep only the first occurrence of each unique id
    const uniqueArray = array.filter((obj) => {
      const key = obj[property];
      // If the key (id) is not already in the map, add it and return true to keep the object
      if (!uniqueObjectMap[key]) {
        uniqueObjectMap[key] = true;
        return true;
      }
      // If the key (id) is already in the map, return false to filter out the duplicate object
      return false;
    });

    return uniqueArray;
  };

  React.useEffect(() => {
    if (nctId === null) return;
    if (studyRef === null) return;
    getCenters();
  }, [nctId, studyRef]);

  React.useEffect(() => {
    if (study === null) return;
    setLeads([]);
    setDeals([]);
    setStudyRef(study.document.ref);
    getDeals();
    getLeads();
  }, [study]);

  React.useEffect(() => {
    if (studyRef === null) return;
    getCenters();
  }, [studyRef]);

  const reformatContacted = (status, data = {}) => {
    if (status === 'Unqualified') {
      return data?.custom_fields.lastStatus
        ? reformatContacted(data?.custom_fields.lastStatus)
        : 3;
    }
    if (status.includes('1')) return 1;
    if (status.includes('2')) return 2;
    if (status.includes('3')) return 3;
    return 0;
  };

  const reformatStatus = (status) => {
    if (status === 'Unqualified') return 'Failed';
    if (status === 'Pending') return status;
    return 'Active';
  };

  const getLeads = async (
    url = 'https://api.getbase.com/v2/leads?per_page=100',
    params = {
      'custom_fields[Disease State of Interest]':
        study.studyData.zendeskDisease,
    },
  ) => {
    if (!study.studyData?.zendeskDisease) {
      setLoading(false);
      setDeals([]);
      setLeads([]);
      return;
    }
    let data = await fetchLeads(url, params);
    if (data) {
      const items = data.items;
      const fromTimestamp = moment(study.studyData.zendeskStartDate).valueOf();
      data.items = items.filter(
        (item) => moment(item.data.created_at).valueOf() > fromTimestamp,
        // item.data.tags.includes('bp1'),
      );
      setLeads((l) =>
        removeDuplicates(
          [
            ...l,
            ...data.items.map((item) => ({
              ...item.data,
              ...item.data.custom_fields,
              name: `${item.data.first_name} ${item.data.last_name}`,
              created_at: moment(item.data.created_at).format('lll'),
              updated_at: moment(item.data.updated_at).format('lll'),
              status: reformatStatus(item.data.status),
              contacted: reformatContacted(item.data.status, item.data),
            })),
          ],
          'id',
        ),
      );
      if (data.meta.links.next_page) {
        getLeads(data.meta.links.next_page, params);
      }
    }
    setLoading(false);
  };

  const getDeals = async (
    url = 'https://api.getbase.com/v2/deals?per_page=100',
    params = {
      'custom_fields[Disease State of Interest]':
        study.studyData.zendeskDisease,
    },
  ) => {
    if (!study.studyData?.zendeskDisease) {
      setDeals([]);
      setLeads([]);
      setLoading(false);
      return;
    }
    let data = await fetchLeads(url, params);
    if (data) {
      const items = data.items;
      const fromTimestamp = moment(study.studyData.zendeskStartDate).valueOf();
      data.items = items.filter(
        (item) => moment(item.data.created_at).valueOf() >= fromTimestamp,
      );
      setDeals((d) => [
        ...removeDuplicates(d, 'id'),
        ...data.items.map((item) => ({
          ...item.data,
          ...item.data.custom_fields,
          name: item.data.name,
          created_at: moment(item.data.created_at).format('lll'),
          updated_at: moment(item.data.updated_at).format('lll'),
          email: item.data.dropbox_email,
          contacted: '3',
          status: 'Applied',
        })),
      ]);
      if (data.meta.links.next_page) {
        getDeals(data.meta.links.next_page, params);
      }
    }
    setLoading(false);
  };

  const updateCenterDataByRef = async (ref, data) => {
    await StudyService.updateCenterByRef(ref, data);
    getCenters();
  };

  const getCenters = async () => {
    if (!studyRef) return;
    setLoading(true);
    let centers = await StudyService.getCentersByStudyRef(studyRef);
    setCenters([...centers]);
    let locationFacilities = [];
    for (let index = 0; index < centers.length; index++) {
      const center = centers[index];
      locationFacilities.push(center.data().LocationFacility);
    }
    let otherStudies = await StudyService.getStudyDataByNCTID(
      nctId,
      locationFacilities,
    );
    setOtherCenters([...otherStudies]);
    setLoading(false);
  };

  return (
    <StudyContext.Provider
      value={{
        centers,
        setCenters,
        otherCenters,
        setOtherCenters,
        loading,
        setLoading,
        nctId,
        setNctId,
        updateCenterDataByRef,
        getCenters,
        studyRef,
        setStudyRef,
        study,
        setStudy,
        leads,
        deals,
      }}
    >
      <div>{props.children}</div>
    </StudyContext.Provider>
  );
}

export default StudyContextProvider;
