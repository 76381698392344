import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { StudyContext } from '../../../contexts/StudyContext';

const useStyles = makeStyles(MuiStyleFunction);

const StudyCenterStatus = ({ studyCenterRef, studyCenterData }) => {
  const classes = useStyles();
  const { updateCenterDataByRef } = React.useContext(StudyContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const select = (data) => {
    updateCenterDataByRef(studyCenterRef, data);
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
        }}
        className={
          studyCenterData.status === 'Live'
            ? classes.liveStatusBox
            : studyCenterData.status === 'Paused' ||
              studyCenterData.status === 'paused'
            ? classes.pausedStatusBox
            : studyCenterData.status === 'Pending'
            ? classes.pendingStatusBox
            : studyCenterData.status === 'Opted Out'
            ? classes.optedOutStatusBox
            : classes.optedOutStatusBox
        }
        onClick={handleClick}
      >
        <Typography variant="h4" className={classes.status}>
          {studyCenterData.status}
        </Typography>
        <img
          alt=""
          className={classes.status}
          src={require('../../../Assets/Images/whiteArrowDown.png')}
          width={8.41}
          height={9.13}
          style={{ marginLeft: 5 }}
        />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => select({ status: 'Live' })}>
          <div className={classes.liveStatusBox} onClick={handleClick}>
            <Typography variant="h4" className={classes.status}>
              Live
            </Typography>
          </div>
        </MenuItem>
        <MenuItem onClick={() => select({ status: 'Paused' })}>
          <div className={classes.pausedStatusBox} onClick={handleClick}>
            <Typography variant="h4" className={classes.status}>
              Paused
            </Typography>
          </div>
        </MenuItem>
        <MenuItem onClick={() => select({ status: 'Pending' })}>
          <div className={classes.pendingStatusBox} onClick={handleClick}>
            <Typography variant="h4" className={classes.status}>
              Pending
            </Typography>
          </div>
        </MenuItem>
        <MenuItem onClick={() => select({ status: 'Opted Out' })}>
          <div className={classes.optedOutStatusBox} onClick={handleClick}>
            <Typography variant="h4" className={classes.status}>
              Opted Out
            </Typography>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default StudyCenterStatus;
