import axios from 'axios';

export default {
    getLocationCoordinatesAndFormattedAddress
}

async function getLocationCoordinatesAndFormattedAddress(location) {
    const address = location + ', United States'
    var link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + location + ":US&key=AIzaSyBU_iDguwAqFTyYxbKRNR87Xn4SuHYGWH4"
    const response = await axios.get(link);
    if (response.data.results.length > 0) {
      var locationData = {lat: response.data.results[0].geometry.location.lat, lng: response.data.results[0].geometry.location.lng, formattedAddress: response.data.results[0].formatted_address}
      return locationData
    }
}